import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Link, ButtonGroup, Menu, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    width: '80px',
  },
});

const CustomButton = styled(Button)({
  height: '40px',
  minWidth: '240px',
  fontSize: '0.8rem',
});

const InputPlataRata = ({ rateID, onPaymentUpdate, creeazaLinkDePlata }) => {
  const [sumaAchitata, setSumaAchitata] = useState('');
  const [linkPlata, setLinkPlata] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedActionIndex, setSelectedActionIndex] = useState(0);
  const isMenuOpen = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileURL, setFileURL] = useState(null);

  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, index) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedActionIndex(index);
    setAnchorEl(null);
  };

  const handlePlataSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/llp/inregistreaza-plata', {
        rateID,
        suma: sumaAchitata,
        dataPlata: new Date().toISOString().slice(0, 10),
        metoda: 'Online',
      });

      onPaymentUpdate?.(rateID, parseFloat(sumaAchitata));

      // Verificăm și actualizăm statusul produsului
      const issue_id = rateID.split('Rata')[0];
      await axios.post('https://server-9p4hx.kinsta.app/api/llp/actualizeaza-status-produs', { issue_id });

      alert('Plata a fost înregistrată cu succes.');
      setSumaAchitata('');
    } catch (error) {
      console.error('Eroare la înregistrarea plății:', error);
      alert('Eroare la înregistrarea plății. Verificați consola pentru detalii.');
    }
  };

  const handleCreeazaLinkDePlataClick = async () => {
    try {
      const link = await creeazaLinkDePlata(rateID, parseFloat(sumaAchitata));
      setLinkPlata(link);
    } catch (error) {
      console.error('Eroare la crearea linkului de plată:', error);
      alert('Eroare la crearea linkului de plată. Verificați consola pentru detalii.');
    }
  };

  const genereazaFisaClient = async () => {
    try {
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/llp/genereaza-fisa-client', {
        rateID,
      }, {
        responseType: 'blob',
      });

      const pdfURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      setFileURL(pdfURL);
      setOpenDialog(true);
    } catch (error) {
      console.error('Eroare la generarea fișei de client:', error);
      alert('Eroare la generarea fișei de client. Verificați consola pentru detalii.');
    }
  };

  const trimiteFisaLaClient = async () => {
    try {
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/llp/trimite-fisa-client', {
        rateID,
      });
      alert('Fișa a fost trimisă la client cu succes.');
      setOpenDialog(false);
    } catch (error) {
      console.error('Eroare la trimiterea fișei de client:', error);
      if (error.response) {
        console.error('Răspunsul erorii:', error.response.data);
      }
      alert('Eroare la trimiterea fișei de client. Verificați consola pentru detalii.');
    }
  };

  const actions = [
    { label: 'Înregistrează Plata', action: handlePlataSubmit },
    { label: 'Link plată', action: handleCreeazaLinkDePlataClick },
    { label: 'Generează fișă', action: genereazaFisaClient },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <CustomTextField
          type="number"
          value={sumaAchitata}
          onChange={(e) => setSumaAchitata(e.target.value)}
          placeholder="RON"
          variant="outlined"
          size="small"
        />
        <ButtonGroup variant="contained" aria-label="split button">
          <CustomButton onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            actions[selectedActionIndex].action(e);
          }}>{actions[selectedActionIndex].label}</CustomButton>
          <IconButton
            size="small"
            aria-controls={isMenuOpen ? 'split-button-menu' : undefined}
            aria-expanded={isMenuOpen ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleMenuClick}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </ButtonGroup>
        <Menu
          id="split-button-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(null);
          }}
        >
          {actions.map((action, index) => (
            <MenuItem key={index} onClick={(e) => handleClose(e, index)}>
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {linkPlata && (
        <Link href={linkPlata} target="_blank" rel="noopener noreferrer">
          Deschide link de plată
        </Link>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>Fișa Clientului</DialogTitle>
        <DialogContent>
          {fileURL && (
            <iframe src={fileURL} width="100%" height="500px" title="Fișa Clientului"></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">Închide</Button>
          <Button onClick={trimiteFisaLaClient} color="primary">Trimite la Client</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InputPlataRata;
