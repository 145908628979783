import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCard from './ProductCardLux';
import Pagination from 'react-bootstrap/Pagination';

function Page9() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const PRODUCTS_PER_PAGE = 20;

const fetchProducts = async (pageNumber = 1) => {
  try {
    const response = await axios.get(`https://server-9p4hx.kinsta.app/products?page=${pageNumber}&limit=${PRODUCTS_PER_PAGE}`);
    if (response.data && response.data.products && Array.isArray(response.data.products)) {
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages); // Utilizați totalPages de la server
    } else {
      console.error('Răspunsul nu este valid:', response.data);
    }
  } catch (error) {
    console.error('Eroare la încărcarea produselor', error);
  }
};

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

const generatePaginationItems = () => {
    const paginationItems = [];
    const pagesToShow = 5; // numărul maxim de pagini afișate în jurul paginii curente
    const halfPagesToShow = Math.floor(pagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfPagesToShow);
    let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    if (startPage > 1) {
        paginationItems.push(
            <Pagination.Item key="first" onClick={() => paginate(1)}>
                1
            </Pagination.Item>
        );
        paginationItems.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }

    for (let page = startPage; page <= endPage; page++) {
        paginationItems.push(
            <Pagination.Item key={page} active={page === currentPage} onClick={() => paginate(page)}>
                {page}
            </Pagination.Item>
        );
    }

    if (endPage < totalPages) {
        paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" />);
        paginationItems.push(
            <Pagination.Item key="last" onClick={() => paginate(totalPages)}>
                {totalPages}
            </Pagination.Item>
        );
    }

    return paginationItems;
};

  const handleSync = async () => {
    try {
      await axios.get('https://server-9p4hx.kinsta.app/sync-products'); // Asumăm că acesta este endpoint-ul pentru sincronizare
      fetchProducts(currentPage); // Reîncărcați produsele după sincronizare
    } catch (error) {
      console.error('Eroare la sincronizarea produselor', error);
    }
  };

  return (
    <div>
      <h1>Produse 1</h1>
      <button onClick={handleSync}>Sincronizează Produsele</button>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {products.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <Pagination>{generatePaginationItems()}</Pagination>
    </div>
  );
}

export default Page9;
