import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, MenuItem, Select, IconButton, InputAdornment, Button } from '@mui/material';
import Filters from './Filters';
import KPI from './KPI';
import ProductList from './ProductList';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import './App.css';

function Page2() {
  const ITEMS_PER_PAGE = 50; // Numărul de produse pe pagină

  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]); // Produsele afișate
  const [sortOption, setSortOption] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    axios.get('https://server-9p4hx.kinsta.app/api/masterdata/get-products-raw')
      .then(response => {
        const productsArray = response.data.filter(product => product.saved !== 1).map(product => ({
          ...product,
          id: product.id,
        }));
        setProducts(productsArray);
        setFilteredData(productsArray);
        updateDisplayedProducts(productsArray, 1);
      })
      .catch(error => {
        console.error('Eroare la citirea produselor din baza de date:', error);
      });
  }, []);

  useEffect(() => {
    updateDisplayedProducts(filteredData, currentPage);
  }, [filteredData, currentPage]);

  const updateDisplayedProducts = (data, page) => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayedProducts(data.slice(startIndex, endIndex));
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    sortProducts(event.target.value);
  };

  const handleFilterChange = (filters) => {
    let updatedData = products;

    if (filters.category) {
      updatedData = updatedData.filter((product) => product.Category === filters.category);
    }

    if (filters.brand) {
      updatedData = updatedData.filter((product) => product.Brand === filters.brand);
    }

    if (filters.condition) {
      updatedData = updatedData.filter((product) => product.Condition === filters.condition);
    }

    if (filters.model) {
      updatedData = updatedData.filter((product) => product.Model === filters.model);
    }

    if (filters.availability) {
      updatedData = updatedData.filter((product) => product.Availability === filters.availability);
    }

    setFilteredData(updatedData);
    updateDisplayedProducts(updatedData, 1);
    setCurrentPage(1);
  };

  const sortProducts = (option) => {
    let sortedData = [...filteredData];

    switch (option) {
      case 'priceAsc':
        sortedData.sort((a, b) => a.Price - b.Price);
        break;
      case 'priceDesc':
        sortedData.sort((a, b) => b.Price - a.Price);
        break;
      case 'likesAsc':
        sortedData.sort((a, b) => a.Likes - b.Likes);
        break;
      case 'likesDesc':
        sortedData.sort((a, b) => b.Likes - a.Likes);
        break;
      default:
        break;
    }

    setFilteredData(sortedData);
    updateDisplayedProducts(sortedData, currentPage);
  };

  const handleDelete = (productId) => {
    const updatedProducts = filteredData.filter((product) => product.id !== productId);
    setFilteredData(updatedProducts);
    updateDisplayedProducts(updatedProducts, currentPage);
  };

  const handleCheckboxChange = (productId, isChecked) => {
    setSelectedProducts((prevSelectedProducts) => {
      if (isChecked) {
        return [...prevSelectedProducts, productId];
      } else {
        return prevSelectedProducts.filter((id) => id !== productId);
      }
    });
  };

// Schimbăm ruta din `validate-products` în `validate-product`
const handleValidateSelectedProducts = async () => {
  const selectedProductDetails = filteredData.filter((product) =>
    selectedProducts.includes(product.id)
  );

  console.log('Produsele validate:', selectedProductDetails); // Log pentru debugging

  try {
    await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/validate-product', selectedProductDetails);
    alert('Produsele selectate au fost validate și salvate în baza de date.');
  } catch (error) {
    console.error('Eroare la validarea produselor:', error);
    alert('Eroare la validarea produselor.');
  }
};


  function Pagination({ totalProducts, productsPerPage, paginate, currentPage }) {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalProducts / productsPerPage);
    const pagesToShow = 5; // de exemplu

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    let startPage = currentPage - Math.floor(pagesToShow / 2);
    let endPage = currentPage + Math.floor(pagesToShow / 2);

    if (startPage <= 0) {
      endPage -= (startPage - 1);
      startPage = 1;
    }

    if (endPage > totalPages) {
      startPage -= (endPage - totalPages);
      endPage = totalPages;
    }

    return (
      <nav>
        <ul className="pagination">
          {currentPage > 1 && (
            <>
              <li className="page-item">
                <a onClick={() => paginate(1)} href="#!" className="page-link">1</a>
              </li>
              {startPage > 2 && <li className="page-item"><span className="page-link">...</span></li>}
            </>
          )}

          {pageNumbers.slice(startPage - 1, endPage).map(number => (
            <li key={number} className="page-item">
              <a 
                onClick={() => paginate(number)} 
                href="#!" 
                className={`page-link ${currentPage === number ? 'active' : ''}`}
              >
                {number}
              </a>
            </li>
          ))}

          {currentPage < totalPages && (
            <>
              {endPage < totalPages - 1 && <li className="page-item"><span className="page-link">...</span></li>}
              <li className="page-item">
                <a onClick={() => paginate(totalPages)} href="#!" className="page-link">{totalPages}</a>
              </li>
            </>
          )}
        </ul>
      </nav>
    );
  }

  return (
    <div>
      <Grid container spacing={2} alignItems="center" style={{ marginTop: '10px' }}>

        {/* Filtrele */}
        <Grid item xs>
          <Filters data={filteredData} onFilterChange={handleFilterChange} />
        </Grid>

        {/* Sortarea */}
        <Grid item xs={8} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControl variant="outlined">
            <InputLabel id="sort-label">Sortare</InputLabel>
            <Select
              labelId="sort-label"
              id="sort-option"
              value={sortOption}
              onChange={handleSortChange}
              label="Sortare"
              style={{ width: '250px' }}
              startAdornment={
                <InputAdornment position="start">
                  <SortIcon color="primary" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              }
            >
              <MenuItem value="">Nesortat</MenuItem>
              <MenuItem value="priceAsc">Preț (ascendent)</MenuItem>
              <MenuItem value="priceDesc">Preț (descendent)</MenuItem>
              <MenuItem value="likesAsc">Likes (ascendent)</MenuItem>
              <MenuItem value="likesDesc">Likes (descendent)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <KPI filteredProducts={filteredData} />

      <ProductList 
        products={displayedProducts} 
        onDelete={handleDelete} 
        onProductsValidated={handleValidateSelectedProducts} 
        onCheckboxChange={handleCheckboxChange} 
      />

      <Pagination 
         totalProducts={filteredData.length}
         productsPerPage={ITEMS_PER_PAGE}
         paginate={paginate}
         currentPage={currentPage}
      />
    </div>
  );
}

export default Page2;
