import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext'; // Import corect
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import axios from 'axios';

const Login = () => {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth(); // Utilizare corectă a hook-ului
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setIsAuthenticated(true);
            navigate('/');
        }
    }, [setIsAuthenticated, navigate]);

    const logDeviceInfo = async (deviceInfo) => {
        try {
            await axios.post('https://server-9p4hx.kinsta.app/log-device', { username, deviceInfo });
        } catch (error) {
            console.error('Eroare la logarea informațiilor dispozitivului:', error);
        }
    };

    const handleLogin = async () => {
        try {
            setError('');
            const deviceInfo = {
                os: navigator.platform,
                browser: navigator.userAgent,
                timestamp: new Date().toISOString(),
                ip: 'N/A',
                location: null
            };

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async position => {
                    deviceInfo.location = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    };
                    await logDeviceInfo(deviceInfo);
                    await sendLoginRequest(username, password, deviceInfo);
                }, async () => {
                    await logDeviceInfo(deviceInfo);
                    await sendLoginRequest(username, password, deviceInfo);
                });
            } else {
                await logDeviceInfo(deviceInfo);
                await sendLoginRequest(username, password, deviceInfo);
            }
        } catch (error) {
            console.error('Eroare la autentificare:', error);
            setError('Eroare la autentificare. Vă rugăm să încercați mai târziu.');
        }
    };

    const sendLoginRequest = async (username, password, deviceInfo) => {
        try {
            const response = await axios.post('https://server-9p4hx.kinsta.app/login', { username, password, deviceInfo });

            if (response.status === 200) {
                setIsAuthenticated(true);
                if (rememberMe) {
                    localStorage.setItem('authToken', response.data.token);
                }
                navigate('/');
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.message);
            } else {
                setError('Eroare la autentificare. Vă rugăm să încercați mai târziu.');
            }
        }
    };

    const isLoginButtonDisabled = !username || !password;

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    marginTop: '20vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Typography variant="h5">Autentificare</Typography>
                <TextField
                    label="Nume de utilizator"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Parolă"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {error && (
                    <Typography
                        variant="body2"
                        color="error"
                        sx={{ marginTop: 2 }}>
                        {error}
                    </Typography>
                )}
                <FormControlLabel
                    control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
                    label="Ține-mă minte"
                />
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ marginTop: 2 }}
                    onClick={handleLogin}
                    disabled={isLoginButtonDisabled}>
                    Autentificare
                </Button>
            </Box>
        </Container>
    );
};

export default Login;
