import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Link,
  Divider,
  TextField,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlockIcon from '@mui/icons-material/Block';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';

const ActeConfidentiale = ({ email, setIban, setBankName }) => {
  const [entries, setEntries] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState({});
  const [loading, setLoading] = useState(false);
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [hasRecentEntry, setHasRecentEntry] = useState(false);

  // Mapping of bank codes to bank names
  const bankCodes = {
    'RZBR': 'Raiffeisen Bank',
    'BTRL': 'Banca Transilvania',
    'BRDE': 'BRD Groupe Société Générale',
    'INGB': 'ING Bank',
    'BACX': 'Unicredit Bank',
    'RNCB': 'BCR (Banca Comercială Română)',
    'PIRB': 'First Bank (fost Piraeus Bank)',
    'BPOS': 'Bancpost',
    'CECE': 'CEC Bank',
    'OTPV': 'OTP Bank',
    'CARP': 'Patria Bank',
    'BREL': 'LIBRA BANK',
  };

  // IBAN validation function with improved bank detection
  const validateRomanianIBAN = (iban) => {
    iban = iban.replace(/\s+/g, '').toUpperCase(); // Remove spaces and uppercase

    const regex = /^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$/;
    if (!regex.test(iban)) {
      return false;
    }

    const bankCode = iban.slice(4, 8); // Extract bank code from IBAN
    const bankName = bankCodes[bankCode] || 'Necunoscută';

    if (bankCodes[bankCode]) {
      setBankName(bankName); // Set detected bank name
      setIban(iban);         // Set validated IBAN
    } else {
      console.warn(`Codul băncii "${bankCode}" nu a fost găsit în lista definită.`);
    }

    let rearranged = iban.slice(4) + iban.slice(0, 4);
    let numericIBAN = '';

    for (let char of rearranged) {
      const code = char.charCodeAt(0);
      numericIBAN += code >= 65 && code <= 90 ? code - 55 : char;
    }

    let remainder = '';
    for (let i = 0; i < numericIBAN.length; i += 7) {
      const block = remainder + numericIBAN.substring(i, i + 7);
      remainder = parseInt(block, 10) % 97;
    }

    return remainder === 1;
  };

  useEffect(() => {
    const fetchHasRecentEntry = async () => {
      try {
        const response = await axios.get('https://server-9p4hx.kinsta.app/api/contracts/has-recent-entry', {
          params: { email },
        });
        setHasRecentEntry(response.data.hasRecentEntry);
      } catch (error) {
        console.error('Error fetching recent entry status:', error);
      }
    };

    if (email) {
      fetchHasRecentEntry();
    }
  }, [email]);

  useEffect(() => {
    const fetchEntries = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://server-9p4hx.kinsta.app/api/contracts/gravityforms/entries', {
          params: { email },
        });
        const sortedEntries = response.data.entries.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );
        setEntries(sortedEntries);
      } catch (error) {
        console.error('Error fetching entries:', error);
      } finally {
        setLoading(false);
      }
    };

    if (email && passwordVerified) {
      fetchEntries();
    }
  }, [email, passwordVerified]);

  const renderEntry = (entry) => {
    const iban = entry['8'];
    let isIBANValid = false;
    let bankName = 'Necunoscută';

    if (iban) {
      isIBANValid = validateRomanianIBAN(iban);
      const bankCode = iban.replace(/\s+/g, '').toUpperCase().slice(4, 8);
      bankName = bankCodes[bankCode] || 'Necunoscută';
    }

    return (
      <Box key={entry.id} sx={{ marginBottom: '16px' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#6a0dad' }}>
          Entry ID: {entry.id} - {new Date(entry.date_created).toLocaleString()}
        </Typography>
        <Divider sx={{ marginBottom: '8px' }} />
        <Typography>
          <strong>Nume:</strong> {entry['6.3']} {entry['6.6']}
        </Typography>
        <Typography>
          <strong>Email:</strong> {entry['4']}
        </Typography>
        <Typography>
          <strong>Telefon:</strong> {entry['7']}
        </Typography>
        <Typography>
          <strong>Adresa:</strong> {entry['10.3']}, {entry['10.4']}, {entry['10.5']}, {entry['10.6']}
        </Typography>
        <Typography>
          <strong>IBAN:</strong> {iban}{' '}
          {isIBANValid ? (
            <CheckCircleIcon style={{ color: 'green', marginLeft: '8px', marginBottom: '8px' }} />
          ) : (
            <CancelIcon style={{ color: 'red', marginLeft: '8px' }} />
          )}
        </Typography>
        <Typography>
          <strong>BANCA:</strong> {bankName}
        </Typography>
        {entry['1'] && Array.isArray(entry['1']) && entry['1'].length > 0 && (
          <Box sx={{ marginTop: '8px' }}>
            <Typography>
              <strong>Fișiere încărcate:</strong>
            </Typography>
            {entry['1'].map((fileUrl, index) => (
              <Link href={fileUrl} target="_blank" key={index} sx={{ display: 'block' }}>
                {fileUrl.split('/').pop()}
              </Link>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const mostRecentEntry = entries[0];
  const olderEntries = entries.slice(1);

  const handlePasswordSubmit = () => {
    if (passwordInput === 'gheorghelazar1') {
      setPasswordVerified(true);
      setPasswordError('');
    } else {
      setPasswordError('Parolă incorectă. Încercați din nou.');
    }
  };

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ boxShadow: 'none', border: 'none' }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#6a0dad', marginRight: '8px' }}>
              Acte Confidentiale
            </Typography>
            {hasRecentEntry ? <TaskAltIcon sx={{ color: 'green' }} /> : <BlockIcon sx={{ color: 'red' }} />}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {expanded && !passwordVerified ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                Vă rugăm să introduceți parola pentru a accesa conținutul.
              </Typography>
              <TextField
                label="Parolă"
                type="password"
                value={passwordInput}
                onChange={(e) => setPasswordInput(e.target.value)}
                error={Boolean(passwordError)}
                helperText={passwordError}
                sx={{ marginBottom: '10px', width: '100%' }}
              />
              <Button variant="contained" onClick={handlePasswordSubmit}>
                Accesează
              </Button>
            </Box>
          ) : loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : mostRecentEntry ? (
            <>
              {renderEntry(mostRecentEntry)}
              {olderEntries.length > 0 &&
                olderEntries.map((entry) => {
                  const isExpanded = expandedAccordions[entry.id] || false;
                  return (
                    <Accordion
                      key={entry.id}
                      expanded={isExpanded}
                      onChange={() =>
                        setExpandedAccordions((prev) => ({
                          ...prev,
                          [entry.id]: !isExpanded,
                        }))
                      }
                      sx={{ marginTop: '10px' }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#6a0dad' }}>
                          Entry ID: {entry.id} - {new Date(entry.date_created).toLocaleDateString()}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>{renderEntry(entry)}</AccordionDetails>
                    </Accordion>
                  );
                })}
            </>
          ) : (
            <Typography>Nu există înregistrări disponibile.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ActeConfidentiale;
