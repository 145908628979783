import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Tabs,
  Tab,
  Badge,
  Card,
  CardContent,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { cyan, green, pink } from '@mui/material/colors';
import DetaliiClient from './DetaliiClient';
import CalendarScadente from './CalendarScadente';
import ScadentarClienti from './ScadentarClienti';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StatisticiDashboard from './StatisticiDashboard';

const theme = createTheme({
  palette: {
    primary: {
      main: cyan[500],
    },
    secondary: {
      main: pink[500],
    },
    success: {
      main: green[500],
    },
  },
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  '& .MuiTabs-indicator': {
    height: '100%',
    borderRadius: '12px',
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.7)',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&:hover': {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  '&.Mui-selected:hover': {
    color: '#fff',
    opacity: 1,
  },
}));

const ClientiActivitatePage = () => {
  const [clienti, setClienti] = useState([]);
  const [clientSelectat, setClientSelectat] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRate, setIsLoadingRate] = useState(false);
  const [detaliiRate, setDetaliiRate] = useState([]);
  const [tranzactii, setTranzactii] = useState({});
  const [lunaSelectata, setLunaSelectata] = useState(new Date().getMonth() + 1);
  const [anSelectat, setAnSelectat] = useState(new Date().getFullYear());
  const [searchTerm, setSearchTerm] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [isLoadingTranzactii, setIsLoadingTranzactii] = useState(false);

  const schimbaLuna = (directie) => {
    if (directie === 'inapoi') {
      if (lunaSelectata === 1) {
        setLunaSelectata(12);
        setAnSelectat(anSelectat - 1);
      } else {
        setLunaSelectata(lunaSelectata - 1);
      }
    } else if (directie === 'inainte') {
      if (lunaSelectata === 12) {
        setLunaSelectata(1);
        setAnSelectat(anSelectat + 1);
      } else {
        setLunaSelectata(lunaSelectata + 1);
      }
    }
  };

  const fetchClientiActivitate = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/llp/clienti-activitate');
      setClienti(response.data);
    } catch (error) {
      console.error('Failed to fetch client activity:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClientiActivitate();
  }, []);

  useEffect(() => {
    const fetchRateDetails = async () => {
      if (clientSelectat) {
        setIsLoadingRate(true);
        console.log('Fetching rate details for:', clientSelectat.detalii);
        const rateDetaliiPromises = clientSelectat.detalii.map(async (produs) => {
          if (!produs || !produs.issue_id) return null;
          const response = await axios.get(`https://server-9p4hx.kinsta.app/api/llp/get-llp-rate/${produs.issue_id}`);
          console.log(`Rates for ${produs.issue_id}:`, response.data);
          return {
            ...produs,
            rates: response.data,
          };
        });

        const details = await Promise.all(rateDetaliiPromises);
        console.log('Details fetched:', details);
        setDetaliiRate(details.filter((d) => d !== null));
        setIsLoadingRate(false);
      }
    };

    fetchRateDetails();
  }, [clientSelectat]);

  useEffect(() => {
    const fetchTranzactii = async () => {
      if (clientSelectat && detaliiRate.length > 0) {
        setIsLoadingTranzactii(true);
        let tranzactiiPerRate = {};
        let success = false;

        while (!success) {
          try {
            for (let detalii of detaliiRate) {
              if (!detalii || !detalii.rates) continue;
              for (let rata of detalii.rates) {
                if (!rata || !rata.rateID) continue;
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/llp/get-llp-tranzactii/${rata.rateID}`);
                tranzactiiPerRate[rata.rateID] = response.data;
              }
            }
            setTranzactii(tranzactiiPerRate);
            success = true;
          } catch (error) {
            console.error('Failed to fetch transactions for rates, retrying...', error);
            success = false;
          }
        }

        setIsLoadingTranzactii(false);
      }
    };

    fetchTranzactii();
  }, [clientSelectat, detaliiRate]);

  const onSelectClient = (client) => {
    console.log('Client selected:', client);
    setClientSelectat(client);
    setDetaliiRate([]); // Reset details when selecting a new client
  };

  const handlePaymentUpdate = async (rateID, amountPaid) => {
    const updatedDetails = detaliiRate.map((produs) => {
      if (produs.issue_id === rateID.split('Rata')[0]) {
        const rataIndex = parseInt(rateID.split('Rata')[1], 10);
        const rataKey = `suma_platita_rata_${rataIndex}`;
        return { ...produs, [rataKey]: (produs[rataKey] || 0) + amountPaid };
      }
      return produs;
    });

    setDetaliiRate(updatedDetails);

    try {
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/llp/get-llp-tranzactii/${rateID}`);
      setTranzactii((prevTranzactii) => ({
        ...prevTranzactii,
        [rateID]: response.data,
      }));
    } catch (error) {
      console.error(`Failed to fetch transactions for ${rateID}:`, error);
    }
  };

  const creeazaLinkDePlata = async (rateID, sumaAchitata) => {
    console.log('rateID trimis:', rateID); // Adaugă acest log
    console.log('sumaAchitata trimisă:', sumaAchitata);
    try {
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/llp/creaza-link-plata', {
        rateID,
        suma: sumaAchitata,
      });
      return response.data.link;
    } catch (error) {
      console.error('Eroare la crearea linkului de plată:', error);
      if (error.response) {
        console.error('Detalii răspuns eroare:', error.response.data);
      }
      alert('Eroare la crearea linkului de plată.');
    }
  };


  const handleSetLivrat = async (issue_id) => {
    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/llp/set-livrat', { issue_id });
      // Actualizează starea clientului în mod local
      setClienti((prevClienti) => {
        const updatedClienti = prevClienti.map((client) => {
          const updatedDetalii = client.detalii.map((produs) => {
            if (produs.issue_id === issue_id) {
              return { ...produs, livratLLP: 1 };
            }
            return produs;
          });
          return { ...client, detalii: updatedDetalii };
        });
        return updatedClienti;
      });
    } catch (error) {
      console.error('Failed to set product as delivered:', error);
      alert('Eroare la setarea produsului ca livrat.');
    }
  };

  const handleSetAnulat = async (issue_id) => {
    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/llp/set-anulat', { issue_id });
      // Actualizează starea clientului în mod local
      setClienti((prevClienti) => {
        const updatedClienti = prevClienti.map((client) => {
          const updatedDetalii = client.detalii.map((produs) => {
            if (produs.issue_id === issue_id) {
              return { ...produs, anulatLLP: 1 };
            }
            return produs;
          });
          return { ...client, detalii: updatedDetalii };
        });
        return updatedClienti;
      });
    } catch (error) {
      console.error('Failed to set product as canceled:', error);
      alert('Eroare la setarea produsului ca anulat.');
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const calculateRateRamase = (rates) => {
    if (!rates) return 0;
    const rateNeachitate = rates.filter((rata) => rata && rata.suma_totala > rata.suma_platita);

    const ultimaRata = rates[rates.length - 1];
    const lunaCurenta = new Date().getMonth() + 1;
    const anCurent = new Date().getFullYear();
    const lunaRata = new Date(ultimaRata?.data_scadentei).getMonth() + 1;
    const anRata = new Date(ultimaRata?.data_scadentei).getFullYear();

    if (
      ultimaRata &&
      lunaRata === lunaCurenta &&
      anRata === anCurent &&
      ultimaRata.suma_totala <= ultimaRata.suma_platita
    ) {
      return (
        <span style={{ color: 'green' }}>
          <LocalShippingIcon style={{ verticalAlign: 'middle', marginRight: '4px' }} />
          Pregătit pentru livrare
        </span>
      );
    }

    return rateNeachitate.length;
  };

  const calculateSumaRestanta = (rates) => {
    if (!rates) return 0;
    const sumaRestanta = rates.reduce(
      (total, rata) => (rata ? total + (rata.suma_totala - rata.suma_platita) : total),
      0
    );
    return sumaRestanta.toFixed(2);
  };

  const calculateArticole = (client) => {
    return client.detalii.length;
  };

  const filteredClienti = clienti.filter(
    (client) =>
      client.nume.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !client.detalii.every((detalii) => detalii.livratLLP === 1 || detalii.anulatLLP === 1)
  );

  const clientiActive = filteredClienti.filter((client) =>
    client.detalii.some((detalii) => detalii.livratLLP !== 1 && detalii.anulatLLP !== 1)
  );

  const clientiLivrare = clienti.filter((client) =>
    client.detalii.some((detalii) => detalii.livratLLP === 1)
  );

  const clientiAnulat = clienti.filter((client) =>
    client.detalii.some((detalii) => detalii.anulatLLP === 1)
  );

  const currentMonth = new Date().getMonth() + 1;
  const clientiLivrareLunaCurenta = clientiLivrare.filter((client) =>
    client.detalii.some((detalii) => new Date(detalii.dataAdaugare).getMonth() + 1 === currentMonth)
  ).length;
  const clientiAnulatLunaCurenta = clientiAnulat.filter((client) =>
    client.detalii.some((detalii) => new Date(detalii.dataAdaugare).getMonth() + 1 === currentMonth)
  ).length;

  console.log('Clienti Livrare: ', clientiLivrare);

  return (
    <ThemeProvider theme={theme}>
      <Container style={{ marginTop: '20px', maxWidth: 'none', width: '100%' }}>
        <StatisticiDashboard lunaSelectata={lunaSelectata} anSelectat={anSelectat} />
      </Container>
      <Container style={{ marginTop: '20px', maxWidth: 'none', width: '100%' }}>

        <CalendarScadente lunaSelectata={lunaSelectata} anSelectat={anSelectat} style={{ width: '100%' }} />
      </Container>
      <Container style={{ marginTop: '20px', maxWidth: 'none', width: '100%' }}>
        <ScadentarClienti
          lunaSelectata={lunaSelectata}
          anSelectat={anSelectat}
          schimbaLuna={schimbaLuna}
        />
      </Container>
      <Container style={{ marginTop: '20px', maxWidth: 'none', width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Activitate Clienți
        </Typography>
        <TextField
          label="Caută după nume"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <StyledTabs
              value={tabValue}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <StyledTab label="Active" />
              <StyledTab
                label={
                  <Badge
                    badgeContent={clientiLivrare.length}
                    color="primary"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    Livrare
                  </Badge>
                }
              />
              <StyledTab
                label={
                  <Badge
                    badgeContent={clientiAnulat.length}
                    color="primary"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    Anulat
                  </Badge>
                }
              />
            </StyledTabs>
            <Grid container spacing={3} justifyContent="space-between" style={{ marginTop: '20px' }}>
              {tabValue === 0 && (
                <Grid item xs={12} md={6}>
                  <Card elevation={3} sx={{ borderRadius: '12px' }}>
                    <CardContent>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '30%' }}>Nume și Prenume</TableCell>
                              <TableCell>Suma Restantă</TableCell>
                              <TableCell>Articole</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {clientiActive.map((client) => (
                              <TableRow key={client.nume} onClick={() => onSelectClient(client)}>
                                <TableCell>{client.nume}</TableCell>
                                <TableCell>
                                  {calculateSumaRestanta(
                                    detaliiRate
                                      .filter((detalii) => detalii.issue_id === client.detalii[0]?.issue_id)
                                      .flatMap((detalii) => detalii.rates || [])
                                  )}
                                </TableCell>
                                <TableCell>{calculateArticole(client)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {tabValue === 1 && (
                <Grid item xs={12} md={7}>
                  <Card elevation={3} sx={{ borderRadius: '12px' }}>
                    <CardContent>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '30%' }}>Nume și Prenume</TableCell>
                              <TableCell>Nr Comanda</TableCell>
                              <TableCell>SKU</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {clientiLivrare.flatMap((client) =>
                              (client.detalii || [])
                                .filter((produs) => produs.livratLLP === 1)
                                .map((produs, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell>{client.nume}</TableCell>
                                    <TableCell>{produs.nr_comanda}</TableCell>
                                    <TableCell>{produs.sku}</TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {tabValue === 2 && (
                <Grid item xs={12} md={7}>
                  <Card elevation={3} sx={{ borderRadius: '12px' }}>
                    <CardContent>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: '30%' }}>Nume și Prenume</TableCell>
                              <TableCell>Nr Comanda</TableCell>
                              <TableCell>SKU</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {clientiAnulat.flatMap((client) =>
                              (client.detalii || [])
                                .filter((produs) => produs.anulatLLP === 1)
                                .map((produs, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell>{client.nume}</TableCell>
                                    <TableCell>{produs.nrComanda}</TableCell>
                                    <TableCell>{produs.sku}</TableCell>
                                  </TableRow>
                                ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                {clientSelectat && (isLoadingRate || isLoadingTranzactii ? (
                  <CircularProgress />
                ) : (
                  <Card elevation={3} xs={12} md={9} sx={{ borderRadius: '12px' }}>
                    <CardContent>
                      <DetaliiClient
                        client={clientSelectat}
                        detaliiRate={detaliiRate}
                        tranzactii={tranzactii}
                        onPaymentUpdate={handlePaymentUpdate}
                        creeazaLinkDePlata={creeazaLinkDePlata}
                        handleSetLivrat={handleSetLivrat}
                        handleSetAnulat={handleSetAnulat}
                      />
                    </CardContent>
                  </Card>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default ClientiActivitatePage;
