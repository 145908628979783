import React, {useEffect, useState} from 'react';
import {
    Table,
    FormControl, InputLabel, Select,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    MenuItem,
    TableRow,
    Paper,
    Button,
    Container,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    InputAdornment,
    Fab,
    Box,
    IconButton,
    Tabs,
    Tab,
    Grid
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';
import {styled} from '@mui/material/styles';
import {Card, CardContent, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';

const KpiCard = ({title, value}) => (
    <Card
        sx={{
        minWidth: 275,
        margin: 2,
        textAlign: 'center'
    }}>
        <CardContent>
            <Typography color="textSecondary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h5" component="h2">
                {value}
            </Typography>
        </CardContent>
    </Card>
);

// Stiluri personalizate
const StyledFab = styled(Fab)({position: 'fixed', bottom: 16, right: 16});

const Page8 = () => {
    const [searchTerm,
        setSearchTerm] = useState('');
    const [orders,
        setOrders] = useState([]);
    const [loading,
        setLoading] = useState(true);
    const [expanded,
        setExpanded] = useState(null);
    const [addDialogOpen,
        setAddDialogOpen] = useState(false);
    const [editDialogOpen,
        setEditDialogOpen] = useState(false);
    const [selectedOrderId,
        setSelectedOrderId] = useState(null);
    const [selectedDetailId,
        setSelectedDetailId] = useState(null);
    const [selectedOrderDetails,
        setSelectedOrderDetails] = useState({Categorie: '', Brand: '', Data: '', Pret: '', Moneda: 'RON'});
    const [selectedTab,
        setSelectedTab] = useState(0); // 0 pentru "Listari Gratuite", 1 pentru "Listari Achizitionate"
    const [freeListings,
        setFreeListings] = useState([]);
    const [searchTermTab0,
        setSearchTermTab0] = useState('');

    const [addFreeListingDialogOpen,
        setAddFreeListingDialogOpen] = useState(false);
    const [newFreeListingDetail,
        setNewFreeListingDetail] = useState({Categorie: '', Brand: '', Data: '', Pret: '', Moneda: 'RON'});

    const handleEditFreeListingClick = (detail) => {
        setSelectedFreeListingDetail({idDetaliu: detail.idDetaliu, Categorie: detail.Categorie, Brand: detail.Brand, Data: detail.Data, Pret: detail.Pret, Moneda: detail.Moneda});
        setEditFreeListingDialogOpen(true);
    };

    const [currentPage,
        setCurrentPage] = useState(1);
    const [itemsPerPage,
        setItemsPerPage] = useState(20);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const handleEditFreeListingDialogClose = () => {
        setEditFreeListingDialogOpen(false);
    };

    // Resetarea la prima pagină după fiecare căutare
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTermTab0]);

    const handleEditFreeListingDetailsSubmit = async() => {
        console.log("Trimite la server:", selectedFreeListingDetail);
        try {
            console.log("Trimite la server:", selectedFreeListingDetail);
            const response = await fetch(`https://server-9p4hx.kinsta.app/update-free-listing-details/${selectedFreeListingDetail.idDetaliu}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(selectedFreeListingDetail)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setEditFreeListingDialogOpen(false);
            loadFreeListings();

        } catch (error) {
            console.error('Error updating free listing details:', error);
        }
    };

    const [editFreeListingDialogOpen,
        setEditFreeListingDialogOpen] = useState(false);
    const [selectedFreeListingDetail,
        setSelectedFreeListingDetail] = useState({idDetaliu: null, Categorie: '', Brand: '', Data: '', Pret: '', Moneda: 'RON'});

    const handleAddFreeListingClick = (listing) => {
        setSelectedOrderId(listing.NrComanda); // presupunând că ai această stare definită
        setAddFreeListingDialogOpen(true);
    };

    const handleAddFreeListingDialogClose = () => {
        setAddFreeListingDialogOpen(false);
    };

    const [addOrderDialogOpen,
        setAddOrderDialogOpen] = useState(false);
    const [newOrder,
        setNewOrder] = useState({
        NumePrenume: '',
        DataComenzii: new Date()
            .toISOString()
            .slice(0, 10), // Data curentă formatată ca YYYY-MM-DD
        NrProduse: '',
        Status: 'Valid',
        NrComanda: 'LCE0001' // Valoare inițială
    });

    const handleOpenAddOrderDialog = async() => {
        const nextOrderNumber = await fetchNextOrderNumber();
        setNewOrder({
            ...newOrder,
            NrComanda: nextOrderNumber
        });
        setAddOrderDialogOpen(true);
    };

    const fetchNextOrderNumber = async() => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/next-order-number');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data.nextOrderNumber;
        } catch (error) {
            console.error('Error fetching next order number:', error);
            return 'LCE0001'; // O valoare implicită în caz de eroare
        }
    };

    const handleAddOrder = async() => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/add-free-listing', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newOrder)
            });

            if (!response.ok) {
                throw new Error('Eroare la adăugarea comenzii noi');
            }

            // Adăugarea a fost un succes, reîncarcă lista
            await loadFreeListings();
            setAddOrderDialogOpen(false); // Închide dialogul după adăugare
        } catch (error) {
            console.error('Eroare la adăugarea comenzii:', error);
            // Gestionare eroare
        }
    };

    const handleAddFreeListingDetailsSubmit = async() => {
        try {
            const response = await fetch(`https://server-9p4hx.kinsta.app/add-order-detail-gratuit/${selectedOrderId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...newFreeListingDetail,
                    NrComanda: selectedOrderId
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setAddFreeListingDialogOpen(false);
            await loadFreeListings(); // Reîncarcă lista pentru a reflecta schimbările

        } catch (error) {
            console.error('Error adding free listing details:', error);
        }
    };

    const loadFreeListings = async() => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/get-free-listings');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const listings = await response.json();

            setFreeListings(listings.map(listing => ({
                ...listing,
                DetaliiComanda: []
            })));
        } catch (error) {
            console.error('Error fetching free listings:', error);
        }
    };

    const totalNrProduse = freeListings.reduce((sum, listing) => sum + parseInt(listing.NrProduse, 10), 0);
    const totalListariGratuite = freeListings.length;
    const valoareEstimata = totalNrProduse * 5; // 5 lei pe produs
    const listariInchise = freeListings.filter(listing => parseInt(listing.NrProduse, 10) === 0).length;

    const loadOrders = async() => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/get-orders');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let ordersFromDb = await response.json();

            for (const order of ordersFromDb) {
                const detailsResponse = await fetch(`https://server-9p4hx.kinsta.app/get-order-details/${order.NrComanda}`);
                if (detailsResponse.ok) {
                    const details = await detailsResponse.json();
                    order.DetaliiComanda = details; // Adaugă detaliile la comandă
                }
            }

            setOrders(ordersFromDb);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFetchAndSaveNewOrders = async() => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/fetch-and-save-orders');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            loadOrders();
        } catch (error) {
            console.error('Error fetching and saving new orders:', error);
        }
    };

    const handleExpandChange = (orderNrComanda) => async(event, isExpanded) => {
        setExpanded(isExpanded
            ? orderNrComanda
            : null);

        if (selectedTab === 0) {
            // Pentru ListariGratuite
            const listing = freeListings.find(listing => listing.NrComanda === orderNrComanda);
            if (isExpanded && listing && (!listing.DetaliiComanda || listing.DetaliiComanda.length === 0)) {
                await loadFreeListingDetails(orderNrComanda);
            }
        } else {
            // Pentru Comenzi

            const order = orders.find(order => order.NrComanda === orderNrComanda);
            if (isExpanded && order && (!order.DetaliiComanda || order.DetaliiComanda.length === 0)) {
                await loadOrderDetails(orderNrComanda, orders, setOrders);
            }
        }
    };

    const loadFreeListingDetails = async(nrComanda) => {
        try {
            const response = await fetch(`https://server-9p4hx.kinsta.app/get-free-listing-details/${nrComanda}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const detailsFromDb = await response.json();

            setFreeListings(freeListings => freeListings.map(listing => listing.NrComanda === nrComanda
                ? {
                    ...listing,
                    DetaliiComanda: detailsFromDb
                }
                : listing));
        } catch (error) {
            console.error('Error fetching free listing details:', error);
        }
    };

    const loadOrderDetails = async(orderNrComanda, sourceArray, updateFunction) => {
        try {
            console.log(`Fetching details for NrComanda: ${orderNrComanda}`);
            const response = await fetch(`https://server-9p4hx.kinsta.app/get-order-details/${orderNrComanda}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const detailsFromDb = await response.json();

            console.log(`Detalii extrase pentru ${orderNrComanda}:`, detailsFromDb);

            // Actualizează sursa cu detaliile încărcate
            const updatedArray = sourceArray.map(order => order.NrComanda === orderNrComanda
                ? {
                    ...order,
                    DetaliiComanda: detailsFromDb
                }
                : order);
            updateFunction(updatedArray);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const handleAddOrderDetails = async(detaliiComanda, nrComanda) => {
        try {
            const response = await fetch(`https://server-9p4hx.kinsta.app/add-order-detail-gratuit/${nrComanda}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(detaliiComanda)
            });

            if (!response.ok) {
                throw new Error('Eroare la adăugarea detaliilor comenzii');
            }

            await loadListariGratuite();

        } catch (error) {
            console.error('Eroare la adăugarea detaliilor comenzii:', error);
        }
    };

    const loadListariGratuite = async() => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/get-free-listings'); // Înlocuiește cu URL-ul corect al API-ului tău
            if (!response.ok) {
                throw new Error('Eroare la preluarea listărilor gratuite');
            }
            const data = await response.json();
            setFreeListings(data); // Actualizează starea cu datele noi
        } catch (error) {
            console.error('Eroare la încărcarea listărilor gratuite:', error);
            // Aici poți trata eroarea, de exemplu, afișând un mesaj în interfața
            // utilizatorului
        }
    };

    // Funcție pentru încărcarea și filtrarea Listărilor Gratuite
    const loadAndFilterFreeListings = async () => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/get-free-listings');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let listings = await response.json();

            // Filtrarea listărilor gratuite în funcție de termenul de căutare
            if (searchTermTab0) {
                listings = listings.filter(listing =>
                    listing.NrComanda.toLowerCase().includes(searchTermTab0.toLowerCase()) ||
                    listing.NumePrenume.toLowerCase().includes(searchTermTab0.toLowerCase())
                    // ... alte condiții de filtrare după necesitate
                );
            }

            setFreeListings(listings);
        } catch (error) {
            console.error('Error fetching free listings:', error);
        }
    };

    // useEffect actualizat pentru a utiliza noua funcție
useEffect(() => {
    loadOrders();
    if (selectedTab === 0) {
        loadAndFilterFreeListings();
    }
    const interval = setInterval(loadOrders, 30000);
    return () => clearInterval(interval);
}, [selectedTab, searchTermTab0]); // Adaugă 'searchTermTab0' ca dependență

    const handleAddClick = (order) => {
        setSelectedOrderId(order.NrComanda);
        setAddDialogOpen(true);
    };

    const handleEditClick = (detail) => {
        setSelectedDetailId(detail.idDetaliu); // Setează idDetaliu pentru editare
        setSelectedOrderDetails({
            Categorie: detail.Categorie || '',
            Brand: detail.Brand || '',
            Data: detail.Data || '',
            Pret: detail.Pret || '',
            Moneda: detail.Moneda || '',
            idDetaliu: detail.idDetaliu, // Nu uita să incluzi idDetaliu
        });
        setEditDialogOpen(true);
    };

    const onSubmitEdit = () => {
        handleEditDetails(selectedDetailId, selectedOrderDetails);
        handleEditDialogClose(); // Închide dialogul de editare după trimiterea datelor
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const handleAddDetails = async() => {
        try {
            const response = await fetch('https://server-9p4hx.kinsta.app/add-order-details', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    NrComanda: selectedOrderId,
                    ...selectedOrderDetails, // aici Data ar trebui să fie deja în formatul YYYY-MM-DD
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Închide dialogul după finalizarea cu succes
            setAddDialogOpen(false);

            // Reîncarcă detaliile comenzilor pentru a reflecta schimbările
            loadOrders();

        } catch (error) {
            console.error('Error adding order details:', error);
            // Poți alege să lași dialogul deschis dacă există o eroare
        }
    };

    console.log("selectedOrderDetails la apelul handleEditClick:", selectedOrderDetails);
    const handleEditDetails = async(idDetaliu, updatedDetails) => {
        console.log("idDetaliu (înainte de request):", idDetaliu);
        console.log("Detalii actualizate (înainte de request):", updatedDetails);

        try {
            const response = await fetch(`https://server-9p4hx.kinsta.app/update-order-details/${idDetaliu}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedDetails)
            });

            if (!response.ok) {
                const errorResponse = await response.text();
                console.error('Error response body:', errorResponse);
                throw new Error('Network response was not ok');
            }

            // Închide dialogul și reîncarcă comenzile după actualizare
            setEditDialogOpen(false);
            loadOrders();

        } catch (error) {
            console.error('Error updating order details:', error);
        }
    };

    const handleDeleteDetails = async(idDetaliu) => {
        try {
            // Găsește NrComanda pentru idDetaliu specificat
            let NrComanda;
            for (let order of orders) {
                const foundDetail = order
                    .DetaliiComanda
                    .find(detail => detail.idDetaliu === idDetaliu);
                if (foundDetail) {
                    NrComanda = order.NrComanda;
                    break;
                }
            }

            if (!NrComanda) {
                throw new Error('NrComanda not found for the given detail id');
            }

            // Trimite cererea DELETE pentru a șterge detaliul
            const deleteResponse = await fetch(`https://server-9p4hx.kinsta.app/delete-order-detail/${idDetaliu}`, {method: 'DELETE'});
            if (!deleteResponse.ok) {
                throw new Error('Network response was not ok for delete operation');
            }

            // Trimite o cerere separată pentru a actualiza NrProduse în baza de date
            const updateResponse = await fetch(`https://server-9p4hx.kinsta.app/increment-order-produse/${NrComanda}`, {
                method: 'PATCH' // Aici, presupunem că ai un endpoint PATCH corespunzător în backend
            });

            if (!updateResponse.ok) {
                throw new Error('Failed to update NrProduse in the database');
            }

            // Actualizează starea locală după ce NrProduse a fost actualizat în backend
            const updatedOrders = orders.map(order => {
                if (order.NrComanda === NrComanda) {
                    return {
                        ...order,
                        DetaliiComanda: order
                            .DetaliiComanda
                            .filter(detail => detail.idDetaliu !== idDetaliu),
                        NrProduse: parseInt(order.NrProduse, 10) + 1
                    };
                }
                return order;
            });

            setOrders(updatedOrders);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDeleteFreeListingDetails = async(idDetaliu) => {
        try {
            // Găsește NrComanda pentru idDetaliu specificat
            let NrComanda;
            for (let listing of freeListings) {
                const foundDetail = listing
                    .DetaliiComanda
                    .find(detail => detail.idDetaliu === idDetaliu);
                if (foundDetail) {
                    NrComanda = listing.NrComanda;
                    break;
                }
            }

            if (!NrComanda) {
                throw new Error('NrComanda not found for the given detail id');
            }

            // Trimite cererea DELETE pentru a șterge detaliul
            const deleteResponse = await fetch(`https://server-9p4hx.kinsta.app/delete-free-listing-detail/${idDetaliu}`, {method: 'DELETE'});
            if (!deleteResponse.ok) {
                throw new Error('Network response was not ok for delete operation');
            }

            // Trimite o cerere separată pentru a actualiza NrProduse în baza de date
            const updateResponse = await fetch(`https://server-9p4hx.kinsta.app/increment-free-listing-produse/${NrComanda}`, {method: 'PATCH'});

            if (!updateResponse.ok) {
                throw new Error('Failed to update NrProduse in the database');
            }

            // Actualizează starea locală după ce NrProduse a fost actualizat în backend
            const updatedListings = freeListings.map(listing => {
                if (listing.NrComanda === NrComanda) {
                    return {
                        ...listing,
                        DetaliiComanda: listing
                            .DetaliiComanda
                            .filter(detail => detail.idDetaliu !== idDetaliu),
                        NrProduse: parseInt(listing.NrProduse, 10) + 1
                    };
                }
                return listing;
            });

            setFreeListings(updatedListings);

        } catch (error) {
            console.error('Error:', error);
        }
    };

   

    const filteredOrders = orders.filter(order => order.NrComanda.toLowerCase().includes(searchTerm.toLowerCase()) || order.DataComenzii.toLowerCase().includes(searchTerm.toLowerCase()) || order.SKU.toLowerCase().includes(searchTerm.toLowerCase()) || order.Telefon.toLowerCase().includes(searchTerm.toLowerCase()) || order.Email.toLowerCase().includes(searchTerm.toLowerCase()) || order.NumePrenume.toLowerCase().includes(searchTerm.toLowerCase())); // Filtrează comenzile în funcție de termenul de căutare

    const filteredFreeListings = freeListings.filter(listing => listing.NrComanda.toLowerCase().includes(searchTermTab0.toLowerCase()) || listing.NumePrenume.toLowerCase().includes(searchTermTab0.toLowerCase()));

    const currentItems = filteredFreeListings.slice(indexOfFirstItem, indexOfLastItem);
    const pageCount = Math.ceil(filteredFreeListings.length / itemsPerPage);
    
    ///////////////////////////
    
    return (
    <Grid item xs={12}  container spacing={3} style={{ marginBottom: '20px', marginTop: '20px' }}>
        <Container maxWidth={false} style={{ padding: 0, margin: 0 }}>
            <Tabs
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                centered>
                <Tab label="Listari Gratuite"/>
                <Tab label="Listari Achizitionate"/>
            </Tabs>
            {selectedTab === 0 && (
                <div>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginBottom: 2
                    }}>
                        <KpiCard title="Total Nr. Produse" value={totalNrProduse}/>
                        <KpiCard title="Total Listări Gratuite" value={totalListariGratuite}/>
                        <KpiCard
                            title="Valoare Estimată (5 lei/produs)"
                            value={`${valoareEstimata} lei`}/>
                        <KpiCard title="Listări Închise" value={listariInchise}/>
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 2,
                        marginTop: 3
                    }}>
                        <TextField
                            label="Caută în listări gratuite"
                            variant="outlined"
                            value={searchTermTab0}
                            onChange={(e) => setSearchTermTab0(e.target.value)}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                            sx={{
                            flex: 1,
                            marginRight: 2
                        }}/>
                        <Button variant="contained" startIcon={< SearchIcon />}>
                            Caută
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenAddOrderDialog}
                            style={{
                            marginLeft: '15px'
                        }}>
                            Adaugă Comandă
                        </Button>
                    </Box>
                    <Box>
                        <Pagination count={pageCount} page={currentPage} onChange={(event, page) => setCurrentPage(page)} color="primary" style={{
                            marginBottom: '20px',
                            marginTop: '20px'
                        }} // stilizare opțională
                        />
                    </Box>
                    {currentItems.map((listing) => (
                        <React.Fragment key={listing.idComanda}>
                            <TableRow>
                                <TableCell>{listing.idComanda}</TableCell>
                                <TableCell>{listing.NrComanda}</TableCell>
                                <TableCell>{listing.Status}</TableCell>
                                <TableCell>{listing.NrProduse}</TableCell>
                                <TableCell>{listing.DataComenzii}</TableCell>
                                <TableCell>{listing.NumePrenume}</TableCell>
                                <TableCell>
                                    {listing.Status !== 'Consumat' && (
                                        <Button
                                            onClick={() => handleAddFreeListingClick(listing)}
                                            variant="contained"
                                            color="primary">
                                            ADD
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Accordion
                                        expanded={expanded === listing.NrComanda}
                                        onChange={handleExpandChange(listing.NrComanda)}>
                                        <AccordionSummary
                                            expandIcon={< ExpandMoreIcon style = {{ color: listing.Status === 'Consumat' ? '#bbb' : '#666' }}/>}
                                            aria-controls={`${listing.NrComanda}-content`}
                                            id={`${listing.NrComanda}-header`}
                                            style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '10px 10px',
                                            backgroundColor: listing.Status !== 'Consumat'
                                                ? '#c6ffcb'
                                                : '#e0e0e0', // culoarea verde deschis pentru status diferit de "Consumat" 
                                                color: listing.Status === 'Consumat' ? '#bbb' : 'inherit', // text mai subtil pentru "Consumat" 
                                                borderBottom: '1px solid #ddd', transition: 'background-color 0.3s', }}>
                                            <div
                                                style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{
                                                    fontWeight: listing.Status === 'Consumat'
                                                        ? 'normal'
                                                        : 'bold'
                                                }}>
                                                    Detalii Comandă
                                                </Typography>
                                                {listing.PromovareOnline === 'promovare-online' && (<Chip label="PROMOVAT" color="primary" style={{
                                                    marginLeft: 10,
                                                    opacity: listing.Status === 'Consumat'
                                                        ? 0.5
                                                        : 1
                                                }} // opacitate mai mică pentru "Consumat"
                                        />)}
                                            </div>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Table size="small" aria-label="Detalii Comandă">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Brand</TableCell>
                                                        <TableCell>Categorie</TableCell>
                                                        <TableCell>Data</TableCell>
                                                        <TableCell>Pret</TableCell>
                                                        <TableCell>Acțiuni</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {listing.DetaliiComanda && listing.DetaliiComanda.length > 0
                                                        ? (listing.DetaliiComanda.map((detail) => (
                                                            <TableRow key={detail.idDetaliu}>
                                                                <TableCell>{detail.Brand}</TableCell>
                                                                <TableCell>{detail.Categorie}</TableCell>
                                                                <TableCell>{detail.Data}</TableCell>
                                                                <TableCell>{detail.Pret} {detail.Moneda}</TableCell>
                                                                <TableCell>
                                                                    {listing.Status !== 'Consumat' && (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => handleEditFreeListingClick(detail)}>
                                                                            EDIT
                                                                        </Button>
                                                                    )}
                                                                    {listing.Status !== 'Consumat' && (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={() => handleDeleteFreeListingDetails(detail.idDetaliu)}>
                                                                            DELETE
                                                                        </Button>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )))
                                                        : (
                                                            <TableRow>
                                                                <TableCell colSpan={5}>Nu există detalii pentru această comandă.</TableCell>
                                                            </TableRow>
                                                        )}
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                    <Dialog
                        open={addFreeListingDialogOpen}
                        onClose={handleAddFreeListingDialogClose}>
                        <DialogTitle>Adaugă Detalii Listare Gratuită</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Categorie"
                                fullWidth
                                value={newFreeListingDetail.Categorie}
                                onChange={(e) => setNewFreeListingDetail({
                                ...newFreeListingDetail,
                                Categorie: e.target.value
                            })}/>
                            <TextField
                                label="Brand"
                                fullWidth
                                value={newFreeListingDetail.Brand}
                                onChange={(e) => setNewFreeListingDetail({
                                ...newFreeListingDetail,
                                Brand: e.target.value
                            })}/>
                            <TextField
                                label="Data"
                                type="date"
                                fullWidth
                                value={newFreeListingDetail.Data}
                                onChange={(e) => setNewFreeListingDetail({
                                ...newFreeListingDetail,
                                Data: e.target.value
                            })}
                                InputLabelProps={{
                                shrink: true
                            }}/>
                            <TextField
                                label="Pret"
                                type="number"
                                fullWidth
                                value={newFreeListingDetail.Pret}
                                onChange={(e) => setNewFreeListingDetail({
                                ...newFreeListingDetail,
                                Pret: e.target.value
                            })}/>
                        <FormControl fullWidth>
                            <InputLabel>Moneda</InputLabel>
                            <Select
                                value={newFreeListingDetail.Moneda}
                                label="Moneda"
                                onChange={(e) => setNewFreeListingDetail({
                                    ...newFreeListingDetail,
                                    Moneda: e.target.value
                                })}
                            >
                                <MenuItem value="RON">RON</MenuItem>
                                <MenuItem value="EUR">EUR</MenuItem>
                            </Select>
                        </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAddFreeListingDialogClose} color="primary">
                                Anulează
                            </Button>
                            <Button onClick={handleAddFreeListingDetailsSubmit} color="primary">
                                Adaugă
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Edit FreeListingDetails */}
                    <Dialog
                        open={editFreeListingDialogOpen}
                        onClose={handleEditFreeListingDialogClose}>
                        <DialogTitle>Editare Detalii Listare Gratuită</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Categorie"
                                fullWidth
                                value={selectedFreeListingDetail.Categorie}
                                onChange={(e) => setSelectedFreeListingDetail({
                                ...selectedFreeListingDetail,
                                Categorie: e.target.value
                            })}/>
                            <TextField
                                label="Brand"
                                fullWidth
                                value={selectedFreeListingDetail.Brand}
                                onChange={(e) => setSelectedFreeListingDetail({
                                ...selectedFreeListingDetail,
                                Brand: e.target.value
                            })}/>
                            <TextField
                                label="Data"
                                type="date"
                                fullWidth
                                value={selectedFreeListingDetail.Data}
                                onChange={(e) => setSelectedFreeListingDetail({
                                ...selectedFreeListingDetail,
                                Data: e.target.value
                            })}
                                InputLabelProps={{
                                shrink: true
                            }}/>
                            <TextField
                                label="Pret"
                                fullWidth
                                value={selectedFreeListingDetail.Pret}
                                onChange={(e) => setSelectedFreeListingDetail({
                                ...selectedFreeListingDetail,
                                Pret: e.target.value
                            })}/>
                            <FormControl fullWidth>
    <InputLabel>Moneda</InputLabel>
    <Select
        value={selectedOrderDetails.Moneda}
        label="Moneda"
        onChange={(e) => setSelectedOrderDetails({
            ...selectedOrderDetails,
            Moneda: e.target.value
        })}
    >
        <MenuItem value="RON">RON</MenuItem>
        <MenuItem value="EUR">EUR</MenuItem>
    </Select>
</FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditFreeListingDialogClose} color="primary">
                                Anulează
                            </Button>
                            <Button onClick={handleEditFreeListingDetailsSubmit} color="primary">
                                Salvează
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={addOrderDialogOpen} onClose={() => setAddOrderDialogOpen(false)}>
                        <DialogTitle>Adaugă Comandă Nouă</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Nr. Comandă"
                                fullWidth
                                margin="normal"
                                value={newOrder.NrComanda}
                                InputProps={{
                                readOnly: true
                            }}/>
                            <TextField
                                label="Nume și Prenume"
                                fullWidth
                                margin="normal"
                                value={newOrder.NumePrenume}
                                onChange={(e) => setNewOrder({
                                ...newOrder,
                                NumePrenume: e.target.value
                            })}/>
                            <TextField
                                label="Nr. Produse"
                                fullWidth
                                margin="normal"
                                type="number"
                                value={newOrder.NrProduse}
                                onChange={(e) => setNewOrder({
                                ...newOrder,
                                NrProduse: e.target.value
                            })}/>
                            <TextField
                                label="Status"
                                fullWidth
                                margin="normal"
                                value={newOrder.Status}
                                InputProps={{
                                readOnly: true, // Face câmpul read-only 
                            }}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddOrderDialogOpen(false)} color="primary">
                                Anulează
                            </Button>
                            <Button onClick={handleAddOrder} color="primary">
                                Adaugă
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            )}
            {selectedTab === 1 && (
                <div>
                    <Box
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 2,
                        marginTop: 3
                    }}>
                        <TextField
                            label="Caută comandă"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                            sx={{
                            flex: 1,
                            marginRight: 2
                        }}/>
                        <Button variant="contained" startIcon={< SearchIcon />}>
                            Caută
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFetchAndSaveNewOrders}
                            style={{
                            marginLeft: '15px'
                        }}
                            startIcon={< CachedIcon />}>
                            Reincarca comenzi
                        </Button>
                    </Box>

                    <StyledFab
                        color="primary"
                        aria-label="add"
                        onClick={handleFetchAndSaveNewOrders}>
                        <AddIcon/>
                    </StyledFab>
                    <Box>
                        <Pagination count={pageCount} page={currentPage} onChange={(event, page) => setCurrentPage(page)} color="primary" style={{
                            marginBottom: '20px',
                            marginTop: '20px'
                        }} // stilizare opțională
                        />
                    </Box>
                    {loading
                        ? "Se încarcă..."
                        : (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nr. Comanda</TableCell>
                                            <TableCell>Data Comenzii</TableCell>
                                            <TableCell>SKU</TableCell>
                                            <TableCell>Telefon</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Nume și Prenume</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Nr de Produse</TableCell>
                                            <TableCell>Promovare</TableCell>
                                            {/* Noua coloană */}
                                            <TableCell>Acțiuni</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredOrders.map((order) => (
                                            <React.Fragment key={order.NrComanda}>
                                                <TableRow>
                                                    <TableCell>{order.NrComanda}</TableCell>
                                                    <TableCell>{order.DataComenzii}</TableCell>
                                                    <TableCell>{order.SKU}</TableCell>
                                                    <TableCell>{order.Telefon}</TableCell>
                                                    <TableCell>{order.Email}</TableCell>
                                                    <TableCell>{order.NumePrenume}</TableCell>
                                                    <TableCell>{order.Status}</TableCell>
                                                    <TableCell>{order.NrProduse}</TableCell>
                                                    <TableCell>{order.PromovareOnline}</TableCell>
                                                    {/* Valoarea promovareOnline */}
                                                    <TableCell>
                                                        {order.Status !== 'Consumat' && (
                                                            <Button
                                                                onClick={() => handleAddClick(order)}
                                                                variant="contained"
                                                                color="primary">
                                                                ADD
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={9}>
                                                        <Accordion
                                                            expanded={expanded === order.NrComanda}
                                                            onChange={handleExpandChange(order.NrComanda)}
                                                            style={order.Status === 'Consumat'
                                                            ? {
                                                                backgroundColor: '#f2f2f2'
                                                            }
                                                            : {
                                                                backgroundColor: '#d2f8d2'
                                                            }}>
                                                            <AccordionSummary
                                                                expandIcon={< ExpandMoreIcon style = {{ color: order.Status === 'Consumat' ? '#bbb' : '#666' }}/>}
                                                                aria-controls={`${order.NrComanda}-content`}
                                                                id={`${order.NrComanda}-header`}
                                                                style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                padding: '10px 20px',
                                                                backgroundColor: order.Status !== 'Consumat'
                                                                    ? '#c6ffcb'
                                                                    : '#e0e0e0', // culoarea verde deschis pentru status diferit de "Consumat" 
                                                                    color: order.Status === 'Consumat' ? '#bbb' : 'inherit', 
                                                                    borderBottom: '1px solid #ddd', transition: 'background-color 0.3s', }}>
                                                                <div
                                                                    style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        style={{
                                                                        fontWeight: order.Status === 'Consumat'
                                                                            ? 'normal'
                                                                            : 'bold'
                                                                    }}>
                                                                        Detalii Comandă
                                                                    </Typography>
                                                                    {order.PromovareOnline === 'promovare-online' && (<Chip label="PROMOVAT" color="primary" style={{
                                                                        marginLeft: 10,
                                                                        opacity: order.Status === 'Consumat'
                                                                            ? 0.5
                                                                            : 1
                                                                    }} // opacitate mai mică pentru "Consumat"
                                                            />)}
                                                                </div>
                                                            </AccordionSummary>

                                                            <AccordionDetails>
                                                                <Table size="small" aria-label="Detalii Comandă">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Brand</TableCell>
                                                                            <TableCell>Categorie</TableCell>
                                                                            <TableCell>Data</TableCell>
                                                                            <TableCell>Pret</TableCell>
                                                                            <TableCell>Acțiuni</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {order.DetaliiComanda && order.DetaliiComanda.length > 0
                                                                            ? (order.DetaliiComanda.map((detail) => (
                                                                                <TableRow key={detail.idDetaliu}>
                                                                                    <TableCell>{detail.Brand}</TableCell>
                                                                                    <TableCell>{detail.Categorie}</TableCell>
                                                                                    <TableCell>{detail.Data}</TableCell>
                                                                                    <TableCell>{detail.Pret} {detail.Moneda}</TableCell>
                                                                                    <TableCell>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            onClick={() => handleEditClick(detail)}>
                                                                                            EDIT
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="secondary"
                                                                                            onClick={() => handleDeleteDetails(detail.idDetaliu)}>
                                                                                            DELETE
                                                                                        </Button>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )))
                                                                            : (
                                                                                <TableRow>
                                                                                    <TableCell colSpan={5}>Nu există detalii pentru această comandă.</TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                    </TableBody>
                                                                </Table>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
                        <DialogTitle>Adaugă Detalii Comandă</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Categorie"
                                fullWidth
                                value={selectedOrderDetails.Categorie}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Categorie: e.target.value
                            })}/>
                            <TextField
                                label="Brand"
                                fullWidth
                                value={selectedOrderDetails.Brand}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Brand: e.target.value
                            })}/>
                            <TextField
                                label="Data"
                                type="date"
                                fullWidth
                                value={selectedOrderDetails.Data}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Data: e.target.value
                            })}
                                InputLabelProps={{
                                shrink: true
                            }}/>
                            <TextField
                                label="Pret"
                                type="number"
                                fullWidth
                                value={selectedOrderDetails.Pret}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Pret: e.target.value
                            })}/>
                            <FormControl fullWidth>
                            <InputLabel>Moneda</InputLabel>
                            <Select
                                value={selectedOrderDetails.Moneda}
                                label="Moneda"
                                onChange={(e) => setSelectedOrderDetails({
                                    ...selectedOrderDetails,
                                    Moneda: e.target.value
                                })}
                            >
                                <MenuItem value="RON">RON</MenuItem>
                                <MenuItem value="EUR">EUR</MenuItem>
                            </Select>
                        </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAddDialogClose} color="primary">
                                Anulează
                            </Button>
                            <Button onClick={handleAddDetails} color="primary">
                                Adaugă
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
                        <DialogTitle>Editare Detalii Comandă</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Categorie"
                                fullWidth
                                value={selectedOrderDetails.Categorie}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Categorie: e.target.value
                            })}/>
                            <TextField
                                label="Brand"
                                fullWidth
                                value={selectedOrderDetails.Brand}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Brand: e.target.value
                            })}/>
                            <TextField
                                label="Data"
                                type="date"
                                fullWidth
                                value={selectedOrderDetails.Data}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Data: e.target.value
                            })}/>
                            <TextField
                                label="Pret"
                                type="number"
                                fullWidth
                                value={selectedOrderDetails.Pret}
                                onChange={(e) => setSelectedOrderDetails({
                                ...selectedOrderDetails,
                                Pret: e.target.value
                            })}/>
                        <FormControl fullWidth>
                            <InputLabel>Moneda</InputLabel>
                            <Select
                                value={selectedOrderDetails.Moneda}
                                label="Moneda"
                                onChange={(e) => setSelectedOrderDetails({
                                    ...selectedOrderDetails,
                                    Moneda: e.target.value
                                })}
                            >
                                <MenuItem value="RON">RON</MenuItem>
                                <MenuItem value="EUR">EUR</MenuItem>
                            </Select>
                        </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditDialogClose} color="primary">
                                Anulează
                            </Button>
                            <Button onClick={onSubmitEdit} color="primary">
                                Salvează
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </Container>
        </Grid>
    );
};

export default Page8;