const calculateAveragePrice = (products) => {
    if (products.length === 0) return 0;
    const total = products.reduce((acc, product) => acc + product.Price, 0);
    return Number((total / products.length)).toFixed(0); // folosim toFixed(2) pentru a avea două zecimale
};

const calculateTax = (avgPrice) => {
    return Number(avgPrice * 0.01).toFixed(2);
};

const calculateImpozit = (pretDeVanzare) => {
    return Number((1 * pretDeVanzare) / 100).toFixed(1);
};

const calculateProfitBrut = (comisionLuxura, pretDeVanzare) => {
    return (comisionLuxura * pretDeVanzare) / 100;
};

const calculateTVA = (pretDeVanzare, pretDeAchizitie) => {
    return Number((pretDeVanzare - pretDeAchizitie) / 100 * 19).toFixed(1);
};

const calculateProfitNet = (profitBrut, tva, impozit, marketing) => {
    return Number((profitBrut - tva - impozit - marketing).toFixed(1));
};

const calculatePretDeAchizitie = (pretDeVanzare, profitBrut) => {
    return Number(pretDeVanzare - profitBrut).toFixed(0);
};

const calculateMarketing = () => {
  return 90;  // Valoarea de 90 EUR pentru marketing
};

const calculateMaxPrice = (products) => {
  if (products.length === 0) return 0;
  return Math.max(...products.map(product => product.Price));
};

const calculateMinPrice = (products) => {
  if (products.length === 0) return 0;
  return Math.min(...products.map(product => product.Price));
};

const calculusFunctions = {
    calculateAveragePrice,
    calculateTax,
    calculateImpozit,
    calculateProfitBrut,
    calculateTVA,
    calculateProfitNet,
    calculatePretDeAchizitie,
    calculateMarketing,
    calculateMaxPrice,
    calculateMinPrice
};

export default calculusFunctions;
