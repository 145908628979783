import React from 'react';

function ProductCard({ product }) {
    // Presupunem că product.images este deja un array de obiecte
  const imageUrl = product.images && product.images.length > 0 ? product.images[0].src : 'placeholder.jpg';

  console.log('Images string:', product.images); // Acest log va arăta ce conține product.images


  if (product.images) {
    try {
      const imagesArray = JSON.parse(product.images);
      if (Array.isArray(imagesArray) && imagesArray.length > 0) {
        imageUrl = imagesArray[0].src;
      }
    } catch (error) {
      console.error('Eroare la parsarea imaginilor:', error);
    }
  }

  return (
    <div style={{ margin: '10px', border: '1px solid #ddd', padding: '10px', width: '23%' }}>
      <img src={imageUrl} alt={product.name || 'Produs'} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
      <h3 dangerouslySetInnerHTML={{ __html: product.name || 'Nume Produs' }}></h3>
      <p>SKU: {product.sku || 'N/A'}</p>
      <p>Status: {product.status || 'N/A'}</p>
      <p>Preț: {product.price || 'N/A'}</p>
    </div>
  );
}

export default ProductCard;
