import React from 'react';
import { Card, CardContent, Typography, Box, LinearProgress } from '@mui/material';

const SummaryCard = ({ title, value, maxValue }) => {
  const progressBarColor = (value, maxValue) => {
    return value >= maxValue ? 'green' : 'tomato';
  };

  const progressBarValue = (value, maxValue) => {
    return (value / maxValue) * 100;
  };

  const calculateExcess = (value, maxValue) => {
    return value > maxValue ? ((value - maxValue) / maxValue) * 100 : 0;
  };

  const formatNumber = (number) => {
    return number != null ? number.toLocaleString('ro-RO', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0;
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">
          {formatNumber(value)} RON covered {value > maxValue && (
            <span style={{ color: 'green' }}>
              (+{formatNumber(value - maxValue)} RON)
            </span>
          )}
        </Typography>
        {value < maxValue && (
          <Typography variant="body2">
            Fetelor mai trebuie să aduceți: {formatNumber(maxValue - value)} RON
          </Typography>
        )}
        <Box sx={{ width: '100%', mt: 2, position: 'relative' }}>
          <LinearProgress
            variant="determinate"
            value={progressBarValue(value, Math.max(value, maxValue))}
            sx={{
              height: 10,
              bgcolor: 'lightgray',
              '& .MuiLinearProgress-bar': {
                bgcolor: progressBarColor(value, maxValue),
              },
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              left: `${progressBarValue(maxValue, Math.max(value, maxValue))}%`,
              top: 0,
              height: '100%',
              borderLeft: '2px solid black',
            }}
          />
          {value > maxValue && (
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                left: `${progressBarValue(maxValue, Math.max(value, maxValue))}%`,
                top: -20,
                transform: 'translateX(-50%)',
                bgcolor: 'white',
                p: '2px 4px',
                borderRadius: '4px',
                boxShadow: 1,
              }}
            >
              {calculateExcess(value, maxValue).toFixed(2)}% over
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
