import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Button, IconButton, List, Snackbar, Tooltip, FormHelperText, ListItem, ListItemButton, ListItemText, Paper, TextField, Divider, Box, Typography, Container, Select, MenuItem, FormControl, InputLabel, Alert } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AssistantSharpIcon from '@mui/icons-material/AssistantSharp';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; 
import CachedIcon from '@mui/icons-material/Cached';
import MuiAlert from '@mui/material/Alert';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

function TaskList() {
  const [tasks, setTasks] = useState([]);
  const [priceOption, setPriceOption] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [selectedTask, setSelectedTask] = useState(null);
  const [authenticity, setAuthenticity] = useState('');
  const [accompaniedItems, setAccompaniedItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [observations, setObservations] = useState('');
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([null, null, null, null]);
  const [inspector, setInspector] = useState('');
  const [repainted, setRepainted] = useState('');
  const [repaired, setRepaired] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailSuccess, setEmailSuccess] = useState(false); // Pentru Snackbar

// Funcția Alert pentru Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

  const [materials, setMaterials] = useState({
    exterior: '',
    interior: '',
    shape: '',
    hardware: '',
    zippers: '',
    finishes: '',
    smell: '',
    sole: '',
    surfaces: '',
    stones: '',
    polish: '',
    mechanism: '',
    dial: '',
    strapBracelet: '',
    glass: '',
    caseBack: '',
  });

const materialLabelsToKeys = {
  "Exterior": "exterior",
  "Interior": "interior",
  "Forma": "shape",
  "Hardware": "hardware",
  "Fermoare-Inchideri": "zippers",
  "Finisaje": "finishes",
  "Mirosuri": "smell",
  "Talpa": "sole",
  "Cute": "creases",
  "Suprafețe": "surfaces",
  "Pietre": "stones",
  "Cadran": "dial",
  "Bratara-Curea": "strapBracelet",
  "Geam": "glass",
  "Spate": "caseBack",
  "Mecanism": "mechanism",
};


  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const { data: tasks } = await axios.get('https://server-9p4hx.kinsta.app/api/qc/filtered-tasks');
      const tasksWithStatus = await Promise.all(tasks.map(async (task) => {
        const { data: statusData } = await axios.get(`https://server-9p4hx.kinsta.app/api/qc/task-status/${task.issue_id}`);
        return { ...task, status: statusData.status, statusLabel: getStatusLabel(statusData.status), statusColor: getStatusColor(statusData.status) };
      }));
      setTasks(tasksWithStatus);
    } catch (error) {
      console.error("Could not fetch tasks or statuses:", error);
    }
  };

  const handleEmailSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setEmailSuccess(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Draft': return 'blue';
      case 'Done': return 'black';
      case 'New': return 'green';
      default: return 'grey';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'Draft': return 'Draft';
      case 'Done': return 'Done';
      case 'New': return 'New';
      default: return 'Necreat';
    }
  };

const handleListItemClick = async (task) => {
  console.log("Selectat issue_id:", task.issue_id);

  if (!task.status || task.status === "Necreat") {
    const payload = {
      TaskID: task.issue_id,
      Summary: task.summary,
      EntryPrice: task.pret_intrare_ron,
      EntryDate: task.data_de_intrare ? new Date(task.data_de_intrare).toISOString().slice(0, 10) : null,
      ProductCategory: task.categorie_prod,
      FullName: task.nume_si_prenume,
      Email: task.email,
      Phone: task.telefon,
      Status: "New",
      CurrentStatus: task.status,
      DesiredStatus: "New",
    };

    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/qc/save-quality-check', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Task-ul a fost salvat cu succes.');
      fetchTasks();
    } catch (error) {
      console.error("Eroare la salvarea task-ului:", error.response?.data?.message || error.message);
    }
  } else {
    try {
      setSelectedTask(null);
      setPriceOption('');
      setNewPrice('');
      setAccompaniedItems([]);
      setImages([]);
      setImageFiles([null, null, null, null]);
      setErrors({});

      const { data: detailedTask } = await axios.get(`https://server-9p4hx.kinsta.app/api/qc/task-status/${task.issue_id}`);

      if (detailedTask.status === "Draft") {
        const accompaniedItems = Array.isArray(detailedTask.accompaniedItems) ? detailedTask.accompaniedItems : JSON.parse(detailedTask.accompaniedItems || "[]");
        const images = Array.isArray(detailedTask.images) ? detailedTask.images : JSON.parse(detailedTask.images || "[]");
        const materials = detailedTask.materials ? JSON.parse(detailedTask.materials) : {};

        setSelectedTask({
          ...task,
          ...detailedTask,
          accompaniedItems: accompaniedItems,
          images: images,
          materials: materials,
          Observations: detailedTask.observations,
          authenticity: detailedTask.authenticity,
          inspector: detailedTask.inspector,
          repainted: detailedTask.repainted,
          repaired: detailedTask.repaired,
          euMarking: detailedTask.euMarking,
          gemologicalCertificate: detailedTask.gemologicalCertificate,
          surfaces: detailedTask.surfaces,
          stones: detailedTask.stones,
          summary: detailedTask.summary || task.summary,
          entryPrice: detailedTask.entryPrice || task.pret_intrare_ron,
          entryDate: detailedTask.entryDate || task.data_de_intrare,
          productCategory: detailedTask.productCategory || task.categorie_prod,
          fullName: detailedTask.fullName || task.nume_si_prenume,
          email: detailedTask.email || task.email,
          phone: detailedTask.phone || task.telefon
        });

        setPriceOption(detailedTask.priceOption || '');
        setNewPrice(detailedTask.newPrice || '');
        setAccompaniedItems(accompaniedItems);
        setImages(images);
        setImageFiles(images.map(url => ({ previewUrl: url, serverPath: url })) || [null, null, null, null]);
      } else {
        setSelectedTask({
          ...task,
          accompaniedItems: [],
          images: [],
          materials: {},
          Observations: '',
          authenticity: '',
          inspector: '',
          repainted: '',
          repaired: '',
          euMarking: '',
          gemologicalCertificate: '',
          surfaces: '',
          stones: '',
          summary: task.summary,
          entryPrice: task.pret_intrare_ron,
          entryDate: task.data_de_intrare,
          productCategory: task.categorie_prod,
          fullName: task.nume_si_prenume,
          email: task.email,
          phone: task.telefon
        });
      }
    } catch (error) {
      console.error("Eroare la încărcarea detaliilor task-ului:", error.response?.data?.message || error.message);
    }
  }
};


const handleMaterialChange = (prop) => (event) => {
  console.log(`Modificare la: ${prop}, Valoare nouă: ${event.target.value}`);

  const updatedMaterials = {
    ...selectedTask.materials,
    [prop]: event.target.value.match(/^([1-9]|10)$/) ? event.target.value : ''
  };

  setSelectedTask(prevState => ({
    ...prevState,
    materials: updatedMaterials
  }));
};


  const handleItemChange = (index) => (event) => {
    const updatedAccompaniedItems = [...accompaniedItems];
    updatedAccompaniedItems[index] = event.target.value;
    setAccompaniedItems(updatedAccompaniedItems);
    setSelectedTask(prevState => ({
      ...prevState,
      accompaniedItems: JSON.stringify(updatedAccompaniedItems)
    }));
  };

  const handleDeleteItem = (index) => {
    const updatedAccompaniedItems = [...accompaniedItems];
    updatedAccompaniedItems.splice(index, 1);
    setAccompaniedItems(updatedAccompaniedItems);
    setSelectedTask(prevState => ({
      ...prevState,
      accompaniedItems: JSON.stringify(updatedAccompaniedItems)
    }));
  };

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
      const updatedAccompaniedItems = [...accompaniedItems, newItem];
      setAccompaniedItems(updatedAccompaniedItems);
      setSelectedTask(prevState => ({
        ...prevState,
        accompaniedItems: updatedAccompaniedItems
      }));
      setNewItem('');
    }
  };

  const handlePriceOptionChange = (event) => {
    const updatedPriceOption = event.target.value;
    setPriceOption(updatedPriceOption);
    setSelectedTask(prevState => ({
      ...prevState,
      priceOption: updatedPriceOption
    }));
    if (updatedPriceOption !== 'Propunere pret nou') {
      setNewPrice('');
      setSelectedTask(prevState => ({
        ...prevState,
        newPrice: null
      }));
    }
  };

  const handleNewPriceChange = (event) => {
    const updatedNewPrice = event.target.value;
    setNewPrice(updatedNewPrice);
    setSelectedTask(prevState => ({
      ...prevState,
      newPrice: updatedNewPrice ? parseFloat(updatedNewPrice) : null
    }));
  };

const handleImageChange = (index) => async (event) => {
  const file = event.target.files[0];
  if (file) {
    const localImageUrl = URL.createObjectURL(file);

    setImageFiles(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles[index] = {
        previewUrl: localImageUrl,
        serverPath: ''
      };
      return newFiles;
    });

    try {
      const formData = new FormData();
      formData.append('image', file);

      const response = await axios.post('https://server-9p4hx.kinsta.app/api/qc/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setImageFiles(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles[index] = {
          ...newFiles[index],
          serverPath: response.data.filePath
        };
        return newFiles;
      });

      setSelectedTask(prevTask => ({
        ...prevTask,
        images: [...prevTask.images, response.data.filePath]
      }));
    } catch (error) {
      console.error("Eroare la încărcarea imaginii:", error);
      setImageFiles(prevFiles => prevFiles.map((item, idx) => idx === index ? null : item));
    }
  }
};

const handleRemoveImage = async (index) => {
  const imageToDelete = imageFiles[index];
  if (!imageToDelete) return;

  try {
    await axios.post('https://server-9p4hx.kinsta.app/api/qc/delete-image', { imagePath: imageToDelete.serverPath });

    setImageFiles(prevFiles => prevFiles.map((file, idx) => idx === index ? null : file));
    URL.revokeObjectURL(imageToDelete.previewUrl);

    setSelectedTask(prevTask => ({
      ...prevTask,
      images: prevTask.images.filter((_, i) => i !== index)
    }));
  } catch (error) {
    console.error("Eroare la ștergerea imaginii de pe server:", error);
  }
};

const handleSubmit = async (desiredStatus) => {
  const validationErrors = validateFields();
  if (Object.keys(validationErrors).length > 0) {
    return;
  }

  const formattedEntryDate = selectedTask.entryDate ? new Date(selectedTask.entryDate).toISOString().slice(0, 10) : '';

  const filteredImageFiles = imageFiles
    .filter(file => file != null && file.serverPath != null)
    .map(file => file.serverPath);

  const { total, condition } = calculateTotalAndCondition();

  const dataToSend = {
    TaskID: selectedTask.issue_id,
    PriceOption: selectedTask.priceOption,
    NewPrice: selectedTask.newPrice ? parseFloat(selectedTask.newPrice) : null,
    Authenticity: selectedTask.authenticity,
    AccompaniedItems: JSON.stringify(accompaniedItems),
    Observations: selectedTask.Observations,
    Puncte: total,
    Conditie: condition,
    Images: JSON.stringify(filteredImageFiles),
    Materials: JSON.stringify(selectedTask.materials),
    Summary: selectedTask.summary,
    EntryPrice: selectedTask.entryPrice,
    EntryDate: formattedEntryDate,
    ProductCategory: selectedTask.productCategory,
    FullName: selectedTask.fullName,
    Email: selectedTask.email,
    Phone: selectedTask.phone,
    CurrentStatus: selectedTask.status,
    DesiredStatus: desiredStatus,
    Data: new Date().toISOString().slice(0, 10),
    Hide: "Nu",
    Inspector: selectedTask.inspector // Asigură-te că Inspector este inclus
  };

  console.log("Data trimisă la backend:", JSON.stringify(dataToSend, null, 2));

  try {
    const response = await axios.post('https://server-9p4hx.kinsta.app/api/qc/save-quality-check', dataToSend, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      await generateAndDownloadPdf(dataToSend);
    }

    console.log(response.data.message);

    await fetchTasks();
    
  } catch (error) {
    console.error("Eroare la salvarea verificării calității:", error.response?.data?.message || error.message);
  }
  setEmailSuccess(true);
  setSelectedTask(prevTask => ({...prevTask, status: 'Done'}));
  fetchTasks();
};



const handleSubmitDraft = async (desiredStatus) => {
    const formattedEntryDate = selectedTask.data_de_intrare ? new Date(selectedTask.data_de_intrare).toISOString().slice(0, 10) : '';

  const filteredImageFiles = imageFiles
    .filter(file => file != null && file.serverPath != null)
    .map(file => file.serverPath);

  const { total, condition } = calculateTotalAndCondition();

  const dataToSend = {
    TaskID: selectedTask.issue_id,
    PriceOption: priceOption,
    NewPrice: newPrice ? parseFloat(newPrice) : null,
    SelectedTaskID: selectedTask.id,
    Authenticity: selectedTask.authenticity,
    AccompaniedItems: JSON.stringify(accompaniedItems),
    Observations: selectedTask.Observations,
    Puncte: total,
    Conditie: condition,
    Images: JSON.stringify(filteredImageFiles),
    Inspector: selectedTask.inspector,
    Repainted: selectedTask.repainted,
    Repaired: selectedTask.repaired,
    EUMarking: selectedTask.euMarking,
    GemologicalCertificate: selectedTask.gemologicalCertificate,
    Surfaces: selectedTask.materials?.surfaces,
    Stones: selectedTask.materials?.stones,
    Materials: JSON.stringify(selectedTask.materials),
    Summary: selectedTask.summary,
    EntryPrice: selectedTask.entryPrice,
    EntryDate: formattedEntryDate,
    ProductCategory: selectedTask.productCategory,
    FullName: selectedTask.fullName,
    Email: selectedTask.email,  // corectat aici
    Phone: selectedTask.phone,
    CurrentStatus: selectedTask.status,
    DesiredStatus: desiredStatus,
    Data: new Date().toISOString().slice(0, 10),
    Hide: "Nu",
  };

  console.log("Data trimisă la backend pentru ciornă:", JSON.stringify(dataToSend, null, 2));

  try {
    const response = await axios.post('https://server-9p4hx.kinsta.app/api/qc/save-quality-check', dataToSend, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200) {
      console.log(response.data.message);
      setEmailSuccess(true); // Afișează Snackbar-ul pentru succes
      await fetchTasks();
    }
  } catch (error) {
    console.error("Eroare la salvarea ciornă:", error.response?.data?.message || error.message);
    setEmailSuccess(false); // Afișează Snackbar-ul pentru eroare
  }
};

// Adăugarea funcției pentru închiderea Snackbar-ului pentru mesaje de ciornă
const handleDraftSuccessClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setEmailSuccess(false);
};

  const calculateTotalAndCondition = () => {
    const materialValues = Object.values(selectedTask.materials || {}).map(value => parseInt(value, 10) || 0);
    const total = materialValues.reduce((acc, curr) => acc + curr, 0);
    const numberOfPositiveFields = materialValues.filter(value => value > 0).length;
    const average = numberOfPositiveFields > 0 ? total / numberOfPositiveFields : 0;
    let condition;
    if (average === 10) {
      condition = "Nou";
    } else if (average < 10 && average >= 9) {
      condition = "Excelent";
    } else if (average < 9 && average >= 8) {
      condition = "Foarte Bun";
    } else if (average < 8 && average >= 7) {
      condition = "Bun";
    } else if (average < 7) {
      condition = "Rezonabil";
    } else {
      condition = "N/A";
    }
    return { 
      total: average.toFixed(2),
      condition 
    };
  };

const generateAndDownloadPdf = async (selectedTask, additionalData = {}) => {
  try {
    const dataForPdf = {
      selectedTask: selectedTask,
      additionalData: additionalData
    };

    console.log('Data for PDF:', JSON.stringify(dataForPdf, null, 2)); // Log pentru a vedea ce date sunt trimise

    const pdfResponse = await fetch('https://server-9p4hx.kinsta.app/api/qc/generate-quality-report', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataForPdf),
    });

    if (!pdfResponse.ok) {
      throw new Error('Network response was not ok.');
    }

    const now = new Date();
    const formattedTimestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;

    const fileName = `QualityCheck_${selectedTask.TaskID}-${formattedTimestamp}.pdf`;

    const blob = await pdfResponse.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Eroare la generarea sau descărcarea PDF:', error);
  }
};



  const rephraseObservations = async () => {
    if (!selectedTask.Observations) return;
    setIsProcessing(true);
    try {
      const rephrasedText = await fetchRephrasedText(selectedTask.Observations);
      setSelectedTask(prevState => ({
        ...prevState,
        Observations: rephrasedText
      }));
    } catch (error) {
      console.error("Eroare la reformularea textului:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchRephrasedText = async (text) => {
    try {
      const category = selectedTask.categorie_prod;
      const { condition, total } = calculateTotalAndCondition();

      const prompt = `Reformulează, dar fara diacritice, într-o manieră de business și profesionist ca și un inspector de calitate expert de la o consignație de lux. Te rog sa nu scrii ca esti inspector de calitate, scrie direct observatia ta. Articolul este din categoria: ${category} si este in conditia: ${condition}; Nota totala: ${total} ${text}`;

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4",
          messages: [{"role": "user", "content": prompt}],
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer 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`
          }
        }
      );

      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error("Eroare la reformularea textului:", error);
    }
  };

  const validateFields = () => {
    let newErrors = {};

    const materials = selectedTask.materials || {};

    if (!selectedTask.authenticity) {
      newErrors.authenticity = "Acest câmp este obligatoriu.";
    }
    if (!selectedTask.inspector) {
      newErrors.inspector = "Alegerea unui inspector este obligatorie.";
    }
    if (!selectedTask.priceOption) {
      newErrors.priceOption = "Selectarea unei opțiuni de preț este obligatorie.";
    }
    if (selectedTask.priceOption === 'Propunere pret nou' && !selectedTask.newPrice) {
      newErrors.newPrice = "Introducerea noului preț este obligatorie atunci când se propune un preț nou.";
    }
    if (!selectedTask.materials.cutie) {
      newErrors.cutie = "Câmpul Cutie este obligatoriu.";
    }
    if (!selectedTask.materials.accesorii) {
      newErrors.accesorii = "Câmpul Accesorii este obligatoriu.";
    }
    if (!selectedTask.Observations) {
      newErrors.Observations = "Acest câmp este obligatoriu.";
    }
    if (accompaniedItems.length === 0) {
      newErrors.accompaniedItems = "Trebuie adăugat cel puțin un element însoțitor.";
    }
    
    if (selectedTask.categorie_prod !== "Bijuterii" && selectedTask.categorie_prod !== "Imbracaminte") {
      if (!selectedTask.repainted) {
        newErrors.repainted = "Specificarea dacă articolul a fost revopsit este obligatorie.";
      }
      if (!selectedTask.repaired) {
        newErrors.repaired = "Specificarea dacă articolul a fost reparat este obligatorie.";
      }
    }
    
    const category = selectedTask.categorie_prod;
    switch (category) {
      case "Ceasuri":
        break;
      case "Bijuterii":
        break;
      case "Imbracaminte":
      case "Genti":
        ["exterior", "interior", "shape", "hardware"].forEach(field => {
          if (!materials[field]) {
            newErrors[field] = `Câmpul ${field} este obligatoriu pentru categoria ${category}.`;
          }
        });
        break;
      case "Incaltaminte":
        ["exterior", "interior", "shape", "sole","creases"].forEach(field => {
          if (!materials[field]) {
            newErrors[field] = `Câmpul ${field} este obligatoriu pentru categoria Încălțăminte.`;
          }
        });
        break;
    }

    console.log("Erori de validare:", newErrors);
    setErrors(newErrors);
    return newErrors;
  };

  const handleExportTasks = () => {
    const doc = new jsPDF();
    const tableColumn = ["Nr.", "Articol", "Status"];
    const tableRows = [];

    tasks.forEach((task, index) => {
      const taskData = [
        index + 1,
        task.summary,
        task.status,
      ];
      tableRows.push(taskData);
    });

    doc.text("Lista de Articole", 14, 15);
    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("lista_task-uri.pdf");
  };

    const getOldestTaskDate = () => {
    if (tasks.length === 0) return 'N/A';
    const oldestTask = tasks.reduce((oldest, task) => oldest.data_de_intrare < task.data_de_intrare ? oldest : task);
    return new Date(oldestTask.data_de_intrare).toLocaleDateString();
  };

  return (
  <Box sx={{ width: '100%', padding: 2 }}>
    <Typography variant="h4" gutterBottom component="div" sx={{ textAlign: 'center', marginBottom: 4 }}>
      Quality Check
    </Typography>
    <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 4, alignItems: 'center' }}>
      <Alert icon={<AddTaskIcon fontSize="inherit" />} severity="success" sx={{ width: '100%' }}>
        Total QC: {tasks.length}
      </Alert>
      <Alert icon={<AccessAlarmsIcon fontSize="inherit" />} severity="warning" sx={{ width: '100%' }}>
        Cel mai vechi: {getOldestTaskDate()}
      </Alert>
    </Container>
    <Tooltip title="Export to PDF">
      <IconButton aria-label="export to pdf" onClick={handleExportTasks} style={{ color: "red" }}>
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="Refresh Tasks">
      <IconButton aria-label="refresh tasks" onClick={fetchTasks} style={{ color: "blue" }}>
        <CachedIcon />
      </IconButton>
    </Tooltip>
    <Grid container spacing={2}>
      <Grid item xs={4} sx={{ height: 'calc(100vh - 64px)', overflow: 'auto', position: 'sticky', top: 0 }}>
        <List>
          {tasks.map((task, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => handleListItemClick(task)}>
                <ListItemText primary={task.summary} />
                <Chip
                  label={task.statusLabel}
                  size="small"
                  sx={{
                    backgroundColor: task.statusColor,
                    color: 'white',
                    fontWeight: 'bold',
                    marginLeft: 'auto',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={7}>
        {selectedTask && (
          <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
            <TextField
              label="Summary"
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              value={selectedTask.summary}
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Pret negociat"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={`${selectedTask.entryPrice} RON`}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Data de intrare"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={new Date(selectedTask.entryDate).toLocaleDateString()}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Categorie Prod"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selectedTask.productCategory || "N/A"}
                  sx={{ '& .MuiInputBase-input': { color: 'green' } }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Nume și Prenume"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selectedTask.fullName}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Email"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selectedTask.email}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Telefon"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={selectedTask.phone}
                />
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
              Autentificare
            </Typography>
            <FormControl fullWidth error={Boolean(errors.authenticity)}>
              <InputLabel id="authenticity-select-label">Articolul este autentic?</InputLabel>
              <Select
                labelId="authenticity-select-label"
                id="authenticity-select"
                value={selectedTask.authenticity || ''}
                error={Boolean(errors.authenticity)}
                helperText={errors.authenticity || ''}
                onChange={(event) => {
                  const updatedTaskForAuthenticity = {
                    ...selectedTask,
                    authenticity: event.target.value
                  };
                  setSelectedTask(updatedTaskForAuthenticity);
                  setErrors(prevErrors => ({...prevErrors, authenticity: undefined}));
                }}
              >
                <MenuItem value="DA">DA</MenuItem>
                <MenuItem value="NU">NU</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="h6" component="h2" gutterBottom sx={{ marginTop: 4 }}>
              Inspecția materialelor
            </Typography>

            {
            selectedTask.productCategory === "Ceasuri" ? (
                <Grid container spacing={2}>
                {['Cadran', 'Bratara-Curea', 'Geam', 'Spate'].map((label, index) => (
                    <Grid item xs={3} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Bijuterii" ? (
                <Grid container spacing={2}>
                {['Suprafețe', 'Pietre'].map((label, index) => (
                    <Grid item xs={6} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Genti" || selectedTask.productCategory === "Imbracaminte" ? (
                <Grid container spacing={2}>
                {['Exterior', 'Interior', 'Forma', 'Hardware'].map((label, index) => (
                    <Grid item xs={3} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Incaltaminte" ? (
                <Grid container spacing={2}>
                {['Exterior', 'Interior', 'Forma', 'Talpa', 'Cute'].map((label, index) => (
                    <Grid item xs={3} key={index}>
                    <TextField
                        label={label}
                        type="number"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : null
            }

            <Alert severity="info" sx={{ marginTop: 2 }}>
              Notă: 1-10 (1 fiind neacceptat, 10 fiind maxim)
            </Alert>

<Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
  Funcționalitatea și designul
</Typography>

{
  selectedTask.productCategory === "Bijuterii" ? (
    <Grid container spacing={2}>
      {['Finisaje', 'Pietre'].map((label, index) => (
        <Grid item xs={6} key={index}>
          <TextField
            label={label}
            type="text"
            value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
            onChange={handleMaterialChange(materialLabelsToKeys[label])}
            fullWidth
            error={Boolean(errors[materialLabelsToKeys[label]])}
            helperText={errors[materialLabelsToKeys[label]]}
          />
        </Grid>
      ))}
    </Grid>
  ) : selectedTask.productCategory === "Ceasuri" ? (
    <Grid container spacing={2}>
      {['Finisaje', 'Mecanism'].map((label, index) => (
        <Grid item xs={6} key={index}>
          <TextField
            label={label}
            type="text"
            value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
            onChange={handleMaterialChange(materialLabelsToKeys[label])}
            fullWidth
            error={Boolean(errors[materialLabelsToKeys[label]])}
            helperText={errors[materialLabelsToKeys[label]]}
          />
        </Grid>
      ))}
    </Grid>
  ) : selectedTask.productCategory === "Accesorii" ? (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      <Grid item xs={4}>
        <TextField
          required
          label="Finisaje"
          type="text"
          value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys['Finisaje']] || '' : ''}
          onChange={handleMaterialChange(materialLabelsToKeys['Finisaje'])}
          fullWidth
          error={Boolean(errors[materialLabelsToKeys['Finisaje']])}
          helperText={errors[materialLabelsToKeys['Finisaje']]}
        />
      </Grid>
    </Grid>
  ) : selectedTask.productCategory === "Incaltaminte" ? (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      <Grid item xs={12}>
        <TextField
          required
          label="Cute"
          type="text"
          value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys['Cute']] || '' : ''}
          onChange={handleMaterialChange(materialLabelsToKeys['Cute'])}
          fullWidth
          error={Boolean(errors[materialLabelsToKeys['Cute']])}
          helperText={errors[materialLabelsToKeys['Cute']]}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      {['Fermoare-Inchideri', 'Finisaje', 'Mirosuri'].map((label, index) => (
        <Grid item xs={4} key={index}>
          <TextField
            required
            label={label}
            type="text"
            value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
            onChange={handleMaterialChange(materialLabelsToKeys[label])}
            fullWidth
            error={Boolean(errors[materialLabelsToKeys[label]])}
            helperText={errors[materialLabelsToKeys[label]]}
          />
        </Grid>
      ))}
    </Grid>
  )
}


            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Ambalajul și accesoriile
            </Typography>
            <Grid container spacing={2}>
              {['Cutie', 'Accesorii'].map((label, index) => (
                <Grid item xs={6} key={index}>
                  <TextField
                    required
                    label={label}
                    value={selectedTask.materials ? selectedTask.materials[label.toLowerCase()] || '' : ''}
                    onChange={(event) => {
                      const prop = label.toLowerCase();
                      const updatedMaterials = {
                        ...selectedTask.materials,
                        [prop]: event.target.value
                      };

                      setSelectedTask({
                        ...selectedTask,
                        materials: updatedMaterials
                      });
                    }}
                    error={Boolean(errors[label.toLowerCase()])}
                    helperText={errors[label.toLowerCase()]}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Istoricul și proveniența produsului
            </Typography>
            {
              selectedTask.productCategory === "Bijuterii" ? (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="eu-marking-label">Marcaj EU</InputLabel>
                      <Select
                        labelId="eu-marking-label"
                        id="eu-marking-select"
                        value={selectedTask.euMarking || ''}
                        label="Marcaj EU"
                        onChange={(e) => {
                          setSelectedTask({...selectedTask, euMarking: e.target.value});
                        }}
                      >
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="gemological-certificate-label">Certificat Gemologic</InputLabel>
                      <Select
                        labelId="gemological-certificate-label"
                        id="gemological-certificate-select"
                        value={selectedTask.gemologicalCertificate || ''}
                        label="Certificat Gemologic"
                        onChange={(e) => setSelectedTask({...selectedTask, gemologicalCertificate: e.target.value})}
                      >
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2} alignItems="center">
                  {selectedTask.productCategory !== "Imbracaminte" && (
                    <Grid item xs={6}>
                      <FormControl fullWidth sx={{ mb: 2 }} error={Boolean(errors.Repainted)}>
                        <InputLabel id="repainted-label">{selectedTask.productCategory === "Ceasuri" ? "Polisat" : "Revopsit"}</InputLabel>
                        <Select
                          labelId="repainted-label"
                          id="repainted-select"
                          value={selectedTask.repainted || ''}
                          label={selectedTask.productCategory === "Ceasuri" ? "Polisat" : "Revopsit"}
                          onChange={(e) => {
                            setSelectedTask({...selectedTask, repainted: e.target.value});
                            setErrors(prevErrors => ({...prevErrors, Repainted: undefined}));
                          }}
                        >
                          <MenuItem value="DA">DA</MenuItem>
                          <MenuItem value="NU">NU</MenuItem>
                        </Select>
                        {errors.Repainted && <FormHelperText>{errors.Repainted}</FormHelperText>}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FormControl fullWidth error={Boolean(errors.Repaired)} sx={{ mb: 2 }}>
                      <InputLabel id="repaired-label">Reparat</InputLabel>
                      <Select
                        labelId="repaired-label"
                        id="repaired-select"
                        value={selectedTask.repaired || ''}
                        label="Reparat"
                        onChange={(e) => setSelectedTask({...selectedTask, repaired: e.target.value})}
                      >
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                      </Select>
                      {errors.Repaired && <FormHelperText>{errors.Repaired}</FormHelperText>}
                    </FormControl>
                  </Grid>
                </Grid>
              )
            }

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Nota Finală
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="A venit însoțit cu:"
                  value={newItem}
                  onChange={(e) => setNewItem(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                  error={Boolean(errors.accompaniedItems)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={handleAddItem}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
            {Array.isArray(accompaniedItems) && accompaniedItems.map((item, index) => (
              <Grid container spacing={2} alignItems="center" key={index}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={item}
                    sx={{ mb: 2 }}
                    onChange={handleItemChange(index)}
                    error={Boolean(errors.accompaniedItems)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleDeleteItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            {errors.accompaniedItems && (
              <FormHelperText error sx={{ ml: 2 }}>{errors.accompaniedItems}</FormHelperText>
            )}

            <Box sx={{ position: 'relative', display: 'inline-block', mb: 2, width: '100%' }}>
              <FormControl fullWidth error={Boolean(errors.priceOption)} sx={{ width: '100%' }}>
                <InputLabel id="price-option-label">Preț</InputLabel>
                <Select
                  labelId="price-option-label"
                  id="price-option"
                  value={priceOption}
                  label="Preț"
                  onChange={handlePriceOptionChange}
                >
                  <MenuItem value="Cel agreat initial">Cel agreat inițial</MenuItem>
                  <MenuItem value="Propunere pret nou">Propunere preț nou</MenuItem>
                </Select>
                {errors.priceOption && <FormHelperText>{errors.priceOption}</FormHelperText>}
              </FormControl>
              {priceOption === 'Propunere pret nou' && (
                <TextField
                  error={Boolean(errors.newPrice)}
                  helperText={errors.newPrice}
                  label="Preț Nou"
                  type="number"
                  value={newPrice}
                  onChange={handleNewPriceChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                    inputProps: { min: 0, pattern: "\\d*" }
                  }}
                />
              )}
              <Box sx={{
                position: 'absolute',
                top: 0,
                right: 40,
                transform: 'translate(50%, -50%)',
                bgcolor: 'green',
                color: 'white',
                px: 2,
                py: 0.5,
                borderRadius: '16px',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '24px',
              }}>
                {selectedTask.entryPrice} RON
              </Box>
            </Box>

            <TextField
              label="Observații inspector de calitate"
              multiline
              rows={4}
              value={selectedTask.Observations || ''}
              onChange={(e) => setSelectedTask(prevState => ({...prevState, Observations: e.target.value}))}
              fullWidth
              sx={{ marginBottom: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={rephraseObservations} edge="end" sx={{ color: 'purple' }}>
                      {isProcessing ? <CircularProgress size={24} /> : <AssistantSharpIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(errors.Observations)}
              helperText={errors.Observations}
            />

            <Grid container spacing={2}>
            {[0, 1, 2, 3].map((index) => (
                <Grid item xs={6} key={index} sx={{ position: 'relative' }}>
                <Button variant="outlined" component="label" fullWidth>
                    Încarcă Imagine
                    <input
                    type="file"
                    hidden
                    onChange={handleImageChange(index)}
                    />
                </Button>
                {imageFiles[index] && imageFiles[index].previewUrl && (
                    <Box sx={{ position: 'relative', mt: 1, width: '100%' }}>
                    <img
                        src={imageFiles[index].previewUrl}
                        alt={`Preview ${index + 1}`}
                        style={{ width: '100%', maxHeight: 200, objectFit: 'contain' }}
                    />
                    <IconButton
                        onClick={() => handleRemoveImage(index)}
                        sx={{ position: 'absolute', top: 0, right: 0, color: 'red', zIndex: 1 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    </Box>
                )}
                </Grid>
            ))}
            </Grid>

            <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} error={Boolean(errors.Inspector)}>
              <InputLabel id="inspector-label">Inspector</InputLabel>
              <Select
                labelId="inspector-label"
                id="inspector-select"
                value={selectedTask.inspector || ''}
                label="Inspector"
                onChange={(e) => {
                  setSelectedTask({...selectedTask, inspector: e.target.value});
                }}
              >
                {['Alexandra N.', 'Ciprian B.', 'Alina B.', 'Mirela B.'].map((name) => (
                  <MenuItem key={name} value={name}>{name}</MenuItem>
                ))}
              </Select>
              {errors.Inspector && <FormHelperText>{errors.Inspector}</FormHelperText>}
            </FormControl>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Alert variant="outlined" severity="info">
                    TOTAL: {calculateTotalAndCondition().total}
                  </Alert>
                </Grid>
                <Grid item xs={6}>
                  <Alert variant="outlined" severity="info">
                    Condiție: {calculateTotalAndCondition().condition}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="outlined" onClick={() => handleSubmitDraft('Draft')} sx={{ marginRight: 2 }} disabled={selectedTask?.status === 'Done'}>
                Ciorna
              </Button>
              <Button fullWidth variant="contained" onClick={() => handleSubmit('Done')} sx={{ backgroundColor: '#000', color: '#fff' }} disabled={selectedTask?.status === 'Done'}>
                Salvează & Trimite la Client
              </Button>
                <Snackbar open={emailSuccess} autoHideDuration={6000} onClose={handleEmailSuccessClose}>
                <Alert onClose={handleEmailSuccessClose} severity="success" sx={{ width: '100%' }}>
                    Emailul a fost trimis cu succes către consignat!
                </Alert>
                </Snackbar>
                <Snackbar open={emailSuccess} autoHideDuration={6000} onClose={handleDraftSuccessClose}>
                <Alert onClose={handleDraftSuccessClose} severity="success" sx={{ width: '100%' }}>
                    Ciorna a fost salvată cu succes!
                </Alert>
                </Snackbar>
            </Box>
          </Paper>
        )}
      </Grid>
    </Grid>
  </Box>
);
}

export default TaskList;
