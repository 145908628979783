import React, { useState, useEffect, useRef } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, blue } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import axios from 'axios';
import CreareRaport from './CreareRaportQC';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AnalysisIcon from '@mui/icons-material/Analytics'; // Importați icon-ul pentru analiză
import {
    Checkbox,
    CardMedia,

} from '@mui/material';
import CreateProductWoo from './CreateProductWoo'; // Importă componenta CreateProductWoo
import { FormControlLabel } from '@mui/material';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const modalStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
};

const auroraAnimation = keyframes`
  0% { box-shadow: 0 0 10px #1E90FF, 0 0 20px #1E90FF, 0 0 30px #1E90FF; }
  50% { box-shadow: 0 0 20px #00FFAB, 0 0 30px #00FFAB, 0 0 40px #00FFAB; }
  100% { box-shadow: 0 0 10px #1E90FF, 0 0 20px #1E90FF, 0 0 30px #1E90FF; }
`;

// Stil pentru iconița butonului cu animația aplicată
const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  color: '#1E90FF',
  '&:hover': {
    animation: `${auroraAnimation} 2s infinite`,
  },
}));

function extractTitle(htmlString) {
  const strongMatch = htmlString.match(/<strong>\s*(.*?)\s*<\/strong>/i);
  const strongText = strongMatch ? strongMatch[1].trim() : '';
  const divMatch = htmlString.match(/<div[^>]*font-size:\s*16px;[^>]*>\s*(.*?)\s*<\/div>/i);
  const divText = divMatch ? divMatch[1].trim() : '';
  return `${strongText} ${divText}`.trim();
}

export default function AutentificareStep({
  issueId,
  customFieldValue,
  onUpdateCustomField,
  contactName,
  contactPhone,
  contactEmail,
  category,
  summary,
}) {
  const [expanded, setExpanded] = useState(false);
  const [condition, setCondition] = useState('');
  const [description, setDescription] = useState('');
  const [qcStatus, setQcStatus] = useState(customFieldValue || '');
  const qcStatusRef = useRef(qcStatus);
  const [priceOption, setPriceOption] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [awbData, setAwbData] = useState({
    street: '',
    number: '',
    postalCode: '',
    city: '',
    county: '',
    country: 'Romania',
    observations: '',
    contactName: contactName || '',
    contactPhone: contactPhone || '',
    contactEmail: contactEmail || '',
    pickupDate: '',
    scheduleDate: '',
  });
  const [rejectionReason, setRejectionReason] = useState('');
  const [sesiuniDisponibile, setSesiuniDisponibile] = useState([]);
  const [selectedSesiune, setSelectedSesiune] = useState('');
  const [linkedIssues, setLinkedIssues] = useState([]);
  const [fisaProdusId, setFisaProdusId] = useState('');
  const [fisaProdusIdStatus, setFisaProdusIdStatus] = useState('');
  const [fisaProdusStatus, setFisaProdusStatus] = useState('');
  const [ciornaPlatforma, setCiornaPlatforma] = useState('Not available');
  const [wooCommerceTitle, setWooCommerceTitle] = useState('');
  const [wooCommerceStatus, setWooCommerceStatus] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [showAnalysis, setShowAnalysis] = useState(false); // Stare pentru a controla vizibilitatea analizei
  const [manualAvgPrice, setManualAvgPrice] = useState(''); // Stare pentru Prețul de Vânzare
  const [error, setError] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]); // Selectarea categoriei
  const [nextProductId, setNextProductId] = useState(null); // Următorul ID de produs
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [productData, setProductData] = useState(null);
  const [availableBadges, setAvailableBadges] = useState([]);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [jiraFields, setJiraFields] = useState(null);
  const [vendorData, setVendorData] = useState(null);
  const [tipContract, setTipContract] = useState('');
  const consumerKey = 'ck_a941824be557253d3765a1f6242e6d6a590da999';
  const consumerSecret = 'cs_6b0d98e4c442e707be9888cafff1fac17cdbf259';

  const handleFilter = async () => {
    const brandFilter = customFields.brand;
    const categoryFilter = customFields.categorie;

    if (!brandFilter || !categoryFilter) {
      alert('Lipsesc valorile necesare pentru brand sau categorie!');
      return;
    }

    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/masterdata/filter-products', {
        params: {
          brand: brandFilter,
          category: categoryFilter
        }
      });
      setFilteredProducts(response.data);
    } catch (error) {
      console.error('Error fetching filtered products:', error);
    }
  };

  const fetchTipContract = async () => {
    try {
        const response = await axios.get(`/jira-task-qc/${issueId}/fields`);
        const tipContractValue = response.data.customfield_10183 || '';
        setTipContract(tipContractValue);
        console.log("Fetched Tip_Contract:", tipContractValue);
    } catch (error) {
        console.error("Error fetching Tip_Contract from JIRA:", error);
    }
};

// Fetch Tip_Contract when the component mounts or the issueId changes
useEffect(() => {
    if (issueId) {
        fetchTipContract();
    }
}, [issueId]);

  useEffect(() => {
    const fetchLastProductId = async () => {
      try {
        const response = await axios.get('https://www.luxuraelite.ro/wp-json/wc/v3/products', {
          params: {
            per_page: 1,
            orderby: 'id',
            order: 'desc'
          },
          headers: {
            Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`)
          }
        });
        const lastProductId = response.data[0]?.id || 0;
        setNextProductId(lastProductId + 1);
      } catch (error) {
        console.error('Error fetching last product ID:', error);
      }
    };

    fetchLastProductId();
  }, []);


    // Funcția de selectare a produsului
    const handleProductSelect = async (sku) => {
      console.log("Produs selectat:", sku); // Verifică produsul selectat
      setSelectedProductIds([sku]);

      try {
        const productData = await handleCloneProduct(sku, consumerKey, consumerSecret);
        console.log("Product Data:", productData); // Afișează datele produsului imediat după selectare
        setProductData(productData);
      } catch (error) {
        console.error('Eroare la clonarea produsului:', error);
      }
    };

    const handleCreareActAditional = async () => {
      try {
        // Fetch Tip_Contract from JIRA (customfield_10183 value)
        const tipContractResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task-qc/${issueId}/fields`);
        const tipContract = tipContractResponse.data.customfield_10183?.value || ''; // Extracts only value
        
        // Consolidate address fields into a single string
        const adresa = `${vendorData?.addresses?.[0]?.address_line1 || ''}, ${vendorData?.addresses?.[0]?.city || ''}, ${vendorData?.addresses?.[0]?.state || ''}`;

        // Define the requestData object
        const requestData = {
          Tip_Contract: jiraFields.Contract || '', // Use the 'Contract' field directly
          nume: jiraFields.nume || '',
          telefon: jiraFields.telefon || '',
          email: jiraFields.email || '',
          nrContract: jiraFields.NrContract || '',
          dataIntrare: jiraFields.DataIntrare || '',
          pretFinal: customFieldValues.customfield_10072 || '', // Use the correct field for Pret
          serieCI: vendorData?.vendor?.serieCI || '',
          nrCI: vendorData?.vendor?.nrCI || '',
          adresaLinia1: adresa // Unified address field
        };

        console.log('Sending request data to backend:', requestData);

        // Call the API with the requestData
        const response = await axios.post('https://server-9p4hx.kinsta.app/api/contracts/signnow/workflow-act-aditional', requestData);

        if (response.status === 200) {
          alert('Act Adițional creat și trimis cu succes!');
        } else {
          alert('A apărut o eroare la crearea Actului Adițional.');
        }
      } catch (error) {
        console.error('Error creating Act Adițional:', error);
        alert('A apărut o eroare la crearea Actului Adițional.');
      }
    };

    // Function to handle "Pret Acceptat" click
    const handlePretAcceptatClick = async () => {
        try {
            // Fetch fields from JIRA
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task-qc/${issueId}/fields`);
            setJiraFields(response.data); // Update state with fetched data
        } catch (error) {
            console.error("Error fetching JIRA fields:", error);
        }
    };

    // Funcție pentru salvarea prețului propus
    const handleSaveNewPrice = async () => {
      try {
        await axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, {
          customfield_10072: newPrice,
        });
        alert('Prețul a fost acceptat și salvat cu succes.');
      } catch (error) {
        console.error('Eroare la salvarea prețului:', error);
        alert('A apărut o eroare la salvarea prețului.');
      }
    };

    const handleRejectPrice = () => {
      alert('Prețul a fost refuzat.');
    };

// Fetch JIRA fields as defined
  const fetchJiraFields = async () => {
    try {
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task-qc/${issueId}/fields`);
      const data = response.data;

      // Update the state with the fields you want to display
      setJiraFields({
        nume: data.nume,
        telefon: data.telefon,
        email: data.email,
        NrContract: data.NrContract,
        DataIntrare: data.DataIntrare,
        Pret: data.Pret,
        Contract: data.Contract,
      });

      console.log("Fetched JIRA fields:", data);

      // If email is present, fetch vendor data based on it
      if (data.email) {
        fetchVendorData(data.email);
      }
    } catch (error) {
      console.error('Error fetching JIRA fields:', error);
    }
  };

  // Fetch vendor data based on email
  const fetchVendorData = async (email) => {
    try {
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/vendor/${email}`);
      setVendorData(response.data);
      console.log("Fetched Vendor data:", response.data);
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

const handleCreateDraftClick = async () => {
  if (selectedProductIds.length === 0) {
    alert('Te rog selectează un produs.');
    return;
  }

  console.log("Creare ciornă pentru SKU:", selectedProductIds[0]);

  try {
    const data = await handleCloneProduct(selectedProductIds[0], consumerKey, consumerSecret);
    
    // Verificare date înainte de a le trimite către CreateProductWoo
    console.log('Product Data pentru CreateProductWoo:', data);
    if (data && data.profileImage && data.galleryImages) {
      setProductData(data);
      setShowCreateProduct(true);
    } else {
      console.error('Date incomplete pentru CreateProductWoo');
      alert('Date incomplete sau incorecte pentru crearea ciornei.');
    }
  } catch (error) {
    console.error('Eroare la clonarea produsului:', error);
  }
};



// Funcția care se ocupă de preluarea datelor din API-ul WooCommerce
const handleCloneProduct = async (sku, consumerKey, consumerSecret) => {
  try {
    console.log("Clonare produs SKU:", sku); // Verificare SKU trimis la clonare
    const productResponse = await axios.get(`https://www.luxuraelite.ro/wp-json/wc/v3/products`, {
      params: {
        sku: sku,
      },
      headers: {
        Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`),
      },
    });
    console.log("Răspuns WooCommerce:", productResponse); // Verifică răspunsul de la WooCommerce
    const originalProduct = productResponse.data[0];
    if (!originalProduct) {
      alert('Produsul nu a fost găsit.');
      return;
    }

    const productData = {
      name: originalProduct.name,
      price: originalProduct.price,
      regularPrice: originalProduct.regular_price,
      salePrice: originalProduct.sale_price,
      sku: originalProduct.sku,
      categories: originalProduct.categories.map((cat) => cat.name),
      description: originalProduct.description,
      shortDescription: originalProduct.short_description,
      externalColor: originalProduct.attributes.find((attr) => attr.name === 'Culoare')?.options[0],
      interiorColor: originalProduct.attributes.find((attr) => attr.name === 'Material Interior')?.options[0],
      exteriorMaterial: originalProduct.attributes.find((attr) => attr.name === 'Material Exterior')?.options[0],
      condition: originalProduct.attributes.find((attr) => attr.name === 'Condiție')?.options[0],
      length: originalProduct.dimensions.length,
      height: originalProduct.dimensions.height,
      depth: originalProduct.dimensions.width,
      includedItems: originalProduct.attributes.find((attr) => attr.name === 'Insotit')?.options,
      designerId: originalProduct.attributes.find((attr) => attr.name === 'Designeri')?.options[0],
      location: originalProduct.meta_data.find((meta) => meta.key === 'Locație')?.value,
      sortlyId: originalProduct.meta_data.find((meta) => meta.key === 'cod_inventar_sortly')?.value,
      profileImage: originalProduct.images[0]?.src,
      galleryImages: originalProduct.images.slice(1).map((image) => image.src),
      layawayOption: originalProduct.attributes.find((attr) => attr.name === 'Badge')?.options[0],
      isLayaway: originalProduct.attributes.find((attr) => attr.name === 'Doresc produsul prin LAYAWAY')?.options.length > 0,
    };

    console.log('Product Data:', productData); // Afișează datele produsului
    return productData;
  } catch (error) {
    console.error('Eroare la preluarea datelor produsului:', error);
    throw error;
  }
};

  const [customFields, setCustomFields] = useState({
  brand: '',
  categorie: '',
  model: '',
  });

const handleFetchCustomFields = async () => {
  try {
    const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/leb-customfield-forciorna/${issueId}`);
    
    setCustomFields({
      brand: response.data.customFields.brand || 'Not available',
      categorie: response.data.customFields.categorie || 'Not available',
      model: response.data.customFields.model || 'Not available',
      stare: response.data.customFields.stare || 'Not available',
      descriereSpecifica: response.data.customFields.descriereSpecifica || 'Not available',
    });
    
  } catch (error) {
    console.error('Error fetching custom fields:', error);
  }
};


const handleSearchSimilarProducts = (product) => {
  const productData = {
    name: product.name || '',
    price: product.price || '',
    regularPrice: product.regular_price || '',
    salePrice: product.sale_price || '',
    sku: product.sku || '',
    categories: Array.isArray(product.categories) ? product.categories.map((cat) => cat.name) : [],
    description: product.description || '',
    shortDescription: product.short_description || '',
    externalColor: product.attributes?.find((attr) => attr.name === 'Culoare')?.options[0] || '',
    interiorColor: product.attributes?.find((attr) => attr.name === 'Material Interior')?.options[0] || '',
    exteriorMaterial: product.attributes?.find((attr) => attr.name === 'Material Exterior')?.options[0] || '',
    condition: product.attributes?.find((attr) => attr.name === 'Condiție')?.options[0] || '',
    length: product.dimensions?.length || '',
    height: product.dimensions?.height || '',
    depth: product.dimensions?.width || '',
    includedItems: product.attributes?.find((attr) => attr.name === 'Insotit')?.options || [],
    designerId: product.attributes?.find((attr) => attr.name === 'Designeri')?.options[0] || '',
    location: product.meta_data?.find((meta) => meta.key === 'Locație')?.value || '',
    sortlyId: product.meta_data?.find((meta) => meta.key === 'cod_inventar_sortly')?.value || '',
    layawayOption: product.attributes?.find((attr) => attr.name === 'Badge')?.options[0] || '',
    isLayaway: product.attributes?.find((attr) => attr.name === 'Doresc produsul prin LAYAWAY')?.options?.length > 0 || false,
  };

  // Deschide noua fereastră
  const newWindow = window.open('/create-product', '_blank');

  // Trimite datele după ce fereastra a fost încărcată
  newWindow.onload = () => {
    newWindow.postMessage({ productData }, '*'); // Trimite datele către fereastra secundară
  };
};


// În componenta CreateProductWoo, poți accesa productData astfel:
useEffect(() => {
  const productData = window.opener ? window.opener.productData : null;
  if (productData) {
    // Poți face ceva cu productData aici, cum ar fi să-l afișezi
    console.log('Received productData:', productData);
  }
}, []);


  useEffect(() => {
    handleFetchCustomFields();
  }, [issueId]);

  useEffect(() => {
    const fetchLastProductId = async () => {
      try {
        const response = await axios.get('https://www.luxuraelite.ro/wp-json/wc/v3/products', {
          params: {
            per_page: 1,
            orderby: 'id',
            order: 'desc'
          },
          headers: {
            Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`)
          }
        });
        const lastProductId = response.data[0]?.id || 0;
        setNextProductId(lastProductId + 1);
      } catch (error) {
        console.error('Error fetching last product ID:', error);
      }
    };

    fetchLastProductId();
  }, []);


  const resetState = () => {
    setExpanded(false);
    setCondition('');
    setDescription('');
    setQcStatus('');
    setFisaProdusStatus('');
    setPriceOption('');
    setCurrentPrice('');
    setNewPrice('');
    setAnchorEl(null);
    setOpenReportModal(false);
    setAwbData({
      street: '',
      number: '',
      postalCode: '',
      city: '',
      county: '',
      country: 'Romania',
      observations: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      pickupDate: '',
      scheduleDate: '',
    });
    setRejectionReason('');
    setSesiuniDisponibile([]);
    setSelectedSesiune('');
    setLinkedIssues([]);
    setFisaProdusId('');
    setFisaProdusIdStatus('');
    setCiornaPlatforma('Not available');
    setWooCommerceTitle('');
    setWooCommerceStatus('');
    setSelectedTags([]);
    setAvailableTags([]);
  };

  useEffect(() => {
    console.log('issueId s-a schimbat:', issueId);
    resetState();
    fetchQcStatus();
    fetchCiornaPlatforma();
  }, [issueId]);

const fetchProdusStatus = async (retryCount = 0) => {
  try {
    const response = await axios.get(
      `https://server-9p4hx.kinsta.app/api/tasks/prod-status/${summary}`
    );
    setFisaProdusStatus(response.data.status || 'Status necunoscut');
    setCondition(response.data.condition || '');
    setDescription(response.data.description || '');
    setFisaProdusId(response.data.id);
    setFisaProdusIdStatus(response.data.key);
  } catch (error) {
    console.error('Error fetching product status:', error);

    if (retryCount < 2) {
      console.log(`Retrying fetchProdusStatus... Attempt ${retryCount + 1}`);
      setTimeout(() => fetchProdusStatus(retryCount + 1), 1000);  // Așteaptă 1 secundă înainte de următoarea încercare
    } else {
      console.error('Max retry attempts reached for fetchProdusStatus');
    }
  }
};

useEffect(() => {
  if (summary) {
    fetchProdusStatus();
  }
}, [summary]);

  const fetchQcStatus = async () => {
    try {
      console.log('Fetching QC status pentru issueId:', issueId);
      const response = await axios.get(
        `https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`
      );
      const fetchedStatus = response.data.customfield_10188 || '';
      qcStatusRef.current = fetchedStatus;
      setQcStatus(fetchedStatus);
    } catch (error) {
      console.error('Error fetching QC status:', error);
    }
  };

  useEffect(() => {
    if (!qcStatusRef.current || qcStatusRef.current === 'Necreat') {
      getRenegotiationQcDetails();
    }
  }, [issueId]);

  useEffect(() => {
    fetchSesiuniDisponibile();
  }, []);

  const fetchCiornaPlatforma = async () => {
    try {
      console.log(`Fetching ciornaPlatforma for issueId: ${issueId}`);
      const response = await axios.get(
        `https://server-9p4hx.kinsta.app/api/tasks/leb-customfield/${issueId}`
      );
      console.log(`Fetched ciornaPlatforma: ${response.data.customFieldValue}`);
      setCiornaPlatforma(response.data.customFieldValue || 'Not available');
    } catch (error) {
      console.error('Error fetching Ciorna Platforma:', error);
    }
  };

  useEffect(() => {
    const fetchWooCommerceProduct = async () => {
      try {
        const consumerKey = 'ck_a941824be557253d3765a1f6242e6d6a590da999';
        const consumerSecret = 'cs_6b0d98e4c442e707be9888cafff1fac17cdbf259';
        const url = `https://luxuraelite.ro/wp-json/wc/v3/products?sku=${ciornaPlatforma}&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;

        console.log('Attempting to fetch product from WooCommerce with URL:', url);

        const response = await axios.get(url);

        if (response.data.length > 0) {
          const product = response.data[0];
          const cleanTitle = extractTitle(product.name);
          setWooCommerceTitle(cleanTitle);
          setWooCommerceStatus(product.status);
        } else {
          setWooCommerceTitle('Product not found');
          setWooCommerceStatus('Unavailable');
        }
      } catch (error) {
        console.error('Error fetching product from WooCommerce:', error);
      }
    };

    if (ciornaPlatforma !== 'Not available') {
      fetchWooCommerceProduct();
    }
  }, [ciornaPlatforma]);

      // Funcție pentru a gestiona clicul pe butonul de analiză
  const handleAnalysisClick = () => {
        setManualAvgPrice(customFieldValues.customfield_10073); // Setăm manualAvgPrice în TaskEdit
        setShowAnalysis(true);
    };

  const fetchAvailableTags = async () => {
    try {
      if (fisaProdusId) {
        const response = await axios.get(
          `https://server-9p4hx.kinsta.app/api/tasks/jira-available-tags/${fisaProdusId}`
        );
        const { availableTags, selectedTags } = response.data;
        if (Array.isArray(availableTags) && Array.isArray(selectedTags)) {
          setAvailableTags(availableTags);
          setSelectedTags(selectedTags);
        } else {
          setAvailableTags([]);
          setSelectedTags([]);
        }
      }
    } catch (error) {
      console.error('Error fetching available tags:', error);
    }
  };

  useEffect(() => {
    fetchAvailableTags();
  }, [fisaProdusId]);

  useEffect(() => {
    if (fisaProdusId) {
      fetchLinkedIssues(fisaProdusId);
    }
  }, [fisaProdusId]);

  const handleSaveCiornaPlatforma = async () => {
    try {
      console.log(`Saving ciornaPlatforma: ${ciornaPlatforma} for issueId: ${issueId}`);
      const response = await axios.put(
        `https://server-9p4hx.kinsta.app/api/tasks/leb-customfield/${issueId}`,
        {
          customFieldValue: ciornaPlatforma,
        }
      );

      if (response.data.success) {
        console.log('Ciorna Platforma saved successfully');
        alert('Valoarea SKU a fost salvată cu succes în JIRA.');
      } else {
        console.error('Failed to save Ciorna Platforma');
        alert('A apărut o eroare la salvarea valorii SKU.');
      }
    } catch (error) {
      console.error('Error saving Ciorna Platforma:', error);
      alert('A apărut o eroare la salvarea valorii SKU.');
    }
  };

  const fetchSesiuniDisponibile = async () => {
    try {
      const response = await axios.get(
        'https://server-9p4hx.kinsta.app/api/tasks/pdp-tasks'
      );
      setSesiuniDisponibile(response.data);
    } catch (error) {
      console.error('Error fetching sesiuni disponibile:', error);
    }
  };

  const handleSesiuneChange = async (event) => {
    const selectedKey = event.target.value;
    console.log('Sesiune selectată:', selectedKey);
    setSelectedSesiune(selectedKey);
    await fetchLinkedIssues(selectedKey);
  };

  const handleStatusCheck = async () => {
    if (!fisaProdusIdStatus) {
      console.error('Fișa Produs ID este null!');
      return;
    }

    console.log('Issue ID trimis pentru schimbarea statusului:', fisaProdusIdStatus);

    try {
      const response = await axios.post(
        `https://server-9p4hx.kinsta.app/api/tasks/jira-transition-prod`,
        {
          issueId: fisaProdusIdStatus,
          transitionId: 'CHECK',
        }
      );

      if (response.data.success) {
        console.log('Statusul a fost schimbat cu succes la "CHECK".');
        setFisaProdusStatus('CHECK');
        alert('Statusul a fost schimbat cu succes la "CHECK".');
      } else {
        console.error('Eroare la schimbarea statusului.');
        alert('A apărut o eroare la schimbarea statusului.');
      }
    } catch (error) {
      console.error('Error performing status check:', error);
      alert('A apărut o eroare la schimbarea statusului.');
    }
  };

  const fetchLinkedIssues = async (issueKey) => {
    try {
      const response = await axios.get(
        `https://server-9p4hx.kinsta.app/api/tasks/pdp-task-links/${issueKey}`
      );
      setLinkedIssues(response.data);
    } catch (error) {
      console.error('Error fetching linked issues:', error);
    }
  };

  useEffect(() => {
    if (fisaProdusId) {
      fetchLinkedIssues(fisaProdusId);
    }
  }, [fisaProdusId]);

  useEffect(() => {
    const fetchRejectionReason = async () => {
      try {
        const response = await axios.get(
          `https://server-9p4hx.kinsta.app/api/tasks/jira-task/rejection-reason/${issueId}`
        );
        const fetchedRejectionReason = response.data.rejectionReason || '';
        setRejectionReason(fetchedRejectionReason);
      } catch (error) {
        console.error('Error fetching rejection reason:', error);
      }
    };

    fetchRejectionReason();
  }, [issueId]);

  const handleAlocare = async () => {
    if (!selectedSesiune) {
      alert('Selectați o sesiune și asigurați-vă că Fișa Produs este încărcată.');
      return;
    }

    try {
      const response = await axios.post(
        'https://server-9p4hx.kinsta.app/api/tasks/link-tasks',
        {
          sourceTaskKey: selectedSesiune,
          targetTaskId: fisaProdusId,
          linkType: 'blocks',
        }
      );

      console.log('Link response:', response.data);
      if (response.data.message) {
        alert('Taskurile au fost legate cu  succes! ');
      }
    } catch (error) {
      console.error('Error linking tasks:', error);
      alert('A apărut o eroare la legarea taskurilor.');
    }
  };

  const getRenegotiationQcDetails = async () => {
    try {
      const response = await axios.get(
        `https://server-9p4hx.kinsta.app/api/qc/renegotiation-qc/${issueId}`
      );
      const { priceOption, newPrice, currentPrice } = response.data;
      setPriceOption(priceOption);
      setCurrentPrice(currentPrice);
      setNewPrice(newPrice);
    } catch (error) {
      console.error('Error fetching task details:', error);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleConditionChange = (event) => {
    setCondition(event.target.value);
  };

  const handleRejectionReasonChange = (event) => {
    setRejectionReason(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleRephraseText = async () => {
    try {
      const prompt = `Te rog să descrii starea articolului în conformitate cu condiția: ${condition}. Menține un ton decent și clar, conform standardelor unei consignatii de lux, și include o descriere concisă: ${description}. Max. 255 caractere.`;

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'chatgpt-4o-latest',
          messages: [{ role: 'user', content: prompt }],
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer sk-proj-e5fOLUf5FGmf8CE0cEW7T3BlbkFJ8C9KRkmZ2OZYSLR37A4k`,
          },
        }
      );

      const rephrasedDescription =
        response.data.choices[0].message.content.trim();
      console.log('Rephrased description:', rephrasedDescription);
      setDescription(rephrasedDescription);
    } catch (error) {
      console.error('Eroare la reformularea textului:', error);
    }
  };

  // Funcția pentru preluarea valorilor disponibile și selectate pentru Badge din proiectul PROD
const fetchProdBadges = async () => {
  try {
    const response = await axios.get(
      `https://server-9p4hx.kinsta.app/api/tasks/prod-task/${summary}`
    );
    const { customfield_10111 } = response.data;
    if (customfield_10111) {
      setAvailableBadges(customfield_10111.allowedValues || []);
      setSelectedBadges(customfield_10111.selectedValues || []);
    } else {
      console.log('customfield_10111 not found in the PROD response.');
      setAvailableBadges([]);
      setSelectedBadges([]);
    }
  } catch (error) {
    console.error('Error fetching badges from PROD:', error);
  }
};

// Apelăm funcția pentru a obține valorile pentru Badge din PROD
useEffect(() => {
  if (summary) {
    fetchProdBadges();
  }
}, [summary]);

// Funcția pentru preluarea valorilor disponibile și selectate pentru Badge-uri, folosind `fisaProdusId`, similar cu `fetchAvailableTags`
const fetchAvailableBadges = async () => {
  try {
    if (fisaProdusId) {
      const response = await axios.get(
        `https://server-9p4hx.kinsta.app/api/tasks/jira-available-badges/${fisaProdusId}` // Endpoint pentru badge-uri, similar cu cel pentru tags
      );
      const { availableBadges, selectedBadges } = response.data;
      if (Array.isArray(availableBadges) && Array.isArray(selectedBadges)) {
        setAvailableBadges(availableBadges);
        setSelectedBadges(selectedBadges);
      } else {
        setAvailableBadges([]);
        setSelectedBadges([]);
      }
    }
  } catch (error) {
    console.error('Error fetching available badges:', error);
  }
};

// Apelăm funcția pentru a obține valorile pentru Badge-uri atunci când `fisaProdusId` se schimbă
useEffect(() => {
  if (fisaProdusId) {
    fetchAvailableBadges();  // Obținem badge-urile la fel cum facem pentru tags
  }
}, [fisaProdusId]);

    // Funcție pentru gestionarea schimbărilor din checkbox-uri
  const handleBadgeChange = (badge) => {
    setSelectedBadges((prevSelected) => {
      if (prevSelected.includes(badge)) {
        return prevSelected.filter((selectedBadge) => selectedBadge !== badge);
      } else {
        return [...prevSelected, badge];
      }
    });
  };

// Funcția pentru salvarea badge-urilor selectate 
const handleSaveBadges = async () => {
  try {
    const updatePayload = {
      customfield_10111: selectedBadges  // Valorile selectate pentru Badge
    };
    await axios.put(`https://server-9p4hx.kinsta.app/api/tasks/update-prod-task/${fisaProdusId}`, updatePayload);
    alert('Badge-urile au fost salvate cu succes!');
  } catch (error) {
    console.error('Error saving badges:', error);
    alert('A apărut o eroare la salvarea badge-urilor.');
  }
};


// Funcția pentru salvarea tuturor datelor (inclusiv badge-urile)
const handleSaveConditionAndDescription = async () => {
  try {
    const updatePayload = {
      condition: condition, // Salvează condiția
      description: description, // Salvează descrierea
      selectedTags: selectedTags, // Salvează tag-urile
      customfield_10111: selectedBadges, // Salvează badge-urile (customfield_10111)
    };

    const updateResponse = await axios.put(
      `https://server-9p4hx.kinsta.app/api/tasks/update-prod-task/${fisaProdusId}`,
      updatePayload
    );

    console.log('Response from backend:', updateResponse.data);

    if (updateResponse.status === 200) {
      console.log('Condition, description, badge, and tags saved successfully in PROD.');
      alert('Datele au fost salvate cu succes!');
    } else {
      console.error('Failed to save condition, description, badge, and tags in PROD.');
      alert('Eroare la salvarea datelor.');
    }
  } catch (error) {
    console.error('Error saving condition, description, badge, and tags in PROD:', error);
    alert('A apărut o eroare la salvarea datelor.');
  }
};



  const handleQcStatusChange = async (status) => {
    try {
      const updatePayload = { customfield_10188: status };

      if (status === 'Respins') {
        updatePayload.customfield_10190 = rejectionReason;
      }

      await axios.put(
        `https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`,
        updatePayload
      );

      qcStatusRef.current = status;
      setQcStatus(status);
      onUpdateCustomField(status);
    } catch (error) {
      console.error('Error updating QC status:', error);
    }
  };

  const handleSaveRejectionReason = async () => {
    try {
      const response = await axios.put(
        `https://server-9p4hx.kinsta.app/api/tasks/jira-task/rejection-reason/${issueId}`,
        { rejectionReason }
      );

      if (response.data.success) {
        console.log('Rejection reason saved successfully.');
        alert('Motivul respingerii a fost salvat cu succes.');
      } else {
        console.error('Failed to save rejection reason.');
        alert('A apărut o eroare la salvarea motivului respingerii.');
      }
    } catch (error) {
      console.error('Error saving rejection reason:', error);
      alert('A apărut o eroare la salvarea motivului respingerii.');
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReportClick = () => {
    setOpenReportModal(true);
    handleMenuClose();
  };

  const handleCloseReportModal = () => {
    setOpenReportModal(false);
  };

  const getCardStyle = () => {
    switch (qcStatus) {
      case 'Acceptat':
        return { backgroundColor: 'rgba(0, 255, 0, 0.1)' };
      case 'Respins':
        return { backgroundColor: 'rgba(255, 255, 0, 0.1)' };
      case 'Fake':
        return { backgroundColor: 'rgba(255, 0, 0, 0.1)' };
      default:
        return {};
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAwbData({
      ...awbData,
      [name]: value,
    });
  };

const handleGenerateAwbAndReport = async () => {
    try {
        const finalAwbData = {
            ...awbData,
            contactName:  awbData.contactName  || contactName,
            contactPhone: awbData.contactPhone || contactPhone,
            contactEmail: awbData.contactEmail || contactEmail,
        };

        const endpoint = qcStatus === 'Fake' ? 'create-awb-qc-fake' : 'create-awb-qc';
        const response = await axios.post(
            `https://server-9p4hx.kinsta.app/api/tasks/${endpoint}`,
            {
                ...finalAwbData,
                issueId,
                category,
                rejectionReason: qcStatus === 'Respins' ? rejectionReason : undefined,
            }
        );

        if (response.status === 200) {
            console.log('AWB și raport generate cu succes');

            const { awbNumber, awbPdf, reportPdf, pdfFilename } = response.data;

            // Download AWB PDF
            const awbFileName = `AWB_${qcStatus}_${awbNumber}.pdf`;
            const awbBlob = new Blob(
                [Uint8Array.from(atob(awbPdf), (c) => c.charCodeAt(0))],
                { type: 'application/pdf' }
            );
            const awbUrl = URL.createObjectURL(awbBlob);
            const awbLink = document.createElement('a');
            awbLink.href = awbUrl;
            awbLink.setAttribute('download', awbFileName);
            document.body.appendChild(awbLink);
            awbLink.click();
            awbLink.parentNode.removeChild(awbLink);

            // Download Report PDF
            const notaReturBlob = new Blob(
                [Uint8Array.from(atob(reportPdf), (c) => c.charCodeAt(0))],
                { type: 'application/pdf' }
            );
            const notaReturUrl = URL.createObjectURL(notaReturBlob);
            const notaReturLink = document.createElement('a');
            notaReturLink.href = notaReturUrl;
            notaReturLink.setAttribute('download', pdfFilename);
            document.body.appendChild(notaReturLink);
            notaReturLink.click();
            notaReturLink.parentNode.removeChild(notaReturLink);

            // Send SMS to the client
            try {
                const smsResponse = await axios.post(
                    'https://server-9p4hx.kinsta.app/api/tasks/send-awb-respins-sms',
                    {
                        contactName: finalAwbData.contactName,
                        contactPhone: finalAwbData.contactPhone,
                    }
                );

                if (smsResponse.status === 200) {
                    console.log('AWB and report SMS sent successfully');
                } else {
                    console.error('Failed to send AWB and report SMS:', smsResponse.statusText);
                }
            } catch (smsError) {
                console.error('Error sending AWB and report SMS:', smsError.message || smsError);
            }
        } else {
            console.error('Failed to create AWB and report');
        }
    } catch (error) {
        console.error('Error creating AWB and report:', error);
    }
};


  const [customFieldValues, setCustomFieldValues] = useState({
    customfield_10066: '',
    customfield_10077: '',
    customfield_10072: '',
    customfield_10073: '',
    customfield_10080: '',
    customfield_10183: '',
    customfield_10173: '',
    customfield_10078: '',
    customfield_10165: '',
    customfield_10185: '',
    customfield_10186: '',
  });

const [customFieldOptions, setCustomFieldOptions] = useState({
  customfield_10080: [],
  customfield_10183: [],
  customfield_10173: [],
  customfield_10165: [],
  customfield_10185: []
});


useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
            const taskData = response.data;

            console.log('Fetched task data:', taskData); // Verifică ce date sunt primite

            setCustomFieldValues({
                customfield_10066: taskData.customfield_10066 || taskData.uniqueId || '',
                customfield_10077: taskData.customfield_10077 || '',
                customfield_10072: taskData.customfield_10072 || '',
                customfield_10073: taskData.customfield_10073 || '',
                customfield_10080: taskData.customfield_10080 || '',
                customfield_10183: taskData.customfield_10183 || '',
                customfield_10173: taskData.customfield_10173 || '',
                customfield_10078: taskData.customfield_10078 || '',
                customfield_10165: taskData.customfield_10165 || '',
                customfield_10185: taskData.customfield_10185 || '',
                customfield_10067: taskData.customfield_10067 || '',
                customfield_10069: taskData.customfield_10069 || '',
                customfield_10068: taskData.customfield_10068 || '',
                customfield_10186: taskData.customfield_10186 || '',
                customfield_10188: taskData.customfield_10188 || ''
            });

            const customFields = ["customfield_10161", "customfield_10160", "customfield_10080", "customfield_10183", "customfield_10173", "customfield_10165", "customfield_10185"];
            const options = {};

            for (const field of customFields) {
                const customFieldResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-custom-field-options/${field}`);
                options[field] = customFieldResponse.data;
                console.log(`Options for ${field}:`, customFieldResponse.data); // Verifică opțiunile returnate pentru fiecare câmp
            }

            setCustomFieldOptions(options);
        } catch (error) {
            console.error('There was an error fetching the task data!', error);
            setError('There was an error fetching the task data!');
        }
    };

    fetchData();
}, [issueId]);


const fetchCustomFields = async () => {
  try {
    const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
    const data = response.data;

    setCustomFieldValues({
      customfield_10066: data.customfield_10066 || '',
      customfield_10077: data.customfield_10077 || '',
      customfield_10072: data.customfield_10072 || '',
      customfield_10073: data.customfield_10073 || '',
      customfield_10080: data.customfield_10080 || '',
      customfield_10183: data.customfield_10183 || '',
      customfield_10173: data.customfield_10173 || '',
      customfield_10078: data.customfield_10078 || '',
      customfield_10165: data.customfield_10165 || '',
      customfield_10185: data.customfield_10185 || '',
      customfield_10111: data.customfield_10111 || [],  // Adăugăm aici noul customfield pentru Badge
      customfield_10186: data.customfield_10186 || '',
    });

    setCustomFieldOptions({
      customfield_10080: data.customfield_10080_options || [],
      customfield_10183: data.customfield_10183_options || [],
      customfield_10173: data.customfield_10173_options || [],
      customfield_10165: data.customfield_10165_options || [],
      customfield_10111: data.customfield_10111_options || [],  // Adăugăm opțiunile pentru customfield_10111
      customfield_10185: data.customfield_10185_options || [],
    });
  } catch (error) {
    console.error('Error fetching custom fields:', error);
  }
};


  useEffect(() => {
    if (issueId) {
      fetchCustomFields();
    }
  }, [issueId]);

  useEffect(() => {
    console.log('customFieldOptions:', customFieldOptions);
  }, [customFieldOptions]);

    const handleCustomFieldChange = (field, value) => {
        setCustomFieldValues(prevValues => ({
            ...prevValues,
            [field]: value
        }));
    };

  const handleUnlinkTask = async (sourceTaskKey, targetTaskKey) => {
    if (!sourceTaskKey || !targetTaskKey) {
      console.error('sourceTaskKey sau targetTaskKey este gol!');
      return;
    }

    try {
      const response = await axios.post(
        'https://server-9p4hx.kinsta.app/api/tasks/unlink-tasks',
        {
          sourceTaskKey,
          targetTaskKey,
        }
      );

      console.log('Unlink response:', response.data);

      setLinkedIssues((prevIssues) =>
        prevIssues.filter((issue) => issue.key !== targetTaskKey)
      );
    } catch (error) {
      console.error('Error unlinking tasks:', error);
    }
  };

const handleCheckboxChange = (field, value) => {
  setCustomFieldValues((prevValues) => {
    const isChecked = prevValues[field].includes(value);

    const updatedValues = isChecked
      ? prevValues[field].filter((item) => item !== value)
      : [...prevValues[field], value];

    return {
      ...prevValues,
      [field]: updatedValues,
    };
  });
};


// Funcția care trimite valorile în JIRA:
const handleSaveCustomFieldValues = async () => {
  try {
    const updatePayload = {
      customfield_10111: customFieldValues.customfield_10111,  // Salvăm valorile selectate pentru Badge
    };

    await axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, updatePayload);

    alert('Custom field saved successfully!');
  } catch (error) {
    console.error('Error saving custom field:', error);
  }
};





  return (
    <>
      <Card sx={{ width: '100%', marginBottom: 2, ...getCardStyle() }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              QC
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
          }
          title="Quality Check"
          subheader={
            <Typography sx={{ color: '#1E90FF', fontWeight: 'bold' }}>
              Status: {qcStatus}
            </Typography>
          }
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleReportClick}>Creare Raport</MenuItem>
        </Menu>
        <CardContent>
          <Alert
            severity="info"
            sx={{
              backgroundColor: '#E3F2FD',
              color: '#0D47A1',
              fontWeight: 'normal',
              marginBottom: 2,
            }}
          >
            Te rog pregateste pozele produsului inainte de crearea raportului.
          </Alert>

          {priceOption === 'Propunere pret nou' && (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: '#1E90FF',
                  fontWeight: 'bold',
                  marginBottom: 2,
                }}
              >
                Renegociere
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <TextField
                  label="Pret Actual"
                  value={currentPrice}
                  onChange={(e) => setCurrentPrice(e.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1E90FF',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1C86EE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1E90FF',
                      },
                    },
                  }}
                />
                <TextField
                  label="Pret Propus"
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                  variant="outlined"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1E90FF',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1C86EE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1E90FF',
                      },
                    },
                  }}
                />
              </Box>

              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', marginTop: 2 }}>
                <Button
                  variant="contained"
                  onClick={async () => {
                    await handleSaveNewPrice(); // Save new price in JIRA
                    fetchJiraFields(); // Fetch fields from JIRA after accepting the price
                  }}
                  sx={{
                    backgroundColor: '#32CD32',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    '&:hover': { backgroundColor: '#28A428' },
                  }}
                >
                  Pret Acceptat
                </Button>
                <Button
                  variant="contained"
                  onClick={handleRejectPrice}
                  sx={{
                    backgroundColor: '#FF6347',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    '&:hover': { backgroundColor: '#FF4500' },
                  }}
                >
                  Pret Refuzat
                </Button>
              </Box>

              {jiraFields && (
                <Box sx={{ marginTop: 3 }}>
                  <Typography variant="body1"><strong>Contract:</strong> {jiraFields.Contract}</Typography>
                  <Typography variant="body1"><strong>Nume:</strong> {jiraFields.nume}</Typography>
                  <Typography variant="body1"><strong>Telefon:</strong> {jiraFields.telefon}</Typography>
                  <Typography variant="body1"><strong>Email:</strong> {jiraFields.email}</Typography>
                  <Typography variant="body1"><strong>NrContract:</strong> {jiraFields.NrContract}</Typography>
                  <Typography variant="body1"><strong>Data Intrare:</strong> {jiraFields.DataIntrare}</Typography>
                  <Typography variant="body1"><strong>Pret:</strong> {jiraFields.Pret}</Typography>
                </Box>
              )}
            </>
          )}
          {/* Display Vendor Data if available */}
          {vendorData && (
            <Box sx={{ marginTop: 3 }}>
              <Typography><strong>Serie CI:</strong> {vendorData.vendor.serieCI}</Typography>
              <Typography><strong>Nr CI:</strong> {vendorData.vendor.nrCI}</Typography>

              {/* Address where categorie = 'ci' */}
              {vendorData.addresses && vendorData.addresses.some(addr => addr.categorie === 'ci') ? (
                <>
                  {vendorData.addresses
                    .filter(address => address.categorie === 'ci')
                    .map((address, index) => (
                      <Box key={index} sx={{ marginBottom: 1 }}>
                        <Typography><strong>Linia 1:</strong> {address.address_line1}</Typography>
                        <Typography><strong>Linia 2:</strong> {address.address_line2}</Typography>
                        <Typography><strong>Oras:</strong> {address.city}</Typography>
                        <Typography><strong>Judet:</strong> {address.state}</Typography>
                        <Typography><strong>Cod Postal:</strong> {address.postal_code}</Typography>
                        <Typography><strong>Tara:</strong> {address.country}</Typography>
                      </Box>
                    ))}
                </>
              ) : (
                <Typography variant="body1">Nu există adresa CI.</Typography>
              )}
                {/* Modern-styled button for "Creare Act Aditional" */}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
<Button
  variant="contained"
  onClick={handleCreareActAditional}
  sx={{
    backgroundColor: '#007BFF',
    color: 'white',
    fontWeight: 'bold',
    padding: '8px 16px',
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 123, 255, 0.3)',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#0056b3',
      boxShadow: '0 6px 18px rgba(0, 86, 179, 0.3)',
    },
  }}
>
  Creare Act Adițional
</Button>

              </Box>
          </Box>
          )}


        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'space-around' }}>
          <IconButton
            aria-label="Acceptat"
            onClick={() => handleQcStatusChange('Acceptat')}
          >
            <Typography variant="button" sx={{ color: '#32CD32' }}>
              Acceptat
            </Typography>
          </IconButton>
          <IconButton
            aria-label="Respins"
            onClick={() => handleQcStatusChange('Respins')}
          >
            <Typography variant="button" sx={{ color: '#FFD700' }}>
              Respins
            </Typography>
          </IconButton>
          <IconButton
            aria-label="Fake"
            onClick={() => handleQcStatusChange('Fake')}
          >
            <Typography variant="button" sx={{ color: '#FF4500' }}>
              Fake
            </Typography>
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {qcStatus === 'Respins' && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    color: '#1E90FF',
                    fontWeight: 'bold',
                    marginBottom: 2,
                  }}
                >
                  Motiv Respins
                </Typography>
                <Select
                  value={rejectionReason}
                  onChange={handleRejectionReasonChange}
                  displayEmpty
                  fullWidth
                  sx={{
                    marginBottom: 2,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1E90FF',
                      },
                      '&:hover fieldset': {
                        borderColor: '#1C86EE',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1E90FF',
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Selecteaza motivul
                  </MenuItem>
                  <MenuItem value="Stare generala uzata: Produsul prezinta semne evidente de uzura, cum ar fi zgarieturi, pete sau decolorari, care nu respecta standardele noastre de calitate.">
                    Stare generala uzata: Produsul prezinta semne evidente de uzura, cum ar fi zgarieturi, pete sau decolorari, care nu respecta standardele noastre de calitate.
                  </MenuItem>
                  <MenuItem value="Defecte structurale: Produsul are defecte structurale, cum ar fi cusaturi desfacute, fermoare defecte sau parti lipsa, care afecteaza functionalitatea si estetica.">
                    Defecte structurale: Produsul are defecte structurale, cum ar fi cusaturi desfacute, fermoare defecte sau parti lipsa, care afecteaza functionalitatea si estetica.
                  </MenuItem>
                  <MenuItem value="Piese neoriginale sau inlocuite: Am constatat ca anumite componente ale produsului nu sunt originale sau au fost inlocuite, ceea ce afecteaza autenticitatea si valoarea acestuia.">
                    Piese neoriginale sau inlocuite: Am constatat ca anumite componente ale produsului nu sunt originale sau au fost inlocuite, ceea ce afecteaza autenticitatea si valoarea acestuia.
                  </MenuItem>
                  <MenuItem value="Semne de reparatii neautorizate: Produsul prezinta semne de reparatii sau modificari care nu au fost realizate de un specialist autorizat, ceea ce reduce valoarea si integritatea sa.">
                    Semne de reparatii neautorizate: Produsul prezinta semne de reparatii sau modificari care nu au fost realizate de un specialist autorizat, ceea ce reduce valoarea si integritatea sa.
                  </MenuItem>
                  <MenuItem value="Lipsa documentatiei necesare: Produsul nu este insotit de documentatia necesara, cum ar fi certificate de autenticitate, facturi sau alte acte, ceea ce ne impiedica sa confirmam provenienta si autenticitatea.">
                    Lipsa documentatiei necesare: Produsul nu este insotit de documentatia necesara, cum ar fi certificate de autenticitate, facturi sau alte acte, ceea ce ne impiedica sa confirmam provenienta si autenticitatea.
                  </MenuItem>
                  <MenuItem value="Miros persistent: Produsul emana un miros persistent care nu poate fi eliminat si care nu este compatibil cu standardele noastre de calitate.">
                    Miros persistent: Produsul emana un miros persistent care nu poate fi eliminat si care nu este compatibil cu standardele noastre de calitate.
                  </MenuItem>
                  <MenuItem value="Culoare modificata: Culoarea produsului a suferit modificari semnificative, fie din cauza expunerii la soare, fie din alte cauze, ceea ce il face nepotrivit pentru vanzare.">
                    Culoare modificata: Culoarea produsului a suferit modificari semnificative, fie din cauza expunerii la soare, fie din alte cauze, ceea ce il face nepotrivit pentru vanzare.
                  </MenuItem>
                  <MenuItem value="Uzura interioara excesiva: Interiorul produsului prezinta semne de uzura excesiva, cum ar fi captuseala deteriorata, pete sau alte defecte, care afecteaza atractivitatea acestuia.">
                    Uzura interioara excesiva: Interiorul produsului prezinta semne de uzura excesiva, cum ar fi captuseala deteriorata, pete sau alte defecte, care afecteaza atractivitatea acestuia.
                  </MenuItem>
                  <MenuItem value="Materiale deteriorate: Materialele din care este confectionat produsul, cum ar fi pielea sau metalul, prezinta semne de deteriorare, cum ar fi crapaturi, rugina sau decolorarea.">
                    Materiale deteriorate: Materialele din care este confectionat produsul, cum ar fi pielea sau metalul, prezinta semne de deteriorare, cum ar fi crapaturi, rugina sau decolorarea.
                  </MenuItem>
                  <MenuItem value="Nerespectarea cerintelor de brand: Produsul nu respecta standardele de calitate si design specifice brandului, ceea ce ne impiedica sa il acceptam pentru vanzare in consignatia noastra.">
                    Nerespectarea cerintelor de brand: Produsul nu respecta standardele de calitate si design specifice brandului, ceea ce ne impiedica sa il acceptam pentru vanzare in consignatia noastra.
                  </MenuItem>
                  <MenuItem value="Pretul propus nu a fost acceptat: Dupa evaluarea produsului, pretul nou propus nu a fost acceptat de catre dumneavoastra, ceea ce ne impiedica sa continuam procesul de consignatie.">
                    Pretul propus nu a fost acceptat: Dupa evaluarea produsului, pretul nou propus nu a fost acceptat de catre dumneavoastra, ceea ce ne impiedica sa continuam procesul de consignatie.
                  </MenuItem>
                  <MenuItem value="Lipsa accesoriilor sau a componentelor mentionate: Produsul nu a fost insotit de toate accesoriile sau componentele mentionate in cererea initiala, ceea ce ne impiedica sa il acceptam pentru consignatie.">
                    Lipsa accesoriilor sau a componentelor mentionate: Produsul nu a fost insotit de toate accesoriile sau componentele mentionate in cererea initiala, ceea ce ne impiedica sa il acceptam pentru consignatie.
                  </MenuItem>
                </Select>
                {qcStatus === 'Respins' && (
                  <>
                    <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#1E90FF',
                          '&:hover': { backgroundColor: '#1C86EE' },
                        }}
                        onClick={handleSaveRejectionReason}
                      >
                        Salvează Motiv Respins
                      </Button>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    marginBottom: 2,
                  }}
                >
                  <TextField
                    label="Strada"
                    variant="outlined"
                    fullWidth
                    name="street"
                    value={awbData.street}
                    onChange={handleInputChange}
                  />
                  <Box sx={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label="Nr"
                      variant="outlined"
                      fullWidth
                      name="number"
                      value={awbData.number}
                      onChange={handleInputChange}
                    />
                    <TextField
                      label="Cod postal"
                      variant="outlined"
                      fullWidth
                      name="postalCode"
                      value={awbData.postalCode}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label="Oras"
                      variant="outlined"
                      fullWidth
                      name="city"
                      value={awbData.city}
                      onChange={handleInputChange}
                    />
                    <TextField
                      label="Judet"
                      variant="outlined"
                      fullWidth
                      name="county"
                      value={awbData.county}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <TextField
                    label="Observatii"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    name="observations"
                    value={awbData.observations}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#1E90FF',
                      '&:hover': { backgroundColor: '#1C86EE' },
                    }}
                    onClick={handleGenerateAwbAndReport}
                  >
                    Generează AWB & Raport
                  </Button>
                </Box>
              </>
            )}

            {qcStatus === 'Fake' && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    color: '#FF4500',
                    fontWeight: 'bold',
                    marginBottom: 2,
                  }}
                >
                  Produs Contrafăcut
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    marginBottom: 2,
                  }}
                >
                  <TextField
                    label="Strada"
                    variant="outlined"
                    fullWidth
                    name="street"
                    value={awbData.street}
                    onChange={handleInputChange}
                  />
                  <Box sx={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label="Nr"
                      variant="outlined"
                      fullWidth
                      name="number"
                      value={awbData.number}
                      onChange={handleInputChange}
                    />
                    <TextField
                      label="Cod postal"
                      variant="outlined"
                      fullWidth
                      name="postalCode"
                      value={awbData.postalCode}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', gap: '16px' }}>
                    <TextField
                      label="Oras"
                      variant="outlined"
                      fullWidth
                      name="city"
                      value={awbData.city}
                      onChange={handleInputChange}
                    />
                    <TextField
                      label="Judet"
                      variant="outlined"
                      fullWidth
                      name="county"
                      value={awbData.county}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <TextField
                    label="Observatii"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    name="observations"
                    value={awbData.observations}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box sx={{ textAlign: 'right', marginTop: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#FF4500',
                      '&:hover': { backgroundColor: '#FF6347' },
                    }}
                    onClick={handleGenerateAwbAndReport}
                  >
                    Generează AWB & Raport Fake
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Collapse>

        <Modal
          open={openReportModal}
          onClose={handleCloseReportModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <CreareRaport issueId={issueId} onBack={handleCloseReportModal} />
          </Box>
        </Modal>
      </Card>

      <Card
        sx={{
          width: '100%',
          marginBottom: 2,
          backgroundColor: 'rgba(173, 216, 230, 0.1)',
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
              FP
            </Avatar>
          }
          title="Fișa Produs"
          subheader={
            <Typography sx={{ color: '#1E90FF', fontWeight: 'bold' }}>
              Status: {fisaProdusStatus || 'Loading...'}
            </Typography>
          }
        />
        <CardContent>
          <Alert
            severity="info"
            sx={{
              backgroundColor: '#E3F2FD',
              color: '#0D47A1',
              fontWeight: 'normal',
              marginBottom: 2,
            }}
          >
            Statusul produsului este obținut din proiectul "PROD" pe baza
            rezumatului (summary).
          </Alert>
          <Typography
            variant="h6"
            sx={{
              color: '#1E90FF',
              fontWeight: 'bold',
              marginBottom: 2,
            }}
          >
            Conditie Luxura
          </Typography>
          <Select
            value={condition}
            onChange={handleConditionChange}
            displayEmpty
            fullWidth
            sx={{
              marginBottom: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#1E90FF',
                },
                '&:hover fieldset': {
                  borderColor: '#1C86EE',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#1E90FF',
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              Selectează Condiția
            </MenuItem>
            <MenuItem value="Nou">Nou</MenuItem>
            <MenuItem value="Excelent">Excelent</MenuItem>
            <MenuItem value="Foarte Bun">Foarte Bun</MenuItem>
            <MenuItem value="Bun">Bun</MenuItem>
            <MenuItem value="Rezonabil">Rezonabil</MenuItem>
          </Select>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              label="Caracteristici specifice"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={handleDescriptionChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1E90FF',
                  },
                  '&:hover fieldset': {
                    borderColor: '#1C86EE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1E90FF',
                  },
                },
              }}
            />
            <AnimatedIconButton onClick={handleRephraseText}>
              <AutoFixHighIcon />
            </AnimatedIconButton>
          </Box>

          <Typography
            variant="h6"
            sx={{
              color: '#1E90FF',
              fontWeight: 'bold',
              marginBottom: 2,
            }}
          >
            Insotit de:
          </Typography>

          <Autocomplete
            multiple
            options={availableTags}
            getOptionLabel={(option) => option}
            value={selectedTags}
            onChange={(event, newValue) => {
              setSelectedTags(newValue);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Selectează valorile"
                placeholder="Alege..."
              />
            )}
          />

<Box>
  <Typography variant="h6" sx={{ color: '#1E90FF', fontWeight: 'bold', marginBottom: 2 }}>
    Badge
  </Typography>

  {availableBadges.length > 0 ? (
    availableBadges.map((badge) => (
      <FormControlLabel
        key={badge}
        control={
          <Checkbox
            checked={selectedBadges.includes(badge)}
            onChange={() => handleBadgeChange(badge)}
          />
        }
        label={badge}
      />
    ))
  ) : (
    <Typography variant="body1">Nu există badge-uri disponibile.</Typography>
  )}
</Box>

          <Box sx={{ textAlign: 'right', marginTop: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#1E90FF',
                '&:hover': { backgroundColor: '#1C86EE' },
              }}
              onClick={handleSaveConditionAndDescription}
            >
              Save
            </Button>
            {fisaProdusStatus !== 'CHECK' && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#4CAF50',
                  '&:hover': { backgroundColor: '#45A049' },
                }}
                onClick={handleStatusCheck}
                style={{ marginLeft: '10px' }}
              >
                Status Check
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      <Card
        sx={{
          width: '100%',
          marginBottom: 2,
          backgroundColor: 'rgba(173, 216, 230, 0.1)',
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
              SP
            </Avatar>
          }
          title="Studio Programare"
          subheader={
            <Typography sx={{ color: '#1E90FF', fontWeight: 'bold' }}>
              Selectează o sesiune disponibilă
            </Typography>
          }
        />
        <CardContent>
          <Alert
            severity="info"
            sx={{
              backgroundColor: '#E3F2FD',
              color: '#0D47A1',
              fontWeight: 'normal',
              marginBottom: 2,
            }}
          >
            Selectează o sesiune de programare disponibilă din proiectul PDP.
          </Alert>

          {selectedSesiune && (
            <Typography
              variant="h6"
              sx={{
                color: '#1E90FF',
                fontWeight: 'bold',
                marginBottom: 2,
              }}
            >
              Detalii Sesiune: {selectedSesiune}
            </Typography>
          )}
          <Select
            value={selectedSesiune}
            onChange={handleSesiuneChange}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              Selectează o sesiune
            </MenuItem>
            {sesiuniDisponibile.map((sesiune) => (
              <MenuItem key={sesiune.id} value={sesiune.key}>
                {sesiune.summary}
              </MenuItem>
            ))}
          </Select>

          <Box>
            <Typography
              variant="h6"
              sx={{
                color: '#1E90FF',
                fontWeight: 'bold',
                marginBottom: 2,
              }}
            >
              Articole programate - {linkedIssues.length}
            </Typography>
            <List>
              {linkedIssues.length > 0 ? (
                linkedIssues.map((issue) => (
                  <ListItem
                    key={issue.key}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          if (!fisaProdusId) {
                            console.error('fisaProdusId este gol!');
                            return;
                          }
                          handleUnlinkTask(fisaProdusId, issue.key);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={issue.summary}
                      secondary={issue.key}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="Nu există articole programate in aceasta sesiune." />
                </ListItem>
              )}
            </List>
          </Box>

          <Box sx={{ textAlign: 'center', marginTop: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAlocare}
            >
              Alocare
            </Button>
          </Box>
        </CardContent>
      </Card>

<Card
  sx={{
    width: '100%',
    marginBottom: 2,
    backgroundColor: 'rgba(240, 128, 128, 0.1)',
  }}
>
  <CardHeader
    avatar={
      <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
        CP
      </Avatar>
    }
    title="Ciorna Produs"
    subheader={
      <Typography sx={{ color: '#1E90FF', fontWeight: 'bold' }}>
        Status Platforma: {wooCommerceStatus || 'Loading...'}
      </Typography>
    }
  />
  <CardContent>
    <Alert
      severity="info"
      sx={{
        backgroundColor: '#E3F2FD',
        color: '#0D47A1',
        fontWeight: 'normal',
        marginBottom: 2,
      }}
    >
      Statusul ciornei este obținut din proiectul "LEB" pe baza issueId-ului.
    </Alert>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <TextField
        label="SKU"
        variant="outlined"
        fullWidth
        value={ciornaPlatforma || ''}
        onChange={(e) => setCiornaPlatforma(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#1E90FF',
            },
            '&:hover fieldset': {
              borderColor: '#1C86EE',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1E90FF',
            },
          },
        }}
      />
      <IconButton onClick={handleSaveCiornaPlatforma} sx={{ color: '#1E90FF' }}>
        <SaveIcon />
      </IconButton>
    </Box>
    <Typography
      variant="body2"
      sx={{
        color: '#1E90FF',
        marginTop: 2,
        fontSize: '1.2rem',
      }}
    >
      {wooCommerceTitle || 'Loading...'}
    </Typography>

    {/* Adăugați aici Accordionul pentru Date Receptie */}
<Accordion sx={{ marginTop: 2 }}>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography variant="h6" sx={{ color: '#1E90FF', fontWeight: 'bold' }}>
      Date Receptie
    </Typography>
  </AccordionSummary>
  <AccordionDetails>
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', marginTop: '20px' }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Tip Achizitie:
          </Typography>
        <Select
          value={customFieldValues.customfield_10080}
          onChange={(e) => handleCustomFieldChange('customfield_10080', e.target.value)}
          variant="standard"
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ marginLeft: '0px', fontSize: '16px' }}
        >
          <MenuItem value="" disabled>Select Tip Achizitie</MenuItem>
          {customFieldOptions.customfield_10080.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Luxura Policy:
          </Typography>
          <Select
            value={customFieldValues.customfield_10183}
            onChange={(e) => handleCustomFieldChange('customfield_10183', e.target.value)}
            variant="standard"
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ marginLeft: '0px', fontSize: '16px' }}
          >
            <MenuItem value="" disabled>Select Luxura Policy</MenuItem>
            {customFieldOptions.customfield_10183.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Data de Intrare:
          </Typography>
          <TextField
            type="date"
            value={customFieldValues.customfield_10077}
            onChange={(e) => handleCustomFieldChange('customfield_10077', e.target.value)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: '16px', textAlign: 'center' },
            }}
            sx={{ marginLeft: '0px' }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Expira Contract:
          </Typography>
          <TextField
            type="date"
            value={customFieldValues.customfield_10078}
            onChange={(e) => handleCustomFieldChange('customfield_10078', e.target.value)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: '16px', textAlign: 'center' },
            }}
            sx={{ marginLeft: '0px' }}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Nr Jurnal:
          </Typography>
          <TextField
            value={customFieldValues.customfield_10066}
            onChange={(e) => handleCustomFieldChange('customfield_10066', e.target.value)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: '16px', textAlign: 'center' },
            }}
            sx={{ marginLeft: '0px' }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Categorie:
          </Typography>
          <Select
            value={customFieldValues.customfield_10173}
            onChange={(e) => handleCustomFieldChange('customfield_10173', e.target.value)}
            variant="standard"
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ marginLeft: '0px', fontSize: '16px' }}
          >
            <MenuItem value="" disabled>Select Categorie</MenuItem>
            {customFieldOptions.customfield_10173.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Preț de Intrare (RON):
          </Typography>
          <TextField
            type="number"
            value={customFieldValues.customfield_10072}
            onChange={(e) => handleCustomFieldChange('customfield_10072', parseFloat(e.target.value))}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: { fontSize: '16px', textAlign: 'center' },
            }}
            sx={{ marginLeft: '0px' }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" component="h3" sx={{ marginLeft: '0px' }}>
            Preț de Vânzare (RON):
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '0px' }}>
            <TextField
              type="number"
              value={customFieldValues.customfield_10073}
              onChange={(e) => handleCustomFieldChange('customfield_10073', parseFloat(e.target.value))}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: { fontSize: '16px', textAlign: 'center' },
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  </AccordionDetails>
</Accordion>
  {/* Adăugați aici noul Accordion pentru Creare Ciorna */}
  <Accordion sx={{ marginTop: 2 }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6" sx={{ color: '#1E90FF', fontWeight: 'bold' }}>
        Creare Ciorna
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <>
        {/* Primul rând - Brand și Categorie */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '20px', gap: '5px' }}>
          <Box sx={{ flex: '1 1 50%' }}>
            <Typography variant="subtitle1" component="h3" sx={{ marginBottom: '8px' }}>
              Brand:
            </Typography>
            <TextField
              value={customFields.brand}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#1E90FF' },
                  '&:hover fieldset': { borderColor: '#1C86EE' },
                  '&.Mui-focused fieldset': { borderColor: '#1E90FF' },
                },
                fontSize: '14px',
              }}
            />
          </Box>
          <Box sx={{ flex: '1 1 50%' }}>
            <Typography variant="subtitle1" component="h3" sx={{ marginBottom: '8px' }}>
              Categorie:
            </Typography>
            <TextField
              value={customFields.categorie}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#1E90FF' },
                  '&:hover fieldset': { borderColor: '#1C86EE' },
                  '&.Mui-focused fieldset': { borderColor: '#1E90FF' },
                },
                fontSize: '14px',
              }}
            />
          </Box>
        </Box>

        {/* Al doilea rând - Model */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" component="h3" sx={{ marginBottom: '8px' }}>
              Model:
            </Typography>
            <TextField
              value={customFields.model}
              variant="outlined"
              fullWidth
              InputProps={{ readOnly: true }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: '#1E90FF' },
                  '&:hover fieldset': { borderColor: '#1C86EE' },
                  '&.Mui-focused fieldset': { borderColor: '#1E90FF' },
                },
                fontSize: '14px',
              }}
            />
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#1E90FF',
              '&:hover': { backgroundColor: '#1C86EE' },
            }}
            onClick={handleFilter} // Utilizează funcția de filtrare
          >
            Caută Produse Asemănătoare
          </Button>
        </Box>


        <Box>
          {/* Afișarea produselor filtrate */}
          {filteredProducts.length > 0 && (
            <Box>
              {filteredProducts.map((product) => (
                <Card key={product.sku}>
                  <Checkbox
                    checked={selectedProductIds.includes(product.sku)}
                    onChange={() => handleProductSelect(product.sku)}
                  />
                  <CardMedia component="img" image={product.df_image_link} alt={product.name} />
                  <Typography>{product.name}</Typography>
                </Card>
              ))}
            </Box>
          )}

          {/* Buton pentru crearea ciornei */}
          <Button variant="contained" onClick={handleCreateDraftClick}>
            Creare Ciorna
          </Button>

          {/* Afișează componenta CreateProductWoo cu datele produsului selectat */}
          {showCreateProduct && productData && <CreateProductWoo productData={productData} />}
      </Box>

    </>

    </AccordionDetails>
  </Accordion>


  </CardContent>
</Card>
    </>
  );
}