import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CardMedia, Select, MenuItem, FormControl, Checkbox, Button, TextField } from '@mui/material';
import { CardActions, IconButton, Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const brandList = [
  '3.1 Phillip Lim', 'Alaia', 'Alexander McQueen', 'Alexander Wang', 'Anita Ko',
  'Audemars Piguet', 'Balenciaga', 'Bottega Veneta', 'Boucheron', 'Breguet', 'Breitling',
  'Buccellati', 'Bulgari', 'Burberry', 'Cartier', 'Celine', 'Chanel', 'Chloe', 'Chopard',
  'Christian Dior', 'Christian Louboutin', 'Damiani', 'David Yurman', 'Delvaux', 'Dolce Gabbana',
  'Elizabeth Locke', 'FASHIONPHILE swag', 'Fendi', 'Foundrae', 'Franck Muller', 'Givenchy', 'Goyard',
  'Graff', 'Gucci', 'Harry Winston', 'Hermes', 'Hublot', 'Irene Neuwirth', 'IWC', 'Jacquemus',
  'Jaeger-LeCoultre', 'Jennifer Meyer', 'Jimmy Choo', 'John Hardy', 'Judith Leiber', 'Khaite', 'Lanvin',
  'Loewe', 'Loro Piana', 'Louis Vuitton', 'Mansur Gavriel', 'Marc Jacobs', 'Marco Bicego', 'MCM',
  'Messika', 'Mikimoto', 'Miu Miu', 'Moynat', 'Mulberry', 'Off-White', 'Oliver Peoples', 'Omega',
  'Panerai', 'Pasquale Bruni', 'Patek Philippe', 'Phoebe Philo', 'Piaget', 'Pomellato', 'Prada',
  'Proenza Schouler', 'Rimowa', 'Roberto Coin', 'Rolex', 'Saint Laurent', 'Salvatore Ferragamo',
  'Stella McCartney', 'Suzanne Kalan', 'Tag Heuer', 'Telfar', 'Temple St. Clair', 'The Row', 'Tiffany',
  'Tom Ford', 'TUDOR', 'Vacheron Constantin', 'Valentino Garavani', 'Van Cleef & Arpels', 'Versace'
];

const conditionMapping = {
  'New': 'Nou',
  'Never worn': 'Nou',
  'Never warn with tag': 'Nou',
  'Giftable': 'Nou',
  'Condition: New': 'Nou',
  'Condition: Giftable': 'Nou',
  'Excellent': 'Excelent',
  'Condition: Excellent': 'Excelent',
  'Very Good': 'Foarte Bun',
  'Condition: Very Good': 'Foarte Bun',
  'Very good condition': 'Foarte Bun',
  'Good': 'Bun',
  'Condition: Good': 'Bun',
  'Good Condition': 'Bun',
  'Fair': 'Rezonabil',
  'Fair condition': 'Rezonabil'
};

const conditionList = ['Nou', 'Excelent', 'Foarte Bun', 'Bun', 'Rezonabil'];

const categoryMapping = {
  'Handbags': 'Genti',
  'Shoes': 'Incaltaminte',
  'Accesories': 'Accesorii',
  'Backpacks': 'Genti',
  'Belt Bags': 'Genti',
  'Clutch & Evening': 'Genti',
  'Crossbody': 'Genti',
  'Handbags': 'Genti',
  'Men\'s Bags': 'Genti',
  'Professional': 'Genti',
  'Shoulder Bags': 'Genti',
  'Totes': 'Genti',
  'Travel & Luggage': 'Genti',
  'Wallet Style': 'Genti',
  'Agendas': 'Accesorii',
  'Bag Charms': 'Accesorii',
  'Belts': 'Accesorii',
  'Cosmetic Cases': 'Accesorii',
  'Extra Straps': 'Accesorii',
  'Fashionphile Merchandise': 'Accesorii',
  'Gloves': 'Accesorii',
  'Hair Accessories': 'Accesorii',
  'Hats': 'Accesorii',
  'Household Items': 'Accesorii',
  'Key Rings': 'Accesorii',
  'Luggage Tags': 'Accesorii',
  'Men\'s Accessories': 'Accesorii',
  'Men\'s Wallets': 'Accesorii',
  'Pet Accessories': 'Accesorii',
  'Phone/Tablet Case': 'Accesorii',
  'Pins & Brooches': 'Accesorii',
  'Pouches': 'Accesorii',
  'Scarves': 'Accesorii',
  'Shoes': 'Incaltaminte',
  'Sunglasses': 'Accesorii',
  'T-Shirt': 'Accesorii',
  'Wallets': 'Accesorii'
};

const categoryList = ['Genti', 'Incaltaminte', 'Accesorii'];

function ProductCard({ product, onDelete, id, onCheckboxChange }) {
  const [priceInRON, setPriceInRON] = useState(null);
  const [purchasePriceInRON, setPurchasePriceInRON] = useState(null);
  const [brand, setBrand] = useState(product.Brand);
  const [condition, setCondition] = useState(conditionMapping[product.Conditio] || product.Conditio);
  const [category, setCategory] = useState(categoryMapping[product.Category] || product.Category);
  const [model, setModel] = useState(product.Model);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (product.Provider === 'Fashionphile' && product.Currency === 'USD') {
      axios.get(`https://api.exchangerate-api.com/v4/latest/USD`)
        .then(response => {
          const rate = response.data.rates.RON;
          setPriceInRON((product.Price * rate).toFixed(2));
          setPurchasePriceInRON((product.Purchase_price * rate).toFixed(2));
        })
        .catch(error => console.error('Error fetching exchange rate:', error));
    }
  }, [product.Price, product.Purchase_price, product.Provider, product.Currency]);

  const handleBrandChange = (event) => {
    setBrand(event.target.value);
  };

  const handleConditionChange = (event) => {
    setCondition(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);
    onCheckboxChange(product.id, isChecked);
  };

  const handleValidate = async () => {
    const updatedProduct = {
      ...product,
      Brand: brand,
      Conditio: condition,
      Category: category,
      Model: model
    };

    console.log('Validating product:', updatedProduct);

    try {
      await axios.post(`https://server-9p4hx.kinsta.app/api/masterdata/validate-product`, [updatedProduct]);
      alert('Produsul a fost validat și salvat în baza de date.');
    } catch (error) {
      console.error('Eroare la validarea produsului:', error);
      alert('Eroare la validarea produsului.');
    }
  };

  const handleDelete = async () => {
    try {
      const response = await axios.post(`https://server-9p4hx.kinsta.app/api/masterdata/delete-product`, { id: product.id });
      if (response.status === 200) {
        onDelete(id);
      } else {
        console.error('Eroare la ștergerea produsului:', response.data);
        alert('Eroare la ștergerea produsului.');
      }
    } catch (error) {
      console.error('Eroare la ștergerea produsului:', error);
      alert('Eroare la ștergerea produsului.');
    }
  };

  return (
    <Card>
      <CardMedia
        component="img"
        height="auto"
        image={product.Image}
        alt={product.Name}
      />
      <CardContent>
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <Select
            value={brand}
            onChange={handleBrandChange}
            displayEmpty
          >
            {brandList.map((brand, index) => (
              <MenuItem key={index} value={brand}>{brand}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <Select
            value={condition}
            onChange={handleConditionChange}
            displayEmpty
          >
            {conditionList.map((condition, index) => (
              <MenuItem key={index} value={condition}>{condition}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <Select
            value={category}
            onChange={handleCategoryChange}
            displayEmpty
          >
            {categoryList.map((category, index) => (
              <MenuItem key={index} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <TextField
            label="Model"
            value={model}
            onChange={handleModelChange}
            variant="outlined"
          />
        </FormControl>
        <Typography variant="subtitle1">{product.Namemodel}</Typography>
        <Typography variant="body2">Preț: {priceInRON ? `${priceInRON} RON` : `${product.Price} ${product.Currency}`}</Typography>
        <Typography variant="body2">Preț de achiziție: {purchasePriceInRON ? `${purchasePriceInRON} RON` : `${product.Purchase_price} USD`}</Typography>
        <Typography variant="body2">Condiție: {condition}</Typography>
        <Typography variant="body2">Subcategorie: {product.Subcategory}</Typography>
        <Typography variant="body2">Provider: {product.Provider}</Typography>
        <Typography variant="body2">SKU: {id}</Typography>
        <Typography variant="body2">Culoare: {product.Color}</Typography>
        <Typography variant="body2">Vine insotit cu: {product.ComesWith}</Typography>
        <Typography variant="body2">De la: {product.Published}</Typography>
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="body2">Articol Verificat</Typography>
          </Box>
        </FormControl>
      </CardContent>
      <CardActions>
        <IconButton color="primary">
          <FavoriteIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {product.Likes} likes
        </Typography>
        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <Typography
            onClick={handleValidate}
            style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
          >
            Validează
          </Typography>
          <IconButton color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardActions>
    </Card>
  );
}

export default ProductCard;
