import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Button, IconButton, Snackbar, FormHelperText, Paper, TextField, Box, Typography, Container, Select, MenuItem, FormControl, InputLabel, Alert, CircularProgress, InputAdornment } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AssistantSharpIcon from '@mui/icons-material/AssistantSharp';

function CreareRaport({ issueId }) {
  const [selectedTask, setSelectedTask] = useState(null);
  const [priceOption, setPriceOption] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [accompaniedItems, setAccompaniedItems] = useState([]);
  const [newItem, setNewItem] = useState('');
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([null, null, null, null]);
  const [errors, setErrors] = useState({});
  const [messages, setMessages] = useState([]);

  // Stări pentru Snackbar-uri
  const [emailSuccess, setEmailSuccess] = useState(false);  // pentru trimiterea emailului
  const [pdfCreated, setPdfCreated] = useState(false);      // pentru crearea PDF-ului
  const [jiraUploaded, setJiraUploaded] = useState(false);  // pentru încărcarea în JIRA

  const [isProcessing, setIsProcessing] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showPdfCreated, setShowPdfCreated] = useState(false);
  const [showJiraUploaded, setShowJiraUploaded] = useState(false);

  const materialLabelsToKeys = {
    "Exterior": "exterior",
    "Interior": "interior",
    "Forma": "shape",
    "Hardware": "hardware",
    "Fermoare-Inchideri": "zippers",
    "Finisaje": "finishes",
    "Mirosuri": "smell",
    "Talpa": "sole",
    "Cute": "creases",
    "Suprafețe": "surfaces",
    "Pietre": "stones",
    "Cadran": "dial",
    "Bratara-Curea": "strapBracelet",
    "Geam": "glass",
    "Spate": "caseBack",
    "Mecanism": "mechanism",
  };

  // Verificăm dacă issueId este definit înainte de a face orice operațiune
  useEffect(() => {
    if (issueId) {
      fetchTaskDetailsFromBackend();
    } else {
      console.error('issueId is undefined or null');
      setErrors(prevErrors => ({ ...prevErrors, issueId: 'issueId is undefined or null' }));
    }
  }, [issueId]);

  const fetchTaskDetailsFromBackend = async () => {
    try {
      console.log(`Fetching task details for issueId: ${issueId}`);
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
      console.log('Response data from JIRA:', response.data); // Printează datele primite

      if (response.data) {
        const taskDetails = {
          summary: response.data.summary,
          entryPrice: response.data.customfield_10072, // Preț negociat
          entryDate: response.data.customfield_10077, // Data de intrare
          productCategory: productCategoryMap[response.data.customfield_10173] || 'N/A', // Categorie Prod
          fullName: response.data.name, // Nume și Prenume
          email: response.data.email, // Email
          phone: response.data.phone, // Telefon
          issue_id: issueId,
          materials: {}, 
          authenticity: '',
          inspector: '',
          Observations: '',
          images: [] // Inițializează `images` ca un array gol
        };

        setSelectedTask(taskDetails);
        saveInitialTaskData(taskDetails);
      } else {
        console.error('No data returned from backend');
      }
    } catch (error) {
      console.error("Eroare la preluarea datelor din backend:", error.message || error);
    }
  };

  const productCategoryMap = {
    10207: 'Genti',
    10208: 'Incaltaminte',
    10209: 'Accesorii',
    10210: 'Bijuterii',
    10211: 'Ceasuri',
    10212: 'Imbracaminte',
  };

  const saveInitialTaskData = async (taskDetails) => {
    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/qc/save-quality-check', {
        TaskID: taskDetails.issue_id,
        Summary: taskDetails.summary,
        EntryPrice: taskDetails.entryPrice,  // Folosește direct valoarea din taskDetails
        EntryDate: taskDetails.entryDate,    // Folosește direct valoarea din taskDetails
        ProductCategory: taskDetails.productCategory,  // Folosește direct valoarea din taskDetails
        FullName: taskDetails.fullName,  // Folosește direct valoarea din taskDetails
        Email: taskDetails.email,        // Folosește direct valoarea din taskDetails
        Phone: taskDetails.phone,        // Folosește direct valoarea din taskDetails
        Status: "New"
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Task-ul a fost salvat cu succes.');
    } catch (error) {
      console.error("Eroare la salvarea task-ului:", error.response?.data?.message || error.message);
    }
  };

const handleMaterialChange = (prop) => (event) => {
  const updatedMaterials = {
    ...selectedTask.materials,
    [prop]: event.target.value.match(/^([1-9]|10)$/) ? event.target.value : ''
  };

  setSelectedTask(prevState => ({
    ...prevState,
    materials: updatedMaterials
  }));
};

  const handleItemChange = (index) => (event) => {
    const updatedAccompaniedItems = [...accompaniedItems];
    updatedAccompaniedItems[index] = event.target.value;
    setAccompaniedItems(updatedAccompaniedItems);
    setSelectedTask(prevState => ({
      ...prevState,
      accompaniedItems: JSON.stringify(updatedAccompaniedItems)
    }));
  };

  const handleDeleteItem = (index) => {
    const updatedAccompaniedItems = [...accompaniedItems];
    updatedAccompaniedItems.splice(index, 1);
    setAccompaniedItems(updatedAccompaniedItems);
    setSelectedTask(prevState => ({
      ...prevState,
      accompaniedItems: JSON.stringify(updatedAccompaniedItems)
    }));
  };

  const handleAddItem = () => {
    if (newItem.trim() !== '') {
      const updatedAccompaniedItems = [...accompaniedItems, newItem];
      setAccompaniedItems(updatedAccompaniedItems);
      setSelectedTask(prevState => ({
        ...prevState,
        accompaniedItems: updatedAccompaniedItems
      }));
      setNewItem('');
    }
  };

const handlePriceOptionChange = (event) => {
  const updatedPriceOption = event.target.value;
  setPriceOption(updatedPriceOption);
  setSelectedTask(prevState => ({
    ...prevState,
    priceOption: updatedPriceOption
  }));
  if (updatedPriceOption !== 'Propunere pret nou') {
    setNewPrice('');
    setSelectedTask(prevState => ({
      ...prevState,
      newPrice: null
    }));
  }
};

const handleNewPriceChange = (event) => {
  const updatedNewPrice = event.target.value;
  setNewPrice(updatedNewPrice);
  setSelectedTask(prevState => ({
    ...prevState,
    newPrice: updatedNewPrice ? parseFloat(updatedNewPrice) : null
  }));
};

const handleObservationChange = (event) => {
  const updatedObservation = event.target.value;
  setSelectedTask(prevState => ({
    ...prevState,
    Observations: updatedObservation
  }));
};

  const handleImageChange = (index) => async (event) => {
    const file = event.target.files[0];
    if (file) {
      const localImageUrl = URL.createObjectURL(file);

      setImageFiles(prevFiles => {
        const newFiles = [...prevFiles];
        newFiles[index] = {
          previewUrl: localImageUrl,
          serverPath: ''
        };
        return newFiles;
      });

      try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await axios.post('https://server-9p4hx.kinsta.app/api/qc/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setImageFiles(prevFiles => {
          const newFiles = [...prevFiles];
          newFiles[index] = {
            ...newFiles[index],
            serverPath: response.data.filePath
          };
          return newFiles;
        });

        setSelectedTask(prevTask => ({
          ...prevTask,
          images: [...(prevTask.images || []), response.data.filePath] // Folosește un array gol dacă `images` este null/undefined
        }));
      } catch (error) {
        console.error("Eroare la încărcarea imaginii:", error);
        setImageFiles(prevFiles => prevFiles.map((item, idx) => idx === index ? null : item));
      }
    }
  };

  const handleRemoveImage = async (index) => {
    const imageToDelete = imageFiles[index];
    if (!imageToDelete) return;

    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/qc/delete-image', { imagePath: imageToDelete.serverPath });

      setImageFiles(prevFiles => prevFiles.map((file, idx) => idx === index ? null : file));
      URL.revokeObjectURL(imageToDelete.previewUrl);

      setSelectedTask(prevTask => ({
        ...prevTask,
        images: prevTask.images.filter((_, i) => i !== index)
      }));
    } catch (error) {
      console.error("Eroare la ștergerea imaginii de pe server:", error);
    }
  };

  const handleRepaintedChange = (event) => {
    setSelectedTask(prevState => ({
      ...prevState,
      repainted: event.target.value
    }));
  };

  const handleRepairedChange = (event) => {
    setSelectedTask(prevState => ({
      ...prevState,
      repaired: event.target.value
    }));
  };

// Definirea funcției `uploadToJiraForQualityCheck`
const uploadToJiraForQualityCheck = async (pdfBufferBase64, issueId, contactName, timestamp) => {
    try {
        // Verificăm dacă șirul base64 este corect și complet
        if (!pdfBufferBase64 || pdfBufferBase64.length % 4 !== 0) {
            throw new Error("PDF Base64 string is not valid or has incorrect length.");
        }

        const [firstName, ...lastName] = contactName.split(' ');
        const lastNameStr = lastName.join(' ');

        const fileName = `QualityCheck_${issueId}-${timestamp}.pdf`;

        // Convertim pdfBufferBase64 într-un Uint8Array
        const byteCharacters = atob(pdfBufferBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const formData = new FormData();
        formData.append('file', new Blob([byteArray], { type: 'application/pdf' }), fileName);

        const response = await axios.post(
            `${process.env.REACT_APP_JIRA_BASE_URL}/issue/${issueId}/attachments`,
            formData,
            {
                headers: {
                    'Authorization': `Basic ${btoa(`${process.env.REACT_APP_JIRA_EMAIL}:${process.env.REACT_APP_JIRA_API_TOKEN}`)}`,
                    'X-Atlassian-Token': 'no-check',
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.status === 200) {
            console.log(`QualityCheck PDF uploaded successfully to JIRA with filename: ${fileName}`);
            setJiraUploaded(true); // Setează starea pentru încărcarea în JIRA
        } else {
            console.error('Failed to upload QualityCheck PDF to JIRA:', response.statusText);
        }
    } catch (error) {
        console.error('Error uploading QualityCheck PDF to JIRA:', error);
    }
};

  const handleSubmit = async (desiredStatus = "Done") => {
      // Validate fields before submitting data
      const validationErrors = validateFields();
      if (Object.keys(validationErrors).length > 0) {
          return;
      }

      // Format entry date and filter valid image files
      const formattedEntryDate = selectedTask.entryDate ? new Date(selectedTask.entryDate).toISOString().slice(0, 10) : '';
      const filteredImageFiles = imageFiles
          .filter(file => file != null && file.serverPath != null)
          .map(file => file.serverPath);

      // Calculate total points and general condition
      const { total, condition } = calculateTotalAndCondition();

      // Prepare the data object to be sent to the backend
      const updatedTask = {
          TaskID: selectedTask.issue_id,
          PriceOption: priceOption,
          NewPrice: newPrice ? parseFloat(newPrice) : null,
          Authenticity: selectedTask.authenticity,
          AccompaniedItems: JSON.stringify(accompaniedItems),
          Observations: selectedTask.Observations,
          Puncte: total,
          Conditie: condition,
          Images: JSON.stringify(filteredImageFiles),
          Materials: JSON.stringify(selectedTask.materials),
          Repainted: selectedTask.repainted,
          Repaired: selectedTask.repaired,
          Summary: selectedTask.summary,
          EntryPrice: selectedTask.entryPrice,
          EntryDate: formattedEntryDate,
          ProductCategory: selectedTask.productCategory,
          FullName: selectedTask.fullName,
          Email: selectedTask.email,
          Phone: selectedTask.phone,
          CurrentStatus: "Done",
          DesiredStatus: desiredStatus,
          Data: new Date().toISOString().slice(0, 10),
          Hide: "Nu",
          Inspector: selectedTask.inspector
      };

      console.log("Data sent to backend:", JSON.stringify(updatedTask, null, 2));

      try {
          // Save data to the database
          const saveResponse = await axios.post('https://server-9p4hx.kinsta.app/api/qc/save-quality-check', updatedTask);

          if (saveResponse.status === 200) {
              console.log("Task saved successfully.");

              // Generate quality report and send email
              try {
                  const reportResponse = await axios.post('https://server-9p4hx.kinsta.app/api/qc/generate-quality-report', {
                      selectedTask: updatedTask
                  });

                  if (reportResponse.status === 200) {
                      console.log(reportResponse.data);

                      // Set states for displaying success messages
                      setShowPdfCreated(true);
                      setShowEmailSuccess(true);

                      // Upload PDF to JIRA
                      try {
                          await uploadToJiraForQualityCheck(
                              reportResponse.data.pdfBuffer,
                              selectedTask.issue_id,
                              selectedTask.fullName,
                              reportResponse.data.timestamp
                          );
                          setShowJiraUploaded(true);
                          console.log('PDF uploaded to JIRA successfully');
                      } catch (jiraError) {
                          console.error("Error uploading PDF to JIRA:", jiraError.message);
                      }

                      // Send SMS to the client
                      try {
                          const smsResponse = await axios.post('https://server-9p4hx.kinsta.app/api/tasks/send-quality-check-sms', {
                              contactName: selectedTask.fullName,
                              contactPhone: selectedTask.phone
                          });

                          if (smsResponse.status === 200) {
                              console.log('Quality check SMS sent successfully');
                          } else {
                              console.error('Failed to send quality check SMS:', smsResponse.statusText);
                          }
                      } catch (smsError) {
                          console.error('Error sending quality check SMS:', smsError.message || smsError);
                      }

                  }
              } catch (error) {
                  console.error("Error generating report or sending email:", error.response?.data?.message || error.message);
              }
          }
      } catch (error) {
          console.error("Error saving task:", error.response?.data?.message || error.message);
      }
  };

  const handleSubmitDraft = async () => {
      const validationErrors = validateFields();
      if (Object.keys(validationErrors).length > 0) {
        return;
      }

      const formattedEntryDate = selectedTask.entryDate ? new Date(selectedTask.entryDate).toISOString().slice(0, 10) : '';
      const filteredImageFiles = imageFiles
        .filter(file => file != null && file.serverPath != null)
        .map(file => file.serverPath);

      const { total, condition } = calculateTotalAndCondition();

      const dataToSend = {
        TaskID: selectedTask.issue_id,
        PriceOption: selectedTask.priceOption,
        NewPrice: selectedTask.newPrice ? parseFloat(selectedTask.newPrice) : null,
        Authenticity: selectedTask.authenticity,
        AccompaniedItems: JSON.stringify(accompaniedItems),
        Observations: selectedTask.Observations,
        Puncte: total,
        Conditie: condition,
        Images: JSON.stringify(filteredImageFiles),
        Materials: JSON.stringify(selectedTask.materials),
        Summary: selectedTask.summary,
        EntryPrice: selectedTask.entryPrice,
        EntryDate: formattedEntryDate,
        ProductCategory: selectedTask.productCategory,
        FullName: selectedTask.fullName,
        Email: selectedTask.email,
        Phone: selectedTask.phone,
        CurrentStatus: "Ciorna",  // Setează statusul la "Ciorna"
        DesiredStatus: "Draft",
        Data: new Date().toISOString().slice(0, 10),
        Hide: "Nu",
        Inspector: selectedTask.inspector
      };

      console.log("Data trimisă la backend pentru ciornă:", JSON.stringify(dataToSend, null, 2));

      try {
        const response = await axios.post('https://server-9p4hx.kinsta.app/api/qc/save-quality-check', dataToSend, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          console.log(response.data.message);
          setEmailSuccess(true);
        }
      } catch (error) {
        console.error("Eroare la salvarea ciornei:", error.response?.data?.message || error.message);
      }
  };

  const rephraseObservations = async () => {
      if (!selectedTask.Observations) return;
      setIsProcessing(true);
      try {
        const rephrasedText = await fetchRephrasedText(selectedTask.Observations);
        setSelectedTask(prevState => ({
          ...prevState,
          Observations: rephrasedText
        }));
      } catch (error) {
        console.error("Eroare la reformularea textului:", error);
      } finally {
        setIsProcessing(false);
      }
  };

  const fetchRephrasedText = async (text) => {
    try {
      const category = selectedTask.productCategory;
      const { condition, total } = calculateTotalAndCondition();

      const prompt = `Reformulează, dar fara diacritice, într-o manieră de business și profesionist ca și un inspector de calitate expert de la o consignație de lux. Te rog sa nu scrii ca esti inspector de calitate, scrie direct observatia ta. Articolul este din categoria: ${category} si este in conditia: ${condition}; Nota totala: ${total} ${text}`;

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4",
          messages: [{ "role": "user", "content": prompt }],
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer sk-proj-e5fOLUf5FGmf8CE0cEW7T3BlbkFJ8C9KRkmZ2OZYSLR37A4k`
          }
        }
      );

      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error("Eroare la reformularea textului:", error);
    }
  };

  const validateFields = () => {
    let newErrors = {};

    if (!selectedTask.authenticity) {
      newErrors.authenticity = "Acest câmp este obligatoriu.";
    }
    if (!selectedTask.inspector) {
      newErrors.inspector = "Alegerea unui inspector este obligatorie.";
    }
    if (!selectedTask.priceOption) {
      newErrors.priceOption = "Selectarea unei opțiuni de preț este obligatorie.";
    }
    if (selectedTask.priceOption === 'Propunere pret nou' && !selectedTask.newPrice) {
      newErrors.newPrice = "Introducerea noului preț este obligatorie atunci când se propune un preț nou.";
    }
    if (!selectedTask.materials.cutie) {
      newErrors.cutie = "Câmpul Cutie este obligatoriu.";
    }
    if (!selectedTask.materials.accesorii) {
      newErrors.accesorii = "Câmpul Accesorii este obligatoriu.";
    }
    if (!selectedTask.Observations) {
      newErrors.Observations = "Acest câmp este obligatoriu.";
    }
    if (accompaniedItems.length === 0) {
      newErrors.accompaniedItems = "Trebuie adăugat cel puțin un element însoțitor.";
    }

    setErrors(newErrors);
    return newErrors;
  };

  const calculateTotalAndCondition = () => {
    const materialValues = Object.values(selectedTask.materials || {}).map(value => parseInt(value, 10) || 0);
    const total = materialValues.reduce((acc, curr) => acc + curr, 0);
    const numberOfPositiveFields = materialValues.filter(value => value > 0).length;
    const average = numberOfPositiveFields > 0 ? total / numberOfPositiveFields : 0;
    let condition;
    if (average === 10) {
      condition = "Nou";
    } else if (average < 10 && average >= 9) {
      condition = "Excelent";
    } else if (average < 9 && average >= 8) {
      condition = "Foarte Bun";
    } else if (average < 8 && average >= 7) {
      condition = "Bun";
    } else if (average < 7) {
      condition = "Rezonabil";
    } else {
      condition = "N/A";
    }
    return {
      total: average.toFixed(2),
      condition
    };
  };

  // Returnează componenta cu un mesaj de eroare dacă issueId nu este definit
  if (!issueId) {
    return (
      <Box sx={{ width: '100%', padding: 2 }}>
        <Alert severity="error">Eroare: issueId este undefined sau null.</Alert>
      </Box>
    );
  }

  // Dacă datele taskului nu sunt încă încărcate, afișează un spinner de încărcare
  if (!selectedTask) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ padding: 2 }}>
          <TextField
            label="Summary"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            value={selectedTask?.summary || ''}
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Preț negociat"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                value={`${selectedTask?.entryPrice || ''} RON`}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Data de intrare"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                 value={selectedTask?.entryDate ? new Date(selectedTask.entryDate).toLocaleDateString() : ''}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Categorie Prod"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                value={selectedTask?.productCategory || "N/A"}
                sx={{ '& .MuiInputBase-input': { color: 'green' } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Nume și Prenume"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                value={selectedTask?.fullName || ''}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                value={selectedTask?.email || ''}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Telefon"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                value={selectedTask?.phone || ''}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
              Autentificare
            </Typography>
            <FormControl fullWidth error={Boolean(errors.authenticity)}>
              <InputLabel id="authenticity-select-label">Articolul este autentic?</InputLabel>
              <Select
                labelId="authenticity-select-label"
                id="authenticity-select"
                value={selectedTask.authenticity || ''}
                error={Boolean(errors.authenticity)}
                helperText={errors.authenticity || ''}
                onChange={(event) => {
                  const updatedTaskForAuthenticity = {
                    ...selectedTask,
                    authenticity: event.target.value
                  };
                  setSelectedTask(updatedTaskForAuthenticity);
                  setErrors(prevErrors => ({...prevErrors, authenticity: undefined}));
                }}
              >
                <MenuItem value="DA">DA</MenuItem>
                <MenuItem value="NU">NU</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="h6" component="h2" gutterBottom sx={{ marginTop: 4 }}>
              Inspecția materialelor
            </Typography>

            {
            selectedTask.productCategory === "Ceasuri" ? (
                <Grid container spacing={2}>
                {['Cadran', 'Bratara-Curea', 'Geam', 'Spate'].map((label, index) => (
                    <Grid item xs={3} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Bijuterii" ? (
                <Grid container spacing={2}>
                {['Suprafețe', 'Pietre'].map((label, index) => (
                    <Grid item xs={6} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Genti" || selectedTask.productCategory === "Imbracaminte" ? (
                <Grid container spacing={2}>
                {['Exterior', 'Interior', 'Forma', 'Hardware'].map((label, index) => (
                    <Grid item xs={3} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Incaltaminte" ? (
                <Grid container spacing={2}>
                {['Exterior', 'Interior', 'Forma', 'Talpa', 'Cute'].map((label, index) => (
                    <Grid item xs={3} key={index}>
                    <TextField
                        label={label}
                        type="number"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : null
            }

            <Alert severity="info" sx={{ marginTop: 2 }}>
              Notă: 1-10 (1 fiind neacceptat, 10 fiind maxim)
            </Alert>

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Funcționalitatea și designul
            </Typography>

            {
            selectedTask.productCategory === "Bijuterii" ? (
                <Grid container spacing={2}>
                {['Finisaje', 'Pietre'].map((label, index) => (
                    <Grid item xs={6} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Ceasuri" ? (
                <Grid container spacing={2}>
                {['Finisaje', 'Mecanism'].map((label, index) => (
                    <Grid item xs={6} key={index}>
                    <TextField
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            ) : selectedTask.productCategory === "Accesorii" ? (
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={4}>
                    <TextField
                    required
                    label="Finisaje"
                    type="text"
                    value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys['Finisaje']] || '' : ''}
                    onChange={handleMaterialChange(materialLabelsToKeys['Finisaje'])}
                    fullWidth
                    error={Boolean(errors[materialLabelsToKeys['Finisaje']])}
                    helperText={errors[materialLabelsToKeys['Finisaje']]}
                    />
                </Grid>
                </Grid>
            ) : selectedTask.productCategory === "Incaltaminte" ? (
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                    <TextField
                    required
                    label="Cute"
                    type="text"
                    value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys['Cute']] || '' : ''}
                    onChange={handleMaterialChange(materialLabelsToKeys['Cute'])}
                    fullWidth
                    error={Boolean(errors[materialLabelsToKeys['Cute']])}
                    helperText={errors[materialLabelsToKeys['Cute']]}
                    />
                </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                {['Fermoare-Inchideri', 'Finisaje', 'Mirosuri'].map((label, index) => (
                    <Grid item xs={4} key={index}>
                    <TextField
                        required
                        label={label}
                        type="text"
                        value={selectedTask.materials ? selectedTask.materials[materialLabelsToKeys[label]] || '' : ''}
                        onChange={handleMaterialChange(materialLabelsToKeys[label])}
                        fullWidth
                        error={Boolean(errors[materialLabelsToKeys[label]])}
                        helperText={errors[materialLabelsToKeys[label]]}
                    />
                    </Grid>
                ))}
                </Grid>
            )
            }

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Ambalajul și accesoriile
            </Typography>
            <Grid container spacing={2}>
              {['Cutie', 'Accesorii'].map((label, index) => (
                <Grid item xs={6} key={index}>
                  <TextField
                    required
                    label={label}
                    value={selectedTask.materials ? selectedTask.materials[label.toLowerCase()] || '' : ''}
                    onChange={(event) => {
                      const prop = label.toLowerCase();
                      const updatedMaterials = {
                        ...selectedTask.materials,
                        [prop]: event.target.value
                      };

                      setSelectedTask({
                        ...selectedTask,
                        materials: updatedMaterials
                      });
                    }}
                    error={Boolean(errors[label.toLowerCase()])}
                    helperText={errors[label.toLowerCase()]}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Istoricul și proveniența produsului
            </Typography>
            {
            selectedTask.productCategory === "Bijuterii" ? (
                <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="eu-marking-label">Marcaj EU</InputLabel>
                    <Select
                        labelId="eu-marking-label"
                        id="eu-marking-select"
                        value={selectedTask.euMarking || ''}
                        label="Marcaj EU"
                        onChange={(e) => {
                        setSelectedTask({...selectedTask, euMarking: e.target.value});
                        }}
                    >
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="gemological-certificate-label">Certificat Gemologic</InputLabel>
                    <Select
                        labelId="gemological-certificate-label"
                        id="gemological-certificate-select"
                        value={selectedTask.gemologicalCertificate || ''}
                        label="Certificat Gemologic"
                        onChange={(e) => setSelectedTask({...selectedTask, gemologicalCertificate: e.target.value})}
                    >
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} alignItems="center">
                {selectedTask.productCategory !== "Imbracaminte" && (
                    <Grid item xs={6}>
                    <FormControl fullWidth sx={{ mb: 2 }} error={Boolean(errors.Repainted)}>
                    <InputLabel id="repainted-label">{selectedTask.productCategory === "Ceasuri" ? "Polisat" : "Revopsit"}</InputLabel>
                    <Select
                        labelId="repainted-label"
                        id="repainted-select"
                        value={selectedTask.repainted || ''}
                        label={selectedTask.productCategory === "Ceasuri" ? "Polisat" : "Revopsit"}
                        onChange={handleRepaintedChange}
                    >
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                    </Select>
                    {errors.Repainted && <FormHelperText>{errors.Repainted}</FormHelperText>}
                    </FormControl>
                    </Grid>
                )}
                <Grid item xs={6}>
                    <FormControl fullWidth error={Boolean(errors.Repaired)} sx={{ mb: 2 }}>
                    <InputLabel id="repaired-label">Reparat</InputLabel>
                    <Select
                        labelId="repaired-label"
                        id="repaired-select"
                        value={selectedTask.repaired || ''}
                        label="Reparat"
                        onChange={handleRepairedChange}
                    >
                        <MenuItem value="DA">DA</MenuItem>
                        <MenuItem value="NU">NU</MenuItem>
                    </Select>
                    {errors.Repaired && <FormHelperText>{errors.Repaired}</FormHelperText>}
                    </FormControl>
                </Grid>
                </Grid>
            )
            }

            <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
              Nota Finală
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label="A venit însoțit cu:"
                  value={newItem}
                  onChange={(e) => setNewItem(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                  error={Boolean(errors.accompaniedItems)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={handleAddItem}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
            {Array.isArray(accompaniedItems) && accompaniedItems.map((item, index) => (
              <Grid container spacing={2} alignItems="center" key={index}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={item}
                    sx={{ mb: 2 }}
                    onChange={handleItemChange(index)}
                    error={Boolean(errors.accompaniedItems)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleDeleteItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            {errors.accompaniedItems && (
              <FormHelperText error sx={{ ml: 2 }}>{errors.accompaniedItems}</FormHelperText>
            )}

            <Box sx={{ position: 'relative', display: 'inline-block', mb: 2, width: '100%' }}>
              <FormControl fullWidth error={Boolean(errors.priceOption)} sx={{ width: '100%' }}>
                <InputLabel id="price-option-label">Preț</InputLabel>
                <Select
                  labelId="price-option-label"
                  id="price-option"
                  value={priceOption}
                  label="Preț"
                  onChange={handlePriceOptionChange}
                >
                  <MenuItem value="Cel agreat initial">Cel agreat inițial</MenuItem>
                  <MenuItem value="Propunere pret nou">Propunere preț nou</MenuItem>
                </Select>
                {errors.priceOption && <FormHelperText>{errors.priceOption}</FormHelperText>}
              </FormControl>
              {priceOption === 'Propunere pret nou' && (
                <TextField
                  error={Boolean(errors.newPrice)}
                  helperText={errors.newPrice}
                  label="Preț Nou"
                  type="number"
                  value={newPrice}
                  onChange={handleNewPriceChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                    inputProps: { min: 0, pattern: "\\d*" }
                  }}
                />
              )}
              <Box sx={{
                position: 'absolute',
                top: 0,
                right: 40,
                transform: 'translate(50%, -50%)',
                bgcolor: 'green',
                color: 'white',
                px: 2,
                py: 0.5,
                borderRadius: '16px',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '24px',
              }}>
                {selectedTask.entryPrice} RON
              </Box>
            </Box>

<TextField
  label="Observații inspector de calitate"
  multiline
  rows={4}
  value={selectedTask.Observations || ''}
  onChange={handleObservationChange}
  fullWidth
  sx={{ marginBottom: 2 }}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={rephraseObservations} edge="end" sx={{ color: 'purple' }}>
          {isProcessing ? <CircularProgress size={24} /> : <AssistantSharpIcon />}
        </IconButton>
      </InputAdornment>
    )
  }}
  error={Boolean(errors.Observations)}
  helperText={errors.Observations}
/>


            <Grid container spacing={2}>
            {[0, 1, 2, 3].map((index) => (
                <Grid item xs={6} key={index} sx={{ position: 'relative' }}>
                <Button variant="outlined" component="label" fullWidth>
                    Încarcă Imagine
                    <input
                    type="file"
                    hidden
                    onChange={handleImageChange(index)}
                    />
                </Button>
                {imageFiles[index] && imageFiles[index].previewUrl && (
                    <Box sx={{ position: 'relative', mt: 1, width: '100%' }}>
                    <img
                        src={imageFiles[index].previewUrl}
                        alt={`Preview ${index + 1}`}
                        style={{ width: '100%', maxHeight: 200, objectFit: 'contain' }}
                    />
                    <IconButton
                        onClick={() => handleRemoveImage(index)}
                        sx={{ position: 'absolute', top: 0, right: 0, color: 'red', zIndex: 1 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    </Box>
                )}
                </Grid>
            ))}
            </Grid>

            <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} error={Boolean(errors.Inspector)}>
              <InputLabel id="inspector-label">Inspector</InputLabel>
              <Select
                labelId="inspector-label"
                id="inspector-select"
                value={selectedTask.inspector || ''}
                label="Inspector"
                onChange={(e) => {
                  setSelectedTask({...selectedTask, inspector: e.target.value});
                }}
              >
                {['Alexandra N.', 'Ciprian B.', 'Alina B.', 'Mirela B.'].map((name) => (
                  <MenuItem key={name} value={name}>{name}</MenuItem>
                ))}
              </Select>
              {errors.Inspector && <FormHelperText>{errors.Inspector}</FormHelperText>}
            </FormControl>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Alert variant="outlined" severity="info">
                    TOTAL: {calculateTotalAndCondition().total}
                  </Alert>
                </Grid>
                <Grid item xs={6}>
                  <Alert variant="outlined" severity="info">
                    Condiție: {calculateTotalAndCondition().condition}
                  </Alert>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Button variant="outlined" onClick={() => handleSubmitDraft('Draft')} sx={{ marginRight: 2 }} disabled={selectedTask?.status === 'Done'}>
                        Ciorna
                    </Button>
                    <Button fullWidth variant="contained" onClick={() => handleSubmit('Done')} sx={{ backgroundColor: '#000', color: '#fff' }} disabled={selectedTask?.status === 'Done'}>
                        Salvează & Trimite la Client
                    </Button>
                </Box>

                {/* Afișarea mesajelor de succes sub butoane */}
                {showEmailSuccess && (
                    <Alert severity="success" sx={{ width: '100%', mb: 1 }}>
                        Emailul a fost trimis cu succes către consignat!
                    </Alert>
                )}

                {showPdfCreated && (
                    <Alert severity="success" sx={{ width: '100%', mb: 1 }}>
                        PDF-ul a fost creat cu succes!
                    </Alert>
                )}

                {showJiraUploaded && (
                    <Alert severity="success" sx={{ width: '100%', mb: 1 }}>
                        PDF-ul a fost încărcat cu succes în JIRA!
                    </Alert>
                )}
            </Box>

        </Paper>
      </Container>
    </Box>
  );
}

export default CreareRaport;
