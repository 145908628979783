import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import './CalendarCuScadente.css';
import { Box, Typography, Card, CardContent, Grid, Avatar, MenuItem, Select, FormControl } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

function CalendarCuScadente() {
    const [data, setData] = useState(new Date());
    const [scadente, setScadente] = useState([]);

    const handleMonthChange = (event) => {
        const newDate = new Date(data.setMonth(event.target.value - 1));
        setData(new Date(newDate));
    };

    const handleYearChange = (event) => {
        const newDate = new Date(data.setFullYear(event.target.value));
        setData(new Date(newDate));
    };

    useEffect(() => {
        const lunaSelectata = data.getMonth() + 1;
        const anSelectat = data.getFullYear();
        axios.get(`https://server-9p4hx.kinsta.app/api/llp/scadente?luna=${lunaSelectata}&an=${anSelectat}`)
            .then(response => {
                setScadente(response.data);
            });
    }, [data]);

    const zileCuScadente = (date) => {
        return scadente.filter(scadenta => {
            const dataScadenta = new Date(scadenta.data);
            return dataScadenta.getDate() === date.getDate() &&
                   dataScadenta.getMonth() === date.getMonth() &&
                   dataScadenta.getFullYear() === date.getFullYear();
        });
    };

    const getColorForIndex = (index) => {
        const colors = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff'];
        return colors[index % colors.length];
    };

    const formatSuma = (suma) => {
        return suma.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const months = [
        "Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie", "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"
    ];

    const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i);

    return (
        <>
            <style>
                {`
                .react-calendar__tile--now {
                    background: rgba(0, 128, 128, 0.1) !important;
                }
                .task-container {
                    padding: 4px;
                    border-radius: 4px;
                    margin-bottom: 2px;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                }
                .task-name {
                    font-weight: bold;
                    margin-bottom: 2px;
                }
                .task-sku {
                    font-size: 0.8em;
                    color: rgba(0, 0, 0, 0.6);
                    margin-bottom: 2px;
                }
                .task-suma {
                    font-size: 0.8em;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
                `}
            </style>
            <Box mt={4}>
                <Typography variant="h4" align="center" gutterBottom>
                    Calendarul Scadențelor
                </Typography>
                <Grid container justifyContent="center" spacing={2} mb={3}>
                    <Grid item>
                        <FormControl variant="outlined" size="small">
                            <Select
                                value={data.getMonth() + 1}
                                onChange={handleMonthChange}
                            >
                                {months.map((month, index) => (
                                    <MenuItem key={index} value={index + 1}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" size="small">
                            <Select
                                value={data.getFullYear()}
                                onChange={handleYearChange}
                            >
                                {years.map((year, index) => (
                                    <MenuItem key={index} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Calendar
                    onChange={setData}
                    value={data}
                    locale="ro-RO"
                    showNeighboringMonth={false}
                    tileContent={({ date, view }) => {
                        const scadenteZi = zileCuScadente(date);
                        if (view === 'month' && scadenteZi.length) {
                            return (
                                <Box>
                                    {scadenteZi.map((scadenta, index) => (
                                        <Card
                                            key={index}
                                            className="task-container"
                                            sx={{
                                                backgroundColor: getColorForIndex(index),
                                                boxShadow: 3,
                                                p: 1,
                                                mb: 0.5,
                                                borderRadius: 1,
                                            }}
                                        >
                                            <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                                                <Typography className="task-name">
                                                    {scadenta.nume}
                                                </Typography>
                                                <Typography className="task-sku">
                                                    {scadenta.sku}
                                                </Typography>
                                                <Typography className="task-suma">
                                                    <MonetizationOnIcon fontSize="small" />
                                                    {formatSuma(scadenta.suma)} RON
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Box>
                            );
                        }
                    }}
                />
            </Box>
        </>
    );
}

export default CalendarCuScadente;
