import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Chip,
  FormControl,
  InputLabel,
  Modal,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Toolbar,
  Typography,
  makeStyles
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';

    // Stiluri pentru modal
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    };

const DataDisplay = () => {
    const [data, setData] = useState([]);
    const [emitDate, setEmitDate] = useState(new Date().toISOString().slice(0, 10)); // Data curentă ca valoare inițială
    const [serie, setSerie] = useState('ALEB');
    const [currentNumber, setCurrentNumber] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [invoiceToUpdate, setInvoiceToUpdate] = useState({}); // Datele facturii de actualizat
    const [modalUpdateOpen, setModalUpdateOpen] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentInvoiceData, setCurrentInvoiceData] = useState({ serie: '', numar: '' });
    const [filterYear, setFilterYear] = useState(new Date().getFullYear());
    const [filterMonth, setFilterMonth] = useState(new Date().getMonth() + 1); // Luni de la 1 la 12
    const [availableYears, setAvailableYears] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const [currentNumberInput, setCurrentNumberInput] = useState('');

    const fetchData = async () => {
        try {
            const url = `https://server-9p4hx.kinsta.app/api/get-data-autofactura`;
            const response = await axios.get(url);
            
            let yearsSet = new Set();
            let monthsMap = new Map();

            response.data.forEach(item => {
                if (item.hideautofactura !== 1 && item.autofacturafacuta !== "1") {
                    const itemDate = new Date(item.data_relevanta);
                    const year = itemDate.getFullYear();
                    const month = itemDate.getMonth() + 1; // getMonth returnează luna de la 0 la 11

                    yearsSet.add(year);

                    if (!monthsMap.has(year)) {
                        monthsMap.set(year, new Set());
                    }
                    monthsMap.get(year).add(month);
                }
            });

            const sortedYears = [...yearsSet].sort((a, b) => a - b);

            setAvailableYears(sortedYears);
            // Setează lunile disponibile bazat pe anul selectat
            setAvailableMonths([...monthsMap.get(filterYear) || []].sort((a, b) => a - b));

            const filteredData = response.data.filter(item => {
                const itemDate = new Date(item.data_relevanta);
                return (itemDate.getFullYear() === filterYear && itemDate.getMonth() + 1 === filterMonth) &&
                    item.hideautofactura !== 1 && item.autofacturafacuta !== "1";
            });

            setData(filteredData);
        } catch (error) {
            console.error('Eroare la preluarea datelor:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Apel inițial pentru a încărca datele

    // useEffect pentru încărcarea datelor la inițializare și la schimbarea anului sau lunii
    useEffect(() => {
        fetchData();
    }, [filterYear, filterMonth]);

    useEffect(() => {
        const fetchCurrentNumber = async () => {
            try {
                const response = await axios.get('https://server-9p4hx.kinsta.app/get-current-number');
                if (response.data && response.data.numar) {
                    setCurrentNumber(response.data.numar);
                    // Setează `currentNumberInput` pentru a afișa numărul curent în câmpul de input
                    setCurrentNumberInput(response.data.numar);
                }
            } catch (error) {
                console.error('Eroare la obținerea numărului curent:', error);
            }
        };

        fetchCurrentNumber();
    }, []);

    const incrementNumber = async () => {
        try {
            await axios.post('https://server-9p4hx.kinsta.app/increment-number');
            // Dupa incrementare, actualizeaza numarul curent
            await fetchCurrentNumber();
        } catch (error) {
            console.error('Eroare la incrementarea numărului:', error);
        }
    };

    const handleGenerateInvoice = async (item) => {
        // Obținerea seriei și numărului curent
        await incrementNumber(); 
        const updatedNumberData = await fetchCurrentNumber();

        // Combinați seria și numărul pentru a forma autofacturaid
        const autofacturaid = `${serie}${updatedNumberData.numar}`; // Combinația seriei și numărului

        // Format date to exclude time
        const formattedDate = item.data_relevanta;

        // Actualizează starea UI cu numărul nou
        setCurrentNumber(updatedNumberData.numar);

        // Trimiteți seria și numărul la backend pentru actualizare
        await updateAutofacturaidInDB(item.issue_id, autofacturaid);
        
        const invoiceDetails = {
            ...item,
            serie,
            numar: updatedNumberData.numar,
            emitDate,
            autofacturaid,
            summary: item.summary,
            nume_si_prenume: item.nume_si_prenume,
            telefon: item.telefon,
            email: item.email,
            tip_achizitie: item.tip_achizitie,
            data_relevanta: item.data_relevanta,
            status: item.status,
            hideautofactura: item.hideautofactura,
            pret_intrare_ron: item.pret_intrare_ron,
            sku: item.sku,
            nr_jurnal_special: item.nr_jurnal_special,
            adresa: item.adresa
        };
        console.log('Trimitere detalii factura:', invoiceDetails);
        try {
            // Trimiterea datelor facturii la server pentru generarea PDF-ului
            const response = await axios.post('https://server-9p4hx.kinsta.app/api/generate-invoice', invoiceDetails, {
                responseType: 'blob',
            });

            // Crearea unui URL pentru descărcare și declanșarea descărcării
        if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // Use the new file name format
            link.setAttribute('download', `${autofacturaid}_${formattedDate}_${item.nume_si_prenume.replace(/\s+/g, '_')}.pdf`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);

                // Marchează factura ca generată în baza de date
                await updateAutofacturaidInDB(invoiceDetails);
            } else {
                console.error('Nu s-a primit un răspuns valid pentru descărcarea facturii.');
            }
        } catch (error) {
            console.error('Eroare la generarea facturii:', error);
        }
    };

// Modificăm funcția pentru a accepta întregul obiect de detalii
const updateAutofacturaidInDB = async (invoiceDetails) => {
    try {
        const response = await axios.post('https://server-9p4hx.kinsta.app/mark-invoice-generated', invoiceDetails);
        if (response.status === 200) {
            console.log('Factura a fost marcată ca generată și detaliile au fost actualizate.');
        }
    } catch (error) {
        console.error('Eroare la actualizarea detaliilor facturii:', error);
    }
};

    const handleUpdate = async () => {
        try {
            await axios.post('https://server-9p4hx.kinsta.app/update-json-data-autofactura');
            fetchData(); // Reîncarcă datele după actualizare
        } catch (error) {
            console.error('Eroare la actualizarea datelor:', error);
        }
    };

    const fetchCurrentNumber = async () => {
        try {
            const response = await axios.get('https://server-9p4hx.kinsta.app/get-current-number');
            return response.data; // Aici se presupune că răspunsul include atât seria cât și numărul
        } catch (error) {
            console.error('Eroare la obținerea numărului curent:', error);
        }
    };

const handleSetSerie = async () => {
    try {
        // Trimiterea valorii seriei către backend
        const response = await axios.post('https://server-9p4hx.kinsta.app/set-serie', { serie: serie });
        // Verifică răspunsul și actualizează starea dacă este necesar
        if (response.status === 200) {
            console.log('Serie actualizată cu succes în backend.');
            // Reîncarcă numărul curent după ce setezi seria, dacă este necesar
            const data = await fetchCurrentNumber();
            if (data) {
                setCurrentInvoiceData(data);
            }
        } else {
            console.error('Nu s-a reușit actualizarea seriei în backend.');
        }
    } catch (error) {
        console.error('Eroare la setarea seriei:', error);
    }
};

useEffect(() => {
        const loadData = async () => {
            const data = await fetchCurrentNumber();
            if (data) {
                setCurrentInvoiceData(data);
            }
        };
        loadData();
        fetchData();
    }, [filterYear, filterMonth]);

    const openUpdateModal = (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
        setModalUpdateOpen(true);
        // Presupunem că avem o funcție care încarcă datele facturii pentru a fi editate
        loadInvoiceData(invoiceId);
    };

    const closeUpdateModal = () => {
        setModalUpdateOpen(false);
        setSearchTerm('');
        setSearchResults([]);
        setSelectedInvoice(null);
    };

    const handleSearch = async () => {
        // Logica de căutare în baza de date
        console.log("Căutare pentru", searchTerm);

        try {
            // Trimitere cerere GET la server cu termenul de căutare
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/search-invoice`, {
                params: { term: searchTerm }
            });

            // Setarea rezultatelor căutării în starea componentei
            setSearchResults(response.data);

            // Opțional: Resetarea termenului de căutare după afișarea rezultatelor
            setSearchTerm('');
        } catch (error) {
            console.error('Eroare la căutarea facturilor:', error);
            // Opțional: Gestionează erorile, cum ar fi afișarea unui mesaj de eroare în UI
        }
    };

    const selectInvoiceForUpdate = (invoice) => {
        setSelectedInvoice(invoice); // Aici setăm factura selectată în starea componentei
        setSearchResults([]); // Curățăm rezultatele căutării pentru a ascunde lista de căutare
        setSearchTerm(''); // Opțional, resetăm termenul de căutare
    };

    const loadInvoiceData = async (invoiceId) => {
        console.log("Încărcare date pentru factura", invoiceId);

        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/get-data-autofactura`, {
                params: { id: invoiceId }
            });

            if (response.data) {
                const invoiceData = response.data;

                // Setează datele facturii în starea componentei pentru a le putea edita
                // De exemplu: setInvoiceToUpdate(invoiceData);
            } else {
                console.log('Nu s-au găsit date pentru factura cu ID-ul:', invoiceId);
                // Gestionarea cazului în care factura nu este găsită
            }
        } catch (error) {
            console.error('Eroare la încărcarea datelor facturii:', error);
            // Gestionarea erorilor
        }
    };

    const updateInvoiceData = async (invoiceData) => {
        try {
            // Presupunând că endpoint-ul și metoda sunt corecte
            const response = await axios.post('https://server-9p4hx.kinsta.app/api/update-invoice', invoiceData);

            if (response.status === 200) {
                console.log('Factura a fost actualizată cu succes.');
                // Alte acțiuni după actualizare, cum ar fi reîncărcarea datelor sau închiderea modalului
            } else {
                console.log('A apărut o problemă la actualizarea facturii.');
            }
        } catch (error) {
            console.error('Eroare la actualizarea facturii:', error);
            // Gestionarea erorilor, de exemplu afișarea unui mesaj de eroare
        }
    };

    const downloadInvoice = async (invoiceId) => {
        try {
            const response = await axios({
                url: `https://server-9p4hx.kinsta.app/api/download-invoice/${invoiceId}`,
                method: 'GET',
                responseType: 'blob', // Important pentru a primi un fișier
            });

            // Crearea unui URL pentru descărcare
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // Setarea atributelor pentru descărcare, incluzând numele fișierului
            link.setAttribute('download', `factura-${invoiceId}.pdf`); // Presupunând că factura este un PDF
            document.body.appendChild(link);

            // Declanșarea descărcării și curățarea ulterioară
            link.click();
            window.URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Eroare la descărcarea facturii:', error);
            // Gestionarea erorilor
        }
    };

    const handleHideInvoice = async (id) => {
    try {
        await axios.post('https://server-9p4hx.kinsta.app/api/hide-invoice', { id });
        // Reîncărcarea datelor pentru a reflecta schimbările
        fetchData();
    } catch (error) {
        console.error('Eroare la ascunderea facturii:', error);
    }
    };

const handleSetCurrentNumber = async () => {
    // Verifică dacă numărul introdus are minim 6 cifre
    if (currentNumberInput.length < 6) {
        alert('Numărul introdus trebuie să conțină minim 6 cifre.');
        return; // Împiedică continuarea execuției dacă condiția nu este îndeplinită
    }

    try {
        // Aici "currentNumberInput" este starea locală care păstrează valoarea introdusă de utilizator
        await axios.post('https://server-9p4hx.kinsta.app/set-current-number', { currentNumber: currentNumberInput });
        alert('Numărul curent a fost setat cu succes.');
        setCurrentNumberInput(''); // Opțional: Resetarea câmpului după trimitere
    } catch (error) {
        console.error('Eroare la setarea numărului curent:', error);
        alert('Eroare la setarea numărului curent.');
    }
};

    return (
      <Box sx={{ flexGrow: 1, p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <TextField
                    label="Data emitere"
                    type="date"
                    value={emitDate}
                    onChange={(e) => setEmitDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mr: 2 }}
                />
                <TextField 
                    label="Serie" 
                    value={serie} 
                    onChange={(e) => setSerie(e.target.value)} 
                    placeholder="Introduceți seria"
                    sx={{ mr: 2 }}
                />
    
                <Button variant="contained" onClick={handleSetSerie} sx={{ mr: 1 }}>
                    Setează Seria
                </Button>
                <TextField
                    label="Număr curent"
                    value={currentNumberInput}
                    onChange={(e) => setCurrentNumberInput(e.target.value)}
                    placeholder="Introduceți numărul curent"
                    sx={{ mr: 2 }}
                />
                <Button variant="contained" onClick={handleSetCurrentNumber}>
                    Setează Numărul Curent
                </Button>
            </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', ml: 0 }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="year-select-label">An</InputLabel>
                <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={filterYear}
                    label="An"
                    onChange={(e) => setFilterYear(e.target.value)}
                >
                    {availableYears.map((year) => (
                        <MenuItem key={year} value={year}>{year}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="month-select-label">Lună</InputLabel>
                <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={filterMonth}
                    label="Lună"
                    onChange={(e) => setFilterMonth(e.target.value)}
                >
                    {availableMonths.map((month) => (
                        <MenuItem key={month} value={month}>
                            {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>

            <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table sx={{ minWidth: 650 }} aria-label="facturi table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Tip Contract</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Data intrare</TableCell>
                            <TableCell>Nume Prenume</TableCell>
                            <TableCell>Telefon</TableCell>
                            <TableCell>Summary</TableCell>
                            <TableCell>Pret Intrare RON</TableCell>
                            <TableCell>Actiuni</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.issue_id}</TableCell>
                                <TableCell>{item.tip_achizitie}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>{item.data_relevanta}</TableCell>
                                <TableCell>{item.nume_si_prenume}</TableCell>
                                <TableCell>{item.telefon}</TableCell>
                                <TableCell>{item.summary}</TableCell>
                                <TableCell>{item.pret_intrare_ron}</TableCell>
                                <TableCell>
                                    <IconButton 
                                        color="primary" 
                                        onClick={() => handleGenerateInvoice(item)}
                                    >
                                        <PrintIcon />
                                    </IconButton>
                                    <IconButton 
                                        color="secondary" 
                                        onClick={() => handleHideInvoice(item.issue_id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

      </Box>
    );
};

export default DataDisplay;
