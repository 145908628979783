import React, { useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import calculusFunctions from './math';
    
const cardStyles = [
  {
    backgroundColor: '#FFD700', // Auriu
    color: '#000',
  },
  {
    backgroundColor: '#C0C0C0', // Argintiu
    color: '#000',
  },
  {
    backgroundColor: '#CD7F32', // Bronz
    color: '#FFF',
  },
  {
    backgroundColor: '#76D7C4', // Turquoise deschis
    color: '#FFF',
  },
];

const KPI = ({ filteredProducts }) => {
    const [comisionLuxura, setComisionLuxura] = useState(30); // Comision Luxura
    const averagePrice = calculusFunctions.calculateAveragePrice(filteredProducts);
    const pretDeVanzare = averagePrice;  // Acesta este pretul de vanzare
    const marketing = calculusFunctions.calculateMarketing();
    const impozit = calculusFunctions.calculateImpozit(pretDeVanzare);
    const profitBrut = calculusFunctions.calculateProfitBrut(comisionLuxura, pretDeVanzare);
    const pretDeAchizitie = calculusFunctions.calculatePretDeAchizitie(pretDeVanzare, profitBrut);
    const tva = calculusFunctions.calculateTVA(pretDeVanzare, pretDeAchizitie);
    const profitNet = calculusFunctions.calculateProfitNet(profitBrut, tva, impozit, marketing);
    const maxPrice = calculusFunctions.calculateMaxPrice(filteredProducts);
    const minPrice = calculusFunctions.calculateMinPrice(filteredProducts);

  return (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
  {cardStyles.map((style, index) => (
    <Card key={index} style={{ ...style, width: '24%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <CardContent>

        {/* Casuta 1 */}
        {index === 0 && (
          <div>
            <Typography variant="body1">Pret mediu de Vanzare: {pretDeVanzare} eur</Typography>
            <Typography variant="body1">Pret mediu de Achizitie: {pretDeAchizitie} eur</Typography>
          </div>
        )}

        {/* Casuta 2 */}
        {index === 1 && (
          <div>
            <Typography variant="body1">Marketing: {marketing} eur</Typography>
            <Typography variant="body1">Impozit: {impozit} eur</Typography>
            <Typography variant="body1">TVA: {tva} eur</Typography>
          </div>
        )}

        {/* Casuta 3 */}
        {index === 2 && (
        <div>
            <Typography variant="body1">
            Comision Luxura: 
            <input 
                type="number" 
                value={comisionLuxura} 
                onChange={(e) => setComisionLuxura(e.target.value)}
                style={{ width: '50px', marginLeft: '10px' }}
            />
            %
            </Typography>
            <Typography variant="body1">Profit Brut: {profitBrut} eur</Typography>
            <Typography variant="body1">Profit Net: {profitNet} eur</Typography>
        </div>
        )}

        {/* Casuta 4 */}
        {index === 3 && (
          <div>
            <Typography variant="body1">Pret Max: {maxPrice} eur</Typography>
            <Typography variant="body1">Pret Min: {minPrice}eur </Typography>
            <Typography variant="body1">Total Produse Filtrate: {filteredProducts.length} buc.</Typography>
          </div>
        )}

      </CardContent>
    </Card>
  ))}
</div>

  );
};

export default KPI;
