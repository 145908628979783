// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Stilurile pentru CalendarCuScadente */
.react-calendar__tile {
    border: 1px solid #d3d3d3 !important;
    color: #2e2e2e !important;
}

.task-container {
    padding: 2px;
    border-radius: 4px;
    margin-bottom: 2px;
    color: #2e2e2e;
    /* Text gri închis */
}

.calendar-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.react-calendar {
    width: 100%;
    max-width: 100%;
}

.react-calendar__tile {
    text-align: left;
}

/* CalendarCuScadente.css */

.react-calendar__tile {
    padding: 10px !important;
}

.react-calendar__tile--now {
    background: rgba(0, 128, 128, 0.1) !important;
}

.task-container {
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.task-name {
    font-weight: bold;
    margin-bottom: 2px;
}

.task-sku {
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 2px;
}

.task-suma {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/CalendarCuScadente.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;IACI,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA,2BAA2B;;AAE3B;IACI,wBAAwB;AAC5B;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":["/* Stilurile pentru CalendarCuScadente */\n.react-calendar__tile {\n    border: 1px solid #d3d3d3 !important;\n    color: #2e2e2e !important;\n}\n\n.task-container {\n    padding: 2px;\n    border-radius: 4px;\n    margin-bottom: 2px;\n    color: #2e2e2e;\n    /* Text gri închis */\n}\n\n.calendar-container {\n    width: 100%;\n    max-width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.react-calendar {\n    width: 100%;\n    max-width: 100%;\n}\n\n.react-calendar__tile {\n    text-align: left;\n}\n\n/* CalendarCuScadente.css */\n\n.react-calendar__tile {\n    padding: 10px !important;\n}\n\n.react-calendar__tile--now {\n    background: rgba(0, 128, 128, 0.1) !important;\n}\n\n.task-container {\n    padding: 8px;\n    border-radius: 8px;\n    margin-bottom: 4px;\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n}\n\n.task-name {\n    font-weight: bold;\n    margin-bottom: 2px;\n}\n\n.task-sku {\n    font-size: 0.9em;\n    color: rgba(0, 0, 0, 0.6);\n    margin-bottom: 2px;\n}\n\n.task-suma {\n    font-size: 0.9em;\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
