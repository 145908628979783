import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Paper, Chip } from '@mui/material';

const calculateDiscount = (pretDeVanzareRON, pretIntrareRON, daysInStock) => {
  let discountRate = 0;
  let color = 'green';
  let level = 'Nivel1';
  
  if (daysInStock > 180) {
    discountRate = 0.90;
    color = 'darkred';
    level = 'Nivel 4';
  } else if (daysInStock > 150) {
    discountRate = 0.70;
    color = 'magenta';
    level = 'Nivel 3';
  } else if (daysInStock > 120) {
    discountRate = 0.50;
    color = 'orange';
    level = 'Nivel 2';
  } else if (daysInStock > 90) {
    discountRate = 0.30;
    color = 'yellow';
    level = 'Nivel 1';
  }
  
  const profitBrut = pretDeVanzareRON - pretIntrareRON;
  const discountedPrice = profitBrut * (1 - discountRate);
  const newPrice = pretIntrareRON + discountedPrice;

  return { discountedPrice, profitBrut, newPrice, color, level };
};

const formatNumber = (number) => {
  return number ? number.toLocaleString('ro-RO', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }) : '';
};

const DiscountPolicyCard = ({ pretDeVanzareRON, pretIntrareRON, dateCreated }) => {
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [profitBrut, setProfitBrut] = useState(0);
  const [color, setColor] = useState('green');
  const [newPrice, setNewPrice] = useState(0);
  const [level, setLevel] = useState('Nivel1');
  
  useEffect(() => {
    const daysInStock = Math.floor((new Date() - new Date(dateCreated)) / (1000 * 60 * 60 * 24));
    const { discountedPrice, profitBrut, newPrice, color, level } = calculateDiscount(pretDeVanzareRON, pretIntrareRON, daysInStock);
    setDiscountedPrice(discountedPrice);
    setProfitBrut(profitBrut);
    setColor(color);
    setNewPrice(newPrice);
    setLevel(level);
  }, [pretDeVanzareRON, pretIntrareRON, dateCreated]);

  return (
    <Paper sx={{ p: 3, borderRadius: 2, borderTop: `10px solid ${color}`, position: 'relative', boxShadow: 3 }}>
      <Chip 
        label={level} 
        sx={{ 
          position: 'absolute', 
          top: 16, 
          right: 16, 
          backgroundColor: color, 
          color: 'white',
          fontSize: '1rem',
          fontWeight: 'bold'
        }} 
      />
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>Politica de reduceri</Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        <strong style={{ color: color }}>Pret nou:</strong> <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{formatNumber(newPrice)} RON</span>
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Profit cu discount: <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{formatNumber(discountedPrice)} RON</span>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Profit brut: {formatNumber(profitBrut)} RON
      </Typography>
    </Paper>
  );
};

export default DiscountPolicyCard;
