import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    LinearProgress,
    Typography,
    InputAdornment,
    Switch,
    Card,
    CardMedia,
    CardContent
} from '@mui/material';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import ImageUploader from './ImageUploader';
import buildHtmlContent from './HtmlContentBuilder';

const conditionOptions = {
    Nou: 1,
    Excelent: 2,
    'Foarte Buna': 3,
    Buna: 4,
    Rezonabil: 5
};

const EditProductWoo = ({ product, wooCategories, onSave, onCancel }) => {
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [msrpPrice, setMsrpPrice] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('draft');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [productType, setProductType] = useState('HARD TO FIND');
    const [brand, setBrand] = useState('HERMÈS');
    const [condition, setCondition] = useState('Nou');
    const [customDescription, setCustomDescription] = useState('');
    const [isSaleScheduled, setIsSaleScheduled] = useState(false);
    const [isProductScheduled, setIsProductScheduled] = useState(false);
    const [scheduledDate, setScheduledDate] = useState(null);
    const [scheduledTime, setScheduledTime] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [progress, setProgress] = useState(0);
    const [sku, setSku] = useState('');
    const [exteriorColor, setExteriorColor] = useState('Maro');
    const [interiorColor, setInteriorColor] = useState('Bordo');
    const [exteriorMaterial, setExteriorMaterial] = useState('Monogram Canvas');
    const [interiorMaterial, setInteriorMaterial] = useState('Microfibra');
    const [includedItems, setIncludedItems] = useState([]);
    const [hardwareColor, setHardwareColor] = useState('Auriu');
    const [designerId, setDesignerId] = useState('M46197');
    const [entrupyId, setEntrupyId] = useState('DHGGCC8');
    const [location, setLocation] = useState('Exclusiv Online');
    const [length, setLength] = useState('');
    const [height, setHeight] = useState('');
    const [depth, setDepth] = useState('');
    const [detailedDescription, setDetailedDescription] = useState('');
    const [sortlyId, setSortlyId] = useState('');
    const [layawayOption, setLayawayOption] = useState('');
    const [isLayaway, setIsLayaway] = useState(false);
    const [pentru, setPentru] = useState('');

    const productTypes = [
        'BEST DEAL',
        'MUST HAVE',
        'VINTAGE',
        'HARD TO FIND',
        'LUXURA EXCLUSIVE',
        'TRENDING',
        'NEW COLLECTION'
    ];
    const brands = ['HERMÈS', 'DIOR', 'CHANEL', 'GUCCI'];

    const consumerKey = 'ck_a941824be557253d3765a1f6242e6d6a590da999';
    const consumerSecret = 'cs_6b0d98e4c442e707be9888cafff1fac17cdbf259';
    const username = 'ciprian.bucurei@gmail.com';
    const password = 'dgUp Z1Cv Rqxg pjx2 s3TF ZjOQ';

    useEffect(() => {
        if (product) {
            setTitle(product.name);
            setPrice(product.regular_price);
            setSalePrice(product.sale_price);
            setMsrpPrice(product.msrp_price);
            setDescription(product.description);
            setStatus(product.status);
            setSelectedCategories(product.categories.map(cat => cat.id));
            setProductType(product.attributes.find(attr => attr.name === 'Badge')?.options[0] || 'HARD TO FIND');
            setBrand(product.attributes.find(attr => attr.name === 'Designeri')?.options[0] || 'HERMÈS');
            setCondition(product.attributes.find(attr => attr.name === 'Condiție')?.options[0] || 'Nou');
            setCustomDescription(product.attributes.find(attr => attr.name === 'Descriere Condiție')?.options[0] || '');
            setExteriorColor(product.attributes.find(attr => attr.name === 'Culoare')?.options[0] || 'Maro');
            setInteriorColor(product.attributes.find(attr => attr.name === 'Culoare Interioară')?.options[0] || 'Bordo');
            setExteriorMaterial(product.attributes.find(attr => attr.name === 'Material Exterior')?.options[0] || 'Monogram Canvas');
            setInteriorMaterial(product.attributes.find(attr => attr.name === 'Material Interior')?.options[0] || 'Microfibra');
            setIncludedItems(product.attributes.find(attr => attr.name === 'Insotit')?.options || []);
            setHardwareColor(product.attributes.find(attr => attr.name === 'Culoare Hardware')?.options[0] || 'Auriu');
            setDesignerId(product.attributes.find(attr => attr.name === 'DesignerID')?.options[0] || 'M46197');
            setEntrupyId(product.attributes.find(attr => attr.name === 'Entrupy')?.options[0] || 'DHGGCC8');
            setLocation(product.attributes.find(attr => attr.name === 'Locație')?.options[0] || 'Exclusiv Online');
            setLength(product.attributes.find(attr => attr.name === 'Lungimea')?.options[0] || '');
            setHeight(product.attributes.find(attr => attr.name === 'Inălțime')?.options[0] || '');
            setDepth(product.attributes.find(attr => attr.name === 'Adâncime')?.options[0] || '');
            setDetailedDescription(product.attributes.find(attr => attr.name === 'Descriere Detaliată')?.options[0] || '');
            setSortlyId(product.attributes.find(attr => attr.name === 'SortlyID')?.options[0] || '');
            setLayawayOption(product.attributes.find(attr => attr.name === 'LLP')?.options[0] || '');
            setIsLayaway(!!product.attributes.find(attr => attr.name === 'LLP'));
            setPentru(product.attributes.find(attr => attr.name === 'Pentru')?.options[0] || '');
            setSku(product.sku);
        }
    }, [product]);

    const uploadImage = async (imageFile) => {
        const url = 'https://www.luxuraelite.ro/wp-json/wp/v2/media';

        const formData = new FormData();
        formData.append('file', imageFile);

        const auth = btoa(`${username}:${password}`);

        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Basic ${auth}`,
                    'Content-Disposition': `attachment; filename=${imageFile.name}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            });

            console.log('Image uploaded successfully:', response.data);
            return response.data.id;
        } catch (error) {
            console.error('Error uploading image:', error.response
                ? error.response.data
                : error.message);
            alert('Failed to upload image.');
            return null;
        }
    };

    const handleSaveProduct = async () => {
        let profileImageId = null;
        let galleryImageIds = [];

        if (profileImage) {
            profileImageId = await uploadImage(profileImage);
            if (!profileImageId) {
                return;
            }
        }

        for (const image of galleryImages) {
            const imageId = await uploadImage(image);
            if (imageId) {
                galleryImageIds.push(imageId);
            }
        }

        const htmlTitle = `
        <span style="font-size: 10px; color: rgb(101, 101, 101);letter-spacing: .15em;">${productType}</span><br>
        <strong>${brand}</strong><br>
        <div style="margin-top:10px; font-size:16px;">${title}</div><br>
        `;

        const conditionVal = conditionOptions[condition];

        const finalDescription = buildHtmlContent({
            conditionVal,
            customDescription,
            exteriorColor,
            interiorColor,
            exteriorMaterial,
            interiorMaterial,
            includedItems,
            hardwareColor,
            designerId,
            entrupyId,
            location,
            length,
            height,
            depth,
            detailedDescription,
            sortlyId,
            salePrice,
            price,
            brand,
            layawayPlan: isLayaway ? layawayOption : null
        });

        const publishDateTime = isProductScheduled && scheduledDate && scheduledTime
            ? dayjs(scheduledDate)
                .hour(dayjs(scheduledTime).hour())
                .minute(dayjs(scheduledTime).minute())
                .second(0)
                .format('YYYY-MM-DDTHH:mm:ss')
            : null;

        const productData = {
            name: htmlTitle,
            regular_price: price,
            sale_price: salePrice,
            msrp_price: msrpPrice,
            description: finalDescription,
            status: isProductScheduled ? 'future' : status,
            date_created: publishDateTime,
            categories: selectedCategories.map(category => ({ id: category.id })),
            date_on_sale_from: isSaleScheduled && dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : null,
            date_on_sale_to: isSaleScheduled && dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : null,
            images: [
                ...(profileImageId ? [{ id: profileImageId }] : []),
                ...galleryImageIds.map(id => ({ id }))
            ],
            stock_quantity: 1,
            manage_stock: true,
            sku: sku,
            tags: [{ name: brand }],
            attributes: [
                { id: 9, name: 'Condiție', options: [condition] },
                { id: 10, name: 'Designeri', options: [brand] },
                { id: 15, name: 'Badge', options: [productType] },
                { id: 2, name: 'Culoare', options: [exteriorColor] },
                { id: 11, name: 'Categorie', options: selectedCategories.map(category => category.name) },
                { id: 24, name: 'DesignerID', options: [designerId] },
                { id: 22, name: 'Entrupy', options: [entrupyId] },
                { id: 23, name: 'Insotit', options: includedItems },
                { id: 17, name: 'Pentru', options: [pentru] }
            ],
            meta_data: [
                { key: 'msrp_price', value: msrpPrice }
            ],
        };

        try {
            const productResponse = await axios.put(`https://www.luxuraelite.ro/wp-json/wc/v3/products/${product.id}`, productData, {
                headers: {
                    Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`),
                    'Content-Type': 'application/json'
                }
            });

            console.log('Product Response:', productResponse.data);

            onSave(productResponse.data);
        } catch (error) {
            console.error('Error updating product:', error.response ? error.response.data : error.message);
            alert('Failed to update product.');
        }
    };

    const renderCategoryOptions = (categories, level = 0) => {
        return categories.flatMap(category => [
            {
                ...category,
                label: `${'—'.repeat(level)} ${category.name}`
            },
            ...renderCategoryOptions(category.children || [], level + 1)
        ]);
    };

    const flatCategories = renderCategoryOptions(wooCategories);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        label="Titlu"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        sx={{ flex: 1 }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 150px' }}>
                        <InputLabel>Tip Produs</InputLabel>
                        <Select
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            label="Tip Produs"
                        >
                            {productTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ mr: 2, flex: '0 0 150px' }}>
                        <InputLabel>Brand</InputLabel>
                        <Select value={brand} onChange={(e) => setBrand(e.target.value)} label="Brand">
                            {brands.map((brand) => (
                                <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        label="Preț"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                        InputProps={{ startAdornment: <InputAdornment position="start">RON</InputAdornment> }}
                    />
                    <TextField
                        label="Preț la Reducere"
                        value={salePrice}
                        onChange={(e) => setSalePrice(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                        InputProps={{ startAdornment: <InputAdornment position="start">RON</InputAdornment> }}
                    />
                    <TextField
                        label="Preț de Retail MSRP"
                        value={msrpPrice}
                        onChange={(e) => setMsrpPrice(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                        InputProps={{ startAdornment: <InputAdornment position="start">RON</InputAdornment> }}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isSaleScheduled} onChange={(e) => setIsSaleScheduled(e.target.checked)} />}
                        label="Schedule Sale"
                    />
                </Box>
                {isSaleScheduled && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <DesktopDatePicker
                            label="De la"
                            inputFormat="DD/MM/YYYY"
                            value={dateFrom}
                            onChange={(newValue) => setDateFrom(newValue)}
                            renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
                        />
                        <DesktopDatePicker
                            label="Până la"
                            inputFormat="DD/MM/YYYY"
                            value={dateTo}
                            onChange={(newValue) => setDateTo(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Condiție</InputLabel>
                        <Select
                            value={condition}
                            onChange={(e) => setCondition(e.target.value)}
                            label="Condiție">
                            {Object.keys(conditionOptions).map((cond) => (
                                <MenuItem key={cond} value={cond}>{cond}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Descriere Condiție"
                        value={customDescription}
                        onChange={(e) => setCustomDescription(e.target.value)}
                        fullWidth
                        sx={{ flex: 1 }}
                    />
                </Box>
                <TextField
                    label="Descriere"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <Autocomplete
                        multiple
                        options={flatCategories}
                        getOptionLabel={(option) => option.label}
                        value={selectedCategories}
                        onChange={(event, newValue) => setSelectedCategories(newValue)}
                        renderTags={(value, getTagProps) => value.map((option, index) => (
                            <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
                        ))}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Categorii" placeholder="Selectează categoriile" />
                        )}
                    />
                </FormControl>
                <ImageUploader onImagesChange={setGalleryImages} />
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Culoare Exterioară</InputLabel>
                        <Select
                            value={exteriorColor}
                            onChange={(e) => setExteriorColor(e.target.value)}
                            label="Culoarea Exterioară"
                        >
                            <MenuItem value="Maro">Maro</MenuItem>
                            <MenuItem value="Negru">Negru</MenuItem>
                            <MenuItem value="Alb">Alb</MenuItem>
                            <MenuItem value="Albastru">Albastru</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Culoare Interioară</InputLabel>
                        <Select
                            value={interiorColor}
                            onChange={(e) => setInteriorColor(e.target.value)}
                            label="Culoarea Interioară">
                            <MenuItem value="Bordo">Bordo</MenuItem>
                            <MenuItem value="Negru">Negru</MenuItem>
                            <MenuItem value="Alb">Alb</MenuItem>
                            <MenuItem value="Albastru">Albastru</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Material Exterior</InputLabel>
                        <Select
                            value={exteriorMaterial}
                            onChange={(e) => setExteriorMaterial(e.target.value)}
                            label="Material Exterior">
                            <MenuItem value="Monogram Canvas">Monogram Canvas</MenuItem>
                            <MenuItem value="Piele">Piele</MenuItem>
                            <MenuItem value="Bumbac">Bumbac</MenuItem>
                            <MenuItem value="Aur">Aur</MenuItem>
                            <MenuItem value="Argint">Argint</MenuItem>
                            <MenuItem value="Oțel">Oțel</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Material Interior</InputLabel>
                        <Select
                            value={interiorMaterial}
                            onChange={(e) => setInteriorMaterial(e.target.value)}
                            label="Material Interior">
                            <MenuItem value="Microfibra">Microfibra</MenuItem>
                            <MenuItem value="Piele">Piele</MenuItem>
                            <MenuItem value="Material">Material</MenuItem>
                            <MenuItem value="Microfibra">Microfibra</MenuItem>
                            <MenuItem value="Bumbac">Bumbac</MenuItem>
                            <MenuItem value="Canvas">Canvas</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 300px' }}>
                        <InputLabel>Vine Însoțit</InputLabel>
                        <Select
                            multiple
                            value={includedItems}
                            onChange={(e) => setIncludedItems(e.target.value)}
                            renderValue={(selected) => selected.join(', ')}
                            label="Vine Însoțit">
                            <MenuItem value="Dust bag">Dust bag</MenuItem>
                            <MenuItem value="Cutie">Cutie</MenuItem>
                            <MenuItem value="Certificat de conformitate">Certificat de conformitate</MenuItem>
                            <MenuItem value="Certificat Entrupy">Certificat Entrupy</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Culoare Hardware</InputLabel>
                        <Select
                            value={hardwareColor}
                            onChange={(e) => setHardwareColor(e.target.value)}
                            label="Culoare Hardware">
                            <MenuItem value="Auriu">Auriu</MenuItem>
                            <MenuItem value="Argintiu">Argintiu</MenuItem>
                            <MenuItem value="Mat">Mat</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        label="Designer ID"
                        value={designerId}
                        onChange={(e) => setDesignerId(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }} />
                    <TextField
                        label="Entrupy ID"
                        value={entrupyId}
                        onChange={(e) => setEntrupyId(e.target.value)}
                        fullWidth
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Locație</InputLabel>
                        <Select
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            label="Locație">
                            <MenuItem value="Exclusiv Online">Exclusiv Online</MenuItem>
                            <MenuItem value="Luxura Boutique Timișoara">Luxura Boutique Timișoara</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        label="Lungimea (cm)"
                        value={length}
                        onChange={(e) => setLength(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }} />
                    <TextField
                        label="Inălțime (cm)"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                    />
                    <TextField
                        label="Adâncime (cm)"
                        value={depth}
                        onChange={(e) => setDepth(e.target.value)}
                        fullWidth
                    />
                </Box>
                <TextField
                    label="Sortly ID"
                    value={sortlyId}
                    onChange={(e) => setSortlyId(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Descriere Detaliată"
                    value={detailedDescription}
                    onChange={(e) => setDetailedDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 200px' }}>
                        <InputLabel>Pentru</InputLabel>
                        <Select
                            value={pentru}
                            onChange={(e) => setPentru(e.target.value)}
                            label="Pentru">
                            <MenuItem value="Femei">Femei</MenuItem>
                            <MenuItem value="Barbati">Barbati</MenuItem>
                            <MenuItem value="Copii">Copii</MenuItem>
                            <MenuItem value="Casa">Casa</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControlLabel
                        control={<Switch checked={status === 'publish'}
                            onChange={(e) => setStatus(e.target.checked
                                ? 'publish'
                                : 'draft')} />}
                        label={status === 'publish'
                            ? 'Published'
                            : 'Ciorna'} />
                    <FormControlLabel
                        control={<Checkbox checked={isProductScheduled}
                            onChange={(e) => setIsProductScheduled(e.target.checked)} />}
                        label="Programare" />
                </Box>
                {isProductScheduled && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <DesktopDatePicker
                            label="Data"
                            inputFormat="DD/MM/YYYY"
                            value={scheduledDate}
                            onChange={(newValue) => setScheduledDate(newValue)}
                            renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
                        />
                        <TimePicker
                            label="Ora"
                            value={scheduledTime}
                            onChange={(newValue) => setScheduledTime(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                )}
                <FormControlLabel
                    control={<Checkbox checked={isLayaway} onChange={(e) => setIsLayaway(e.target.checked)} />}
                    label="Dorești produsul în LLP?"
                />
                {isLayaway && (
                    <>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>LLP</InputLabel>
                            <Select
                                value={layawayOption}
                                onChange={(e) => setLayawayOption(e.target.value)}
                                label="LLP"
                            >
                                {(salePrice || price) <= 2000
                                    ? <MenuItem value="1 Rata Speciala">1 Rata Speciala</MenuItem>
                                    : [
                                        <MenuItem key="1 Rata" value="1 Rata">1 Rata</MenuItem>,
                                        <MenuItem key="2 Rate" value="2 Rate">2 Rate</MenuItem>,
                                        <MenuItem key="3 Rate" value="3 Rate">3 Rate</MenuItem>
                                    ]}
                            </Select>
                        </FormControl>
                    </>
                )}

                {progress > 0 && (
                    <Box sx={{ width: '100%', mb: 2 }}>
                        <Typography variant="body2" color="textSecondary">{`Uploading images: ${progress}%`}</Typography>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                )}
                <Button variant="contained" color="primary" onClick={handleSaveProduct}>
                    Salvează Produs
                </Button>
                <Button variant="contained" color="secondary" onClick={onCancel} sx={{ ml: 2 }}>
                    Anulează
                </Button>
            </Box>
        </LocalizationProvider>
    );
};

export default EditProductWoo;
