import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Paper, Grid, CircularProgress, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import axios from 'axios';
import PurchasePriceCard from './PurchasePriceCardAnalysisStoc';
import AdditionalPriceCard from './AdditionalPriceCardAnalysisStoc';
import DiscountPolicyCard from './DiscountPolicyCardAnalysisStoc';
import WooCommerceProductDetails from './WooCommerceProductDetailsAnalysisStoc';
import { useProductContext } from './ProductContextAnalysisStoc';
import LabelIcon from '@mui/icons-material/Label';
import CardIcon from '@mui/icons-material/CardGiftcard'; // Import the icon
import { saveAs } from 'file-saver';

const ProductDetails = ({ product, onBack }) => {
    const [taskjiraDetails, setTaskjiraDetails] = useState(null);
    const [benchmarkPrice, setBenchmarkPrice] = useState(null);
    const [avgPurchasePrice, setAvgPurchasePrice] = useState(null);
    const [exchangeRate, setExchangeRate] = useState(null);
    const [analysisDetails, setAnalysisDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const { setPage } = useProductContext();

    useEffect(() => {
        if (product) {
            setBrand(product.attributes?.find(attr => attr.name === 'Designeri')?.options[0] || '');
            setModel(product.name.replace(/<[^>]+>/g, '')); // Remove HTML tags
            fetchTaskjiraDetails(product.sku);
            fetchBenchmarkPrice(product.name, getCondition(product));
            fetchAnalysisDetails(product.name);
        }
    }, [product]);

    const fetchTaskjiraDetails = async (sku) => {
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/masterdata/product-details/${sku}`);
            setTaskjiraDetails(response.data.taskjiraDetails);
        } catch (error) {
            console.error('Error fetching taskjira details:', error);
        }
    };

    const fetchAnalysisDetails = async (name) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/masterdata/products-details-analysis`, {
                params: { name }
            });
            setAnalysisDetails(response.data);
        } catch (error) {
            console.error('Error fetching analysis details:', error);
        } finally {
            setLoading(false);
        }
    };

    const getCondition = (product) => {
        const conditionAttribute = product.attributes?.find(attr => attr.name === 'Condiție');
        return conditionAttribute ? conditionAttribute.options[0] : 'N/A';
    };

    const fetchBenchmarkPrice = async (name, condition) => {
        try {
            const response = await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/calculate-price', { summary: name, stare: condition });
            const { avgPrice, avgPurchasePrice: purchasePrice, currency } = response.data;
            const priceInRON = await convertToRON(avgPrice, currency);
            const purchasePriceInRON = await convertToRON(purchasePrice, currency);
            setBenchmarkPrice(priceInRON);
            setAvgPurchasePrice(purchasePriceInRON);
        } catch (error) {
            console.error('Error fetching benchmark price:', error);
        }
    };

    const convertToRON = async (price, currency) => {
        if (currency === 'USD') {
            try {
                const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/USD`);
                const rate = response.data.rates.RON;
                setExchangeRate(rate);
                return (price * rate).toFixed(2);
            } catch (error) {
                console.error('Error fetching exchange rate:', error);
                return price;
            }
        }
        return price;
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        const updatedName = `<strong> ${brand} </strong><br><div style="margin-top:10px; font-size:16px;">${model}</div><br>`;
        fetchAnalysisDetails(updatedName); // Refresh analysis details with the updated name
        setIsEditing(false);
    };

    if (!product) return null;

    const dateCreated = new Date(product.dateCreated).toLocaleDateString();
    const price = product.price;
    const salePrice = product.salePrice !== 'N/A' ? product.salePrice : null;
    const daysInStock = Math.floor((new Date() - new Date(product.dateCreated)) / (1000 * 60 * 60 * 24));
    const productImage = product.images && product.images.length > 0 ? product.images[0].src : '';

    const category = product.categories && product.categories.length > 0 ? product.categories[0].name : 'Unknown';

    const handleBackClick = () => {
        setPage(0); // Reset the page to 0 to show the filtered list from the beginning
        onBack();
    };

    const calculateMetrics = (details) => {
        return details.map((detail) => {
            const entryPrice = detail.JIRA?.[0]?.entryPrice || 0;
            const pretDeVanzare = detail.JIRA?.[0]?.pret_de_vanzare_ron || 0;
            const dataIntrare = new Date(detail.JIRA?.[0]?.entryDate).getTime();
            const dataVandut = new Date(detail.JIRA?.[0]?.data_vandut || detail.sold?.[0]?.invoiceDate || new Date()).getTime();
            const daysInStock = (dataVandut - dataIntrare) / (1000 * 60 * 60 * 24);

            const marjaBruta = pretDeVanzare ? ((pretDeVanzare - entryPrice) / pretDeVanzare) * 100 : 0;
            const roi = entryPrice ? ((pretDeVanzare - entryPrice) / entryPrice) * 100 : 0;
            const gain = pretDeVanzare - entryPrice;

            return {
                sku: detail.sku,
                conditie: detail.conditie,
                marjaBruta: isNaN(marjaBruta) ? 0 : marjaBruta,
                roi: isNaN(roi) ? 0 : roi,
                gain: isNaN(gain) ? 0 : gain,
                daysInStock: isNaN(daysInStock) ? 0 : daysInStock,
                entryPrice,
                pretDeVanzare
            };
        });
    };

    const calculatedDetails = calculateMetrics(analysisDetails || []);
    const averages = calculatedDetails.reduce((acc, detail, _, { length }) => {
        acc.marjaBruta += detail.marjaBruta / length;
        acc.roi += detail.roi / length;
        acc.gain += detail.gain / length;
        acc.daysInStock += detail.daysInStock / length;
        return acc;
    }, { marjaBruta: 0, roi: 0, gain: 0, daysInStock: 0 });

    const handleCreateLabel = async () => {
        try {
            const response = await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/create-label', { sku: product.sku }, {
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(blob, `${product.sku}-label.pdf`);
            alert('Eticheta a fost creată cu succes!');
        } catch (error) {
            console.error('Error creating label:', error);
            alert('Eroare la crearea etichetei.');
        }
    };

const handleCreateCard = async () => {
    try {
        // Download card PDF
        const cardResponse = await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/create-card-pdf', { sku: product.sku }, {
            responseType: 'blob'
        });
        const cardBlob = new Blob([cardResponse.data], { type: 'application/pdf' });
        saveAs(cardBlob, `${product.sku}-card.pdf`);

        // Download Entrupy PDF
        const entrupyResponse = await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/create-entrupy-pdf', { sku: product.sku }, {
            responseType: 'blob'
        });
        const entrupyBlob = new Blob([entrupyResponse.data], { type: 'application/pdf' });
        saveAs(entrupyBlob, `${product.sku}-entrupy.pdf`);

        alert('Cardurile au fost create cu succes!');
    } catch (error) {
        console.error('Error creating cards:', error);
        alert('Eroare la crearea cardurilor.');
    }
};



    return (
       <Paper sx={{ p: 3, m: 2 }}>
            <Button variant="contained" color="primary" onClick={handleBackClick} sx={{ mb: 2 }}>
                Back to Product List
            </Button>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                        {productImage && (
                            <Grid item xs={4} md={2}>
                                <Box sx={{ textAlign: 'center', mb: 2 }}>
                                    <img src={productImage} alt="Product" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={8} md={10}>
                            {isEditing ? (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField label="Brand" value={brand} onChange={(e) => setBrand(e.target.value)} sx={{ mr: 2 }} />
                                    <TextField label="Model" value={model} onChange={(e) => setModel(e.target.value)} sx={{ mr: 2 }} />
                                    <Button variant="contained" color="primary" onClick={handleSave}>
                                        Actualizează
                                    </Button>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography variant="h4" component="div">
                                            {brand}
                                        </Typography>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {model}
                                        </Typography>
                                    </Box>
                                    <Button variant="outlined" color="primary" onClick={handleEdit}>
                                        Edit
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCreateLabel}
                            startIcon={<LabelIcon />}
                            sx={{ mr: 1 }}
                        >
                            Crează Eticheta
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCreateCard}
                            startIcon={<CardIcon />}
                        >
                            Crează Card
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                            <Typography variant="body1"><strong>Publicat:</strong> {dateCreated}</Typography>
                            <Typography variant="body1"><strong>Preț:</strong> {price} RON</Typography>
                            {salePrice && (
                                <Typography variant="body1" color="error">
                                    <strong>Reducere:</strong> {salePrice} RON
                                </Typography>
                            )}
                            <Typography variant="body1"><strong>Pe stoc de:</strong> {daysInStock} zile</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1"><strong>Benchmark:</strong> {benchmarkPrice !== null ? `${benchmarkPrice} RON` : 'Calculating...'}</Typography>
                            {avgPurchasePrice !== null && (
                                <>
                                    <Typography variant="body1"><strong>Benchmark achiziție:</strong> {avgPurchasePrice} RON</Typography>
                                    {exchangeRate && (
                                        <Typography variant="body2" color="textSecondary">
                                            Curs de schimb: {exchangeRate} RON/USD
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {taskjiraDetails && (
                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h6" component="div" gutterBottom>
                                Detalii Achiziție
                            </Typography>
                            {taskjiraDetails.map((detail, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                    <Typography variant="body1"><strong>Tip Achiziție:</strong> {detail.tip_achizitie}</Typography>
                                    <Typography variant="body1"><strong>Data de Intrare:</strong> {new Date(detail.data_de_intrare).toLocaleDateString()}</Typography>
                                    <Typography variant="body1"><strong>Preț Intrare RON:</strong> {detail.pret_intrare_ron}</Typography>
                                    <Typography variant="body1"><strong>Preț Vânzare RON:</strong> {detail.pret_de_vanzare_ron}</Typography>
                                    <Typography variant="body1"><strong>Nr. Jurnal Special:</strong> {detail.nr_jurnal_special}</Typography>
                                    <Typography variant="body1"><strong>SKU:</strong> {detail.sku}</Typography>
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" component="div" gutterBottom>
                            Analiza Interna
                        </Typography>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>SKU</TableCell>
                                                <TableCell>Condiție</TableCell>
                                                <TableCell>Marja Bruta</TableCell>
                                                <TableCell>ROI</TableCell>
                                                <TableCell>Profit</TableCell>
                                                <TableCell>Durată în Stoc</TableCell>
                                                <TableCell>Preț de Intrare</TableCell>
                                                <TableCell>Preț de Vânzare</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {calculatedDetails.map((detail, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{detail.sku}</TableCell>
                                                    <TableCell>{detail.conditie}</TableCell>
                                                    <TableCell>{detail.marjaBruta.toFixed(2)}%</TableCell>
                                                    <TableCell>{detail.roi.toFixed(2)}%</TableCell>
                                                    <TableCell>{detail.gain.toFixed(2)} RON</TableCell>
                                                    <TableCell>{detail.daysInStock.toFixed(2)} zile</TableCell>
                                                    <TableCell>{detail.entryPrice.toFixed(2)} RON</TableCell>
                                                    <TableCell>{detail.pretDeVanzare.toFixed(2)} RON}</TableCell>
                                                </TableRow>
                                            ))}
                                            {calculatedDetails.length > 0 && (
                                                <TableRow>
                                                    <TableCell><strong>Medie</strong></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>{(averages.marjaBruta).toFixed(2)}%</TableCell>
                                                    <TableCell>{(averages.roi).toFixed(2)}%</TableCell>
                                                    <TableCell>{(averages.gain).toFixed(2)} RON</TableCell>
                                                    <TableCell>{(averages.daysInStock).toFixed(2)} zile</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    {analysisDetails && analysisDetails.map((detail, index) => (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <Paper sx={{ p: 2 }}>
                                                <Box sx={{ textAlign: 'center', mb: 2 }}>
                                                    <img src={detail.dfImageLink} alt="Product" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
                                                </Box>
                                                <Typography variant="body1"><strong>Model:</strong> {model}</Typography>
                                                <Typography variant="body1"><strong>Condiție:</strong> {detail.conditie}</Typography>
                                                <Typography variant="body1"><strong>SKU:</strong> {detail.sku}</Typography>
                                                <Typography variant="body1"><strong>Data Creată:</strong> {new Date(detail.dateCreated).toLocaleDateString()}</Typography>
                                                <Typography variant="body1"><strong>Preț:</strong> {detail.price} RON</Typography>
                                                {detail.salePrice && (
                                                    <Typography variant="body1" color="error">
                                                        <strong>Preț Reducere:</strong> {detail.salePrice} RON
                                                    </Typography>
                                                )}
                                                <Typography variant="body1"><strong>Pe Stoc:</strong> {detail.inStock}</Typography>
                                                {detail.sold && detail.sold.length > 0 && (
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography variant="h6" component="div" gutterBottom>
                                                            Date Vânzări
                                                        </Typography>
                                                        {detail.sold.map((soldItem, soldIndex) => (
                                                            <Box key={soldIndex} sx={{ mb: 2 }}>
                                                                <Typography variant="body1"><strong>Data Facturii:</strong> {new Date(soldItem.invoiceDate).toLocaleDateString()}</Typography>
                                                                <Typography variant="body1"><strong>Subtotal:</strong> {soldItem.subtotal} RON</Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                                {detail.JIRA && detail.JIRA.length > 0 && (
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography variant="h6" component="div" gutterBottom>
                                                            Detalii JIRA
                                                        </Typography>
                                                        {detail.JIRA.map((jiraItem, jiraIndex) => (
                                                            <Box key={jiraIndex} sx={{ mb: 2 }}>
                                                                <Typography variant="body1"><strong>Data Intrare:</strong> {new Date(jiraItem.entryDate).toLocaleDateString()}</Typography>
                                                                <Typography variant="body1"><strong>Preț Intrare:</strong> {jiraItem.entryPrice} RON</Typography>
                                                                <Typography variant="body1"><strong>Tip Contract:</strong> {jiraItem.contractType}</Typography>
                                                                <Typography variant="body1"><strong>Data Vandut:</strong> {jiraItem.data_vandut ? new Date(jiraItem.data_vandut).toLocaleDateString() : 'N/A'}</Typography>
                                                                <Typography variant="body1"><strong>Pret Vanzare:</strong> {jiraItem.pret_de_vanzare_ron} RON}</Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}
                    </Box>
                    <Box sx={{ mt: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                {taskjiraDetails && taskjiraDetails[0] && (
                                    <PurchasePriceCard
                                        avgPrice={taskjiraDetails[0].pret_de_vanzare_ron}
                                        pretIntrareRON={taskjiraDetails[0].pret_intrare_ron}
                                        category={category}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                {taskjiraDetails && taskjiraDetails[0] && (
                                    <AdditionalPriceCard
                                        avgPrice={taskjiraDetails[0].pret_de_vanzare_ron}
                                        category={category}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                {taskjiraDetails && taskjiraDetails[0] && (
                                    <DiscountPolicyCard
                                        pretDeVanzareRON={taskjiraDetails[0].pret_de_vanzare_ron}
                                        pretIntrareRON={taskjiraDetails[0].pret_intrare_ron}
                                        dateCreated={product.dateCreated}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                    <WooCommerceProductDetails sku={product.sku} journalNumber={taskjiraDetails && taskjiraDetails[0]?.nr_jurnal_special} />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ProductDetails;
