import React, { useState } from 'react';
import { Grid, Snackbar, Paper, Typography, SnackbarContent } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.shape.borderRadius,
  color: '#1976d2',
  padding: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  height: 180,
  transition: 'box-shadow 0.3s',
  '&:hover': {
    boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.15)',
  },
}));

const Logo = styled('img')({
  width: 50,
  height: 50,
  marginBottom: 8,
});

export default function UploadGrid() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);  // Adaugă un nou state pentru succes
  
  const services = [
    { name: "Stripe", logo: "/path/to/stripe-logo.png" },
    { name: "EuPlatesc", logo: "/path/to/euplatesc-logo.png" },
    { name: "TBI", logo: "/path/to/tbi-logo.png" },
    { name: "FanCourier", logo: "/path/to/fancourier-logo.png" },
    { name: "GLS", logo: "/path/to/gls-logo.png" },
    { name: "Cargus", logo: "/path/to/cargus-logo.png" }
  ];

  return (
    <>
      <Grid container spacing={5} style={{ paddingTop: 50 }}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Dropzone
              service={service.name}
              logo={service.logo}
              setOpen={setOpen}
              setMessage={setMessage}
            />
          </Grid>
        ))}
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <SnackbarContent style={{ backgroundColor: isSuccess ? 'green' : 'red' }} message={message} />
      </Snackbar>
    </>
  );
}

function Dropzone({ service, logo, setOpen, setMessage }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      formData.append('service', service);

      console.log('Attempting to upload:', service);
      console.log('File:', acceptedFiles[0]);

      try {
        const response = await axios.post(`https://server-9p4hx.kinsta.app/upload-deconturi/${service}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Server response:', response.data);
        setOpen(true);
        setMessage(response.data.message);
      } catch (error) {
        console.error('Error uploading file:', error);
        setOpen(true);
        setMessage('Error uploading file.');
      }
    }
  });

  return (
    <StyledPaper {...getRootProps()}>
      <input {...getInputProps()} />
      <Typography variant="h6">{service}</Typography>
      <Typography variant="body2">Trageți fișierul aici, sau faceți click pentru a încărca</Typography>
      <Logo src={logo} alt={`${service} logo`} />
      <CloudUploadIcon style={{ fontSize: 48 }} />
    </StyledPaper>
  );
}
