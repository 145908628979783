import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const PieChartComponent = ({ profit }) => {
  const maxProfit = 176000;
  const excess = profit > maxProfit ? ((profit - maxProfit) / maxProfit) * 100 : 0;
  const data = [
    { name: 'Covered', value: Math.min(profit, maxProfit) },
    { name: 'Remaining', value: profit > maxProfit ? 0 : maxProfit - profit }
  ];
  const COLORS = ['#0088FE', '#FF0000'];

  const formatNumber = (number) => {
    return number.toLocaleString('ro-RO', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const formatPercentage = (number) => {
    return number.toFixed(0);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Progres Cheltuieli</Typography>
        {profit > maxProfit ? (
          <Box sx={{ textAlign: 'center', position: 'relative', width: 400, height: 400 }}>
            <Box sx={{
              width: 400,
              height: 400,
              borderRadius: '50%',
              backgroundColor: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              color: 'white',
            }}>
              <Typography variant="h4" sx={{ color: 'white' }}>+{formatPercentage(excess)}%</Typography>
              <Typography variant="body1" sx={{ color: 'white' }}>Suma peste: {formatNumber(profit - maxProfit)} RON</Typography>
            </Box>
          </Box>
        ) : (
          <>
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                startAngle={90}
                endAngle={-270}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
            <Typography variant="body1">
              Total profit acoperit: {formatNumber(profit)} RON {profit > maxProfit && (
                <span style={{ color: 'green' }}>
                  (+{formatNumber(profit - maxProfit)} RON)
                </span>
              )}
            </Typography>
            <Typography variant="body2">
              Mai trebuie să aduceți: {formatNumber(maxProfit - profit)} RON
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PieChartComponent;
