import React, { useState, useEffect } from 'react';
import { Container, Typography, CircularProgress, Box } from '@mui/material';
import InputForm from './InputForm';
import Results from './Results';
import calculateCost from './calculateCost';

function Page18({ manualAvgPrice }) { // Primiți manualAvgPrice ca prop
  const [formData, setFormData] = useState({
    categorie: '',
    brand: '',
    model: '',
    size: '',
    avgPrice: manualAvgPrice || localStorage.getItem('avgPrice') || '',  // Setăm valoarea initială din manualAvgPrice
    conditie: '',
    accessories: []
  });
  
  const [results, setResults] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exchangeRate, setExchangeRate] = useState(1); // Default exchange rate 1 for simplicity

  useEffect(() => {
    fetch('https://server-9p4hx.kinsta.app/api/masterdata/get-products-raw')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetch('https://api.exchangerate-api.com/v4/latest/USD')
      .then(response => response.json())
      .then(data => {
        setExchangeRate(data.rates.RON);
      })
      .catch(error => console.error('Error fetching exchange rate:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCalculate = (manualAvgPrice) => {
    const filteredData = data.filter(item =>
      item.Category === formData.categorie &&
      item.Brand === formData.brand &&
      item.Model === formData.model &&
      item.Conditio === formData.conditie
    );

    const total = filteredData.reduce((sum, item) => sum + parseFloat(item.Price_sale), 0);
    const average = filteredData.length ? total / filteredData.length : 0;
    const convertedAverage = manualAvgPrice ? parseFloat(manualAvgPrice) : average * exchangeRate;

    if (isNaN(convertedAverage)) {
      console.error('Converted average is not a number');
      return;
    }

    const updatedFormData = { ...formData, avgPrice: convertedAverage.toFixed(2) };
    setFormData(updatedFormData);

    console.log(`Converted Average: ${convertedAverage}`);

    const costResults = calculateCost(convertedAverage, updatedFormData.accessories, formData.categorie);
    console.log(`Results from calculateCost:`, costResults);
    setResults({ ...costResults, avgPrice: convertedAverage.toFixed(2), filteredData });
  };

  const handleReset = () => {
    setFormData({ categorie: '', brand: '', model: '', size: '', avgPrice: '', conditie: '', accessories: [] });
    setResults(null);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Product Cost Calculator
      </Typography>
      <InputForm
        formData={formData}
        onInputChange={handleInputChange}
        onCalculate={handleCalculate}
        onReset={handleReset}
        data={data}
        setResults={setResults}
        manualAvgPrice={manualAvgPrice} // Transmiterea prețului către InputForm
      />
      {results && <Results results={results} category={formData.categorie} avgPrice={formData.avgPrice} />}
    </Container>
  );
}

export default Page18;
