import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';

const statusColors = {
    CREAT: 'default',
    PROSPECT: 'primary',
    TRANZIT: 'secondary',
    AUTENTIFICARE: 'warning',
    ACCEPTAT: 'success'
};

export default function ActiveRequests({ email }) {
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-by-email?email=${email}`);
                setRequests(response.data);
            } catch (error) {
                console.error('Error fetching tasks from JIRA:', error);
            }
        };

        fetchRequests();
    }, [email]);

    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }} // Set width to 100% of the parent container
            subheader={<ListSubheader>Cereri active</ListSubheader>}
        >
            {requests.map((task) => (
                <ListItem key={task.issueId} sx={{ width: '100%' }}>
                    <ListItemIcon>
                        <ArtTrackIcon />
                    </ListItemIcon>
                    <ListItemText 
                        primary={task.summary} 
                        primaryTypographyProps={{ 
                            variant: 'body2', // Set the font size smaller
                            style: { 
                                whiteSpace: 'nowrap', // Prevent text from wrapping
                                overflow: 'hidden', // Hide overflowing text
                                textOverflow: 'ellipsis' // Add ellipsis if text is too long
                            } 
                        }} 
                    />
                    <Chip 
                        label={task.status} 
                        color={statusColors[task.status] || 'default'} 
                        sx={{ 
                            ml: 2, 
                            fontSize: '0.75rem', // Smaller font size for Chip text
                            height: 24, // Smaller height for the Chip
                            '& .MuiChip-label': {
                                paddingLeft: 1,
                                paddingRight: 1,
                            }
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
}
