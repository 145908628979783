import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductTable from './ProductTableAnalysisStoc';
import ProductDetails from './ProductDetailsAnalysisStoc';
import CreateProductWoo from './CreateProductWoo';
import EditProductWoo from './EditProductWoo';
import { ProductProvider } from './ProductContextAnalysisStoc';
import { Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const App = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updatedToday, setUpdatedToday] = useState(false);
  const [creatingProduct, setCreatingProduct] = useState(false);
  const [editingProduct, setEditingProduct] = useState(false);
  const [wooCategories, setWooCategories] = useState([]);
  const [structuredCategories, setStructuredCategories] = useState([]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleBack = () => {
    setSelectedProduct(null);
  };

  const handleCreateProductClick = () => {
    setCreatingProduct(true);
    setEditingProduct(false);
  };

  const handleEditProductClick = (product) => {
    setSelectedProduct(product);
    setEditingProduct(true);
    setCreatingProduct(false);
  };

  const handleSaveProduct = (product) => {
    setEditingProduct(false);
    setSelectedProduct(null);
    // Actualizați lista de produse dacă este necesar  
  };

  const handleCancelEdit = () => {
    setEditingProduct(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    checkForUpdates();
    fetchCategories();
  }, []);

  const checkForUpdates = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/masterdata/check-updates');
      setUpdatedToday(response.data.updatedToday);
    } catch (error) {
      console.error('Error checking for updates:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      let allCategories = [];
      let page = 1;
      let totalPages = 1;
      do {
        const response = await axios.get(`https://www.luxuraelite.ro/wp-json/wc/v3/products/categories?per_page=100&page=${page}`, {
          headers: {
            Authorization: 'Basic ' + btoa('ck_a941824be557253d3765a1f6242e6d6a590da999:cs_6b0d98e4c442e707be9888cafff1fac17cdbf259')
          }
        });
        allCategories = allCategories.concat(response.data);
        totalPages = parseInt(response.headers['x-wp-totalpages'], 10);
        page++;
      } while (page <= totalPages);

      const structuredCategories = structureCategories(allCategories);
      setWooCategories(allCategories);
      setStructuredCategories(structuredCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const structureCategories = (categories) => {
    const categoryMap = {};
    categories.forEach(category => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    const structuredCategories = [];
    categories.forEach(category => {
      if (category.parent === 0) {
        structuredCategories.push(categoryMap[category.id]);
      } else {
        if (categoryMap[category.parent]) {
          categoryMap[category.parent].children.push(categoryMap[category.id]);
        }
      }
    });

    return structuredCategories;
  };

  const handleSendReport = async () => {
    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/send-report');
      alert('Report sent to Slack successfully!');
    } catch (error) {
      console.error('Error sending report:', error);
      alert('Failed to send report.');
    }
  };

  return (
    <ProductProvider>
      <div>
        {updatedToday && (
          <Button
            variant="contained"
            color="warning"
            onClick={handleSendReport}
            sx={{ position: 'absolute', top: 10, right: 10 }}
            startIcon={<WarningIcon />}
          >
            Send Report to Slack
          </Button>
        )}
        {selectedProduct && editingProduct ? (
          <EditProductWoo
            product={selectedProduct}
            wooCategories={structuredCategories}
            onSave={handleSaveProduct}
            onCancel={handleCancelEdit}
          />
        ) : selectedProduct ? (
          <ProductDetails product={selectedProduct} onBack={handleBack} />
        ) : creatingProduct ? (
          <CreateProductWoo wooCategories={structuredCategories} />
        ) : (
          <ProductTable
            onProductClick={handleProductClick}
            onCreateProductClick={handleCreateProductClick}
            onEditProductClick={handleEditProductClick}
          />
        )}
      </div>
    </ProductProvider>
  );
};

export default App;
