import axios from 'axios';

const WooCommerceApi = axios.create({
  baseURL: 'https://luxuraelite.ro/wp-json/wc/v3',
  auth: {
    username: 'ck_de8402273c9c6525fb84be8c80cf82f2a465e9a1',
    password: 'cs_3039dfefac2bf37a89ca4fd78648f6475e8ae17a'
  }
});

export const fetchProducts = async () => {
  try {
    const response = await WooCommerceApi.get('/products', {
      params: {
        per_page: 100,
        //status: 'publish'
      }
    });
    console.log("Produse încărcate:", response.data); // Adaugă această linie pentru a vedea datele returnate
    return response.data;
  } catch (error) {
    console.error('Eroare la preluarea produselor:', error);
    return [];
    
  }
};

export const updateProduct = async (productId, metaData) => {
  try {
    await WooCommerceApi.put(`/products/${productId}`, { meta_data: metaData });
  } catch (error) {
    console.error('Eroare la actualizarea produsului:', error);
    
  }
  
};

export const uploadImage = async (imageFile) => {
  const formData = new FormData();
  formData.append('file', imageFile);

  try {
    const response = await WooCommerceApi.post('/media', formData, {
      headers: {
        'Content-Disposition': `attachment; filename=${imageFile.name}`,
        'Content-Type': imageFile.type
      }
    });
    console.log("Imagine încărcată:", response.data);
    return response.data;
  } catch (error) {
    console.error('Eroare la încărcarea imaginii:', error);
    throw error; // Aruncă eroarea pentru a putea fi gestionată în componenta Page7
  }
};