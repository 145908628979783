import React, { useEffect, useState, useCallback } from 'react';
import { fetchProducts, updateProduct, fetchProduct, uploadImage } from './woocommerceApi';
import {
  Button, Snackbar, Alert, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper, Select, 
  MenuItem, CircularProgress, Link
} from '@mui/material';

function Page7() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filter, setFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [updatingProductId, setUpdatingProductId] = useState(null);

  const loadProducts = useCallback(async () => {
    setLoading(true);
    try {
      const productsData = await fetchProducts();
      setProducts(productsData);
      setFilteredProducts(productsData);
    } catch (error) {
      console.error('Eroare la încărcarea produselor:', error);
      setError('Eroare la încărcarea produselor.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleUpdateProductMarketing = async (productId, newMarketingValue) => {
    setUpdatingProductId(productId);
    try {
      await updateProduct(productId, [{ key: 'marketing_facut', value: newMarketingValue }]);
      setProducts(prevProducts =>
        prevProducts.map(product => 
          product.id === productId 
            ? { 
                ...product, 
                meta_data: product.meta_data.map(meta => 
                  meta.key === 'marketing_facut' ? { ...meta, value: newMarketingValue } : meta
                )
              } 
            : product
        )
      );
      setFilteredProducts(prevFilteredProducts =>
        prevFilteredProducts.map(product => 
          product.id === productId 
            ? { 
                ...product, 
                meta_data: product.meta_data.map(meta => 
                  meta.key === 'marketing_facut' ? { ...meta, value: newMarketingValue } : meta
                )
              } 
            : product
        )
      );
    } catch (error) {
      console.error('Eroare la actualizarea produsului:', error);
      setError('Eroare la actualizarea produsului.');
      setOpenSnackbar(true);
    } finally {
      setUpdatingProductId(null);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilter(value);
    applyFilters(value, statusFilter);
  };

  const handleStatusFilterChange = (event) => {
    const value = event.target.value;
    setStatusFilter(value);
    applyFilters(filter, value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      setError('Niciun fișier selectat.');
      setOpenSnackbar(true);
      return;
    }

    try {
      const imageData = await uploadImage(selectedFile);
      console.log('Imagine încărcată cu succes:', imageData);
      // Aici poți actualiza starea aplicației tale cu informațiile imaginii încărcate
    } catch (error) {
      setError('Eroare la încărcarea imaginii: ' + error.message);
      setOpenSnackbar(true);
    }
  };

  const applyFilters = (marketingFilter, statusFilter) => {
    let newFilteredProducts = products;
    
    if (marketingFilter !== 'all') {
      newFilteredProducts = newFilteredProducts.filter(product => {
        const marketingData = product.meta_data.find(meta => meta.key === 'marketing_facut');
        return marketingData ? marketingData.value === marketingFilter : marketingFilter === 'N/A';
      });
    }

    if (statusFilter !== 'all') {
      newFilteredProducts = newFilteredProducts.filter(product => product.status === statusFilter);
    }

    setFilteredProducts(newFilteredProducts);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Select value={filter} onChange={handleFilterChange}>
        <MenuItem value="all">Toate Produsele</MenuItem>
        <MenuItem value="1">1</MenuItem>
        <MenuItem value="0">0</MenuItem>
        <MenuItem value="N/A">N/A</MenuItem>
      </Select>
      <Select value={statusFilter} onChange={handleStatusFilterChange}>
        <MenuItem value="all">Toate Statusurile</MenuItem>
        <MenuItem value="publish">Publish</MenuItem>
        <MenuItem value="future">Future</MenuItem>
        <MenuItem value="draft">Draft</MenuItem>
        <MenuItem value="private">Private</MenuItem>
      </Select>
      <Button onClick={loadProducts}>Reîncarcă Produsele</Button>

      <TableContainer component={Paper}>
        <Table aria-label="Tabel Marketing">
          <TableHead>
            <TableRow>
              <TableCell>Produs</TableCell>
              <TableCell align="right">SKU</TableCell>
              <TableCell align="right">Preț</TableCell>
              <TableCell align="right">Data Modificării</TableCell>
              <TableCell align="right">Link Produs</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Marketing Făcut</TableCell>
              <TableCell align="right">Acțiuni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((product) => {
              const marketingData = product.meta_data.find(meta => meta.key === 'marketing_facut');
              const marketingValue = marketingData ? marketingData.value : 'N/A';

              return (
                <TableRow key={product.id}>
                  <TableCell component="th" scope="row">
                    {product.name}
                  </TableCell>
                  <TableCell align="right">{product.sku}</TableCell>
                  <TableCell align="right">{product.price}</TableCell>
                  <TableCell align="right">{product.date_modified}</TableCell>
                  <TableCell align="right">
                    <Link href={product.permalink} target="_blank">Vizualizează</Link>
                  </TableCell>
                  <TableCell align="right">{product.status}</TableCell>
                  <TableCell align="right">
                    {updatingProductId === product.id ? <CircularProgress size={24} /> : marketingValue}
                  </TableCell>
                  <TableCell align="right">
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={() => handleUpdateProductMarketing(
                        product.id, 
                        marketingValue === '1' ? '0' : '1'
                      )}
                      disabled={updatingProductId === product.id}
                    >
                      {updatingProductId === product.id ? 'Actualizare...' : 'Schimbă Status'}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Page7;
