import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Paper } from "@mui/material";

function ReceptionTable({ data, onCheckboxChange, isCreatTab }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product ID</TableCell>
            <TableCell>Summary</TableCell>
            <TableCell>Seria Recepție</TableCell>
            <TableCell>Data Recepție</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Completat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((product) => {
            const isChecked = isCreatTab ? true : product[product.serieReceptie] === 1; // Bifat implicit în tabul CREAT
            return (
              <TableRow key={`${product.productId}-${product.serieReceptie}`}>
                <TableCell>{product.productId}</TableCell>
                <TableCell>{product.summary}</TableCell>
                <TableCell>{product.serieReceptie}</TableCell>
                <TableCell>{new Date(product.dataReceptie).toLocaleDateString()}</TableCell>
                <TableCell>{product.status}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) =>
                      onCheckboxChange(
                        product.productId,
                        product.serieReceptie,
                        e.target.checked
                      )
                    }
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReceptionTable;
