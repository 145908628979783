// src/TaskCard.js
import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';

// Funcție helper pentru formatarea datei
const formatDate = (dateString) => {
    if (!dateString) return 'N/A'; // Dacă nu există o dată, returnează 'N/A'
    const date = new Date(dateString);
    return date.toLocaleDateString('ro-RO'); // Ajustează în funcție de localizarea dorită
};

function TaskCardComponent ({id, task}) {
    return (
        <Card
            variant="outlined"
            style={{
            margin: '10px',
            
        }}>
            <CardContent>
                <Typography variant="h6">{task.summary}</Typography>
                <Typography>ID: {task.issue_id}</Typography>
                <Typography>Nume: {task.nume_si_prenume || 'N/A'}</Typography>
                <Typography>Telefon: {task.telefon || 'N/A'}</Typography>
                <Typography>Status: {task.status}</Typography>
                {task.issue_id && typeof task.issue_id === 'string' && !task.issue_id.startsWith("LEB") && <Typography variant="body1">Due Date: {formatDate(task.due_date)}</Typography>}
                <Typography>Tip Contract: {task.tip_achizitie || 'N/A'}</Typography>
                <Typography>Data creării: {formatDate(task.created_date)}</Typography>
                {/* Adaugă aici orice alte detalii dorești să afișezi */}
            </CardContent>
        </Card>
    );
}

const TaskCard = React.memo(TaskCardComponent);

export default TaskCard;
