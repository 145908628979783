import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const StatisticiDashboard = ({ lunaSelectata, anSelectat }) => {
  const [statistici, setStatistici] = useState({
    incasatSaptamana: 0,
    incasatLuna: 0,
    rateDepasite: 0,
    produseLivrare: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchStatistici = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/llp/statistici`, {
        params: { luna: lunaSelectata, an: anSelectat },
      });
      setStatistici(response.data);
    } catch (error) {
      console.error('Failed to fetch statistics:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatistici();
  }, [lunaSelectata, anSelectat]);

  const formatNumber = (num) => {
    return num.toLocaleString('ro-RO', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  const convertToEuro = (ron) => {
    const euro = ron / 5;
    return euro.toLocaleString('ro-RO', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={3} style={{ marginTop: '20px' }}>
      {[
        { label: 'Săptămâna aceasta', value: statistici.incasatSaptamana, showEuro: true },
        { label: 'Luna aceasta', value: statistici.incasatLuna, showEuro: true },
        { label: 'Rate cu scadența depășită', value: statistici.rateDepasite, showEuro: false },
        { label: 'Produse pregătite de livrare', value: statistici.produseLivrare, showEuro: false }
      ].map((stat, index) => (
        <Grid item xs={12} md={3} key={index}>
          <Card
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              borderRadius: '12px',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
              },
            }}
          >
            <CardContent style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                {stat.label}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: 'bold', color: '#4caf50', marginBottom: '8px' }}>
                {formatNumber(stat.value)} {stat.showEuro ? 'RON' : ''}
              </Typography>
              {stat.showEuro && (
                <Typography variant="body2" style={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
                  <MonetizationOnIcon fontSize="small" /> {convertToEuro(stat.value)} €
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatisticiDashboard;
