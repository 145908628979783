import React, { useEffect, useState } from 'react';
import LLpTable from './llptable';
import axios from 'axios';
import { Grid, createTheme, ThemeProvider, Button, Dialog, DialogTitle, DialogContent, TextField, MenuItem, DialogActions } from '@mui/material';
import { cyan, green, pink } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

const theme = createTheme({
  palette: {
    primary: {
      main: cyan[500],
    },
    secondary: {
      main: pink[500],
    },
    success: {
      main: green[500],
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: '1px solid #e0e0e0',
          padding: '16px 24px',
          fontSize: '0.875rem',
        },
        head: {
          backgroundColor: '#f5f5f5',
          color: '#5f6368',
          fontWeight: 500,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 'none',
          },
          '&:hover': {
            backgroundColor: '#f5f5f5',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          overflow: 'hidden',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
        },
      },
    },
  },
});

const Page14 = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newOrder, setNewOrder] = useState({
    issue_id: '',
    nr_de_rate: '',
    client_email: '',
    client_nume_prenume: '',
    client_telefon: '',
    avans_llp: '',
    model: '',
    sku: '',
    pret_de_vanzare_ron: '',
    data_vandut: '',
    nr_comanda: '',
    rata1: '',
    rata2: '',
    rata3: '',
    rata4: '',
    rata5: '',
    rata6: '',
    rata7: '',
    rata8: '',
    rata9: '',
    rata10: '',
    rata11: '',
    rata12: '',
    rata13: '',
    rata14: '',
    rata15: '',
    rata16: '',
    rata17: '',
    rata18: '',
    rata19: '',
    rata20: '',
    data_rata_1: '',
    data_rata_2: '',
    data_rata_3: '',
    data_rata_4: '',
    data_rata_5: '',
    data_rata_6: '',
    data_rata_7: '',
    data_rata_8: '',
    data_rata_9: '',
    data_rata_10: '',
    data_rata_11: '',
    data_rata_12: '',
    data_rata_13: '',
    data_rata_14: '',
    data_rata_15: '',
    data_rata_16: '',
    data_rata_17: '',
    data_rata_18: '',
    data_rata_19: '',
    data_rata_20: ''
  });

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewOrder(current => {
      const updates = { ...current, [name]: value };

      if (name === 'nr_de_rate' && value && current.pret_de_vanzare_ron && current.avans_llp && current.data_vandut) {
        const installmentAmount = (current.pret_de_vanzare_ron - current.avans_llp) / value;
        for (let i = 0; i < value; i++) {
          const dueDate = new Date(new Date(current.data_vandut).setDate(new Date(current.data_vandut).getDate() + 30 * (i + 1)));
          updates[`rata${i + 1}`] = installmentAmount.toFixed(2);
          updates[`data_rata_${i + 1}`] = dueDate.toISOString().split('T')[0];
        }
      }
      return updates;
    });
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/llp/get-llp');
      setTasks(response.data);
      setNewOrder(n => ({ ...n, issue_id: `LLP-${10000 + response.data.length}` }));
      setSelectedRows(new Set());
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
    }
  };

  const handleRowClick = async (row) => {
    const updatedSelectedRows = new Set(selectedRows);
    updatedSelectedRows.add(row.issue_id);
    setSelectedRows(updatedSelectedRows);

    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/llp/save-llp', { ...row, statusclient: 'Activ' });
      console.log('Status updated to Active');
      fetchTasks();
    } catch (error) {
      console.error('Error on row click:', error);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleSave = async () => {
    try {
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/llp/custom-save-llp', newOrder);
      console.log('Save successful:', response.data);
      setDialogOpen(false);
    } catch (error) {
      console.error('Save failed:', error);
      alert('Failed to save data.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" color="primary" onClick={handleOpenDialog} style={{ margin: '20px' }}>
        Adaugă Comandă
      </Button>
      <LLpTable data={tasks} handleRowClick={handleRowClick} selectedRows={selectedRows} />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Adaugă o nouă comandă</DialogTitle>
        <DialogContent>
          <TextField margin="dense" name="issue_id" label="Issue_ID" type="text" fullWidth variant="outlined" value={newOrder.issue_id} InputProps={{ readOnly: true }} />
          <TextField margin="dense" name="client_email" label="Email" type="email" fullWidth variant="outlined" value={newOrder.client_email} onChange={handleInputChange} />
          <TextField margin="dense" name="client_nume_prenume" label="Nume și Prenume" fullWidth variant="outlined" value={newOrder.client_nume_prenume} onChange={handleInputChange} />
          <TextField margin="dense" name="client_telefon" label="Telefon" fullWidth variant="outlined" value={newOrder.client_telefon} onChange={handleInputChange} />
          <TextField margin="dense" name="avans_llp" label="Avans LLP" type="number" fullWidth variant="outlined" value={newOrder.avans_llp} onChange={handleInputChange} />
          <TextField margin="dense" name="model" label="Model" fullWidth variant="outlined" value={newOrder.model} onChange={handleInputChange} />
          <TextField margin="dense" name="sku" label="SKU" fullWidth variant="outlined" value={newOrder.sku} onChange={handleInputChange} />
          <TextField margin="dense" name="pret_de_vanzare_ron" label="Pret de vanzare" type="number" fullWidth variant="outlined" value={newOrder.pret_de_vanzare_ron} onChange={handleInputChange} />
          <TextField margin="dense" name="data_vandut" label="Data Vandut" type="date" fullWidth variant="outlined" value={newOrder.data_vandut} onChange={handleInputChange} />
          <TextField margin="dense" name="nr_comanda" label="Nr Comanda" type="number" fullWidth variant="outlined" value={newOrder.nr_comanda} onChange={handleInputChange} />
          <TextField select label="Nr Rate" name="nr_de_rate" value={newOrder.nr_de_rate} onChange={handleInputChange} fullWidth>
            {Array.from({ length: 20 }, (_, i) => i + 1).map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>
          {Array.from({ length: newOrder.nr_de_rate }, (_, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={6}>
                <TextField margin="dense" label={`Rata ${index + 1}`} fullWidth variant="outlined" value={newOrder[`rata${index + 1}`]} InputProps={{ readOnly: true }} />
              </Grid>
              <Grid item xs={6}>
                <TextField margin="dense" label={`Data Rata ${index + 1}`} type="date" fullWidth variant="outlined" value={newOrder[`data_rata_${index + 1}`]} InputProps={{ readOnly: true }} />
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button startIcon={<CancelIcon />} onClick={() => setDialogOpen(false)} color="secondary">
            Anulează
          </Button>
          <Button startIcon={<SaveIcon />} onClick={handleSave} color="primary">
            Salvează
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default Page14;
