import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    LinearProgress,
    Typography,
    InputAdornment,
    Switch,
    Card,
    CardMedia,
    CardContent,
    Divider,
    IconButton
} from '@mui/material';
import { ArrowBack, ArrowForward, Delete as DeleteIcon } from '@mui/icons-material';
import {
    DesktopDatePicker,
    TimePicker
} from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import ImageUploader from './ImageUploader';
import buildHtmlContent from './HtmlContentBuilder';
import CategoryIcon from '@mui/icons-material/Category';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InfoIcon from '@mui/icons-material/Info';
import ImageIcon from '@mui/icons-material/Image';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DetailsIcon from '@mui/icons-material/Details';
import StorageIcon from '@mui/icons-material/Storage';

const conditionOptions = {
    Nou: 1,
    Excelent: 2,
    'Foarte Buna': 3,
    Buna: 4,
    Rezonabil: 5
};


const CreateProductWoo = ({ wooCategories, productData }) => {
    const [localWooCategories, setLocalWooCategories] = useState([]); // Definim starea locală pentru categoriile preluate
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [msrpPrice, setMsrpPrice] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('draft');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [productType, setProductType] = useState('');
    const [brand, setBrand] = useState('');
    const [condition, setCondition] = useState('');
    const [customDescription, setCustomDescription] = useState('');
    const [isSaleScheduled, setIsSaleScheduled] = useState(false);
    const [isProductScheduled, setIsProductScheduled] = useState(false);
    const [scheduledDate, setScheduledDate] = useState(null);
    const [scheduledTime, setScheduledTime] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [progress, setProgress] = useState(0);
    const [nextProductId, setNextProductId] = useState(null);
    const [sku, setSku] = useState('');
    const [exteriorColor, setExteriorColor] = useState('');
    const [interiorColor, setInteriorColor] = useState('');
    const [exteriorMaterial, setExteriorMaterial] = useState('');
    const [interiorMaterial, setInteriorMaterial] = useState('');
    const [includedItems, setIncludedItems] = useState([]);
    const [hardwareColor, setHardwareColor] = useState('');
    const [designerId, setDesignerId] = useState('');
    const [entrupyId, setEntrupyId] = useState('');
    const [location, setLocation] = useState('');
    const [length, setLength] = useState('');
    const [height, setHeight] = useState('');
    const [depth, setDepth] = useState('');
    const [detailedDescription, setDetailedDescription] = useState('');
    const [sortlyId, setSortlyId] = useState('');
    const [layawayOption, setLayawayOption] = useState('');
    const [isLayaway, setIsLayaway] = useState(false);
    const [pentru, setPentru] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const [name, setName] = useState('');
    const [categorie, setCategorie] = useState('');
    const [stare, setStare] = useState('');
    const [descriereSpecifica, setDescriereSpecifica] = useState('');
    const [shortDescription, setShortDescription] = useState('');

    const productTypes = [
        'BEST DEAL',
        'MUST HAVE',
        'VINTAGE',
        'HARD TO FIND',
        'LUXURA EXCLUSIVE',
        'TRENDING',
        'NEW COLLECTION'
    ];
    const brands = [
        'HERMÈS', 
        'DIOR', 
        'CHANEL', 
        'GUCCI',
        'ADIDAS',
        'ALEXANDER MCQUEEN',
        'AQUAZZURA',
        'BALENCIAGA',
        'BALMAIN',
        'BOTTEGA VENETA',
        'BOUCHERON',
        'BRIONI',
        'BURBERRY',
        'BUSCEMI',
        'CARTIER',
        'CASADEI',
        'CHLOE',
        'CHOPARD',
        'CHRISTIAN LOUBOUTIN',
        'DAVIDOFF BY S.T.DUPONT',
        'ELISABETTA FRANCHI',
        'ERMANNO SCERVINO',
        'FENDI',
        'GIUSEPPE ZANOTTI',
        'GIVENCHY',
        'HAWES&Curtis',
        'JACQUEMUS',
        'JF ANDERSON',
        'JUST CAVALLI',
        'JW ANDERSON',
        'LE SILLA',
        'LOEWE',
        'LOUIS VUITTON',
        'MANOLO BLAHNIK',
        'MAX MARA',
        'MCM',
        'MEDAGLIANI',
        'MITILIANE COUTURE',
        'MIU MIU',
        'MONCLER',
        'MOSCHINO',
        'POMELLATO',
        'PRADA',
        'S.T.DUPONT',
        'SAINT LAURENT',
        'SERGIO ROSSI',
        'SEVDA',
        'TAG HEUER',
        'TISSOT',
        'TOM FORD',
        'VALENTINO',
        'VERSACE',
        'VLTN',
        'YVES SAINT LAURENT',
        'ZIMMERMANN',
        'ADIDAS X GUCCI',
        'ALAIA',
        'ALEXANDER WANG',
        'AMINA MUADDI',
        'AREA',
        'BALENCIAGA X ADIDAS',
        'BALLY',
        'BALLY BREANNE',
        'BALMAIN X BARBIE',
        'BERLUTI',
        'BREITLING',
        'BROWNS',
        'BRUNELLO CUCINELLI',
        'BURBERRY X VIVIENNE WESTWOOD',
        'BVLGARI',
        'CARL F. BUCHERER',
        'CELINE',
        'CULT GAIA',
        'DIAMONDS MINE',
        'DIOR X SACAI',
        'DOLCE & GABBANA',
        'DSQUARED2',
        'EMILIO PUCCI',
        'EMPRESA',
        'ERMENEGILDO ZEGNA',
        'ETRO',
        'FAUSTO PUGLISI',
        'FREYWILLE',
        'GIANVITO ROSSI',
        'GIORGIO VISCONTI',
        'GUCCI X ADIDAS',
        'GUCCI X BALENCIAGA',
        'GUCCI X BANANYA',
        'GUCCI X DISNEY',
        'GUCCI X THE NORTH FACE',
        'GUESS',
        'HULCHI BELLUNI',
        'INEL',
        'ISABEL MARANT',
        'JIMMY CHOO',
        'LORO PIANA',
        'LOUIS VUITTON X JEFF KOONS',
        'MACH & MACH',
        'MARINE SERRE',
        'MARNI',
        'MICHAEL KORS',
        'MONSE',
        'MORE JOY',
        'MULBERRY',
        'NIKE AF1 X SWAROVSKI',
        'OFF-WHITE',
        'OMEGA',
        'PATRICIA AVENDANO',
        'PHILIPP PLEIN',
        'PINKO',
        'POLO RALPH LAUREN',
        'RED VALENTINO',
        'ROBERTO CAVALLI',
        'ROLAND MOURET',
        'ROLEX',
        'ROSANTICA',
        'RUSLAN BAGINSKIY',
        'SALVATORE FERRAGAMO',
        'SOPHIA WEBSTER',
        'STELLA MCCARTNEY',
        'TAG HEUER',
        'TIFFANY',
        'TIFFANY & CO.',
        'TIRISI',
        'VALEXTRA',
        'VALENTINO',
        'VAN CLEEF & ARPELS',
        'WOOLRICH'
    ];


    const categoryPrefixes = {
        'Accesorii': 'ACLEB00',
        'Incaltaminte': 'SSLEB00',
        'Bijuterii': 'JLLEB00',
        'Genti': 'HBLEB00',
        'Imbracaminte': 'CLLEB00',
        // Adaugă aici alte categorii și prefixele lor
    };

    const consumerKey = 'ck_a941824be557253d3765a1f6242e6d6a590da999';
    const consumerSecret = 'cs_6b0d98e4c442e707be9888cafff1fac17cdbf259';

    const username = 'ciprian.bucurei@gmail.com';
    const password = 'dgUp Z1Cv Rqxg pjx2 s3TF ZjOQ';

useEffect(() => {
  // Verificăm dacă există productData pentru a popula câmpurile
  if (productData) {
    console.log('Received productData:', productData);

    // Setăm titlul produsului
    setTitle(productData.name || '');

    // Setăm prețul regular sau cel normal
    setPrice(productData.regularPrice || productData.price || '');

    // Setăm prețul redus (dacă există)
    setSalePrice(productData.salePrice || '');

    // Setăm SKU-ul produsului
    setSku(productData.sku || '');

    // Setăm descrierea produsului
    setDescription(productData.description || '');

    // Setăm categoriile produsului, mapând la formatul necesar
    setSelectedCategories(
      Array.isArray(productData.categories)
        ? productData.categories.map(category => ({ name: category }))
        : []
    );

    // Setează alte câmpuri după caz (asigurându-te că valorile nu sunt undefined sau null)
    setExteriorColor(productData.externalColor || '');
    setInteriorColor(productData.interiorColor || '');
    setExteriorMaterial(productData.exteriorMaterial || '');
    setInteriorMaterial(productData.interiorMaterial || '');
    setCondition(productData.condition || '');
    setLength(productData.length || '');
    setHeight(productData.height || '');
    setDepth(productData.depth || '');
    setIncludedItems(productData.includedItems || '');
    setDesignerId(productData.designerId || '');
    setLocation(productData.location || '');
    setSortlyId(productData.sortlyId || '');
    setLayawayOption(productData.layawayOption || '');
    setIsLayaway(productData.isLayaway || false);
  }
}, [productData]);



    useEffect(() => {
        const fetchLastProductId = async () => {
            try {
                const response = await axios.get('https://www.luxuraelite.ro/wp-json/wc/v3/products', {
                    params: {
                        per_page: 1,
                        orderby: 'id',
                        order: 'desc'
                    },
                    headers: {
                        Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`)
                    }
                });
                const lastProductId = response.data[0]?.id || 0;
                setNextProductId(lastProductId + 1);
            } catch (error) {
                console.error('Error fetching last product ID:', error);
            }
        };

        fetchLastProductId();
    }, []);

    useEffect(() => {
        if (nextProductId && selectedCategories.length > 0) {
            const mainCategory = selectedCategories[0]?.name;
            const prefix = categoryPrefixes[mainCategory] || 'UNKNOWN';
            setSku(`${prefix}${nextProductId}`);
        }
    }, [nextProductId, selectedCategories]);


    // Folosim localWooCategories pentru a stoca categoriile preluate din WooCommerce
useEffect(() => {
  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://www.luxuraelite.ro/wp-json/wc/v3/products/categories', {
        headers: {
          Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`)
        }
      });
      const categories = response.data;
      const hierarchicalCategories = buildCategoryHierarchy(categories);
      console.log("Categorii ierarhizate:", hierarchicalCategories); // Add this
      setLocalWooCategories(hierarchicalCategories);
    } catch (error) {
      console.error('Error fetching WooCommerce categories:', error);
    }
  };

  fetchCategories();
}, []);



const buildCategoryHierarchy = (categories) => {
  const categoryMap = {};
  const roots = [];

  // Creează un map al categoriilor
  categories.forEach((category) => {
    categoryMap[category.id] = { ...category, children: [] };
  });

  // Construiește ierarhia
  categories.forEach((category) => {
    if (category.parent === 0) {
      roots.push(categoryMap[category.id]);
    } else {
      const parentCategory = categoryMap[category.parent];
      if (parentCategory) {
        parentCategory.children.push(categoryMap[category.id]);
      }
    }
  });

  return roots;
};


    const compressAndResizeImage = async (imageFile) => {
        try {
            console.log('Sending image for compression:', imageFile.name);
            const formData = new FormData();
            formData.append('image', imageFile);  

            const response = await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/compress', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            });

            const compressedImage = new File([response.data], imageFile.name, { type: imageFile.type });
            console.log('Image compressed successfully:', compressedImage.name);
            return compressedImage;
        } catch (error) {
            console.error('Error compressing and resizing image:', error);
            alert('Failed to compress and resize image.');
            return null;
        }
    };

    const uploadImage = async (imageFile) => {
        const url = 'https://www.luxuraelite.ro/wp-json/wp/v2/media';

        const formData = new FormData();
        formData.append('file', imageFile);

        const auth = btoa(`${username}:${password}`);

        try {
            console.log('Uploading image to WooCommerce:', imageFile.name);
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Basic ${auth}`,
                    'Content-Disposition': `attachment; filename=${imageFile.name}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            });

            console.log('Image uploaded successfully:', response.data);
            return response.data.id;
        } catch (error) {
            console.error('Error uploading image:', error.response ? error.response.data : error.message);
            alert('Failed to upload image.');
            return null;
        }
    };

    const handleImageChange = async (type, images) => {
        if (type === 'profile') {
            const imageFile = images;
            const compressedImage = await compressAndResizeImage(imageFile);
            if (compressedImage) {
                setProfileImage({ file: imageFile });
                const imageId = await uploadImage(compressedImage);
                if (imageId) {
                    setProfileImage({ file: imageFile, id: imageId });
                }
            }
        } else {
            const newGalleryImages = [];
            let currentImageIndex = galleryImages.length + 1;
            for (const imageFile of images) {
                const compressedImage = await compressAndResizeImage(imageFile);
                if (compressedImage) {
                    setGalleryImages((prevImages) => [
                        ...prevImages,
                        { file: imageFile }
                    ]);
                    setUploadStatus(`Uploading image ${currentImageIndex} of ${images.length}`);
                    currentImageIndex++;
                    const imageId = await uploadImage(compressedImage);
                    if (imageId) {
                        newGalleryImages.push({ file: imageFile, id: imageId });
                    }
                }
            }
            setGalleryImages([...galleryImages, ...newGalleryImages]);
            setUploadStatus('Done');
        }
    };

    const handleMoveImageLeft = (index) => {
        if (index > 0) {
            const newGalleryImages = [...galleryImages];
            const temp = newGalleryImages[index - 1];
            newGalleryImages[index - 1] = newGalleryImages[index];
            newGalleryImages[index] = temp;
            setGalleryImages(newGalleryImages);
        }
    };

    const handleMoveImageRight = (index) => {
        if (index < galleryImages.length - 1) {
            const newGalleryImages = [...galleryImages];
            const temp = newGalleryImages[index + 1];
            newGalleryImages[index + 1] = newGalleryImages[index];
            newGalleryImages[index] = temp;
            setGalleryImages(newGalleryImages);
        }
    };

    const deleteImageFromWoo = async (imageId) => {
        const auth = btoa(`${username}:${password}`);
        try {
            await axios.delete(`https://www.luxuraelite.ro/wp-json/wp/v2/media/${imageId}`, {
                headers: {
                    'Authorization': `Basic ${auth}`
                }
            });
            console.log(`Image ${imageId} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting image:', error.response ? error.response.data : error.message);
        }
    };

    const handleDeleteImage = async (index) => {
        const imageToDelete = galleryImages[index];
        await deleteImageFromWoo(imageToDelete.id);
        const updatedImages = galleryImages.filter((_, i) => i !== index);
        setGalleryImages(updatedImages);
    };

    const handleCreateProduct = async () => {
        let profileImageId = profileImage ? profileImage.id : null;
        let galleryImageIds = galleryImages.map(image => image.id);

        const htmlTitle = `
            <span style="font-size: 10px; color: rgb(101, 101, 101);letter-spacing: .15em;">${productType}</span><br>
            <strong>${brand}</strong><br>
            <div style="margin-top:10px; font-size:16px;">${title}</div><br>
        `;

        const conditionVal = conditionOptions[condition];

        const finalDescription = buildHtmlContent({
            conditionVal,
            customDescription,
            exteriorColor,
            interiorColor,
            exteriorMaterial,
            interiorMaterial,
            includedItems,
            hardwareColor,
            designerId,
            entrupyId,
            location,
            length,
            height,
            depth,
            detailedDescription,
            sortlyId,
            salePrice,
            price,
            brand,
            layawayPlan: isLayaway ? layawayOption : null
        });

        const publishDateTime = isProductScheduled && scheduledDate && scheduledTime
            ? dayjs(scheduledDate)
                .hour(dayjs(scheduledTime).hour())
                .minute(dayjs(scheduledTime).minute())
                .second(0)
                .format('YYYY-MM-DDTHH:mm:ss')
            : null;

        const productData = {
            name: htmlTitle,
            regular_price: price,
            sale_price: salePrice,
            msrp_price: msrpPrice,
            description: finalDescription,
            status: isProductScheduled ? 'future' : status,
            date_created: publishDateTime,
            categories: selectedCategories.map(category => ({ id: category.id })),
            date_on_sale_from: isSaleScheduled && dateFrom ? dayjs(dateFrom).format('YYYY-MM-DD') : null,
            date_on_sale_to: isSaleScheduled && dateTo ? dayjs(dateTo).format('YYYY-MM-DD') : null,
            images: [
                ...(profileImageId ? [{ id: profileImageId }] : []),
                ...galleryImageIds.map(id => ({ id }))
            ],
            stock_quantity: 1,
            manage_stock: true,
            sku: sku,
            tags: [{ name: brand }],
            attributes: [
                { id: 9, name: 'Condiție', options: [condition] },
                { id: 10, name: 'Designeri', options: [brand] },
                { id: 15, name: 'Badge', options: [productType] },
                { id: 2, name: 'Culoare', options: [exteriorColor] },
                { id: 11, name: 'Categorie', options: selectedCategories.map(category => category.name) },
                { id: 24, name: 'DesignerID', options: [designerId] },
                { id: 22, name: 'Entrupy', options: [entrupyId] },
                { id: 23, name: 'Insotit', options: includedItems },
                { id: 17, name: 'Pentru', options: [pentru] }
            ],
            meta_data: [
                { key: 'msrp_price', value: msrpPrice }
            ],
        };

        console.log('Product Data before creation:', JSON.stringify(productData, null, 2));

        try {
            const productResponse = await axios.post('https://www.luxuraelite.ro/wp-json/wc/v3/products', productData, {
                headers: {
                    Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`),
                    'Content-Type': 'application/json'
                }
            });

            console.log('Product Response:', productResponse.data);

            const productId = productResponse.data.id;

            if (isLayaway) {
                const selectedPrice = salePrice || price;
                if (selectedPrice <= 2000) {
                    await setSumoPaymentPlans(productId, '1 Rata Speciala');
                } else {
                    await setSumoPaymentPlans(productId, layawayOption);
                }
            }

            resetForm();
            alert('Product created successfully!');
        } catch (error) {
            console.error('Error creating product:', error.response ? error.response.data : error.message);
            alert('Failed to create product.');
        }
    };

    const setSumoPaymentPlans = async (productId, layawayOption) => {
        const sumoMetaData = [
            { key: '_sumo_pp_enable_sumopaymentplans', value: 'yes' },
            { key: '_sumo_pp_payment_type', value: 'payment-plans' },
            { key: '_sumo_pp_apply_global_settings', value: '' },
            { key: '_sumo_pp_force_deposit', value: '' },
            { key: '_sumo_pp_deposit_type', value: 'pre-defined' },
            { key: '_sumo_pp_deposit_price_type', value: 'fixed-price' },
            { key: '_sumo_pp_pay_balance_type', value: 'after' },
            { key: '_sumo_pp_pay_balance_after', value: '' },
            { key: '_sumo_pp_pay_balance_before', value: '' },
            { key: '_sumo_pp_pay_balance_before_booked_date', value: '' },
            { key: '_sumo_pp_set_expired_deposit_payment_as', value: 'normal' },
            { key: '_sumo_pp_fixed_deposit_price', value: '' },
            { key: '_sumo_pp_fixed_deposit_percent', value: '' },
            { key: '_sumo_pp_user_defined_deposit_type', value: 'percent-of-product-price' },
            { key: '_sumo_pp_min_user_defined_deposit_price', value: '' },
            { key: '_sumo_pp_max_user_defined_deposit_price', value: '' },
            { key: '_sumo_pp_min_deposit', value: '' },
            { key: '_sumo_pp_max_deposit', value: '' },
        ];

        let plans = [];
        switch (layawayOption) {
            case '1 Rata':
                plans = ["10956"];
                break;
            case '2 Rate':
                plans = ["10956", "10957"];
                break;
            case '3 Rate':
                plans = ["10956", "10957", "10969"];
                break;
            case '1 Rata Speciala':
                plans = ["106724"];
                break;
            default:
                break;
        }

        sumoMetaData.push({ key: '_sumo_pp_selected_plans', value: { col_1: plans } });

        try {
            await axios.put(`https://www.luxuraelite.ro/wp-json/wc/v3/products/${productId}`, {
                meta_data: sumoMetaData
            }, {
                headers: {
                    Authorization: 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`),
                    'Content-Type': 'application/json'
                }
            });

            console.log('SUMO Payment Plans set successfully');
        } catch (error) {
            console.error('Error setting SUMO Payment Plans:', error.response ? error.response.data : error.message);
            alert('Failed to set SUMO Payment Plans.');
        }
    };

    const handleFilter = async () => {
        const brandFilter = brand;
        const categoryFilter = selectedCategories.map(category => category.name);

        try {
            const response = await axios.get('https://server-9p4hx.kinsta.app/api/masterdata/filter-products', {
                params: {
                    brand: brandFilter,
                    category: categoryFilter.join(',')
                }
            });
            // Verificăm dacă răspunsul conține un array valid înainte de a-l seta
            if (Array.isArray(response.data)) {
                setFilteredProducts(response.data);
            } else {
                console.error('Filtered products is not an array');
            }
        } catch (error) {
            console.error('Error fetching filtered products:', error);
        }
    };


    const handleProductSelect = (sku) => {
        setSelectedProductIds((prevIds) => {
            if (prevIds.includes(sku)) {
                return prevIds.filter((id) => id !== sku);
            } else {
                return [...prevIds, sku];
            }
        });
    };

    // Funcția pentru a construi opțiunile de categorii și subcategorii
const renderCategoryOptions = (categories, level = 0) => {
  if (!Array.isArray(categories)) {
    return [];
  }

  return categories.flatMap(category => [
    {
      ...category,
      label: `${'—'.repeat(level)} ${category.name}`,
      value: category.id
    },
    // Recursiv pentru subcategorii
    ...renderCategoryOptions(category.children || [], level + 1)
  ]);
};


    const flatCategories = renderCategoryOptions(localWooCategories);



    const resetForm = () => {
        setTitle('');
        setPrice('');
        setSalePrice('');
        setMsrpPrice('');
        setDescription('');
        setStatus('draft');
        setSelectedCategories([]);
        setProductType('HARD TO FIND');
        setBrand('HERMÈS');
        setCondition('Nou');
        setCustomDescription('');
        setIsSaleScheduled(false);
        setIsProductScheduled(false);
        setScheduledDate(null);
        setScheduledTime(null);
        setDateFrom(null);
        setDateTo(null);
        setProfileImage(null);
        setGalleryImages([]);
        setProgress(0);
        setSku('');
        setExteriorColor('Maro');
        setInteriorColor('Bordo');
        setExteriorMaterial('Monogram Canvas');
        setInteriorMaterial('Microfibra');
        setIncludedItems([]);
        setHardwareColor('Auriu');
        setDesignerId('M46197');
        setEntrupyId('DHGGCC8');
        setLocation('Exclusiv Online');
        setLength('');
        setHeight('');
        setDepth('');
        setDetailedDescription('');
        setSortlyId('');
        setLayawayOption('');
        setIsLayaway(false);
        setPentru('');
        setSelectedProductIds([]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>Create Product</Typography>
                
                <Divider><CategoryIcon /> Product Information</Divider>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    {nextProductId && (
                        <TextField
                            label="Product ID"
                            value={nextProductId}
                            fullWidth
                            sx={{ mb: 2, mr: 2 }}
                            InputProps={{ readOnly: true }}
                        />
                    )}
                    {sku && (
                        <TextField
                            label="SKU"
                            value={sku}
                            fullWidth
                            sx={{ mb: 2 }}
                            InputProps={{ readOnly: true }}
                        />
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: '0 0 150px' }}>
                        <InputLabel>Badge</InputLabel>
                        <Select
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            label="Badge"
                        >
                            {productTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ mr: 2, flex: '0 0 150px' }}>
                        <InputLabel>Brand</InputLabel>
                        <Select value={brand} onChange={(e) => setBrand(e.target.value)} label="Brand">
                            {brands.map((brand) => (
                                <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Model"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        sx={{ flex: 1 }}
                    />
                </Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                <Autocomplete
                    multiple
                    options={flatCategories}
                    getOptionLabel={(option) => option.label}
                    value={selectedCategories}
                    onChange={(event, newValue) => setSelectedCategories(newValue)}
                    renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Chip key={option.id} label={option.name} {...getTagProps({ index })} />
                    ))}
                    renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Categorii" placeholder="Selectează categoriile" />
                    )}
                />
                </FormControl>
                
                <Divider><MonetizationOnIcon /> Pricing</Divider>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <TextField
                        label="Preț"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                        InputProps={{ startAdornment: <InputAdornment position="start">RON</InputAdornment> }}
                    />
                    <TextField
                        label="Preț la Reducere"
                        value={salePrice}
                        onChange={(e) => setSalePrice(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                        InputProps={{ startAdornment: <InputAdornment position="start">RON</InputAdornment> }}
                    />
                    <TextField
                        label="Preț de Retail MSRP"
                        value={msrpPrice}
                        onChange={(e) => setMsrpPrice(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                        InputProps={{ startAdornment: <InputAdornment position="start">RON</InputAdornment> }}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isSaleScheduled} onChange={(e) => setIsSaleScheduled(e.target.checked)} />}
                        label="Schedule Sale"
                    />
                </Box>
                {isSaleScheduled && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <DesktopDatePicker
                            label="De la"
                            inputFormat="DD/MM/YYYY"
                            value={dateFrom}
                            onChange={(newValue) => setDateFrom(newValue)}
                            renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
                        />
                        <DesktopDatePicker
                            label="Până la"
                            inputFormat="DD/MM/YYYY"
                            value={dateTo}
                            onChange={(newValue) => setDateTo(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                )}

                
                <Divider><InfoIcon /> Condition & Description</Divider>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <FormControl sx={{ mr: 2, flex: 1 }}>
                        <InputLabel>Condiție</InputLabel>
                        <Select
                            value={condition}
                            onChange={(e) => setCondition(e.target.value)}
                            label="Condiție">
                            {Object.keys(conditionOptions).map((cond) => (
                                <MenuItem key={cond} value={cond}>{cond}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <TextField
                    label="Descriere Condiție"
                    value={customDescription}
                    onChange={(e) => setCustomDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                />

                
                <Divider><ImageIcon /> Images</Divider>
                <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                        <Box
                            sx={{
                                width: '100%',
                                paddingTop: '177.78%',
                                backgroundColor: '#f0f0f0',
                                backgroundImage: profileImage ? `url(${URL.createObjectURL(profileImage.file)})` : 'none',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'relative',
                                border: '1px solid #ddd',
                                mb: 2
                            }}
                        />
                        <Button variant="contained" component="label">
                            Upload Profile Image
                            <input
                                type="file"
                                hidden
                                onChange={(e) => handleImageChange('profile', e.target.files[0])}
                            />
                        </Button>
                        {profileImage && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setProfileImage(null)}
                                sx={{ mt: 1 }}
                            >
                                Delete
                            </Button>
                        )}
                    </Box>
                    <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                        <Button variant="contained" component="label" sx={{ mb: 2 }}>
                            Upload Gallery Images
                            <input
                                type="file"
                                multiple
                                hidden
                                onChange={(e) => handleImageChange('gallery', Array.from(e.target.files))}
                            />
                        </Button>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {galleryImages.map((image, index) => (
                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            width: 136,
                                            height: 180,
                                            backgroundColor: '#f0f0f0',
                                            backgroundImage: `url(${URL.createObjectURL(image.file)})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            position: 'relative',
                                            border: '1px solid #ddd'
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                        <IconButton size="small" onClick={() => handleMoveImageLeft(index)}>
                                            <ArrowBack />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => handleMoveImageRight(index)}>
                                            <ArrowForward />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => handleDeleteImage(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
                {uploadStatus && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Typography variant="body2" color="textSecondary">{uploadStatus}</Typography>
                    </Box>
                )}
                {progress > 0 && (
                    <Box sx={{ width: '100%', mb: 2 }}>
                        <Typography variant="body2" color="textSecondary">{`Uploading images: ${progress}%`}</Typography>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                )}
                
                <Divider><LocalOfferIcon /> Attributes</Divider>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel>Culoare Exterioară</InputLabel>
                        <Select
                            value={exteriorColor}
                            onChange={(e) => setExteriorColor(e.target.value)}
                            label="Culoarea Exterioară"
                        >
                            <MenuItem value="Maro">Maro</MenuItem>
                            <MenuItem value="Negru">Negru</MenuItem>
                            <MenuItem value="Alb">Alb</MenuItem>
                            <MenuItem value="Albastru">Albastru</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel>Culoare Interioară</InputLabel>
                        <Select
                            value={interiorColor}
                            onChange={(e) => setInteriorColor(e.target.value)}
                            label="Culoarea Interioară">
                            <MenuItem value="Bordo">Bordo</MenuItem>
                            <MenuItem value="Negru">Negru</MenuItem>
                            <MenuItem value="Alb">Alb</MenuItem>
                            <MenuItem value="Albastru">Albastru</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel>Material Exterior</InputLabel>
                        <Select
                            value={exteriorMaterial}
                            onChange={(e) => setExteriorMaterial(e.target.value)}
                            label="Material Exterior">
                            <MenuItem value="Monogram Canvas">Monogram Canvas</MenuItem>
                            <MenuItem value="Piele">Piele</MenuItem>
                            <MenuItem value="Bumbac">Bumbac</MenuItem>
                            <MenuItem value="Aur">Aur</MenuItem>
                            <MenuItem value="Argint">Argint</MenuItem>
                            <MenuItem value="Oțel">Oțel</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Material Interior</InputLabel>
                        <Select
                            value={interiorMaterial}
                            onChange={(e) => setInteriorMaterial(e.target.value)}
                            label="Material Interior">
                            <MenuItem value="Microfibra">Microfibra</MenuItem>
                            <MenuItem value="Piele">Piele</MenuItem>
                            <MenuItem value="Material">Material</MenuItem>
                            <MenuItem value="Microfibra">Microfibra</MenuItem>
                            <MenuItem value="Bumbac">Bumbac</MenuItem>
                            <MenuItem value="Canvas">Canvas</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel>Culoare Hardware</InputLabel>
                        <Select
                            value={hardwareColor}
                            onChange={(e) => setHardwareColor(e.target.value)}
                            label="Culoare Hardware">
                            <MenuItem value="Auriu">Auriu</MenuItem>
                            <MenuItem value="Argintiu">Argintiu</MenuItem>
                            <MenuItem value="Mat">Mat</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Pentru</InputLabel>
                        <Select
                            value={pentru}
                            onChange={(e) => setPentru(e.target.value)}
                            label="Pentru">
                            <MenuItem value="Femei">Femei</MenuItem>
                            <MenuItem value="Barbati">Barbati</MenuItem>
                            <MenuItem value="Copii">Copii</MenuItem>
                            <MenuItem value="Casa">Casa</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Vine Însoțit</InputLabel>
                    <Select
                        multiple
                        value={includedItems}
                        onChange={(e) => setIncludedItems(e.target.value)}
                        renderValue={(selected) => selected.join(', ')}
                        label="Vine Însoțit">
                        <MenuItem value="Dust bag">Dust bag</MenuItem>
                        <MenuItem value="Cutie">Cutie</MenuItem>
                        <MenuItem value="Certificat de conformitate">Certificat de conformitate</MenuItem>
                        <MenuItem value="Certificat Entrupy">Certificat Entrupy</MenuItem>
                    </Select>
                </FormControl>
                
                <Divider><DetailsIcon /> Detailed Information</Divider>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <TextField
                        label="Designer ID"
                        value={designerId}
                        onChange={(e) => setDesignerId(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }} />
                    <TextField
                        label="Sortly ID"
                        value={sortlyId}
                        onChange={(e) => setSortlyId(e.target.value)}
                        fullWidth
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        label="Entrupy ID"
                        value={entrupyId}
                        onChange={(e) => setEntrupyId(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }} />
                    <TextField
                        label="Jira ID"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        fullWidth
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        label="Lungimea (cm)"
                        value={length}
                        onChange={(e) => setLength(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }} />
                    <TextField
                        label="Inălțime (cm)"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        fullWidth
                        sx={{ mr: 2 }}
                    />
                    <TextField
                        label="Adâncime (cm)"
                        value={depth}
                        onChange={(e) => setDepth(e.target.value)}
                        fullWidth
                    />
                </Box>
                <TextField
                    label="Descriere Detaliată"
                    value={detailedDescription}
                    onChange={(e) => setDetailedDescription(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 2 }}
                />
                
                <Divider><ScheduleIcon /> Publishing</Divider>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                    <FormControlLabel
                        control={<Switch checked={status === 'publish'}
                            onChange={(e) => setStatus(e.target.checked
                                ? 'publish'
                                : 'draft')} />}
                        label={status === 'publish'
                            ? 'Published'
                            : 'Ciorna'} />
                    <FormControlLabel
                        control={<Checkbox checked={isProductScheduled}
                            onChange={(e) => setIsProductScheduled(e.target.checked)} />}
                        label="Programare" />
                    <FormControl fullWidth sx={{ ml: 2 }}>
                        <InputLabel>Locație</InputLabel>
                        <Select
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            label="Locație">
                            <MenuItem value="Exclusiv Online">Exclusiv Online</MenuItem>
                            <MenuItem value="Luxura Boutique Timișoara">Luxura Boutique Timișoara</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {isProductScheduled && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <DesktopDatePicker
                            label="Data"
                            inputFormat="DD/MM/YYYY"
                            value={scheduledDate}
                            onChange={(newValue) => setScheduledDate(newValue)}
                            renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
                        />
                        <TimePicker
                            label="Ora"
                            value={scheduledTime}
                            onChange={(newValue) => setScheduledTime(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                )}
                
                <Divider><StorageIcon /> Layaway</Divider>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <FormControlLabel
                        control={<Checkbox checked={isLayaway} onChange={(e) => setIsLayaway(e.target.checked)} />}
                        label="Dorești produsul în LLP?"
                    />
                </Box>
                {isLayaway && (
                    <>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>LLP</InputLabel>
                            <Select
                                value={layawayOption}
                                onChange={(e) => setLayawayOption(e.target.value)}
                                label="LLP"
                            >
                                {(salePrice || price) <= 2000
                                    ? <MenuItem value="1 Rata Speciala">1 Rata Speciala</MenuItem>
                                    : [
                                        <MenuItem key="1 Rata" value="1 Rata">1 Rata</MenuItem>,
                                        <MenuItem key="2 Rate" value="2 Rate">2 Rate</MenuItem>,
                                        <MenuItem key="3 Rate" value="3 Rate">3 Rate</MenuItem>
                                    ]}
                            </Select>
                        </FormControl>
                    </>
                )}
                
                {progress > 0 && (
                    <Box sx={{ width: '100%', mb: 2 }}>
                        <Typography variant="body2" color="textSecondary">{`Uploading images: ${progress}%`}</Typography>
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleCreateProduct}>
                        Creează Produs
                    </Button>
                </Box>

                <Box sx={{ mt: 4 }}>
                    <Button variant="contained" color="primary" onClick={handleFilter}>
                        Cauta Produse asemanatoare
                    </Button>

                    <Box sx={{ mt: 2 }}>
                        {filteredProducts.map((product) => (
                            <Card key={product.sku} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Checkbox
                                    checked={selectedProductIds.includes(product.sku)}
                                    onChange={() => handleProductSelect(product.sku)}
                                />
                                <CardMedia
                                    component="img"
                                    sx={{ width: 100 }}
                                    image={product.df_image_link}
                                    alt={product.name}
                                />
                                <CardContent>
                                    <Typography variant="body1">{product.name}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default CreateProductWoo;
