import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Table, TableBody, TableCell, TextField, CardMedia, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DatePickerStyles.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning'; // Asigurați-vă că importați acest icon
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ErrorIcon from '@mui/icons-material/Error';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Switch, FormControlLabel } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import html2canvas from 'html2canvas';
import { Checkbox } from '@mui/material';


const exportToPDF = (rawData, fileName, columns, isFilterActive = false, startDate, endDate) => {
    const doc = new jsPDF('l', 'mm', 'a3'); // 'l' pentru landscape, 'mm' pentru unități de măsură în milimetri, 'a3' pentru dimensiunea paginii

    // Funcții de formatare a datei
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Data invalida';
        } else {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
    };

    const formatDateFromISO = (isoString) => {
        const regex = /^(\d{4})-(\d{2})-(\d{2})T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        const match = isoString.match(regex);
        if (match) {
            const year = match[1];
            const month = match[2];
            const day = match[3];
            return `${day}-${month}-${year}`;
        } else {
            return 'Data Storno invalid';
        }
    };

    // Separarea și prelucrarea datelor pentru clone și non-clone
    let processedData = rawData.map(row => {
        // Prelucrarea datelor pentru clone
        if (row.esteClona && row.refundInfo && row.refundInfo.data_cerere_storno) {
            row.dataVandut = formatDateFromISO(row.refundInfo.data_cerere_storno);
        } else if (!row.esteClona) {
            // Logica pentru non-clone, aplicată când isFilterActive este true
            if (isFilterActive) {
                const dataVandut = new Date(row.dataVandut);
                const dateCreated = row.salesInfo ? new Date(row.salesInfo.date_created) : null;
                const diferentaZile = dateCreated ? Math.abs((dataVandut - dateCreated) / (1000 * 3600 * 24)) : Infinity;
                
                if (row.tip_vandut === 'Offline') {
                    // Tratament special pentru cazurile Offline
                    if (row.smartbill) {
                        row.dataVandut = formatDate(row.smartbill);
                    } else if (row.boutique) {
                        row.dataVandut = formatDate(row.boutique);
                    }
                } else if (diferentaZile > 10 && row.salesInfo && row.salesInfo._ywpi_invoice_date) {
                    // Copierea _ywpi_invoice_date la dataVandut pentru non-Offline și diferență de zile > 10
                    row.dataVandut = formatDateFromISO(row.salesInfo._ywpi_invoice_date);
                }
            } else {
                // Logica pentru când isFilterActive nu este true se menține formatarea standard a datei
                row.dataVandut = formatDate(row.dataVandut);
            }
        }

        // Logica comună de ajustare a datelor
        let invoiceNumber = row.esteClona && row.refundInfo && row.refundInfo.attachments ? row.refundInfo.attachments :
                            row.tip_vandut === 'Offline' ? row.boutique || row.smartbill || 'Lipsă' :
                            row.salesInfo ? row.salesInfo._ywpi_invoice_formatted_number : 'Lipsă';

        return {
            ...row,
            client: row.salesInfo ? `${row.salesInfo.first_name} ${row.salesInfo.last_name}` : 'N/A',
            nrFactura: invoiceNumber,
            nrComanda: row.salesInfo ? row.salesInfo.ID : 'N/A',
            data_de_intrare: row.data_de_intrare ? formatDate(row.data_de_intrare) : 'N/A',
            InvoiceNumber: invoiceNumber,
            dataVandut: row.dataVandut, // Asigură că modificările sunt reflectate
            rec: row.rec === 1 || row.rec === "1" ? "[ x ]" : "[  ]" // Adaugă valoarea checkbox-ului rec
        };
    });

    // Filtrare în funcție de sumo_pp_payment_data_exists și intervalul de date
    processedData = processedData.filter(row => {
        // Aplică logica de filtrare specifică pentru sumo_pp_payment_data_exists doar pentru non-clonă
        if (!row.esteClona && row.salesInfo && row.salesInfo.sumo_pp_payment_data_exists === "YES") {
            const subtotal = parseFloat(row.salesInfo.subtotal);
            const totalPayableAmount = parseFloat(row.salesInfo.total_payable_amount);
            const fifteenPercentOfTotal = (subtotal / totalPayableAmount) * 100;
            if (fifteenPercentOfTotal > 15) {
                console.log(`Acest ID a fost eliminat pentru că nu îndeplinește condiția SUMO: ${row.salesInfo.ID}`);
                return false;
            }
        }
        // Filtrare suplimentară în funcție de intervalul de date pentru toate intrările
        const rowDate = new Date(row.dataVandut);
        return (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate);
    }).sort((a, b) => new Date(a.dataVandut) - new Date(b.dataVandut));

    // Adăugăm starea checkbox-ului 'rec' în coloanele exportului
    const updatedColumns = [
        ...columns,
        { header: 'REC', dataKey: 'rec' }
    ];

    // Generarea tabelului în PDF și salvarea acestuia
    autoTable(doc, {
        body: processedData.map(row => updatedColumns.map(col => row[col.dataKey] != null ? (typeof row[col.dataKey] === 'number' ? row[col.dataKey].toLocaleString() : row[col.dataKey]) : 'N/A')),
        columns: updatedColumns.map(col => ({ header: col.header, dataKey: col.dataKey })),
        theme: 'striped',
        startY: 20,
        styles: { cellPadding: 3, fontSize: 10 },
        columnStyles: { 0: { cellWidth: 'auto' } },
        didDrawPage: (data) => { doc.setFontSize(12); doc.text(fileName, 11, 10); },
    });

    doc.save(`${fileName}.pdf`);
};


// Colone pentru Intrări
const intrariColumns = [
  { header: 'Nr Jurnal', dataKey: 'nrJurnalSpecial' },
  { header: 'SKU', dataKey: 'sku' },
  { header: 'Tip Contract', dataKey: 'tipContract' },
  { header: 'Nume', dataKey: 'numeSiPrenume' },
  { header: 'Summary', dataKey: 'summary' },
  { header: 'Pret Intrare', dataKey: 'pretIntrareRON' },
  { header: 'Data Intrare', dataKey: 'dataIntrare' },
];

// Colone pentru Intrări
const intrariActColumns = [
  { header: 'Nr Jurnal', dataKey: 'nrJurnalSpecial' },
  { header: 'SKU', dataKey: 'sku' },
  { header: 'Tip Contract', dataKey: 'tipContract' },
  { header: 'Nume', dataKey: 'numeSiPrenume' },
  { header: 'Summary', dataKey: 'summary' },
  { header: 'Pret Intrare', dataKey: 'pretIntrareRONAct' },
  { header: 'Data Intrare', dataKey: 'dataIntrareAct' },
];

// Colone pentru Vânzări
const vanzariColumns = [
  // Nu includem coloana "Acțiuni" pentru că aceasta nu corespunde unei date specifice
  { header: 'Nr. Jurnal Special', dataKey: 'nrJurnalSpecial' },
  { header: 'SKU', dataKey: 'sku' },
  { header: 'Summary', dataKey: 'summary' },
  { header: 'Furnizor', dataKey: 'numeSiPrenume' },
  { header: 'Data de Intrare', dataKey: 'data_de_intrare' },
  { header: 'Pret de Intrare', dataKey: 'pret_intrare_ron' },
  { header: 'Client', dataKey: 'client' }, // Trebuie ajustat la prelucrarea datelor
  { header: 'Data Vandut', dataKey: 'dataVandut' },
  { header: 'Nr. Factura', dataKey: 'InvoiceNumber' }, // Trebuie ajustat la prelucrarea datelor
  { header: 'Nr. Comanda', dataKey: 'nrComanda' }, // Trebuie ajustat la prelucrarea datelor

  { header: 'Pret de Vanzare', dataKey: 'pretDeVanzareRON' },
  { header: 'Profit Brut RON', dataKey: 'profitRON' },
  { header: 'TVA RON', dataKey: 'tvaRON' },
  { header: 'Impozit pe profit', dataKey: 'REZULTAT_FORMULA' },
  // Extragem informațiile despre client, nr. factura etc. din "salesInfo" dacă există. 
  // Acest lucru presupune o manipulare suplimentară a datelor în funcția de export PDF.

//  { header: 'Date Comenzi', dataKey: 'dateComenzi' } // Trebuie ajustat la prelucrarea datelor
  //{ header: 'Preț de Vânzare Luxura', dataKey: 'pretVanzareLuxura' } // Trebuie ajustat la prelucrarea datelor
];

// Colone pentru Restituit
const restituitColumns = [
  { header: 'Nr. Jurnal Special', dataKey: 'nrJurnalSpecial' },
  { header: 'Tip Contract', dataKey: 'tipContract' },
  { header: 'SKU', dataKey: 'sku' },
  { header: 'Nume și Prenume', dataKey: 'numeSiPrenume' },
  { header: 'Denumire Produs', dataKey: 'summary' },
  { header: 'Preț Intrare RON', dataKey: 'pretIntrareRON' },
  { header: 'Data Retur', dataKey: 'dataRestituit' },
];

// Coloane pentru exportul datelor de vânzări filtrate
const filteredSalesColumns = [
  { header: 'Nr. Comanda', dataKey: 'ID' },
  { header: 'SKU', dataKey: 'sku' },
  { header: 'First Name', dataKey: 'first_name' },
  { header: 'Last Name', dataKey: 'last_name' },
  { header: 'Email', dataKey: 'email' },
  { header: 'Telefon', dataKey: 'phone' },
  { header: 'Date Created', dataKey: 'date_created', formatter: (value) => new Date(value).toLocaleDateString() },
  { header: 'Total', dataKey: 'total' },
  { header: 'Subtotal', dataKey: 'subtotal' }
];


const rezultatFormulaCalculation = (profitRON, tvaRON) => {
    const profit = parseFloat(profitRON);
    const tva = parseFloat(tvaRON);

    // Verificăm dacă profitul și TVA-ul sunt numere valide
    if (isNaN(profit) || isNaN(tva)) return 'N/A';

    // Efectuăm calculul specificat
    const rezultat = (profit - tva) - ((profit - tva) / 1.16);

    // Returnăm rezultatul formatat cu două zecimale
    return rezultat.toFixed(2);
};

function ActivityTabs() {
  const [value, setValue] = useState('1');
  const [restituitData, setRestituitData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [salesData, setSalesData] = useState([]);
  const [salesDataFiltered, setSalesDataFiltered] = useState([]);
  const [salesStartDate, setSalesStartDate] = useState(null);
  const [salesEndDate, setSalesEndDate] = useState(null);
  const [comparisonData, setComparisonData] = useState({ inSalesNotInTasksAsSold: [], inTasksAsSoldNotInSales: [] });
  const [totalPretVanzareJIRA, setTotalPretVanzareJIRA] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalTVA, setTotalTVA] = useState(0);
  const totalVanzari = salesDataFiltered.length;
  const [intrariData, setIntrariData] = useState([]);
  const [intrariDataFiltered, setIntrariDataFiltered] = useState([]);
  const [intrariStartDate, setIntrariStartDate] = useState(null);
  const [intrariEndDate, setIntrariEndDate] = useState(null);
  const [totalPretIntrare, setTotalPretIntrare] = useState(0);
  const [numarTotalIntrari, setNumarTotalIntrari] = useState(0);
  const [totalImpozitProfit, setTotalImpozitProfit] = useState(0);
  const [isFilterActive, setIsFilterActive] = useState(false); // Starea pentru toggle
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [totalSuma, setTotalSuma] = useState(0);
  const [totalComenzi, setTotalComenzi] = useState(0);
  const [salesFilterStartDate, setSalesFilterStartDate] = useState(null);
  const [salesFilterEndDate, setSalesFilterEndDate] = useState(null);
  const [serviciiFilteredData, setServiciiFilteredData] = useState([]);
  const [serviciiFilteredData2, setServiciiFilteredData2] = useState([]);
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [serviciiData, setServiciiData] = useState({ filteredData: [], filteredData2: [] });
  const [isFilteredServiciiStartDate, setIsFilteredServiciiStartDate] = useState(null);
  const [isFilteredServiciiEndDate, setIsFilteredServiciiEndDate] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [intrariActData, setIntrariActData] = useState([]);
  const [intrariActDataFiltered, setIntrariActDataFiltered] = useState([]);
  const [intrariActStartDate, setIntrariActStartDate] = useState(null);
  const [intrariActEndDate, setIntrariActEndDate] = useState(null);
  const [totalPretIntrareAct, setTotalPretIntrareAct] = useState(0);
  const [numarTotalIntrariAct, setNumarTotalIntrariAct] = useState(0);
  const [category, setCategory] = useState('');
  const [filteredStockData, setFilteredStockData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 30;
  const [checkedRow, setCheckedRow] = useState(null); // Inițial, nicio selecție
  const [receptiiDataFiltered, setReceptiiDataFiltered] = useState([]); // State for RECEPTII data

useEffect(() => {
  const fetchStockData = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/get-all-jira-stocuri');
      setStockData(response.data);
      setFilteredStockData(response.data); // Initialize with all data
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

  fetchStockData();
}, []);

  useEffect(() => {
    setFilteredStockData(stockData.filter(item => !category || item.categorie_prod === category));
  }, [category, stockData]);

const filteredStockDataNew = stockData.filter(item => {
  if (category === 'null') {
    return item.categorie_prod === null || item.categorie_prod === '';
  } else if (category) {
    return item.categorie_prod === category;
  } else {
    return true;
  }
});
const pageCount = Math.ceil(filteredStockDataNew.length / ITEMS_PER_PAGE);
const paginatedStockData = filteredStockDataNew.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

useEffect(() => {
  const fetchStockData = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/get-all-tasks-jira');
      setFilteredStockData(response.data);
    } catch (error) {
      console.error('Eroare la preluarea datelor de stoc:', error);
    }
  };

  fetchStockData();
}, []);

useEffect(() => {
  if (category) {
    setFilteredStockData(prevData => prevData.filter(item => item.categorie_prod === category));
  }
}, [category]);
useEffect(() => {
  const sumaImpozitProfit = salesDataFiltered.reduce((acc, curr) => {
    // Calculul impozitului pe profit pentru fiecare rând și adunarea la suma totală
    const rezultatFormula = (parseFloat(curr.profitRON) - parseFloat(curr.tvaRON))  - ((parseFloat(curr.profitRON) - parseFloat(curr.tvaRON)) / 1.16);
    return acc + (!isNaN(rezultatFormula) ? rezultatFormula : 0);
  }, 0);

setTotalImpozitProfit(sumaImpozitProfit);
}, [salesDataFiltered]); // Dependența este salesDataFiltered pentru a recalcula când se schimbă datele filtrate


  useEffect(() => {
    const fetchRestituitData = async () => {
      try {
        const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/restituit');
        const sortedData = response.data.sort((a, b) => new Date(b.dataRestituit.split('-').reverse().join('-')) - new Date(a.dataRestituit.split('-').reverse().join('-')));
        setRestituitData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error('Eroare la preluarea datelor Restituit:', error);
      }
    };
    fetchRestituitData();
  }, []);

useEffect(() => {
    const filterData = () => {
      if (startDate && endDate) {
      const filtered = restituitData.filter((data) => {
        const dataDate = data.dataRestituit ? new Date(data.dataRestituit.split('-').reverse().join('-')) : null;
        return dataDate && dataDate >= startDate && dataDate <= endDate;
      });
        setFilteredData(filtered);
      } else {
        setFilteredData(restituitData);
      }
    };
    filterData();
}, [startDate, endDate, restituitData]);

const handleChange = (event, newValue) => {
    setValue(newValue);
};

//Export to Excel from frontend  
const exportToExcel = (rawData, fileName, columns, isFilterActive = false, startDate, endDate) => {
    // Funcții de formatare a datei
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Data invalida';
        } else {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
    };

    const formatDateFromISO = (isoString) => {
        const regex = /^(\d{4})-(\d{2})-(\d{2})T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        const match = isoString.match(regex);
        if (match) {
            const year = match[1];
            const month = match[2];
            const day = match[3];
            return `${day}-${month}-${year}`;
        } else {
            return 'Data Storno invalid';
        }
    };

    // Prelucrarea datelor pentru clone și non-clone
    let processedData = rawData.map(row => {
        // Prelucrarea datelor pentru clone
        if (row.esteClona && row.refundInfo && row.refundInfo.data_cerere_storno) {
            row.dataVandut = formatDateFromISO(row.refundInfo.data_cerere_storno);
            
        } else if (!row.esteClona) {
            // Logica pentru non-clone, aplicată când isFilterActive este true
            if (isFilterActive) {
                const dataVandut = new Date(row.dataVandut);
                const dateCreated = row.salesInfo ? new Date(row.salesInfo.date_created) : null;
                const diferentaZile = dateCreated ? Math.abs((dataVandut - dateCreated) / (1000 * 3600 * 24)) : Infinity;

                if (row.tip_vandut === 'Offline') {
                    // Tratament special pentru cazurile Offline
                    if (row.smartbill) {
                        row.dataVandut = formatDate(row.smartbill);
                    } else if (row.boutique) {
                        row.dataVandut = formatDate(row.boutique);
                    }
                } else if (diferentaZile > 10 && row.salesInfo && row.salesInfo._ywpi_invoice_date) {
                    // Copierea _ywpi_invoice_date la dataVandut pentru non-Offline și diferență de zile > 10
                    row.dataVandut = formatDateFromISO(row.salesInfo._ywpi_invoice_date);
                }
            } else {
                // Logica pentru când isFilterActive nu este true se menține formatarea standard a datei
                row.dataVandut = formatDate(row.dataVandut);
            }
        }

        // Logica comună de ajustare a datelor
        let invoiceNumber = row.esteClona && row.refundInfo && row.refundInfo.attachments ? row.refundInfo.attachments :
                            row.tip_vandut === 'Offline' ? row.boutique || row.smartbill || 'Lipsă' :
                            row.salesInfo ? row.salesInfo._ywpi_invoice_formatted_number : 'Lipsă';

        return {
            ...row,
            client: row.salesInfo ? `${row.salesInfo.first_name} ${row.salesInfo.last_name}` : 'N/A',
            nrFactura: invoiceNumber,
            nrComanda: row.salesInfo ? row.salesInfo.ID : 'N/A',
            data_de_intrare: row.data_de_intrare ? formatDate(row.data_de_intrare) : 'N/A',
            InvoiceNumber: invoiceNumber,
            dataVandut: row.dataVandut,  // Asigură că modificările sunt reflectate
            rec: row.rec === 1 || row.rec === "1" ? "[ x ]" : "[  ]" // Adăugăm starea checkbox-ului rec
        };
    });

    // Filtrare în funcție de sumo_pp_payment_data_exists și intervalul de date
    processedData = processedData.filter(row => {
        // Aplicăm logica de filtrare specifică pentru sumo_pp_payment_data_exists doar pentru non-clonă
        if (!row.esteClona && row.salesInfo && row.salesInfo.sumo_pp_payment_data_exists === "YES") {
            const subtotal = parseFloat(row.salesInfo.subtotal);
            const totalPayableAmount = parseFloat(row.salesInfo.total_payable_amount);
            const fifteenPercentOfTotal = (subtotal / totalPayableAmount) * 100;
            if (fifteenPercentOfTotal > 15) {
                console.log(`Acest ID a fost eliminat pentru că nu îndeplinește condiția SUMO: ${row.salesInfo.ID}`);
                return false;
            }
        }
        // Filtrare suplimentară în funcție de intervalul de date pentru toate intrările
        const rowDate = new Date(row.dataVandut);
        return (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate);
    }).sort((a, b) => new Date(a.dataVandut) - new Date(b.dataVandut));

    // Adăugăm starea checkbox-ului rec la coloane
    const updatedColumns = [
        ...columns,
        { header: 'REC', dataKey: 'rec' } // Adăugăm coloana rec
    ];

    // Transformarea datelor procesate în formatul necesar pentru Excel
    const dataForExcel = processedData.map(row => {
        const excelRow = {};
        updatedColumns.forEach(column => {
            excelRow[column.header] = row[column.dataKey] ? row[column.dataKey].toString() : 'N/A';
        });
        return excelRow;
    });

    // Crearea și exportul fișierului Excel
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};


const exportStockToPDF = async () => {
    const doc = new jsPDF('landscape', 'mm', 'a3');
    const margin = 10;

    // Titlu și data exportului
    doc.setFontSize(12);
    doc.text(`Export Date: ${new Date().toLocaleString()}`, margin, margin);

    // Definirea coloanelor pentru tabel
    const columns = [
     //   { header: 'Image', dataKey: 'df_image_link' },
        { header: 'Summary', dataKey: 'summary' },
        { header: 'SKU', dataKey: 'sku' },
        { header: 'Data de Intrare', dataKey: 'data_de_intrare' },
        { header: 'Nume si Prenume', dataKey: 'nume_si_prenume' },
        { header: 'Pret Intrare', dataKey: 'pret_intrare_ron' },
        { header: 'Tip Achizitie', dataKey: 'tip_achizitie' },
        { header: 'Status', dataKey: 'status' },
    ];

    // Descărcăm imaginile și le transformăm în base64
    const downloadImage = async (url) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Failed to fetch image:', error);
            return null; // Return null if fetching image fails
        }
    };

    // Transformarea datelor pentru tabel
    const dataPromises = filteredStockData.map(async (item) => {
        const imageBase64 = item.df_image_link ? await downloadImage(item.df_image_link) : null;
        return {
            df_image_link: imageBase64 ? { content: imageBase64, width: 60, height: 60 } : 'No Image',
            summary: item.summary,
            sku: item.sku,
            data_de_intrare: new Date(item.data_de_intrare).toLocaleDateString(),
            nume_si_prenume: item.nume_si_prenume,
            pret_intrare_ron: `${item.pret_intrare_ron} RON`,
            tip_achizitie: item.tip_achizitie,
            status: item.status || 'N/A',
        };
    });

    const data = await Promise.all(dataPromises);

    // Adăugarea tabelului în PDF
    autoTable(doc, {
        head: [columns.map(col => col.header)],
        body: data.map(row => columns.map(col => {
            if (col.dataKey === 'df_image_link' && typeof row[col.dataKey] === 'object') {
                return { content: row[col.dataKey].content, width: row[col.dataKey].width, height: row[col.dataKey].height };
            }
            return row[col.dataKey] || 'N/A';
        })),
        startY: margin + 20,
        columnStyles: {
            df_image_link: { cellWidth: 60, cellHeight: 60 },
            summary: { cellWidth: 'auto' },
        },
        didDrawCell: (data) => {
            if (data.column.dataKey === 'df_image_link' && typeof data.cell.raw === 'object') {
                doc.addImage(data.cell.raw.content, 'JPEG', data.cell.x + 2, data.cell.y + 2, data.cell.raw.width, data.cell.raw.height);
            }
        },
    });

    doc.save('Stocuri.pdf');
};

// Citire date din JSON si refresh de frontend
async function fetchAndSetRestituitData() {
  try {
    const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/restituit');
    const sortedData = response.data.sort((a, b) => new Date(b.dataRestituit.split('-').reverse().join('-')) - new Date(a.dataRestituit.split('-').reverse().join('-')));
    setRestituitData(sortedData);
    setFilteredData(sortedData);
  } catch (error) {
    console.error('Eroare la preluarea datelor Restituit:', error);
  }
}

useEffect(() => {
  fetchAndSetRestituitData();
}, []);

////////// REINCARCARE TASKURI DIN JSON IN JSON CACHE si actualizare vanzari in Kinsta
const handleRefreshTasks = async () => {
    // Actualizarea comenzilor WooCommerce și reîncărcarea taskurilor
    try {
      // Actualizare comenzi WooCommerce
      const wooUpdateResponse = await fetch('https://server-9p4hx.kinsta.app/update-woocommerce-orders', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!wooUpdateResponse.ok) {
        throw new Error(`HTTP error on Woo! Status: ${wooUpdateResponse.status}`);
      }

      const wooUpdateMessage = await wooUpdateResponse.text();
      console.log(wooUpdateMessage);
      alert(wooUpdateMessage); // Popup pentru actualizare comenzi WooCommerce

      // Reîncărcare taskuri
      const taskRefreshResponse = await fetch('https://server-9p4hx.kinsta.app/api/jurnale/refresh-tasks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!taskRefreshResponse.ok) {
        throw new Error(`HTTP error on Tasks! Status: ${taskRefreshResponse.status}`);
      }

      // Actualizarea taskurilor în baza de date din JIRA
      const taskUpdateResponse = await fetch('https://server-9p4hx.kinsta.app/update-database-taskjira', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!taskUpdateResponse.ok) {
        throw new Error(`HTTP error on Task Update! Status: ${taskUpdateResponse.status}`);
      }

      const taskRefreshMessage = await taskRefreshResponse.text();
      console.log(taskRefreshMessage);
      alert(taskRefreshMessage); // Popup pentru reîncărcare taskuri

      // Reîncărcă datele după refresh
      await fetchAndSetRestituitData();

    } catch (error) {
      console.error("Failed to perform refresh actions:", error);
      alert("Failed to perform refresh actions: " + error.message);
    }

    // Solicitare date de comparație de la /compare-skus
    try {
      const startDateString = salesStartDate ? salesStartDate.toISOString() : '';
      const endDateString = salesEndDate ? salesEndDate.toISOString() : '';
      const compareResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/jurnale/compare-skus?startDate=${startDateString}&endDate=${endDateString}`);
      setComparisonData(compareResponse.data);
    } catch (error) {
      console.error("Failed to fetch comparison data:", error);
    }
};

// Functie pentru a prelucra datele si a include intrarile cu valori doar la salesInfo
function prelucrareDateVanzari(date) {
  let dateModificate = date.map(item => ({
    ...item,
    esteClona: false, // Marchează intrarea originală ca neclonată
  }));

  // Adaugă clonele pentru rândurile cu refundInfo
  date.forEach(item => {
    if (item.refundInfo) {
      const clonaModificata = {
        ...item,
        esteClona: true, // Marchează această intrare ca fiind clona
        dataVandut: item.refundInfo.data_cerere_storno,
        pretDeVanzareRON: "-" + item.pretDeVanzareRON, // Adaugă semnul "-" în fața valorii inițiale
        profitRON: 0, // Setează profitRON la 0 pentru clone
        tvaRON: 0, // Setează tvaRON la 0 pentru clone
      };
      dateModificate.push(clonaModificata);
    }
  });

  // Filtrare în funcție de diferența de zile, dacă este necesar
  if (isFilterActive) {
    dateModificate = dateModificate.filter(item => {
      if (item.salesInfo && item.salesInfo.date_created) {
        const dataVandut = new Date(item.dataVandut);
        const dateCreated = new Date(item.salesInfo.date_created);
        const diferentaZile = Math.abs((dataVandut - dateCreated) / (1000 * 3600 * 24));
        
        // Logica de eliminare pentru diferența > 10 zile
        if (diferentaZile > 10) {
          //console.log(`Eliminat - Diferență > 10 zile: ${item.salesInfo.ID}, Diferență: ${diferentaZile}`);
          //return false; // Exclude rândul
        }
      }
      return true; // Include rândul dacă nu se aplică filtrul de zile
    });
  }

  return dateModificate;
}

function filtreazaDupaDiferentaZile(date, activ) {
    if (!activ) return date; // Dacă filtrul nu este activ, returnăm datele nemodificate

    return date.filter(row => {
        if (row.salesInfo) {
            const dataVandut = new Date(row.dataVandut);
            const dateCreated = new Date(row.salesInfo.date_created);
            const diferentaZile = Math.abs((dataVandut - dateCreated) / (1000 * 3600 * 24));
            return diferentaZile <= 10;
        }
        return true;
    });
}

// Vanzari
useEffect(() => {
  const fetchSalesData = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/vanzari');
      let datePrelucrate = prelucrareDateVanzari(response.data, isFilterActive);
      
      // Identificăm comenzile cu sumo_pp_payment_data_exists ===   "YES"
      const comenziCuSumo = datePrelucrate.filter(d => d.salesInfo && d.salesInfo.sumo_pp_payment_data_exists === "YES");
      console.log(`Număr total de comenzi cu sumo_pp_payment_data_exists = YES: ${comenziCuSumo.length}`);
      
      // Sortează datePrelucrate crescător după 'dataVandut'
      datePrelucrate.sort((a, b) => new Date(a.dataVandut) - new Date(b.dataVandut));

      // Filtrare date conform condițiilor specificate și afișarea ID-urilor eliminate
      const dateFiltrate = datePrelucrate.filter(row => {
        if (row.salesInfo && row.salesInfo.sumo_pp_payment_data_exists === "YES") {
            const subtotal = parseFloat(row.salesInfo.subtotal);
            const totalPayableAmount = parseFloat(row.salesInfo.total_payable_amount);
            const fifteenPercentOfTotal = totalPayableAmount * 0.15;
            const shouldBeFilteredOut = subtotal > fifteenPercentOfTotal;
            if (shouldBeFilteredOut) {
              console.log(`Acest ID a fost eliminat: ${row.salesInfo.ID}`);
            }
            return !shouldBeFilteredOut;
        }
        return true;
      });

      console.log(`Număr de comenzi eliminate: ${comenziCuSumo.length - dateFiltrate.length}`);
      
      setSalesData(dateFiltrate);
    } catch (error) {
      console.error('Eroare la preluarea datelor de vânzări:', error);
    }
  };

  fetchSalesData();
}, [isFilterActive]);


// Adaugă funcția de filtrare pentru datele din TabPanel 2
useEffect(() => {
    const filterSalesData = () => {
        let filtered = salesData;
        if (salesStartDate && salesEndDate) {
            filtered = salesData.filter(data => {
                const dataDate = new Date(data.dataVandut);
                return dataDate >= salesStartDate && dataDate <= salesEndDate;
            });
        }

        setSalesDataFiltered(filtered);
    };

    filterSalesData();
}, [salesStartDate, salesEndDate, salesData]);

// Adăugarea unei noi funcții useEffect pentru a apela endpoint-ul /compare-skus
useEffect(() => {
  const fetchComparisonData = async () => {
    try {
      const startDateString = salesStartDate ? salesStartDate.toISOString() : '';
      const endDateString = salesEndDate ? salesEndDate.toISOString() : '';
      const compareResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/jurnale/compare-skus?startDate=${startDateString}&endDate=${endDateString}`);
      //console.log("Comparison Data fetched:", compareResponse.data); // Verifică datele încărcate
      setComparisonData(compareResponse.data);
    } catch (error) {
      console.error("Failed to fetch comparison data:", error);
    }
  };

  // Apelul funcției de solicitare a datelor de comparație
  fetchComparisonData();
}, [salesStartDate, salesEndDate]); // Asigurați-vă că useEffect se declanșează la schimbarea salesStartDate și salesEndDate

const determineInvoiceNumber = (row) => {
  if (row.boutique) {
    return row.boutique;
  } else if (row.smartbill) {
    return row.smartbill;
  } else {
    return 'Lipsă'; // sau orice alt text dorit pentru cazul în care ambele sunt lipsă
  }
};

useEffect(() => {
  const filterSalesData = () => {
    let totalPretVanzareJIRA = 0;
    let totalProfit = 0;
    let totalTVA = 0;

    let adjustedData = salesData.map(data => {
      // Verifică dacă filtrul este activ și dacă înregistrarea este o clonă, apoi ajustează dataVandut
      if (isFilterActive && data.esteClona && data.refundInfo && data.refundInfo.data_cerere_storno) {
        return { ...data, dataVandut: data.refundInfo.data_cerere_storno };
      }

      if (isFilterActive && data.salesInfo && data.salesInfo.date_created) {
        const dataVandut = new Date(data.dataVandut);
        const dateCreated = new Date(data.salesInfo.date_created);
        const diferentaZile = Math.abs((dataVandut - dateCreated) / (1000 * 3600 * 24));

        if (diferentaZile > 10) {
          if (data.tip_vandut === 'Offline' && data.smartbill) {
            const regex = /(\d{2})-(\d{2})-(\d{4})$/;
            const matches = data.smartbill.match(regex);
            if (matches) {
              const [_, day, month, year] = matches;
              const newDateVandut = `${year}-${month}-${day}`;
              return { ...data, dataVandut: newDateVandut };
            }
          } else {
            return { ...data, dataVandut: data.salesInfo._ywpi_invoice_date };
          }
        }
      }

      return data;
    });

    // Aplică filtrul inițial bazat pe intervalul de date selectat pe datele ajustate
    let filtered = adjustedData.filter(data => {
      const dataDate = new Date(data.dataVandut);
      return (!salesStartDate || dataDate >= salesStartDate) && (!salesEndDate || dataDate <= salesEndDate);
    });

    // Calculează totalurile pe baza datelor filtrate și ajustate și actualizează stările corespunzătoare
    filtered.forEach(data => {
      totalPretVanzareJIRA += parseFloat(data.pretDeVanzareRON || 0);
      totalProfit += parseFloat(data.profitRON || 0);
      totalTVA += parseFloat(data.tvaRON || 0);
    });

    // Actualizează stările pentru datele filtrate și ajustate și pentru totaluri
    setSalesDataFiltered(filtered);
    setTotalPretVanzareJIRA(totalPretVanzareJIRA);
    setTotalProfit(totalProfit);
    setTotalTVA(totalTVA);
  };

  filterSalesData();
}, [salesStartDate, salesEndDate, salesData, isFilterActive]); // Include isFilterActive ca dependență

useEffect(() => {
  const fetchIntrariData = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/intrari');
      setIntrariData(response.data);
      setIntrariDataFiltered(response.data); // Inițial, setăm datele filtrate la totalitatea datelor
    } catch (error) {
      console.error('Eroare la preluarea datelor de intrări:', error);
    }
  };

  fetchIntrariData();
}, []);

useEffect(() => {
  const filterAndSortIntrariData = () => {
    let filtered = intrariData;
    if (intrariStartDate && intrariEndDate) {
      filtered = intrariData.filter((data) => {
        const dataDate = data.dataIntrare ? new Date(data.dataIntrare) : null;
        return dataDate >= intrariStartDate && dataDate <= intrariEndDate;
      });
    }
    // Sortare crescătoare după data_de_intrare
    filtered.sort((a, b) => new Date(a.dataIntrare) - new Date(b.dataIntrare));
    setIntrariDataFiltered(filtered);
  };

  filterAndSortIntrariData();
}, [intrariStartDate, intrariEndDate, intrariData]);

useEffect(() => {
  // Calcularea sumei pentru Pret Intrare și numărul total de intrări
  const sumaPretIntrare = intrariDataFiltered.reduce((acc, curr) => acc + (curr.pretIntrareRON || 0), 0);
  setTotalPretIntrare(sumaPretIntrare);
  setNumarTotalIntrari(intrariDataFiltered.length);
}, [intrariDataFiltered]);

useEffect(() => {
  const updatedSalesData = salesDataFiltered.map(row => {
    // Aici presupunem că `profitRON` și `tvaRON` sunt disponibile direct în `row`
    // și că funcția `rezultatFormulaCalculation` este accesibilă
    const profitRON = row.profitRON ?? '0'; // Asigurăm o valoare implicită
    const tvaRON = row.tvaRON ?? '0';
    const REZULTAT_FORMULA = rezultatFormulaCalculation(profitRON, tvaRON);
    
    return {
      ...row,
      REZULTAT_FORMULA, // Adăugăm sau actualizăm REZULTAT_FORMULA în rând
    };
  });

  // Actualizează starea cu noile date, dacă este necesar
  // Acest lucru depinde de cum sunt gestionate și afișate datele în componenta ta
  setSalesDataFiltered(updatedSalesData);
}, [salesDataFiltered]); // Adaugă dependențele necesare pentru useEffect

useEffect(() => {
  const fetchServiciiData = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/servicii');
      let filteredData = response.data.filteredData.filter(order => {
        const date = new Date(order.date_created);
        return (!isFilteredServiciiStartDate || date >= isFilteredServiciiStartDate) && (!isFilteredServiciiEndDate || date <= isFilteredServiciiEndDate);
      });
      let filteredData2 = response.data.filteredData2.filter(order => {
        const date = new Date(order.date_created);
        return (!isFilteredServiciiStartDate || date >= isFilteredServiciiStartDate) && (!isFilteredServiciiEndDate || date <= isFilteredServiciiEndDate);
      });

      // Verificăm dacă toggle-ul este activ și selectăm datele corespunzătoare
      const dataToUse = isToggleActive ? filteredData : [...filteredData, ...filteredData2];

      // Calculăm sumele și numărul de comenzi pentru setul de date selectat
      const calculatedTotalSuma = dataToUse.reduce((acc, curr) => acc + curr.total, 0);
      const calculatedTotalComenzi = dataToUse.length;

      // Actualizăm stările cu datele filtrate și calculele
      setServiciiData({ filteredData, filteredData2 });
      setTotalSuma(calculatedTotalSuma);
      setTotalComenzi(calculatedTotalComenzi);
    } catch (error) {
      console.error('Eroare la preluarea datelor serviciilor:', error);
    }
  };

  fetchServiciiData();
}, [isFilteredServiciiStartDate, isFilteredServiciiEndDate, isToggleActive]); // Adăugăm isToggleActive ca dependență pentru a re-rula efectul când se schimbă


useEffect(() => {
  const fetchIntrariActData = async () => {
    try {
      const startDateString = intrariActStartDate ? intrariActStartDate.toISOString().split('T')[0] : '';
      const endDateString = intrariActEndDate ? intrariActEndDate.toISOString().split('T')[0] : '';
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/jurnale/intrari-act?startDate=${startDateString}&endDate=${endDateString}`);
      setIntrariActData(response.data);
      setIntrariActDataFiltered(response.data); // Inițial, setăm datele filtrate la totalitatea datelor
    } catch (error) {
      console.error('Eroare la preluarea datelor de intrări act:', error);
    }
  };

  fetchIntrariActData();
}, [intrariActStartDate, intrariActEndDate]);

useEffect(() => {
  const filterAndSortIntrariActData = () => {
    let filtered = intrariActData;
    if (intrariActStartDate && intrariActEndDate) {
      filtered = intrariActData.filter((data) => {
        const dataDate = data.dataIntrareAct ? new Date(data.dataIntrareAct) : null;
        return dataDate >= intrariActStartDate && dataDate <= intrariActEndDate;
      });
    }
    // Sortare crescătoare după dataIntrareAct
    filtered.sort((a, b) => new Date(a.dataIntrareAct) - new Date(b.dataIntrareAct));
    setIntrariActDataFiltered(filtered);
  };

  filterAndSortIntrariActData();
}, [intrariActStartDate, intrariActEndDate, intrariActData]);

useEffect(() => {
  const sumaPretIntrareAct = intrariActDataFiltered.reduce((acc, curr) => acc + parseFloat(curr.pretIntrareRONAct || 0), 0);
  setTotalPretIntrareAct(sumaPretIntrareAct);
  setNumarTotalIntrariAct(intrariActDataFiltered.length);
}, [intrariActDataFiltered]);


  // Handler pentru schimbarea stării toggle-ului
const handleToggleChange = (event) => {
    setIsToggleActive(event.target.checked);
};

const dataToDisplay = isToggleActive ? serviciiData.filteredData : [...serviciiData.filteredData, ...serviciiData.filteredData2];

// Funcția de export pentru Excel
const exportFilteredSalesToExcel = () => {
  // Aici, presupunem că ai o funcție exportToExcel definită similar cu exemplul anterior
  exportToExcel(filteredSalesData, "FilteredSalesExcel", filteredSalesColumns);
};

// Funcția de export pentru PDF
const exportFilteredSalesToPDF = () => {
  // Similar, presupunem că ai o funcție exportToPDF definită
  exportToPDF(filteredSalesData, "FilteredSalesPDF", filteredSalesColumns);
};

//REINCARCA PRODUSELE DIN WOO IN BAZA BO DIN KINSTA
const handleSyncProducts = () => {
        setIsSyncing(true); // Începe sincronizarea
        fetch('https://server-9p4hx.kinsta.app/update-woocommerce-orders')
            .then(response => response.json())
            .then(data => {
                alert(data.message); // Afișează mesajul de succes
            })
            .catch(error => {
                console.error('Eroare la sincronizarea produselor:', error);
                alert('Eroare la sincronizarea produselor.');
            })
            .finally(() => setIsSyncing(false)); // Oprește sincronizarea indiferent de rezultat
};

const handleRecChangeForVanzari = async (issue_id, newRecValue) => {
  try {
    const response = await axios.post('https://server-9p4hx.kinsta.app/api/jurnale/update-rec', {
      issue_id,
      rec: newRecValue ? 1 : 0, // Setăm rec la 1 sau 0 în funcție de stare
    });

    if (response.status === 200) {
      // Actualizăm rec-ul pentru rândul respectiv
      const updatedSalesData = salesDataFiltered.map(row => 
        row.issue_id === issue_id ? { ...row, rec: newRecValue ? 1 : 0 } : row
      );
      
      // Setăm datele actualizate în state și forțăm rerenderizarea
      setSalesDataFiltered(updatedSalesData);
    }
  } catch (error) {
    console.error('Error updating rec value for Vânzări:', error);
  }
};

const handleRecChangeForIntrari = async (issue_id, newRecValue) => {
  try {
    const response = await axios.post('https://server-9p4hx.kinsta.app/api/jurnale/update-rec', {
      issue_id,
      rec: newRecValue ? 1 : 0,  // Setăm valoarea rec în funcție de noua stare
    });

    if (response.status === 200) {
      setIntrariDataFiltered(prevData => {
        const updatedData = prevData.map(row =>
          row.issue_id === issue_id ? { ...row, rec: newRecValue ? 1 : 0 } : row  // Actualizăm doar rândul corespunzător
        );
        console.log('Updated intrariDataFiltered:', updatedData);  // Verificăm actualizarea
        return updatedData;  // Returnăm datele actualizate
      });
    }
  } catch (error) {
    console.error('Error updating rec value for Intrări:', error);
  }
};

const handleRecChangeForReceptii = async (issue_id, currentRecValue, respValue, isChecked) => {
    try {
      // Modify recValue to include/exclude RESP
      let newRecValue;
      if (isChecked) {
        // If checked, add RESP to REC
        newRecValue = currentRecValue ? `${currentRecValue},${respValue}` : `${respValue}`;
      } else {
        // If unchecked, remove RESP from REC
        newRecValue = currentRecValue.replace(`,${respValue}`, '').replace(respValue, '').trim();
      }

      // Send updated REC value to the backend
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/jurnale/update-rec', {
        issue_id,
        rec: newRecValue,
      });

      if (response.status === 200) {
        // Update state with new REC value
        const updatedData = receptiiDataFiltered.map(row =>
          row.issue_id === issue_id ? { ...row, rec: newRecValue } : row
        );
        setReceptiiDataFiltered(updatedData);
      }
    } catch (error) {
      console.error('Error updating REC value for Receptii:', error);
    }
};

////////////////////////////////////////////////////////////////
  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="activity tabs">
            <Tab label="Intrări" value="1" />
            <Tab label="Vânzări" value="2" />
            <Tab label="Restituit" value="3" />
            <Tab label="Servicii" value="4" />
            <Tab label="Modificari" value="5" />
            <Tab label="Stocuri" value="6" />
          </Tabs>
        </Box>

        <TabPanel value="1" sx={{ padding: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1">De la:</Typography>
            <DatePicker
              selected={intrariStartDate}
              onChange={date => setIntrariStartDate(date)}
              selectsStart
              startDate={intrariStartDate}
              endDate={intrariEndDate}
              dateFormat="dd-MM-yyyy"
              isClearable
              placeholderText="Selectează data de început"
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1">Până la:</Typography>
            <DatePicker
              selected={intrariEndDate}
              onChange={date => setIntrariEndDate(date)}
              selectsEnd
              startDate={intrariStartDate}
              endDate={intrariEndDate}
              minDate={intrariStartDate}
              dateFormat="dd-MM-yyyy"
              isClearable
              placeholderText="Selectează data de sfârșit"
            />
          </Box>
            {/* Butonul de Export în Excel */}
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloudDownloadIcon />}
            onClick={() => exportToExcel(intrariDataFiltered, 'Intrari-Excel', intrariColumns)}
            sx={{ m: 2 }}
          >
            Export în Excel
          </Button>

          {/* Adaugă butonul de Export în PDF */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadIcon />}
            onClick={() => exportToPDF(intrariDataFiltered, 'Intrari-PDF', intrariColumns)}
            sx={{ m: 2 }}
          >
            Exportă în PDF
          </Button>

          {/* Butonul de Refresh */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={handleRefreshTasks}
            sx={{ m: 2 }}
          >
            Reîncarcă Intrările
          </Button>
          </Box>
            {/* Zona de sumar pentru Intrări */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, marginBottom: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                    <AttachMoneyIcon color="primary" sx={{ fontSize: 40, marginRight: 2 }} />
                    <CardContent>
                      <Typography color="textSecondary">Total Achizitii</Typography>
                        <Typography variant="h5">
                        {totalPretIntrare.toLocaleString('ro-RO',  { maximumFractionDigits: 0, useGrouping: true })} Ron
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                    <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 40, marginRight: 2 }} />
                    <CardContent>
                      <Typography color="textSecondary">Total Intrări</Typography>
                      <Typography variant="h5">{numarTotalIntrari}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
          </Box>

<TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="intrari table">
    <TableHead>
      <TableRow>
        <TableCell>REC</TableCell> {/* Coloană pentru bifa REC */}
        <TableCell>Nr Jurnal</TableCell>
        <TableCell>SKU</TableCell>
        <TableCell>Tip Contract</TableCell>
        <TableCell>Nume</TableCell>
        <TableCell>Summary</TableCell>
        <TableCell>Pret Intrare</TableCell>
        <TableCell>Data Intrare</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {intrariDataFiltered.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            {/* Checkbox-ul care bifează automat dacă valoarea rec este 1 */}
<Checkbox
  checked={row.rec === 1 || row.rec === "1"} // Verificăm atât pentru numărul 1 cât și pentru string-ul "1"
  onChange={() => handleRecChangeForIntrari(row.issue_id, !(row.rec === 1 || row.rec === "1"))} // Modificăm starea în funcție de valoare
/>

          </TableCell>
          <TableCell>{row.nrJurnalSpecial || 'N/A'}</TableCell>
          <TableCell>{row.sku || 'N/A'}</TableCell>
          <TableCell>{row.tipContract || 'N/A'}</TableCell>
          <TableCell>{row.numeSiPrenume || 'N/A'}</TableCell>
          <TableCell>{row.summary || 'N/A'}</TableCell>
          <TableCell>{row.pretIntrareRON || 'N/A'}</TableCell>
          <TableCell>{row.dataIntrare ? new Date(row.dataIntrare).toLocaleDateString() : 'N/A'}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>

        {/* Acordeon pentru datele de comparație */}
        {comparisonData && ( // Verifică dacă comparisonData este încărcat
            <>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>SKU-uri în Vânzări dar nu și în Jira (Vândut!)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    {comparisonData.inSalesNotInTasksAsSold.map((sale, index) => (
                        <p key={index}>{sale.sku}</p>
                    ))}
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>SKU-uri în Jira (Vândut!) dar nu în Vânzări</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    {comparisonData.inTasksAsSoldNotInSales.map((task, index) => (
                        <p key={index}>{task.SKU}</p>
                    ))}
                    </Typography>
                </AccordionDetails>
                </Accordion>

            </>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, marginBottom: 2 }}>
              <Grid container spacing={2}>
                {/* Card pentru Preț de Vânzare JIRA */}
                <Grid item xs={6} sm={3} md={2.4}>
                  <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                    <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0 }}>
                      <Typography color="textSecondary" sx={{ textAlign: 'center' }}>Preț de Vânzare</Typography>
                      <Typography variant="h5" sx={{ textAlign: 'center' }}>{totalPretVanzareJIRA.toFixed(2)}</Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Card pentru Profit */}
                <Grid item xs={6} sm={3} md={2.4}>
                  <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                    <MonetizationOnIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0 }}>
                      <Typography color="textSecondary" sx={{ textAlign: 'center' }}>Profit</Typography>
                      <Typography variant="h5" sx={{ textAlign: 'center' }}>{totalProfit.toFixed(2)}</Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Card pentru TVA */}
                <Grid item xs={6} sm={3} md={2.4}>
                  <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                    <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0 }}>
                      <Typography color="textSecondary" sx={{ textAlign: 'center' }}>TVA</Typography>
                      <Typography variant="h5" sx={{ textAlign: 'center' }}>{totalTVA.toFixed(2)}</Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Card pentru Impozit pe Profit */}
                <Grid item xs={6} sm={3} md={2.4}>
                  <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                    <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0 }}>
                      <Typography color="textSecondary" sx={{ textAlign: 'center' }}>Impozit pe Profit</Typography>
                      <Typography variant="h5" sx={{ textAlign: 'center' }}>{totalImpozitProfit.toFixed(2)}</Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Card pentru Total Vânzări */}
                <Grid item xs={6} sm={3} md={2.4}>
                  <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                    <ExpandMoreIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0 }}>
                      <Typography color="textSecondary" sx={{ textAlign: 'center' }}>Total Vânzări</Typography>
                      <Typography variant="h5" sx={{ textAlign: 'center' }}>{totalVanzari}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>

            {/* Componente DatePicker și butoane pentru TabPanel 2 */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                  <Typography variant="body1">De la:</Typography>
                  <DatePicker
                      selected={salesStartDate}
                      onChange={setSalesStartDate}
                      selectsStart
                      startDate={salesStartDate}
                      endDate={salesEndDate}
                      dateFormat="dd-MM-yyyy"
                      isClearable
                  />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                  <Typography variant="body1">Până la:</Typography>
                  <DatePicker
                      selected={salesEndDate}
                      onChange={setSalesEndDate}
                      selectsEnd
                      startDate={salesStartDate}
                      endDate={salesEndDate}
                      minDate={salesStartDate}
                      dateFormat="dd-MM-yyyy"
                      isClearable
                  />
              </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body1">Filtrul de 10 zile</Typography>
                  <FormControlLabel
                    control={<Switch checked={isFilterActive} onChange={(event) => setIsFilterActive(event.target.checked)} />}
                    label=""
                    sx={{ marginTop: '-6px' }} // Ajustează switch-ul să fie mai sus cu 6px
                  />
                </Box>
                
                <Button
                variant="contained"
                color="primary"
                onClick={() => exportToExcel(salesDataFiltered, 'Vanzari-Excel', vanzariColumns)}
                startIcon={<CloudDownloadIcon />}
                sx={{ margin: 1 }}
                >
                Export to Excel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => exportToPDF(salesDataFiltered, 'Vanzari-PDF', vanzariColumns)} // Ajustează coloanele după necesități
                  sx={{ m: 2 }}
                >
                  Exportă în PDF
                </Button>

                {/* Adaugă un buton pentru a reîncărca datele de vânzări, dacă este necesar */}
                <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshTasks}
                startIcon={<RefreshIcon />}
                sx={{ margin: 1 }}
                >
                Reîncarcă Vânzările
                </Button>
            </Box>
                {/* Tabel pentru Vânzări */}
<TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="vanzari table">
    <TableHead>
      <TableRow>
        <TableCell>REC</TableCell> {/* Coloană pentru checkbox REC */}
        <TableCell>Nr. Jurnal Special</TableCell>
        <TableCell>SKU</TableCell>
        <TableCell>Summary</TableCell>
        <TableCell>Furnizor</TableCell>
        <TableCell>Data de Intrare</TableCell>
        <TableCell>Pret de Intrare</TableCell>
        <TableCell>Data Vândut</TableCell>
        <TableCell>Preț de Vânzare JIRA</TableCell>
        <TableCell>Profit RON</TableCell>
        <TableCell>TVA RON</TableCell>
        <TableCell>Impozit pe Profit</TableCell>
        <TableCell>Client</TableCell>
        <TableCell>Nr. Factura</TableCell>
        <TableCell>Nr. Comanda</TableCell>
        <TableCell>Date Comenzi</TableCell>
        <TableCell>Preț de Vânzare Luxura</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {salesDataFiltered.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Checkbox
              checked={parseInt(row.rec) === 1} // Verificăm dacă 'rec' este egal cu 1, indiferent de tipul său de date (string sau număr)
              onChange={() => handleRecChangeForVanzari(row.issue_id, !(parseInt(row.rec) === 1))} // Inversăm starea în funcție de valoare
            />
          </TableCell>

          <TableCell>{row.nrJurnalSpecial || 'N/A'}</TableCell>
          <TableCell>{row.sku || 'N/A'}</TableCell>
          <TableCell>{row.summary || 'N/A'}</TableCell>
          <TableCell>{row.numeSiPrenume || 'N/A'}</TableCell>
          <TableCell>{row.data_de_intrare ? new Date(row.data_de_intrare).toLocaleDateString() : 'N/A'}</TableCell>
          <TableCell>{row.pret_intrare_ron || 'N/A'}</TableCell>
          <TableCell>{row.dataVandut ? new Date(row.dataVandut).toLocaleDateString() : 'N/A'}</TableCell>
          <TableCell>{row.pretDeVanzareRON || 'N/A'}</TableCell>
          <TableCell>{row.profitRON || 'N/A'}</TableCell>
          <TableCell>{row.tvaRON || 'N/A'}</TableCell>
          <TableCell>{row.REZULTAT_FORMULA || 'N/A'}</TableCell>
          <TableCell>{row.salesInfo ? `${row.salesInfo.first_name} ${row.salesInfo.last_name}` : 'N/A'}</TableCell>
          <TableCell>{row.salesInfo ? row.salesInfo._ywpi_invoice_formatted_number : 'N/A'}</TableCell>
          <TableCell>{row.salesInfo ? row.salesInfo.ID : 'N/A'}</TableCell>
          <TableCell>{row.salesInfo ? new Date(row.salesInfo._ywpi_invoice_date).toLocaleDateString() : 'N/A'}</TableCell>
          <TableCell>{row.salesInfo ? row.salesInfo.total : 'N/A'}</TableCell>
        </TableRow>
      ))}
    </TableBody>
    </Table>
  </TableContainer>


        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0 }}>
         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <p>De la:</p>
                    <DatePicker
                        selected={startDate}
                        onChange={setStartDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd-MM-yyyy"
                        isClearable
                    />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <p>Până la:</p>
                    <DatePicker
                        selected={endDate}
                        onChange={setEndDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd-MM-yyyy"
                        isClearable
                    />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => exportToExcel(filteredData, 'Restituiri-Excel', restituitColumns)}
                    startIcon={<CloudDownloadIcon />}
                    sx={{ margin: 1 }}
                >
                    Export to Excel
                </Button>

                <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                onClick={() => exportToPDF(filteredData, 'Restituit-PDF', restituitColumns)} // Ajustează coloanele după necesități
                sx={{ m: 2 }}
              >
                Exportă în PDF
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRefreshTasks}
                    startIcon={<RefreshIcon />}
                    sx={{ margin: 1 }}
                >
                    Reîncarcă Taskurile
                </Button>
            </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="restituit table">
              <TableHead>
                <TableRow>
                  <TableCell>Nr. Jurnal Special</TableCell>
                  <TableCell>Tip Contract</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Nume și Prenume</TableCell>
                  <TableCell>Denumire Produs</TableCell>
                  <TableCell>Preț Intrare RON</TableCell>
                  <TableCell>Data Retur</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow key={index}>

                    <TableCell>{row.nrJurnalSpecial}</TableCell>
                    <TableCell>{row.tipContract}</TableCell>
                    <TableCell>{row.sku}</TableCell>
                    <TableCell>{row.numeSiPrenume}</TableCell>
                    <TableCell>{row.summary}</TableCell>
                    <TableCell>{row.pretIntrareRON}</TableCell>
                    <TableCell>{row.dataRestituit}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value="4" sx={{ padding: 0 }}>


          {/* Zona de filtrare si export pentru Servicii */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 4 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                <Typography variant="body1">De la:</Typography>
                <DatePicker
                  selected={isFilteredServiciiStartDate}
                  onChange={date => setIsFilteredServiciiStartDate(date)}
                  selectsStart
                  startDate={isFilteredServiciiStartDate}
                  endDate={isFilteredServiciiEndDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable={true}
                  placeholderText="Selectează data de început"
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
              <Typography variant="body1">Până la:</Typography>
              <DatePicker
                selected={isFilteredServiciiEndDate}
                onChange={date => setIsFilteredServiciiEndDate(date)}
                selectsEnd
                startDate={isFilteredServiciiStartDate}
                endDate={isFilteredServiciiEndDate}
                dateFormat="dd/MM/yyyy"
                isClearable={true}
                placeholderText="Selectează data de sfârșit"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Typography variant="body1">Doar LCE Pachete</Typography>
            <FormControlLabel
            control={<Switch checked={isToggleActive} onChange={handleToggleChange} />}
            label=""
            sx={{ marginTop: '-6px' }}
          />

          </Box>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CloudDownloadIcon />}
              onClick={exportFilteredSalesToExcel}
              sx={{ m: 2 }}
            >
              Export în Excel
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={exportFilteredSalesToPDF}
              sx={{ m: 2 }}
            >
              Exportă în PDF
            </Button>

            <Button 
                variant="outlined" 
                color="primary" 
                onClick={handleSyncProducts} 
                startIcon={<RefreshIcon />}
                style={{ margin: 'auto' }}
                disabled={isSyncing}  // Dezactivează butonul pe durata sincronizării
                sx={{ m: 2 }}
            >
                {isSyncing ? 'Sincronizare...' : 'Reîncarcă stocuri'}
            </Button>
          </Box>

          {/* Zona de sumar pentru Servicii */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, marginBottom: 2 }}>
            <Grid container spacing={2}>
              {/* Card pentru Total Suma */}
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                  <AttachMoneyIcon color="primary" sx={{ fontSize: 40, marginRight: 2 }} />
                  <CardContent>
                    <Typography color="textSecondary">Total Suma</Typography>
                    <Typography variant="h5">
                      {totalSuma.toLocaleString('ro-RO', { maximumFractionDigits: 2, useGrouping: true })} Ron
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Card pentru Total Comenzi */}
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                  <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 40, marginRight: 2 }} />
                  <CardContent>
                    <Typography color="textSecondary">Total Comenzi</Typography>
                    <Typography variant="h5">{totalComenzi}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Card pentru Bonus */}
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                  {/* Aici poți înlocui Icon-ul cu cel dorit pentru "Bonus" */}
                  <MonetizationOnIcon color="secondary" sx={{ fontSize: 40, marginRight: 2 }} />
                  <CardContent>
                    <Typography color="textSecondary">Bonus</Typography>
                    <Typography variant="h5">
                      {/* Logica pentru afișarea valorii Bonus */}
                      {totalSuma > 4000 ? `${(totalSuma - 4000).toLocaleString('ro-RO', { maximumFractionDigits: 2, useGrouping: true })} Ron` : '0 Ron'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          {/* Tabelul cu datele filtrate */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="filtered sales table">
              <TableHead>
                <TableRow>
                  <TableCell>Nr. Comanda</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Telefon</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Subtotal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              
            {dataToDisplay.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.ID}</TableCell>
                    <TableCell>{row.sku}</TableCell>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{new Date(row.date_created).toLocaleDateString()}</TableCell>
                    <TableCell>{row.total}</TableCell>
                    <TableCell>{row.subtotal}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value="5" sx={{ padding: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body1">De la:</Typography>
              <DatePicker
                selected={intrariActStartDate}
                onChange={date => setIntrariActStartDate(date)}
                selectsStart
                startDate={intrariActStartDate}
                endDate={intrariActEndDate}
                dateFormat="dd-MM-yyyy"
                isClearable
                placeholderText="Selectează data de început"
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body1">Până la:</Typography>
              <DatePicker
                selected={intrariActEndDate}
                onChange={date => setIntrariActEndDate(date)}
                selectsEnd
                startDate={intrariActStartDate}
                endDate={intrariActEndDate}
                minDate={intrariActStartDate}
                dateFormat="dd-MM-yyyy"
                isClearable
                placeholderText="Selectează data de sfârșit"
              />
            </Box>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CloudDownloadIcon />}
              onClick={() => exportToExcel(intrariActDataFiltered, 'Intrari-Act-Excel', intrariActColumns)}
              sx={{ m: 2 }}
            >
              Export în Excel
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={() => exportToPDF(intrariActDataFiltered, 'Intrari-Act-PDF', intrariActColumns)}
              sx={{ m: 2 }}
            >
              Exportă în PDF
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={handleRefreshTasks}
              sx={{ m: 2 }}
            >
              Reîncarcă Intrările
            </Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, marginBottom: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                  <AttachMoneyIcon color="primary" sx={{ fontSize: 40, marginRight: 2 }} />
                  <CardContent>
                    <Typography color="textSecondary">Total Achizitii Act</Typography>
                    <Typography variant="h5">
                      {totalPretIntrareAct.toLocaleString('ro-RO', { maximumFractionDigits: 0, useGrouping: true })} Ron
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                  <AccountBalanceWalletIcon color="primary" sx={{ fontSize: 40, marginRight: 2 }} />
                  <CardContent>
                    <Typography color="textSecondary">Total Intrări Act</Typography>
                    <Typography variant="h5">{numarTotalIntrariAct}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="intrari act table">
              <TableHead>
                <TableRow>
                  <TableCell>Nr Jurnal</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Tip Contract</TableCell>
                  <TableCell>Nume</TableCell>
                  <TableCell>Summary</TableCell>
                  <TableCell>Pret Intrare</TableCell>
                  <TableCell>Data Intrare</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {intrariActDataFiltered.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.nrJurnalSpecial || 'N/A'}</TableCell>
                    <TableCell>{row.sku || 'N/A'}</TableCell>
                    <TableCell>{row.tipContract || 'N/A'}</TableCell>
                    <TableCell>{row.numeSiPrenume || 'N/A'}</TableCell>
                    <TableCell>{row.summary || 'N/A'}</TableCell>
                    <TableCell>{row.pretIntrareRONAct || 'N/A'}</TableCell>
                    <TableCell>{row.dataIntrareAct ? new Date(row.dataIntrareAct).toLocaleDateString() : 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        {/* TabPanel pentru Stocuri */}
        <TabPanel value="6" sx={{ padding: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
            <TextField
              select
              label="Selectează Categoria"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              <option value="">Toate</option>
              <option value="null">Fără</option>
              {stockData
                .map(item => item.categorie_prod)
                .filter((value, index, self) => value && self.indexOf(value) === index)
                .map((cat, index) => (
                  <option key={index} value={cat}>
                    {cat}
                  </option>
                ))}
            </TextField>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={exportStockToPDF}
              sx={{ m: 2 }}
            >
              Exportă în PDF
            </Button>
          </Box>

          <Grid container spacing={2} sx={{ padding: 2 }}>
            {paginatedStockData.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card sx={{ display: 'flex', height: '100%' }}>
                  <CardMedia
                    component="img"
                    alt={item.summary}
                    sx={{ width: '200px', height: 'auto' }}
                    image={item.df_image_link || 'placeholder-image-url'}
                    title={item.summary}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography 
                        variant="h6" 
                        gutterBottom 
                        sx={{ 
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                          overflowWrap: 'break-word',
                          maxWidth: '100%'
                        }}
                      >
                        {item.summary}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        SKU: {item.sku}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Data de Intrare: {new Date(item.data_de_intrare).toLocaleDateString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Nume și Prenume: {item.nume_si_prenume}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Preț Intrare: {item.pret_intrare_ron} RON
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Tip Achiziție: {item.tip_achizitie}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        </TabPanel>




      </TabContext>
    </Box>
  );
}

export default ActivityTabs;
