import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import calculateCost from './calculateCostAnalysiSToc';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

const formatNumber = (number) => {
  return number ? number.toLocaleString('ro-RO', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }) : '';
};

const calculatePercentageChange = (newPrice, originalPrice) => {
  return ((originalPrice - newPrice) / originalPrice) * 100;
};

const AdditionalPriceCard = ({ avgPrice, category }) => {
  const [costs, setCosts] = useState({});
  const [pretVoucher, setPretVoucher] = useState(0);
  const [pretVanzare, setPretVanzare] = useState(0);
  const [pretFastSale, setPretFastSale] = useState(0);
  const [percentageVoucher, setPercentageVoucher] = useState(0);
  const [percentageVanzare, setPercentageVanzare] = useState(0);
  const [percentageFastSale, setPercentageFastSale] = useState(0);

  useEffect(() => {
    const result = calculateCost(avgPrice, category);
    const { purchasePrice } = result;
    setCosts(result);

    const voucherPrice = purchasePrice * 0.95;
    const vanzarePrice = purchasePrice * 0.90;
    const fastSalePrice = purchasePrice * 0.85;

    setPretVoucher(voucherPrice);
    setPretVanzare(vanzarePrice);
    setPretFastSale(fastSalePrice);

    setPercentageVoucher(calculatePercentageChange(voucherPrice, purchasePrice).toFixed(2));
    setPercentageVanzare(calculatePercentageChange(vanzarePrice, purchasePrice).toFixed(2));
    setPercentageFastSale(calculatePercentageChange(fastSalePrice, purchasePrice).toFixed(2));
  }, [avgPrice, category]);

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>Pret Voucher:</strong> {formatNumber(pretVoucher)} RON {percentageVoucher > 0 ? <AddIcon fontSize="small" color="error" /> : <RemoveIcon fontSize="small" color="success" />} {percentageVoucher}%
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>Pret Vanzare:</strong> {formatNumber(pretVanzare)} RON {percentageVanzare > 0 ? <AddIcon fontSize="small" color="error" /> : <RemoveIcon fontSize="small" color="success" />} {percentageVanzare}%
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>Pret FAST Sale:</strong> {formatNumber(pretFastSale)} RON {percentageFastSale > 0 ? <AddIcon fontSize="small" color="error" /> : <RemoveIcon fontSize="small" color="success" />} {percentageFastSale}%
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default AdditionalPriceCard;
