import React, { useState } from 'react';
import TaskTable from './TaskTable';
import TaskEdit from './TaskEdit';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { green, blue, grey, orange, red, purple } from '@mui/material/colors'; // Am adăugat purple

const App = () => {
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [reload, setReload] = useState(false); 

    const handleTaskSelect = (issueId) => {
        setSelectedTaskId(issueId);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setSelectedTaskId(null); // Reset the selected task when changing tabs!! 
    };

    const handleCloseTaskEdit = () => {
        setSelectedTaskId(null);
        setReload(!reload); // Toggle reload state to refresh tasks
    };  

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '20px' }}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                TabIndicatorProps={{
                    style: {
                        backgroundColor: 'transparent',
                    },
                }}
                sx={{
                    backgroundColor: 'transparent',
                    '.MuiTab-root': {
                        minWidth: '20%', // Ajustăm dimensiunea  minimă pentru a include toate cele cinci taburi
                        position: 'relative',
                        '&:not(:last-of-type)::after': {
                            content: '""',
                            display: 'block',
                            width: '1px',
                            height: '40px',
                            backgroundColor: grey[300],
                            position: 'absolute',
                            right: 0,
                            top: 'calc(50% - 20px)',
                        },
                    },
                }}
            >
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: green[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Creat</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: blue[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Prospect</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: orange[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Acceptat</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: red[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Tranzit</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: purple[500], // Bulină mov pentru AUTENTIFICARE
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Autentificare</Typography>
                        </Box>
                    }
                />
            </Tabs>
            <Box sx={{ display: 'flex', width: '120%', marginTop: '20px' }}>
                {selectedTab === 0 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="2_CREAT" reload={reload} />}
                {selectedTab === 1 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="PROSPECT" reload={reload} />}
                {selectedTab === 2 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="ACCEPTAT" reload={reload} />}
                {selectedTab === 3 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="TRANZIT" reload={reload} />}
                {selectedTab === 4 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="AUTENTIFICARE" reload={reload} />} {/* Tabul pentru Autentificare */}
                {selectedTaskId && (
                    <TaskEdit
                        issueId={selectedTaskId}
                        style={{ width: '40%' }}
                        tab={selectedTab === 0 ? 'created' : selectedTab === 1 ? 'prospect' : selectedTab === 2 ? 'acceptat' : selectedTab === 3 ? 'tranzit' : 'autentificare'}
                        onClose={handleCloseTaskEdit}
                        reloadTasks={() => setReload(!reload)}
                    />
                )}
            </Box>
        </Box>
    );
};

export default App;
