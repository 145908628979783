import React from 'react';
import { Chip, createTheme, ThemeProvider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { cyan, green, pink, deepPurple, orange } from '@mui/material/colors';
import axios from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: cyan[500],
      contrastText: '#fff',
    },
    secondary: {
      main: pink[500],
      contrastText: '#fff',
    },
    success: {
      main: green[500],
      contrastText: '#fff',
    },
    error: {
      main: deepPurple[500],
      contrastText: '#fff',
    },
    warning: {
      main: orange[500],
      contrastText: '#fff',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: '1px solid #e0e0e0',
          padding: '16px 24px',
          fontSize: '0.875rem',
          '&.MuiTableCell-head': {
            backgroundColor: '#f5f5f5',
            color: '#5f6368',
            fontWeight: 500,
          },
          '&:hover': {
            backgroundColor: '#e8f4fd',
          },
        },
        head: { // Stiluri specifice pentru headerul tabelului
          backgroundColor: cyan[100], // O culoare pastelată de exemplu
          color: deepPurple[500], // Alege o culoare contrastantă pentru text
          fontSize: '1.1rem',
          fontWeight: 'bold',
          borderBottom: `2px solid ${cyan[200]}`, // O bordură mai groasă pentru a separa headerul de restul tabelului
          cursor: 'default', // Previne schimbarea cursorului
          '&:hover': {
            backgroundColor: cyan[100], // Asigură-te că nu se schimbă culoarea la hover
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit', // Previne orice schimbare de culoare la hover pe întregul antet
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 'none',
          },
          '&:hover': {
            backgroundColor: '#f5f5f5',
            cursor: 'pointer',
            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
            transform: 'scale(1.02)',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Pentru a evita suprapunerea cu umbra rândurilor
          borderRadius: '8px',
          overflow: 'hidden',
          border: '1px solid #e0e0e0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
        },
        elevation1: {
          boxShadow: 'none', // Anulăm umbra pentru a folosi doar efectele noastre personalizate
        },
      },
    },
  },
});

const getStatusChip = (status) => {
  switch(status) {
    case 'Activ':
      return <Chip label="Activ" color="success" />;
    case 'Nepreluat':
      return <Chip label="Nepreluat" color="error" />;
    // Adaugă aici mai multe cazuri dacă ai mai multe statusuri
    default:
      return <Chip label="Necunoscut" />;
  }
}

const LLpTable = ({ data, handleRowClick, selectedRows }) => {
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* Adaugă anteturile coloanelor tabelului aici */}
              <TableCell>Client</TableCell>
              <TableCell>Comanda</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Nr. de Rate</TableCell>
              <TableCell>Pret de vanzare</TableCell>
              <TableCell>Status</TableCell>
              {/* Adaugă mai multe TableCell dacă ai mai multe coloane */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.issue_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => handleRowClick(row)} // Adaugă gestionarea click-ului pe rând
                hover // Adaugă efectul de hover pentru rânduri
              >
                {/* Completează celulele cu datele corespunzătoare */}
                <TableCell component="th" scope="row">
                  {row.client_nume_prenume}
                </TableCell>
                <TableCell>{row.nr_comanda}</TableCell>
                <TableCell>{row.sku}</TableCell>
                <TableCell>{row.nr_de_rate}</TableCell>
                <TableCell>{row.pret_de_vanzare_ron}</TableCell>
                <TableCell>
                  {getStatusChip(selectedRows.has(row.issue_id) ? "Activ" : "Nepreluat")}
                </TableCell>
                {/* Continuă cu TableCell pentru restul datelor dacă este necesar */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default LLpTable;
