import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Tab, Tabs, Chip, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './App.css';

function Page4() {
  const [jsonData, setJsonData] = useState({});
  const [activeTab, setActiveTab] = useState('failed');
  const [failedCount, setFailedCount] = useState(0);
  const [passedCount, setPassedCount] = useState(0);
  const [skippedCount, setSkippedCount] = useState(0);
  const [testResults, setTestResults] = useState({});

  useEffect(() => {
    fetchTestResults();
  }, []);

  const fetchTestResults = async () => {
    try {
      const response = await fetch('https://server-9p4hx.kinsta.app/api/tasks/get-test-results');
      const results = await response.json();
      const formattedResults = results.reduce((acc, result) => {
        acc[result.sku] = JSON.parse(result.test_cases);
        return acc;
      }, {});
      setTestResults(formattedResults);
    } catch (error) {
      console.error('Error fetching test results:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const runTests = async () => {
    try {
      const response = await fetch('https://server-9p4hx.kinsta.app/api/tasks/verify-products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data && typeof data === 'object') {
        let combinedData = {};

        Object.keys(data).forEach(sku => {
          combinedData[sku] = { ...data[sku], ...(testResults[sku] || {}) };
        });

        setJsonData(combinedData);

        let failed = 0;
        let passed = 0;
        let skipped = 0;

        Object.keys(combinedData).forEach(sku => {
          const tests = combinedData[sku];
          const hasFailedTests = Object.values(tests).some(value => value === false);
          const allSkippedOrPassed = Object.values(tests).every(value => value === 'SKIPPED' || value === true);

          if (allSkippedOrPassed) {
            skipped++;
          } else if (hasFailedTests) {
            failed++;
          } else {
            passed++;
          }
        });

        setFailedCount(failed);
        setPassedCount(passed);
        setSkippedCount(skipped);
      } else {
        console.error('Invalid data format:', data);
      }
    } catch (error) {
      console.error('Error running tests:', error);
    }
  };

  const handleCopyToClipboard = (sku) => {
    const textToCopy = sku;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('SKU copied to clipboard:', textToCopy);
      })
      .catch(error => {
        console.error('Failed to copy SKU to clipboard:', error);
      });
  };

  const handleSkipTest = async (sku, testName) => {
    setJsonData(prevState => {
      const updatedTests = {
        ...prevState[sku],
        [testName]: 'SKIPPED'
      };

      saveTestResult(sku, updatedTests);
      return {
        ...prevState,
        [sku]: updatedTests
      };
    });
  };

  const saveTestResult = async (sku, testCases) => {
    const verdict = Object.values(testCases).some(status => status === false) ? 'FAILED' : 'SKIPPED';

    try {
      const response = await fetch('https://server-9p4hx.kinsta.app/api/tasks/save-test-result', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sku,
          testCases,
          verdict
        })
      });

      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error('Error saving test result:', error);
    }
  };

  const isNew = (dateCreated) => {
    const currentDate = new Date();
    const dateOfSku = new Date(dateCreated);
    const oneDay = 24 * 60 * 60 * 1000;
    const differenceDays = Math.round(Math.abs((currentDate - dateOfSku) / oneDay));

    return differenceDays <= 7;
  };

  const testDescriptions = {
    "SKU VALID": "Verifică dacă SKU-ul este valid.",
    "INVENTAR ACTIV": "Verifică dacă produsul este în inventar și gestionat.",
    "PRET VALID": "Verifică dacă produsul are un preț valid.",
    "CATEGORIE LUXURA BOUTIQUE": "Verifică dacă produsul aparține categoriei 'Luxura Boutique'.",
    "CONDITION MATCH": "Verifică dacă condiția din descrierea produsului corespunde cu atributele acestuia.",
    "HAS_MIN_2_RELATED_IDS": "Verifică dacă produsul are cel puțin două ID-uri asociate.",
    "LAYAWAY MATCH": "Verifică dacă produsul poate fi achiziționat prin planuri de plată și este specificat în descriere.",
    "COLOR MATCH": "Verifică dacă culoarea produsului din descriere corespunde cu atributele acestuia.",
    "SORTLY ID": "Verifică dacă codul de inventar Sortly este prezent în descriere.",
    "MARIME INCALTAMINTE": "Verifică dacă mărimea încălțămintei din descriere corespunde cu slug-ul și atributele produsului.",
    "APP TEST CAT NOTDISPLAY": "Verifică dacă produsul nu aparține categoriei 'NOTDISPLAY'.",
    "ALL ATTRIBUTES PRESENT": "Verifică dacă toate atributele necesare sunt prezente.",
    "INSOTIT PRESENT": "Verifică dacă atributul 'Insotit' și toate opțiunile sunt prezente în descriere.",
    "DESCRIERE VALIDA PENTRU PRODUS CU PRET PESTE 4999 LEI": "Verifică dacă descrierea este prezentă pentru produse cu preț peste 4999 lei, cu excepția categoriei 'Genti'.",
    "DATE IMAGINI": "Verifică dacă produsul are cel puțin o imagine.",
    "URL IMAGINI VALID": "Verifică dacă URL-urile imaginilor sunt accesibile.",
    "META DATA VALID": "Verifică dacă meta datele necesare sunt prezente și corect configurate.",
    "CATEGORIE PRINCIPALA VALIDA": "Verifică dacă produsul are o categorie principală și că aceasta este validă.",
    "TAG-URI VALIDE": "Verifică dacă tag-urile sunt prezente și corect configurate.",
    "DESCRIERE NEFORMATATA": "Verifică dacă descrierea conține doar text fără coduri HTML neînchise sau formatări incorecte.",
    "PRET REDUS VS. PRET REGULAR": "Verifică dacă prețul redus este mai mic decât prețul regular.",
    "DISPONIBILITATE IN STOC": "Verifică dacă produsul este disponibil în stoc.",
    "URL VALID": "Verifică dacă URL-ul produsului este valid și accesibil.",
    "DIMENSIUNI SPECIFICE": "Verifică dacă dimensiunile produsului sunt specificate.",
    "DATE VALIDE": "Verifică dacă datele de creare și modificare ale produsului sunt valide.",
    "RATING VALID": "Verifică dacă rating-ul produsului este valid.",
    "NUMAR REVIEW-URI VALID": "Verifică dacă numărul de review-uri este valid."
  };

  return (
    <div className="page-container">
      <Button variant="contained" color="primary" onClick={runTests} style={{ marginBottom: '20px' }}>
        Rulează testele
      </Button>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label={`Failed (${failedCount})`} value="failed" />
        <Tab label={`Passed (${passedCount})`} value="passed" />
        <Tab label={`Skipped (${skippedCount})`} value="skipped" />
      </Tabs>
      <div className="accordion-container">
        {Object.keys(jsonData).map(sku => {
          const tests = jsonData[sku];
          const hasFailedTests = Object.values(tests).some(value => value === false);
          const allSkippedOrPassed = Object.values(tests).every(value => value === 'SKIPPED' || value === true);

          if ((activeTab === 'failed' && hasFailedTests) || (activeTab === 'passed' && !hasFailedTests) || (activeTab === 'skipped' && allSkippedOrPassed)) {
            return (
              <Accordion key={sku} className={hasFailedTests ? 'failed-accordion' : ''}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {sku}
                    {isNew(tests.date_created) && (
                      <Chip label="NEW" color="secondary" style={{ marginLeft: '10px' }} />
                    )}
                  </Typography>
                  <Button onClick={() => handleCopyToClipboard(sku)}>Copy</Button>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography variant="subtitle2">Date Created: {tests.date_created}</Typography>
                    <ul>
                      {Object.keys(tests).map(testName => {
                        if (testName !== 'date_created') {
                          const testStatus = tests[testName];
                          const isFailed = testStatus === false;
                          const textStyle = {
                            fontWeight: isFailed ? 'bold' : 'normal',
                            color: isFailed ? 'red' : 'inherit',
                          };

                          return (
                            <Tooltip key={testName} title={testDescriptions[testName] || ''} arrow>
                              <li style={textStyle}>
                                {testName} - {testStatus ? 'PASSED' : testStatus === 'SKIPPED' ? 'SKIPPED' : 'FAILED'}
                                {isFailed && (
                                  <Button
                                    variant="text"
                                    style={{ color: 'blue', textDecoration: 'underline' }}
                                    onClick={() => handleSkipTest(sku, testName)}
                                  >
                                    SKIPPED
                                  </Button>
                                )}
                              </li>
                            </Tooltip>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}

export default Page4;
