import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Card, CardContent, Typography, Button, CardActions, List, ListItem, ListItemIcon, ListItemText, LinearProgress } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CableIcon from '@mui/icons-material/Cable';
import RefreshIcon from '@mui/icons-material/Refresh';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const KPIDashboard = ({
  handleSyncProducts,
  isSyncing
}) => {
  const [totalTasksTransit, setTotalTasksTransit] = useState(0);
  const [totalTasksPozat, setTotalTasksPozat] = useState(0);
  const [misalignedTasksWoo, setMisalignedTasksWoo] = useState(0);
  const [misalignedTasksNEW, setMisalignedTasksNEW] = useState(0);
  const [mismatchedTasksNEW, setMismatchedTasksNEW] = useState(0);
  const [totalTasksRestituite, setTotalTasksRestituite] = useState(0);
  const [totalTasksAutofacturi, setTotalTasksAutofacturi] = useState(0);
  const [apiData, setApiData] = useState({});
  const [vanzariEchipa, setVanzariEchipa] = useState([]);
  const [totalSubtotal, setTotalSubtotal] = useState(0);
  const [expirate, setExpirate] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isSyncingLocal, setIsSyncingLocal] = useState(false);

  const API_BASE_URL = 'https://server-9p4hx.kinsta.app';
  //const API_BASE_URL = 'http://localhost:5099';

  const fetchTasksInTransit = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tasks/tasksInTransit`);
      const data = await response.json();
      const total = Object.values(data).reduce((acc, group) => acc + (Array.isArray(group) ? group.length : 0), 0);
      setTotalTasksTransit(total);
    } catch (error) {
      console.error('Error fetching tasks in transit:', error);
      setTotalTasksTransit(0);
    }
  };

  const fetchAuthTasks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tasks/tasks-in-authentication`);
      const data = await response.json();
      const tasksCount = Object.values(data).flat().length;
      setTotalTasksPozat(tasksCount);
    } catch (error) {
      console.error("Error fetching auth tasks:", error);
      setTotalTasksPozat(0);
    }
  };

  const fetchMisalignedTasks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tasks/misaligned-tasks`);
      const data = await response.json();
      setMisalignedTasksNEW(data);
    } catch (error) {
      console.error("Failed to fetch misaligned tasks:", error);
    }
  };

  const fetchMismatchedPrices = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tasks/check-mismatched-prices`);
      const data = await response.json();
      setMismatchedTasksNEW(data);
    } catch (error) {
      console.error('Error fetching mismatched prices:', error);
    }
  };

  const fetchGroupedTasks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tasks/restituite`);
      const data = await response.json();
      const total = Object.values(data).reduce((acc, tasksGroup) => acc + (Array.isArray(tasksGroup) ? tasksGroup.length : 0), 0);
      setTotalTasksRestituite(total);
    } catch (error) {
      console.error("Failed to fetch grouped tasks:", error);
      setTotalTasksRestituite(0);
    }
  };

  const fetchAutoInvoiceTasks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tasks/autofacturi`);
      const data = await response.json();
      const tasksCount = Object.values(data).reduce((total, nameGroups) => {
        return total + (Array.isArray(nameGroups) ? nameGroups.reduce((subtotal, tasks) => subtotal + (Array.isArray(tasks) ? tasks.length : 0), 0) : 0);
      }, 0);
      setTotalTasksAutofacturi(tasksCount);
    } catch (error) {
      console.error('There was an error fetching the auto invoice tasks', error);
      setTotalTasksAutofacturi(0);
    }
  };

  const fetchApiData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dash/achizitor`);
      const data = await response.json();
      setApiData(data);
    } catch (error) {
      console.error('Error fetching API data:', error);
    }
  };

  const fetchVanzariEchipa = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/dash/asistatvanzare`);
      const data = await response.json();
      setVanzariEchipa(Object.entries(data).map(([asistat, { count, totalAllocation }]) => ({
        Nume: asistat,
        total_vanzari: count,
        totalAllocation
      })));
    } catch (error) {
      console.error('Error fetching vanzari echipa data:', error);
    }
  };

  const fetchTotalSubtotal = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/jurnale/servicii/totalpachete`);
      const data = await response.json();
      const roundedTotal = Math.round(data.totalSubtotal);
      setTotalSubtotal(roundedTotal);
    } catch (error) {
      console.error('Eroare la preluarea datelor', error);
      setTotalSubtotal(0);
    }
  };

  const fetchExpirate = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/tasks/contract-status`);
      const data = await response.json();
      setExpirate(data.expirate.length);
    } catch (error) {
      console.error('Error fetching contract status data:', error);
    }
  };

  const handleRefreshAll = async () => {
    await Promise.all([
      fetchTasksInTransit(),
      fetchMismatchedPrices(),
      fetchMisalignedTasks(),
      fetchApiData(),
      fetchVanzariEchipa(),
      fetchGroupedTasks(),
      fetchAuthTasks(),
      fetchAutoInvoiceTasks(),
      fetchTotalSubtotal(),
      fetchExpirate()
    ]);
  };

const handleRefreshTasksLocal = async () => {
    setIsRefreshing(true); // Activează indicatorul de încărcare și dezactivează butonul
    try {
        // Verificarea existenței fișierului tasks.json
        const responseCheckFile = await fetch(`${API_BASE_URL}/check-tasks-file`);
        const { exists } = await responseCheckFile.json();

        if (!exists) {
            throw new Error('Incarca excelul din Jira sau mai asteapta!.');
        }

        // Al doilea apel la API pentru a actualiza baza de date Task Jira
        const responseUpdate = await fetch(`${API_BASE_URL}/update-database-taskjira`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!responseUpdate.ok) {
            throw new Error(`HTTP error update JIRA to KINSTA! Status: ${responseUpdate.status}`);
        }

        const messageUpdate = await responseUpdate.text();
        console.log(`Update Database: ${messageUpdate}`);

        // Primul apel la API pentru a reîmprospăta task-urile
        const responseRefresh = await fetch(`${API_BASE_URL}/api/tasks/refresh-tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!responseRefresh.ok) {
            throw new Error(`HTTP error refresh tasks! Status: ${responseRefresh.status}`);
        }

        const messageRefresh = await responseRefresh.text();
        console.log(`Refresh: ${messageRefresh}`);

        // Optional: Apel suplimentar la API, dacă este necesar pentru dashboard sau alte date
        const responseRefreshDash = await fetch(`${API_BASE_URL}/api/dash/refresh-tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!responseRefreshDash.ok) {
            throw new Error(`HTTP error refresh dash tasks! Status: ${responseRefreshDash.status}`);
        }

        const messageRefreshDash = await responseRefreshDash.text();
        console.log(`Dashboard Refresh: ${messageRefreshDash}`);

        // Adaugă acest cod pentru a șterge fișierul tasks.json
        const responseDelete = await fetch(`${API_BASE_URL}/api/tasks/delete-tasks-file`, {
            method: 'POST',
        });

        if (!responseDelete.ok) {
            throw new Error('HTTP error when trying to delete tasks file');
        }

        const messageDelete = await responseDelete.json();
        console.log(messageDelete.message);

        // Afișează mesajul de succes după ștergerea fișierului
        alert(messageDelete.message);
    } catch (error) {
        console.error("Failed to perform operations:", error);
        alert(`Failed to perform operations: ${error.message}`);
    } finally {
        setIsRefreshing(false);
        handleRefreshAll();
    }
};

const handleSyncProductsLocal = () => {
        setIsSyncingLocal(true);
        fetch(`${API_BASE_URL}/update-woocommerce-orders`)
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                handleRefreshAll(); // Reîmprospătează toate datele după sincronizare
            })
            .catch(error => {
                console.error('Eroare la sincronizarea produselor:', error);
                alert('Eroare la sincronizarea produselor.');
            })
            .finally(() => setIsSyncingLocal(false));
};

  useEffect(() => {
    handleRefreshAll();
  }, []);

  const sortedApiData = useMemo(() => Object.entries(apiData).sort((a, b) => b[1] - a[1]), [apiData]);

  const sortedVanzariEchipa = useMemo(
    () => [...vanzariEchipa].sort((a, b) => b.total_vanzari - a.total_vanzari),
    [vanzariEchipa]
  );

  const getMedalIcon = (index) => {
    switch (index) {
      case 0:
        return <EmojiEvents style={{ color: 'gold' }} />;
      case 1:
        return <StarIcon style={{ color: 'silver' }} />;
      case 2:
        return <StarHalfIcon style={{ color: 'bronze' }} />;
      default:
        return <StarOutlineIcon />;
    }
  };

  const getRankingIcon = (position) => {
    switch (position) {
      case 0:
        return <EmojiEvents style={{ color: 'gold' }} />;
      case 1:
        return <StarIcon style={{ color: 'silver' }} />;
      case 2:
        return <StarHalfIcon style={{ color: 'bronze' }} />;
      default:
        return <StarOutlineIcon />;
    }
  };

  return (
    <Grid container spacing={3} style={{ marginBottom: '20px', marginTop: '20px' }}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Contracte Expirate
            </Typography>
            <Typography variant="h5">
              <DescriptionIcon /> {expirate}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchExpirate} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Produse în Tranzit
            </Typography>
            <Typography variant="h5">
              <TransitEnterexitIcon /> {totalTasksTransit}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchTasksInTransit} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Produse la Pozat
            </Typography>
            <Typography variant="h5">
              <CameraAltIcon /> {totalTasksPozat}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchAuthTasks} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Alocare Greșită Produse
            </Typography>
            <Typography variant="h5">
              <AccessTimeIcon /> {misalignedTasksWoo + misalignedTasksNEW.length + mismatchedTasksNEW.length}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchMisalignedTasks} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Cereri incomplete Restituit
            </Typography>
            <Typography variant="h5">
              <AutoModeIcon /> {totalTasksRestituite}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchGroupedTasks} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Lipsa Autofacturi
            </Typography>
            <Typography variant="h5">
              <CableIcon /> {totalTasksAutofacturi}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchAutoInvoiceTasks} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent style={{ textAlign: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleRefreshTasksLocal}
              startIcon={<RefreshIcon />}
              style={{ margin: 'auto' }}
              disabled={isRefreshing}
            >
              {isRefreshing ? 'Reîmprospătare...' : 'Reîncarcă Task-urile'}
            </Button>
            {isRefreshing && <LinearProgress />}
          </CardContent>
        </Card>
        <Card>
          <CardContent style={{ textAlign: 'center' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSyncProductsLocal}
              startIcon={<RefreshIcon />}
              style={{ margin: 'auto' }}
              disabled={isSyncingLocal}
            >
              {isSyncingLocal ? 'Sincronizare...' : 'Reîncarcă și Compară stocuri'}
            </Button>
            {isSyncingLocal && <LinearProgress />}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Performanța Achizitorilor
            </Typography>
            <List>
              {sortedApiData.map(([name, count], index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    {getMedalIcon(index)}
                  </ListItemIcon>
                  <ListItemText primary={`${index + 1}. ${name}: ${count}`} />
                </ListItem>
              ))}
            </List>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchApiData} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Vânzări Echipă
            </Typography>
            <List>
              {sortedVanzariEchipa
                .filter(({ total_vanzari }) => total_vanzari > 0)
                .map(({ Nume, total_vanzari, totalAllocation }, index) => (
                  <ListItem key={Nume}>
                    <ListItemIcon>
                      {getRankingIcon(index)}
                    </ListItemIcon>
                    <ListItemText
                      primary={`${index + 1}. ${Nume}: ${total_vanzari.toLocaleString()} ${total_vanzari > 1 ? 'articole' : 'articol'}`}
                      secondary={`Bonus: ${totalAllocation.toLocaleString()} RON`}
                    />
                  </ListItem>
                ))}
            </List>
            <List>
              <Typography color="textSecondary" gutterBottom>
                LCE Pachete: {totalSubtotal} RON
              </Typography>
            </List>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={fetchVanzariEchipa} startIcon={<RefreshIcon />}>Refresh</Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default KPIDashboard;
