import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';

// Custom styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledFooterCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  borderTop: `2px solid ${theme.palette.divider}`,
}));

const ScadentarClienti = ({ anSelectat }) => {
  const [clienti, setClienti] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`https://server-9p4hx.kinsta.app/api/llp/rate-nesoldate-pe-saptamani?an=${anSelectat}`);
        setClienti(response.data);
      } catch (error) {
        console.error('Failed to fetch scadentar data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [anSelectat]);

  return (
    <Box mt={4} mx={2}>
      <Typography variant="h4" align="center" gutterBottom>
        Scadențar {anSelectat}
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <Paper elevation={3}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Lună</StyledTableCell>
                  <StyledTableCell>Săptămâna 1</StyledTableCell>
                  <StyledTableCell>Săptămâna 2</StyledTableCell>
                  <StyledTableCell>Săptămâna 3</StyledTableCell>
                  <StyledTableCell>Săptămâna 4</StyledTableCell>
                  <StyledTableCell>Săptămâna 5</StyledTableCell>
                  <StyledTableCell>Total Lună</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clienti.map(({ luna, saptamani }) => {
                  const totalMonthSuma = saptamani ? saptamani.reduce((sum, week) => sum + (week.sumaTotala || 0), 0) : 0;
                  return (
                    <StyledTableRow key={luna}>
                      <TableCell>{format(new Date(anSelectat, luna - 1), 'LLLL')}</TableCell>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <TableCell key={index}>
                          {saptamani && saptamani[index] && saptamani[index].sumaTotala
                            ? saptamani[index].sumaTotala.toLocaleString()
                            : 0} RON
                        </TableCell>
                      ))}
                      <StyledFooterCell>{totalMonthSuma.toLocaleString()} RON</StyledFooterCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};

export default ScadentarClienti;
