import React, { useState, useRef } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Popover,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

function Filters({ data, onFilterChange }) {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCondition, setSelectedCondition] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedAvailability, setSelectedAvailability] = useState('');

  const categories = [...new Set(data.map((product) => product.Category))];
  const brands = [...new Set(data.filter(product => !selectedCategory || product.Category === selectedCategory).map((product) => product.Brand))];
  const conditions = [...new Set(data.filter(product => !selectedCategory || product.Category === selectedCategory).map((product) => product.Condition))];
  const models = [...new Set(data.filter(product => !selectedCategory || product.Category === selectedCategory).map((product) => product.Model))];
  const availabilityOptions = [...new Set(data.filter(product => !selectedCategory || product.Category === selectedCategory).map((product) => product.Availability))];

  const anchorEl = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

const applyFilters = () => {
  onFilterChange({
    category: selectedCategory,
    brand: selectedBrand,
    condition: selectedCondition,
    model: selectedModel,
    availability: selectedAvailability, 
  });
  handlePopoverClose();
};

const resetFilters = () => {
    setSelectedCategory('');
    setSelectedBrand('');
    setSelectedCondition('');
    setSelectedModel('');
    setSelectedAvailability('');
    onFilterChange({});
};

  return (
    <div>

    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
        <Button
        ref={anchorEl}
        variant="contained"
        color="primary"
        startIcon={<FilterListIcon />}
        onClick={handlePopoverOpen}
      >
        Filtre
      </Button>
        <IconButton onClick={resetFilters} color="primary">
            <RefreshIcon />
        </IconButton>
    </div>

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '16px', width: '300px' }}>
          <Typography variant="h6">Filtre</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Categorie</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(event) => setSelectedCategory(event.target.value)}
                  label="Categorie"
                >
                  <MenuItem value="">
                    <em>Toate categoriile</em>
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Brand</InputLabel>
                <Select
                  value={selectedBrand}
                  onChange={(event) => setSelectedBrand(event.target.value)}
                  label="Brand"
                >
                  <MenuItem value="">
                    <em>Toate brandurile</em>
                  </MenuItem>
                  {brands.map((brand) => (
                    <MenuItem key={brand} value={brand}>
                      {brand}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Condiție</InputLabel>
                <Select
                  value={selectedCondition}
                  onChange={(event) => setSelectedCondition(event.target.value)}
                  label="Condiție"
                >
                  <MenuItem value="">
                    <em>Toate condițiile</em>
                  </MenuItem>
                  {conditions.map((condition) => (
                    <MenuItem key={condition} value={condition}>
                      {condition}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Model</InputLabel>
                <Select
                  value={selectedModel}
                  onChange={(event) => setSelectedModel(event.target.value)}
                  label="Model"
                >
                  <MenuItem value="">
                    <em>Toate modelele</em>
                  </MenuItem>
                  {models.map((model) => (
                    <MenuItem key={model} value={model}>
                      {model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

        <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Disponibilitate</InputLabel>
                <Select
                  value={selectedAvailability}
                  onChange={(event) => setSelectedAvailability(event.target.value)}
                  label="Disponibilitate"
                >
                  <MenuItem value="">
                    <em>Toate</em>
                  </MenuItem>
                  {availabilityOptions.map((availability) => (
                    <MenuItem key={availability} value={availability}>
                      {availability}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="secondary" onClick={applyFilters}>
                Aplică filtre
              </Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}

export default Filters;
