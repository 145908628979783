import React from 'react';
import { Typography, Grid, Button, Box } from '@mui/material';
import ProductCard from './ProductCard';

function ProductList({ products, onDelete, onProductsValidated, onCheckboxChange }) {
  return (
    <div>
      <Typography variant="h6" gutterBottom>Lista de Produse</Typography>
      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
            <ProductCard 
              product={product} 
              onDelete={onDelete} 
              id={product.id} 
              onCheckboxChange={onCheckboxChange} 
            />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" mt={3}>
        <Button variant="contained" color="primary" onClick={onProductsValidated}>
          Validează produsele selectate
        </Button>
      </Box>
    </div>
  );
}

export default ProductList;
