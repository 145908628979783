import React, { useEffect,useState } from 'react';
import { Box, TextField, Typography, Grid, FormControl, Select, MenuItem, InputLabel, Button } from '@mui/material';
import axios from 'axios';

const CreareContractForm = ({ contractData, handleInputChange }) => {
  const numeTitularCont = `${contractData.nume} ${contractData.prenume}`.trim();
  const [message, setMessage] = useState(''); // Mesajul de succes sau eroare
  const [isSubmitting, setIsSubmitting] = useState(false); // Starea butonului Trimite
  const [localContCurent, setLocalContCurent] = useState(contractData.contCurent || ''); // Stat local pentru IBAN
  const [localNumeTitularCont, setLocalNumeTitularCont] = useState(`${contractData.nume} ${contractData.prenume}`.trim() || ''); // Stat local pentru numele titularului de cont
  const [localBanca, setLocalBanca] = useState(contractData.banca || ''); // Stat local pentru banca

  // Sincronizăm stările locale cu `contractData` atunci când se schimbă extern
  useEffect(() => {
    setLocalContCurent(contractData.contCurent || '');
    setLocalNumeTitularCont(`${contractData.nume} ${contractData.prenume}`.trim() || '');
    setLocalBanca(contractData.banca || '');
  }, [contractData.contCurent, contractData.nume, contractData.prenume, contractData.banca]);

  // Funcțiile de handle pentru câmpurile locale
  const handleContCurentChange = (event) => {
    const { value } = event.target;
    setLocalContCurent(value);
    handleInputChange({ target: { name: 'contCurent', value } });
  };

  const handleNumeTitularContChange = (event) => {
    const { value } = event.target;
    setLocalNumeTitularCont(value);
    handleInputChange({ target: { name: 'numeTitularCont', value } });
  };

  const handleBancaChange = (event) => {
    const { value } = event.target;
    setLocalBanca(value);
    handleInputChange({ target: { name: 'banca', value } });
  };

  // Condiții pentru ascunderea câmpurilor
  const isLP001 = contractData.tipContract && contractData.tipContract.includes("LP001");
  const isLP002 = contractData.tipContract && contractData.tipContract.includes("LP002");
  const isLP003 = contractData.tipContract && contractData.tipContract.includes("LP003");

  // Funcție pentru calcularea datei finale, ajustată pentru weekend
  const calculateEndDate = (months) => {
    const dataContract = new Date(contractData.dataContract);
    if (!isNaN(dataContract) && months) {
      let endDate = new Date(dataContract);
      endDate.setMonth(dataContract.getMonth() + months);

      // Verificăm dacă data expirării cade într-un weekend
      const dayOfWeek = endDate.getDay();
      if (dayOfWeek === 6) {
        // Sâmbătă -> mutăm la luni
        endDate.setDate(endDate.getDate() + 2);
      } else if (dayOfWeek === 0) {
        // Duminică -> mutăm la luni
        endDate.setDate(endDate.getDate() + 1);
      }

      return endDate.toISOString().split("T")[0]; // Formatăm în yyyy-mm-dd
    }
    return "";
  };

  // Handle select change for consignatiePerioada
  const handleConsignatiePerioadaChange = (event) => {
    const days = parseInt(event.target.value, 10);
    const months = days / 30; // Convertim zilele în luni întregi
    const newEndDate = calculateEndDate(months);
    handleInputChange(event); // Actualizăm "consignatiePerioada"
    handleInputChange({ target: { name: "panaCand", value: newEndDate } }); // Actualizăm "Pana cand"
  };

// Funcția pentru trimiterea datelor la backend
  const handleSubmit = async () => {
    setIsSubmitting(true); // Dezactivează butonul în timpul trimiterii
    setMessage(''); // Resetează mesajul anterior
    // Construim payload-ul JSON
    const payload = {
      documentName: `${contractData.tipContract}_${contractData.nume}_${contractData.prenume}_${contractData.numarContract}`,
      recipient1Email: contractData.email,
      recipient1Name: `${contractData.nume} ${contractData.prenume}`,
      recipientPhone: contractData.telefon,
      Tip_Contract: contractData.tipContract,  // Asigură-te că acest câmp este inclus
      smartFieldsData: [
        { nume: contractData.nume },
        { prenume: contractData.prenume },
        { telefon: contractData.telefon },
        { email: contractData.email },
        { adresa: contractData.adresa },
        { oras: contractData.oras },
        { judet: contractData.judet },
        { serieCI: contractData.serieCI },
        { nrCI: contractData.nrCI },
        { eliberatDe: contractData.eliberatDe },
        { eliberatLaData: contractData.eliberatLaData },
        { nrContract: contractData.numarContract },
        { dataContract: contractData.dataContract },
        { panaCand: contractData.panaCand },
        { consignatiePerioada: contractData.consignatiePerioada },
        { tipContract: contractData.tipContract },
        { banca: localBanca },
        { contCurent: localContCurent },
        { numeTitularCont: localNumeTitularCont },
        { pretFinal: contractData.pretCuvenit },
        { codVoucher: contractData.codVoucher },
        { dataActivareVoucher: contractData.dataActivareVoucher },
        { dataDePlata: contractData.dataDePlata },
        { pretFinalVanzare: contractData.pretFinalVanzare },
        { dataPlataFinalVanzare: contractData.dataPlataFinalVanzare },
        { um: contractData.um },
        { categoria: contractData.categoria },
        { brand: contractData.brand },
        { model: contractData.model },
        { codInteriorProdus: contractData.codInteriorProdus },
        { checkboxOptions: contractData.checkboxOptions },
        { detinFactura: contractData.detinFactura },
        { nrFactura: contractData.nrFactura },
        { detinCertificat: contractData.detinCertificat },
        { alteClauze: contractData.alteClauze },
        { consignatar: 'Mirela Bucurei'},
        { serieCertificat: contractData.serieCertificat }
      ]
    };

    try {
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/contracts/signnow/workflow', payload);
      setMessage(response.data.message); // Afișăm mesajul de succes din backend
    } catch (error) {
      setMessage(error.response?.data?.message || 'Eroare la trimiterea contractului'); // Afișăm mesajul de eroare
    } finally {
      setIsSubmitting(false); // Re-activăm butonul după ce am primit răspunsul
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      
      {/* Date Vanzator Section */}
      <Typography variant="h6">Date Vanzator</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField label="Nume" name="nume" variant="outlined" fullWidth value={contractData.nume} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Prenume" name="prenume" variant="outlined" fullWidth value={contractData.prenume} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Telefon" name="telefon" variant="outlined" fullWidth value={contractData.telefon} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Email" name="email" variant="outlined" fullWidth value={contractData.email} onChange={handleInputChange} />
        </Grid>
      </Grid>
      <Box sx={{ border: '1px solid #1E90FF', padding: 2, borderRadius: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label="Adresa" name="adresa" variant="outlined" fullWidth value={contractData.adresa} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Oras" name="oras" variant="outlined" fullWidth value={contractData.oras} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Judet" name="judet" variant="outlined" fullWidth value={contractData.judet} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="CI Seria" name="serieCI" variant="outlined" fullWidth value={contractData.serieCI} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="CI Numar" name="nrCI" variant="outlined" fullWidth value={contractData.nrCI} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Eliberat de" name="eliberatDe" variant="outlined" fullWidth value={contractData.eliberatDe} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Eliberat la data" name="eliberatLaData" type="date" InputLabelProps={{ shrink: true }} variant="outlined" fullWidth value={contractData.eliberatLaData} onChange={handleInputChange} />
          </Grid>
        </Grid>
      </Box>
      
     {/* Date Contract Section */}
      <Typography variant="h6">Date Contract</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label="Numar Contract" name="numarContract" variant="outlined" fullWidth value={contractData.numarContract} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Data Contract" name="dataContract" type="date" InputLabelProps={{ shrink: true }} variant="outlined" fullWidth value={contractData.dataContract} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Pana cand" name="panaCand" type="date" InputLabelProps={{ shrink: true }} variant="outlined" fullWidth helperText="Ultima zi cand expira contractul sau prelungim" value={contractData.panaCand} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Consignatie perioada</InputLabel>
            <Select
              name="consignatiePerioada"
              value={contractData.consignatiePerioada || ''}
              onChange={handleConsignatiePerioadaChange}
              label="Consignatie perioada"
            >
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={60}>60</MenuItem>
              <MenuItem value={90}>90</MenuItem>
              <MenuItem value={180}>180</MenuItem>
              <MenuItem value={270}>270</MenuItem>
              <MenuItem value={360}>360</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField label="TIP Contract" name="tipContract" variant="outlined" fullWidth value={contractData.tipContract} onChange={handleInputChange} />
        </Grid>
      </Grid>
  
      {/* Detalii Plata Section */}
      <Typography variant="h6">Detalii Plata</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Banca"
            name="banca"
            variant="outlined"
            fullWidth
            value={localBanca || ''}
            onChange={handleBancaChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Cont Curent (IBAN)"
            name="contCurent"
            variant="outlined"
            fullWidth
            value={localContCurent} // Folosim valoarea locală
            onChange={handleContCurentChange} // Actualizare locală
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Numele titularului de cont"
            name="numeTitularCont"
            variant="outlined"
            fullWidth
            value={localNumeTitularCont}
            onChange={handleNumeTitularContChange}
          />
        </Grid>
        {!isLP003 && (
        <Grid item xs={6}>
          <TextField
            label="Prețul cuvenit consignantului"
            name="pretCuvenit"
            variant="outlined"
            fullWidth
            value={contractData.pretCuvenit}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: <span style={{ color: 'green', fontWeight: 'bold' }}>RON</span>,
              style: { color: 'green', fontWeight: 'bold' },
            }}
          />
        </Grid>

        )}
        {!isLP001 && !isLP002 && (
          <>
            <Grid item xs={3}>
              <TextField
                label="Cod Voucher"
                name="codVoucher"
                variant="outlined"
                fullWidth
                value={contractData.codVoucher || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Data activarii voucher"
                name="dataActivareVoucher"
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                value={contractData.dataActivareVoucher || ''}
                onChange={handleInputChange}
              />
            </Grid>
          </>
        )}
        {!isLP001 && !isLP002 && (
          <Grid item xs={12}>
            <TextField
              label="Data de Plata"
              name="dataDePlata"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              value={contractData.dataDePlata || ''}
              onChange={handleInputChange}
            />
          </Grid>
        )}

        {/* Chenar Verde - Prețul și Data Plății la Final */}
        {!isLP001 && (
          <Grid item xs={12}>
            <Box sx={{ border: '2px solid green', padding: 2, borderRadius: 2, mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Prețul la final - Vanzare"
                    name="pretFinalVanzare"
                    variant="outlined"
                    fullWidth
                    value={contractData.pretFinalVanzare || ''}
                    onChange={handleInputChange}
                    sx={{ color: 'green' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Data platii la final - Vanzare"
                    name="dataPlataFinalVanzare"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    value={contractData.dataPlataFinalVanzare || ''}
                    onChange={handleInputChange}
                    sx={{ color: 'green' }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
  
      {/* Detalii Produs Section */}
      <Typography variant="h6">Detalii Produs</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField label="U/M" name="um" variant="outlined" fullWidth required value={contractData.um || '1 BUC'} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Categoria" name="categoria" variant="outlined" fullWidth value={contractData.categoria || ''} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Brand" name="brand" variant="outlined" fullWidth value={contractData.brand || ''} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Model" name="model" variant="outlined" fullWidth value={contractData.model} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Cod interior produs" name="codInteriorProdus" variant="outlined" fullWidth required value={contractData.codInteriorProdus} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField label="Checkbox Options" name="checkboxOptions" variant="outlined" fullWidth value={contractData.checkboxOptions || ''} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Detin Factura / Bon</InputLabel>
            <Select
              name="detinFactura"
              value={contractData.detinFactura || ''}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value !== "Vine insotita de factura in original aferenta achizitiei") {
                  handleInputChange({ target: { name: "nrFactura", value: '' } });
                }
              }}
              label="Detin Factura / Bon"
            >
              <MenuItem value="Vine insotita de factura in original aferenta achizitiei">Vine insotita de factura in original aferenta achizitiei</MenuItem>
              <MenuItem value="Nu vine insotit de factura">Nu vine insotit de factura</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {contractData.detinFactura === "Vine insotita de factura in original aferenta achizitiei" && (
          <Grid item xs={6}>
            <TextField
              label="Nr Factura"
              name="nrFactura"
              variant="outlined"
              fullWidth
              value={contractData.nrFactura}
              onChange={handleInputChange}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Detin Certificat / Card</InputLabel>
            <Select
              name="detinCertificat"
              value={contractData.detinCertificat || ''}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value !== "DA") {
                  handleInputChange({ target: { name: "serieCertificat", value: '' } });
                }
              }}
              label="Detin Certificat / Card"
            >
              <MenuItem value="DA">DA</MenuItem>
              <MenuItem value="NU">NU</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {contractData.detinCertificat === "DA" && (
          <Grid item xs={6}>
            <TextField
              label="Serie Certificat / Card"
              name="serieCertificat"
              variant="outlined"
              fullWidth
              value={contractData.serieCertificat}
              onChange={handleInputChange}
            />
          </Grid>
        )}
      </Grid>
      
      {/* Detalii Alte clauze Section */}
      <Typography variant="h6">Alte Clauze</Typography>
      <Grid container spacing={1} sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <TextField
            label="Alte Clauze"
            name="alteClauze"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4} // sau mai multe rânduri, după preferință
            value={contractData.alteClauze || ''}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      {/* Afișarea mesajului de succes sau eroare */}
      {message && (
        <Typography variant="body1" color={message.startsWith('SUCCES') ? 'green' : 'red'}>
          {message}
        </Typography>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitting} // Dezactivăm butonul în timpul trimiterii
      >
        Trimite Contract
      </Button>
    </Box>
    
  );
};

export default CreareContractForm;
