import React, { useState, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextField, Box, Button, Typography, Tabs, Tab, Alert, AlertTitle } from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DateTime } from 'luxon';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as XLSX from 'xlsx';

const fetchStripeTransactions = async (startDate, endDate) => {
    try {
        const url = new URL('https://server-9p4hx.kinsta.app/api/deconturi/group-by-stripe');
        if (startDate && endDate) {
            url.searchParams.append('startDate', startDate.toISODate());
            url.searchParams.append('endDate', endDate.toISODate());
        }

        const response = await axios.get(url.toString());
        return Object.values(response.data).flat();
    } catch (error) {
        console.error('Error fetching transactions:', error);
        return [];
    }
};

const fetchEuPlatescTransactions = async (startDate, endDate) => {
    try {
        const url = new URL('https://server-9p4hx.kinsta.app/api/deconturi/group-by-euplatesc/');
        if (startDate && endDate) {
            url.searchParams.append('startDate', startDate.toISODate());
            url.searchParams.append('endDate', endDate.toISODate());
        }

        const response = await axios.get(url.toString());
        return Object.values(response.data).flat();
    } catch (error) {
        console.error('Error fetching EuPlatesc transactions:', error);
        return [];
    }
};

const aggregateStripeData = (transactions) => {
    const groupedByTransfer = {};
    transactions.forEach((transaction) => {
        const key = transaction.Transfer || "No Transfer Data";
        if (!groupedByTransfer[key]) {
            groupedByTransfer[key] = {
                transactions: [],
                summary: {
                    id: "Total",
                    Transfer: key,
                    TotalAmount: 0,
                    TotalFee: 0,
                    PayoutAmount: 0,
                    Available_On_UTC: transaction.Available_On_UTC,
                    isSummary: true
                }
            };
        }
        
        // Creăm o copie a tranzacției și eliminăm PayoutAmount pentru tranzacțiile individuale
        const transactionCopy = { 
            ...transaction, 
            isSummary: false, 
            first_name: transaction.customer_invoice?.first_name || "Unknown",
            last_name: transaction.customer_invoice?.last_name || "Unknown",
            _ywpi_invoice_formatted_number: transaction.customer_invoice?._ywpi_invoice_formatted_number || "No Invoice"
        };
        
        delete transactionCopy.PayoutAmount;  // Eliminăm PayoutAmount din tranzacția individuală

        // Formatăm Amount cu două zecimale
        transactionCopy.Amount = transactionCopy.Amount ? parseFloat(transactionCopy.Amount).toFixed(2) : '0.00';

        groupedByTransfer[key].transactions.push(transactionCopy);

        // Calculăm TotalAmount și TotalFee
        let amount = parseFloat(transaction.Amount);
        let fee = parseFloat(transaction.Fee);
        if (!isNaN(amount)) {
            groupedByTransfer[key].summary.TotalAmount += (transaction.Type === "charge" || transaction.Type === "refund") ? amount : 0;
            groupedByTransfer[key].summary.TotalFee += !isNaN(fee) ? fee : 0;
        }
        if (!isNaN(amount) && transaction.Type === "payout") {
            groupedByTransfer[key].summary.PayoutAmount += Math.abs(amount);
        }
    });

    return Object.values(groupedByTransfer).reduce((acc, group) => [
        ...acc,
        ...group.transactions,
        group.summary
    ], []);
};




const aggregateEuPlatescData = (transactions) => {
    const groupedByFactura = {};
    transactions.forEach((transaction) => {
        const facturaKey = transaction.FacturaEuPlatesc || 'No Factura';

        if (!groupedByFactura[facturaKey]) {
            groupedByFactura[facturaKey] = {
                transactions: [],
                summary: {
                    FacturaEuPlatesc: facturaKey,
                    TotalSuma: 0,
                    TotalSumaRefund: 0,
                    TotalMinim1RON: 0,
                    TotalDiff1RON: 0,
                    LatestDate: new Date(0) // Initialize with a very old date
                }
            };
        }

        const transactionDate = new Date(transaction.DataIncasarii);
        if (transactionDate > groupedByFactura[facturaKey].summary.LatestDate) {
            groupedByFactura[facturaKey].summary.LatestDate = transactionDate;
        }

        groupedByFactura[facturaKey].transactions.push({
            ...transaction,
            first_name: transaction.customer_invoice?.first_name || "Unknown",
            last_name: transaction.customer_invoice?.last_name || "Unknown",
            _ywpi_invoice_formatted_number: transaction.customer_invoice?._ywpi_invoice_formatted_number || "No Invoice"
        });

        groupedByFactura[facturaKey].summary.TotalSuma += parseFloat(transaction.Suma);
        groupedByFactura[facturaKey].summary.TotalSumaRefund += parseFloat(transaction.SumaRefund || 0);
        groupedByFactura[facturaKey].summary.TotalMinim1RON += parseFloat(transaction.Minim1RON);
        groupedByFactura[facturaKey].summary.TotalDiff1RON += parseFloat(transaction.Diff1RON);
    });

    return Object.values(groupedByFactura).map(group => {
        const totalComision = (group.summary.TotalMinim1RON + group.summary.TotalDiff1RON) * 1.19;
        const payoutIncasat = group.summary.TotalSuma - totalComision;

        // Add three days to the latest date
        group.summary.LatestDate.setDate(group.summary.LatestDate.getDate() + 2);
        
        // Check if the new date falls on a weekend
        const dayOfWeek = group.summary.LatestDate.getDay();
        if (dayOfWeek === 6) { // Saturday
            group.summary.LatestDate.setDate(group.summary.LatestDate.getDate() + 2); // Move to Monday
        } else if (dayOfWeek === 0) { // Sunday
            group.summary.LatestDate.setDate(group.summary.LatestDate.getDate() + 1); // Move to Monday 
        }

        // Asigură-te că data este formatată corespunzător pentru exportul PDF
        const formattedDate = group.summary.LatestDate.toISOString().substring(0, 10); // Format YYYY-MM-DD
        
        return {
            ...group,
            summary: {
                FacturaEuPlatesc: group.summary.FacturaEuPlatesc,
                TotalBrut: group.summary.TotalSuma.toFixed(2),
                Data: formattedDate, // Format YYYY-MM-DD
                TotalComision: totalComision.toFixed(2),
                PayoutIncasat: payoutIncasat.toFixed(2),
            }
        };
    });
};

const generateExcel = (transactions) => {
    // Prepare data for Excel
    const data = transactions.map(transaction => ({
        'Factura Stripe': transaction.isSummary ? 'Total' : transaction.Transfer,
        'Suma Bruta': transaction.Amount ? Math.round(parseFloat(transaction.Amount)) : 'N/A',
        'Data': transaction.isSummary ? transaction.Available_On_UTC : '',
        'Nr. Comanda': transaction.Order_ID_Metadata,
        'Factura Luxura': transaction._ywpi_invoice_formatted_number,
        'Prenume': transaction.first_name,
        'Nume': transaction.last_name,
        'Total Brut': typeof transaction.TotalAmount === 'number' ? transaction.TotalAmount.toFixed(2) : '',
        'Total Comision': typeof transaction.TotalFee === 'number' ? transaction.TotalFee.toFixed(2) : '',
        'Total Incasat': typeof transaction.PayoutAmount === 'number' ? transaction.PayoutAmount.toFixed(2) : ''
    }));

    // Create a worksheet and add the data
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stripe Transactions");

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, "Stripe_Transactions.xlsx");
};

const TransactionsPage = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [tabValue, setTabValue] = useState(1);  // Activating EuPlatesc tab by default
    const [euPlatescTransactions, setEuPlatescTransactions] = useState([]);
    const [isSyncing, setIsSyncing] = useState(false);
    const [tbiTransactions, setTbiTransactions] = useState([]);
    const [cargusTransactions, setCargusTransactions] = useState({});

    const StyledButton = ({ onClick, children }) => (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            startIcon={<RefreshIcon />}
            sx={{
                mt: 2,
                mb: 2,
                bgcolor: 'secondary.main', // culoare custom sau folosește o culoare din tema
                '&:hover': {
                    bgcolor: 'secondary.dark', // întunecare la hover,,,
                },
                width: '100%', // face butonul să se întindă pe toată lățimea containerului său
                boxShadow: 3, // adaugă o umbră pentru un efect 3D
            }}
        >
            {children}
        </Button>
    );

    const syncStripeTransactions = async () => {
        try {
            const response = await axios.get('https://server-9p4hx.kinsta.app/api/deconturi/sync-stripe-transactions');
            console.log('Sincronizare completă:', response.data);
            // Opțional: Reîncărcați tranzacțiile dacă este necesar sau afișați un mesaj de succes 
            alert('Sincronizare completă!');
        } catch (error) {
            console.error('Eroare la sincronizarea datelor Stripe:', error);
            alert('Eroare la sincronizare!');
        }
    };

    const syncEuPlatescTransactions = async () => {
        try {
            const response = await axios.get('https://server-9p4hx.kinsta.app/api/deconturi/sync-euplatesc-transactions');
            console.log('Sincronizare completă:', response.data);
            // Opțional: Reîncărcați tranzacțiile dacă este necesar sau afișați un mesaj de succes
            alert('Sincronizare completă!');
        } catch (error) {
            console.error('Eroare la sincronizarea datelor EuPlatesc:', error);
            alert('Eroare la sincronizare!');
        }
    };

    useEffect(() => {
        if (tabValue === 0 && startDate && endDate) {
            const loadData = async () => {
                const fetchedData = await fetchStripeTransactions(startDate, endDate);
                console.log('Stripe transactions fetched:', fetchedData);
                const dataWithSummaries = aggregateStripeData(fetchedData);
                console.log('Stripe data aggregated:', dataWithSummaries);
                setTransactions(dataWithSummaries);
            };
            loadData();

        } else if (tabValue === 1 && startDate && endDate) {
            const loadData = async () => {
                const fetchedData = await fetchEuPlatescTransactions(startDate, endDate);
                console.log('EuPlatesc transactions fetched:', fetchedData);
                const dataWithSummaries = aggregateEuPlatescData(fetchedData);
                console.log('EuPlatesc data aggregated:', dataWithSummaries);
                setEuPlatescTransactions(dataWithSummaries);
            };
            loadData();
        }
    }, [startDate, endDate, tabValue]);

    const handleTabChange = (event, newValue) => {
            setTabValue(newValue);
    };

    //REINCARCA PRODUSELE DIN WOO IN BAZA BO DIN KINSTA
    const handleSyncProducts = () => {
            setIsSyncing(true); // Începe sincronizarea
            fetch('https://server-9p4hx.kinsta.app/update-woocommerce-orders')
                .then(response => response.json())
                .then(data => {
                    alert(data.message); // Afișează mesajul de succes
                })
                .catch(error => {
                    console.error('Eroare la sincronizarea produselor:', error);
                    alert('Eroare la sincronizarea produselor.');
                })
                .finally(() => setIsSyncing(false)); // Oprește sincronizarea indiferent de rezultat
    };

    //Generare PDF Stipe, EuPlatesc, TBIBank, .....
const generatePdf = () => {
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a3'
    });

    // Convert date using Luxon, checking if they exist and are valid
    const startDateFormatted = startDate ? DateTime.fromJSDate(new Date(startDate)).toFormat('dd/LL/yyyy') : 'N/A';
    const endDateFormatted = endDate ? DateTime.fromJSDate(new Date(endDate)).toFormat('dd/LL/yyyy') : 'N/A';
    const dateRange = `De la: ${startDateFormatted} la: ${endDateFormatted}`;

    doc.setFontSize(12);
    doc.text(dateRange, 14, 20);

    // Define columns and data based on the selected tab
    let columns = [];
    let data = [];

    // Define different headers and data setup for each tab
    if (tabValue === 0) {  // Stripe transactions
        columns = [
            { header: 'Factura Stripe', dataKey: 'factura' },
            { header: 'Suma Bruta', dataKey: 'sumaBruta' },
            { header: 'Data', dataKey: 'data' },
            { header: 'Nr. Comanda', dataKey: 'nrComanda' },
            { header: 'Factura Luxura', dataKey: 'facturaLuxura' },
            { header: 'Prenume', dataKey: 'prenume' },
            { header: 'Nume', dataKey: 'nume' },
            { header: 'Total Brut', dataKey: 'totalBrut' },
            { header: 'Total Comision', dataKey: 'totalComision' },
            { header: 'Total Incasat', dataKey: 'totalIncasat' }
        ];
        data = transactions.map(transaction => ({
            factura: transaction.isSummary ? 'Total' : transaction.Transfer,
            sumaBruta: transaction.Amount ? Math.round(parseFloat(transaction.Amount)) : 'N/A',
            data: transaction.isSummary ? transaction.Available_On_UTC : '',
            nrComanda: transaction.Order_ID_Metadata,
            facturaLuxura: transaction._ywpi_invoice_formatted_number,
            prenume: transaction.first_name,
            nume: transaction.last_name,
            totalBrut: typeof transaction.TotalAmount === 'number' ? transaction.TotalAmount.toFixed(2) : '',
            totalComision: typeof transaction.TotalFee === 'number' ? transaction.TotalFee.toFixed(2) : '',
            totalIncasat: typeof transaction.PayoutAmount === 'number' ? transaction.PayoutAmount.toFixed(2) : ''
        }));
    } else if (tabValue === 3) {  // Cargus transactions
        columns = [
            { header: 'Factura Cargus', dataKey: 'facturaCargus' },
            { header: 'Suma Declarata', dataKey: 'sumaDeclarata' },
            { header: 'Data', dataKey: 'data' },
            { header: 'Nr. Serie', dataKey: 'nrSerie' },
            { header: 'AWB', dataKey: 'awb' },
            { header: 'ID Comanda', dataKey: 'idComanda' },
            { header: 'Factura', dataKey: 'factura' },
            { header: 'Prenume', dataKey: 'prenume' },
            { header: 'Nume', dataKey: 'nume' },
            { header: 'Subtotal', dataKey: 'subtotal' }
        ];
        data = [];
        Object.values(cargusTransactions).forEach(group => {
            group.entries.forEach(entry => {
                data.push({
                    facturaCargus: group.SerieRaspuns,
                    sumaDeclarata: entry.ValoareDeclarata,
                    data: new Date(entry.Data).toLocaleDateString(),
                    nrSerie: entry.NrSerie,
                    awb: entry.sales.length > 0 ? entry.sales[0].AWB : 'N/A',
                    idComanda: entry.sales.length > 0 ? entry.sales[0].ID : 'N/A',
                    factura: entry.sales.length > 0 ? entry.sales[0]._ywpi_invoice_formatted_number : 'N/A',
                    prenume: entry.sales.length > 0 ? entry.sales[0].first_name : 'N/A',
                    nume: entry.sales.length > 0 ? entry.sales[0].last_name : 'N/A',
                    subtotal: entry.sales.length > 0 ? entry.sales[0].subtotal : '0'
                });
            });
        });
    }
    // Use jsPDF to format and generate the table
    autoTable(doc, {
        columns: columns,
        body: data,
        theme: 'grid',
        startY: 30,
        styles: { fontSize: 10, cellPadding: 2 },
        columnStyles: { text: { cellWidth: 'auto' } },
        headStyles: { fillColor: [22, 160, 133] }, // Styling header background
        didParseCell: function(data) {
            if (data.row.raw.isSummary) {
                data.cell.styles.fillColor = [230, 230, 250]; // Apply a pastel blue (Lavender)
            }
        }
    });

    doc.save('transactions.pdf');
};


    //Citeste datele din tabela Kinsta pentru TBIBank
    const fetchTbiTransactions = async (startDate, endDate) => {
        try {
            const response = await axios.get('https://server-9p4hx.kinsta.app/api/deconturi/get-filtered-sales-tbi', {
                params: { startdate: startDate.toISODate(), enddate: endDate.toISODate() }
            });
            const filteredData = response.data.flatMap(sale => {
                const entries = [sale]; // Include întotdeauna intrarea originală
                if (sale.STATUS === "refunded" && sale.refundInfo) {
                    const refundDate = new Date(sale.refundInfo.data_cerere_storno);
                    if (refundDate >= startDate && refundDate <= endDate) { // Verificăm dacă data refundului este în interval
                        entries.push({
                            //Extragem datele necesare dn partea de refund
                            ...sale,
                            total: sale.refundInfo.refund_total,
                            date_created: sale.refundInfo.data_cerere_storno,
                            _ywpi_invoice_formatted_number: sale.refundInfo.attachments,
                            refundEntry: true // Marchează ca o intrare de refund pentru a ajusta stilul dacă este necesar
                        });
                    }
                }
                return entries;
            });
            setTbiTransactions(filteredData);
        } catch (error) {
            console.error('Error fetching TBI transactions:', error);
            setTbiTransactions([]);
        }
    };

    useEffect(() => {
        if (tabValue === 2 && startDate && endDate) {
            fetchTbiTransactions(startDate, endDate);
        }
    }, [startDate, endDate, tabValue]);

    console.log('Transactions for the current tab:', tabValue === 0 ? transactions : euPlatescTransactions);
    

    const fetchCargusTransactions = async (startDate, endDate) => {
        try {
            const url = new URL('https://server-9p4hx.kinsta.app/api/deconturi/grouped-cargus-serie-sales');
            if (startDate && endDate) {
                url.searchParams.append('startDate', startDate.toISODate());
                url.searchParams.append('endDate', endDate.toISODate());
            }

            const response = await axios.get(url.toString());
            return response.data; // Presupunem că răspunsul vine sub forma unui obiect cu chei după FacturaCargus
        } catch (error) {
            console.error('Error fetching Cargus transactions:', error);
            return {};
        }
    };

useEffect(() => {
    if (tabValue === 3 && startDate && endDate) {
        const loadData = async () => {
            console.log("Fetching Cargus transactions...");
            const fetchedData = await fetchCargusTransactions(startDate, endDate);
            console.log("Fetched data:", fetchedData);

            if (fetchedData && fetchedData.groupedCargusData) {
                console.log("Processing valid data...");

                const rambursatData = Object.entries(fetchedData.groupedCargusData).reduce((acc, [facturaCargus, data]) => {
                    console.log(`Processing factura ${facturaCargus}:`, data);
                    const filteredEntries = data.entries.filter(entry => entry.Stadiu === "Rambursat" && entry.sales.length > 0);
                    console.log(`Filtered entries for ${facturaCargus}:`, filteredEntries);

                    if (filteredEntries.length > 0) {
                        acc[facturaCargus] = { ...data, entries: filteredEntries };
                    }

                    return acc;
                }, {});

                console.log("Rambursat data prepared:", rambursatData);
                setCargusTransactions(rambursatData);
            } else {
                console.log("No valid data received or data is empty");
                setCargusTransactions({});
            }
        };
        loadData();
    }
}, [startDate, endDate, tabValue]);



    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Banci</Typography>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => {
                            setStartDate(newValue);
                            setTransactions([]); // Clear transactions when date changes
                            setEuPlatescTransactions([]); // Clear EuPlatesc transactions as well
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => {
                            setEndDate(newValue);
                            setTransactions([]); // Clear transactions when date changes
                            setEuPlatescTransactions([]); // Clear EuPlatesc transactions as well
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ ml: 2 }} />}
                    />
                    <Button variant="outlined" sx={{ ml: 2 }} onClick={() => generateExcel(transactions)}>
                        Export to Excel
                    </Button>

                    <Button variant="outlined" sx={{ ml: 2 }} onClick={() => generatePdf(tabValue === 0 ? transactions : euPlatescTransactions)}>
                        Export to PDF
                    </Button>
                    <Button
                        sx={{ ml: 2 }}
                        variant="outlined" 
                        color="primary" 
                        onClick={handleSyncProducts}
                        startIcon={<RefreshIcon />}
                        style={{ margin: 'auto' }}
                        disabled={isSyncing}  // Dezactivează butonul pe durata sincronizării
                    >
                        {isSyncing ? 'Sincronizare...' : 'Reîncarcă comenzi'}
                </Button>
                </Box>
            </LocalizationProvider>

            <Tabs value={tabValue} onChange={handleTabChange} aria-label="transaction data tabs">
                <Tab label="Stripe" />
                <Tab label="EuPlatesc" />
                <Tab label="TBI" />
                <Tab label="Cargus" />
                <Tab label="FanCourier" disabled />
                <Tab label="GLS" disabled />
            </Tabs>

            {/* Componenta tabelului Stripe */}
            {tabValue === 0 && (
                <Box sx={{ width: '100%', mt: 2 }}>
                <StyledButton variant="contained" color="primary" onClick={syncStripeTransactions} startIcon={<RefreshIcon />} sx={{ mb: 2 }}>
                    Reîncarcă Stripe
                </StyledButton>
                
                <Alert severity="info" sx={{ mb: 2 }}>
                    <AlertTitle>Stripe</AlertTitle>
                    Balances - All activity - Export - All Columns
                </Alert>

                <table style={{ width: '100%', marginTop: '20px', textAlign: 'right' }}>
                    <thead>

                        <tr>
                            <th>Factura Stripe</th>
                            <th>Suma Bruta</th>
                            <th>Data</th>
                            <th>Nr. Comanda</th>
                            <th>Factura Luxura</th>
                            <th>Prenume</th>
                            <th>Nume</th>
                            <th>Total Brut</th>
                            <th>Total Comision</th>
                            <th>Total Incasat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((row, index) => (
                            <tr key={index} style={{ backgroundColor: row.isSummary ? '#f0f0f0' : 'white' }}>
                                <td>{row.isSummary ? 'Total' : row.Transfer}</td>
                                <td>{row.isSummary ? row.TotalAmount.toFixed(2) : parseFloat(row.Amount).toFixed(2)}</td>
                                <td>{row.isSummary ? row.Available_On_UTC : ''}</td>
                                <td>{row.Order_ID_Metadata}</td>
                                <td>{row._ywpi_invoice_formatted_number}</td>
                                <td>{row.first_name}</td>
                                <td>{row.last_name}</td>
                                <td>{row.isSummary ? row.TotalAmount.toFixed(2) : ''}</td>
                                <td>{row.isSummary ? row.TotalFee.toFixed(2) : ''}</td>
                                <td>{row.isSummary ? row.PayoutAmount.toFixed(2) : ''}</td> {/* Afișează Total Incasat doar   pentru rezumat */}
                            </tr>
                        ))}
                    </tbody>

                </table>
            </Box>
            )}

            {/* Componenta tabelului EuPlatesc */}
            
            {tabValue === 1 && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <StyledButton variant="contained" color="primary" onClick={syncEuPlatescTransactions} startIcon={<RefreshIcon />} sx={{ mb: 2 }}>
                        Reîncarcă EuPlatesc
                    </StyledButton>
                    <table style={{ width: '100%', marginTop: '20px', textAlign: 'right' }}>
                        <thead>
                            <tr>
                                <th>Factura EuPlatesc</th>
                                <th>Suma Bruta</th>
                                <th>Data</th>
                                <th>Nr.Comanda</th>
                                <th>Factura Luxura</th>
                                <th>Prenume</th>
                                <th>Nume</th>
                                <th>Total Brut</th>
                                <th>Total Comision</th>
                                <th>Total Incasat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {euPlatescTransactions.map((group, index) => (
                                <React.Fragment key={index}>
                                    {group.transactions.map((transaction, transactionIndex) => (
                                        <tr key={transactionIndex}>
                                            <td>{transaction.FacturaEuPlatesc}</td>
                                            <td>{transaction.Suma.toFixed(2)}</td>
                                            <td>{new Date(transaction.DataIncasarii).toLocaleDateString()}</td>
                                            <td>{transaction.InvoiceId}</td>
                                            <td>{transaction._ywpi_invoice_formatted_number}</td>
                                            <td>{transaction.first_name}</td>
                                            <td>{transaction.last_name}</td>
                                            <td></td> {/* Gol pentru tranzacțiile individuale */}
                                            <td></td> {/* Gol pentru tranzacțiile individuale */}
                                            <td></td> {/* Gol pentru tranzacțiile individuale */}
                                        </tr>
                                    ))}
                                    <tr style={{ backgroundColor: '#f0f0f0' }}>
                                        <td>Total</td>
                                        <td>{group.summary.TotalSuma}</td>
                                        <td>{new Date(group.summary.Data).toLocaleDateString()}</td>
                                        <td colSpan={4}></td> {/* Ajustează acest colSpan conform numărului de coloane */}
                                        <td>{group.summary.TotalBrut}</td>
                                        <td>{group.summary.TotalComision}</td>
                                        <td>{group.summary.PayoutIncasat}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </Box>
            )}

            {/* Componenta tabelului TBIbank */}
            {tabValue === 2 && (
                <table style={{ width: '100%', marginTop: '20px', textAlign: 'right' }}>
                    <thead>
                        <tr>
                            <th>Suma Bruta</th>
                            <th>Data</th>
                            <th>Nr. Comanda</th>
                            <th>Factura Luxura</th>
                            <th>Prenume</th>
                            <th>Nume</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbiTransactions.map((transaction, index) => (
                            <tr key={index} style={{ backgroundColor: transaction.refundEntry ? '#ffcccc' : 'white' }}>
                                <td>{transaction.total}</td>
                                <td>{new Date(transaction.date_created).toLocaleDateString()}</td>
                                <td>{transaction.ID}</td>
                                <td>{transaction._ywpi_invoice_formatted_number}</td>
                                <td>{transaction.first_name}</td>
                                <td>{transaction.last_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Componenta tabelului Cargus */}
{tabValue === 3 && (
    <Box sx={{ width: '100%', mt: 2 }}>
        {Object.entries(cargusTransactions).map(([facturaCargus, data]) => (
            <div key={facturaCargus}>
                <Typography variant="h6">{facturaCargus}</Typography>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>FacturaCargus</th>
                            <th>SumaCargus</th>
                            <th>Data</th>
                            <th>Nr. Comanda</th>
                            <th>Factura Luxura</th>
                            <th>Prenume</th>
                            <th>Nume</th>
                            <th>Total Brut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.entries.map((entry, index) => (
                            <tr key={index}>
                                <td>{entry.SerieRaspuns}</td>
                                <td>{entry.ValoareDeclarata}</td>
                                <td>{new Date(entry.Data).toLocaleDateString()}</td>
                                <td>{entry.NrSerie}</td>
                                <td>{entry.sales.length > 0 ? entry.sales[0].AWB : 'N/A'}</td>
                                <td>{entry.sales.length > 0 ? entry.sales[0].ID : 'N/A'}</td>
                                <td>{entry.sales.length > 0 ? entry.sales[0]._ywpi_invoice_formatted_number : 'N/A'}</td>
                                <td>{entry.sales.length > 0 ? entry.sales[0].first_name : 'N/A'}</td>
                                <td>{entry.sales.length > 0 ? entry.sales[0].last_name : 'N/A'}</td>
                                <td>{entry.sales.length > 0 ? entry.sales[0].subtotal : '0'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ))}
    </Box>
)}


            {/* Componenta tabelului FancCourier */}

        </Box>
    );
};

export default TransactionsPage;
