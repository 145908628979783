const buildHtmlContent = ({
    conditionVal,
    customDescription,
    exteriorColor,
    interiorColor,
    exteriorMaterial,
    interiorMaterial,
    includedItems,
    hardwareColor,
    designerId,
    entrupyId,
    location,
    length,
    height,
    depth,
    detailedDescription,
    sortlyId,
    salePrice,
    price,
    brand,
    layawayPlan
}) => {
    let layawayHtml = '';
    const totalPrice = salePrice || price;

    if (layawayPlan) {
        let advance, rate1, rate2, rate3;
        
        if (layawayPlan === '1 Rata Speciala') {
            advance = Math.round(totalPrice * 0.5);
            rate1 = Math.round(totalPrice * 0.5);
            layawayHtml = `
                [vc_toggle title="Doresc produsul prin LAYAWAY" custom_font_container="tag:h5|font_size:19|text_align:left|line_height:3" custom_use_theme_fonts="yes" use_custom_heading="true" css=".vc_custom_1659790924554{margin-top: -30px !important;}"]
                <table style="height: 133px;">
                    <tbody>
                        <tr>
                            <td style="width: 25.0000%;">
                                <div style="text-align: center;"><span style="color: #000000;"><strong><span style="font-family: Symbol; font-size: 14px;">Avans 50%</span></strong></span></div>
                            </td>
                            <td style="width: 24.8855%;">
                                <div style="text-align: center;"><span style="font-family: Symbol; color: #000000;"><span style="font-size: 14px;"><strong>1 Rata</strong></span></span></div>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 25.0000%;">
                                <div style="text-align: center;"><span style="font-size: 12px; color: #000000;">${advance} RON</span></div>
                            </td>
                            <td style="width: 24.8855%;">
                                <div style="text-align: center;"><span style="font-size: 12px; color: #000000;">${rate1} RON</span></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style="text-align: center;">COMISION <strong>0%</strong></p>
                <p style="text-align: center;" align="center">Acum plătești doar avansul iar noi o să te contactăm în maxim 4 ore pentru semnarea contractului. Totul este online și rapid.</p>
                [/vc_toggle]
                [vc_separator]
            `;
        } else {
            advance = Math.round(totalPrice * 0.15);
            const remaining = Math.round(totalPrice - advance);
            rate1 = remaining;
            rate2 = Math.round(remaining / 2);
            rate3 = Math.round(remaining / 3);
            
            layawayHtml = `
                [vc_toggle title="Doresc produsul prin LAYAWAY" custom_font_container="tag:h5|font_size:19|text_align:left|line_height:3" custom_use_theme_fonts="yes" use_custom_heading="true" css=".vc_custom_1659790924554{margin-top: -30px !important;}"]
                <table style="height: 133px;">
                    <tbody>
                        <tr>
                            <td style="width: 25.0000%;">
                                <div style="text-align: center;"><span style="color: #000000;"><strong><span style="font-family: Symbol; font-size: 14px;">Avans 15%</span></strong></span></div>
                            </td>
                            <td style="width: 24.8855%;">
                                <div style="text-align: center;"><span style="font-family: Symbol; color: #000000;"><span style="font-size: 14px;"><strong>${layawayPlan === '1 Rata' ? '1 Rata' : '2 Rate'}</strong></span></span></div>
                            </td>
                            ${layawayPlan === '3 Rate' ? `<td style="width: 25.0534%;">
                                <div style="text-align: center;"><span style="font-family: Symbol; color: #000000;"><span style="font-size: 14px;"><strong>3 Rate</strong></span></span></div>
                            </td>` : ''}
                        </tr>
                        <tr>
                            <td style="width: 25.0000%;">
                                <div style="text-align: center;"><span style="font-size: 12px; color: #000000;">${advance} RON</span></div>
                            </td>
                            <td style="width: 24.8855%;">
                                <div style="text-align: center;"><span style="font-size: 12px; color: #000000;">${layawayPlan === '1 Rata' ? rate1 : rate2}</span></div>
                                <div style="text-align: center;"><span style="color: #000000;"><sup><span style="font-size: 12px;">RON/lună</span></sup></span></div>
                            </td>
                            ${layawayPlan === '3 Rate' ? `<td style="width: 25.0534%;">
                                <div style="text-align: center;"><span style="font-size: 12px; color: #000000;">${rate3}</span></div>
                                <div style="text-align: center;"><sup><span style="font-size: 12px;">RON/lună</span></sup></div>
                            </td>` : ''}
                        </tr>
                    </tbody>
                </table>
                <p style="text-align: center;">COMISION <strong>0%</strong></p>
                <p style="text-align: center;" align="center">Acum plătești doar avansul iar noi o să te contactăm în maxim 4 ore pentru semnarea contractului. Totul este online și rapid.</p>
                [/vc_toggle]
                [vc_separator]
            `;
        }
    }

    return `
        [vc_row]
        [vc_column]

        [vc_column_text]
        [range_shortcode val="${conditionVal}"]
        [/vc_column_text]

        [vc_toggle title="✩ Caracteristici Specifice" custom_font_container="tag:p|font_size:18|text_align:left|line_height:3" custom_use_theme_fonts="yes" use_custom_heading="true" class="fa"]

        ${customDescription || "Pre-owned nepurtat, nu prezintă absolut niciun semn de uzură."}

        [/vc_toggle]

        [vc_separator]

        [vc_toggle title="DETALII" open="true" custom_font_container="tag:h5|font_size:19|text_align:left|line_height:3" custom_use_theme_fonts="yes" use_custom_heading="true"]

        <div class="pdp__section-content" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; display: flex; flex-flow: row wrap; color: #000000; font-family: MaisonNeue-Book; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px 15px 0px 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Culoare Exterioară</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${exteriorColor}</div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Culoare Interioară</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${interiorColor}</div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px 15px 0px 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Material Exterior</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${exteriorMaterial}</div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Material Interior</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${interiorMaterial}</div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Vine însoțit</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">
                    ${includedItems.map(item => `
                    <div>${item}</div>
                    `).join('')}
                </div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px 15px 0px 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Culoare Hardware</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${hardwareColor}</div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Designer ID</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${designerId}</div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px 15px 0px 0px; width: 50%;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Entrupy ID</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${entrupyId}</div>
            </div>
            <div class="pdp__section-group " style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px 0px 25px; padding: 0px 15px 0px 0px; width: 150px;">
                <div class="pdp__group-title" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Demi, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #767676; letter-spacing: 0px; line-height: 24px; display: inline-block;">Locație</div>
                <div class="pdp__group-item" style="box-sizing: border-box; -webkit-font-smoothing: antialiased; margin: 0px; padding: 0px; font-family: MaisonNeue-Book, Helvetica, Arial, sans-serif; font-size: 0.875rem; color: #000000; letter-spacing: 0px; line-height: 24px;">${location}</div>
            </div>
        </div>
        <span style="font-size: 18px; font-weight: 500; color: #767676;">Mărime &amp; Dimensiuni</span>
        Lungimea: ${length} cm
        Inălțime: ${height} cm
        Adâncime: ${depth} cm
        [/vc_toggle]

        [vc_separator]

        [vc_toggle title="DESCRIERE" custom_font_container="tag:h5|font_size:19|text_align:left|line_height:3" custom_use_theme_fonts="yes" use_custom_heading="true" css=".vc_custom_1659790907923{margin-top: -30px !important;}"]

        <p style="text-align: justify;">${detailedDescription}</p>
        [/vc_toggle]

        [vc_separator]

        [vc_toggle title="LIVRARE & RETUR" custom_font_container="tag:h5|font_size:19|text_align:left|line_height:3" custom_use_theme_fonts="yes" use_custom_heading="true" css=".vc_custom_1659790915357{margin-top: -30px !important;}"]

        <p style="text-align: justify;">Expedierea internă standard &icirc;n Romania este <strong>GRATUITĂ.</strong></p>
        <p style="text-align: justify;">Transportul include: <strong>asigurare, confirmare semnătură și ambalaj de calitate superioară</strong>. Toate articolele vor fi expediate, &icirc;n general, &icirc;n termen de 3 zile lucrătoare de la confirmarea plății. Termenii de livrare se calculează &icirc;n zile lucrătoare (de luni p&acirc;nă vineri, cu excepția sărbătorilor legale).</p>
        <p style="text-align: justify;">Returnează: Da! Vrem să fiți mulțumit de achiziția dvs. Articolul poate fi returnat către Luxura Elite Club &icirc;n termen de 14 de zile de la data achiziției (dacă achiziția se face prin plată integrală &icirc;n avans sau la livrare, NU &icirc;n sistem de rate ), cu etichetele de siguranța atașate și &icirc;n starea inițială pentru a primi o rambursare completă. Vedeți aici politica completă de returnare.</p>
        <p style="text-align: justify;"><br></p>
        <div>
            <div tabindex="0">
                <div>
                    <div tabindex="0">
                        <div>
                            <div tabindex="0">
                                <div>
                                    <div tabindex="0"><span class="sc-beySbM jyPPDw">${sortlyId}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        [/vc_toggle]

        [vc_separator]

        ${layawayHtml}

        <span style="color: #595959; font-family: Metropolis, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 10.26px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 200; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;">${brand} este marcă înregistrată ${brand}. Luxura Elite nu este afiliată cu ${brand}.</span>
    `;
};

export default buildHtmlContent;
