import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import DateFilter from './DateFilter';
import SummaryCard from './SummaryCard';
import ArticleCountCard from './ArticleCountCard';
import PieChartComponent from './PieChartComponent';
import AlexandraPayout from './AlexandraPayout';

const PriceAnalytics = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({});
  const [articleCount, setArticleCount] = useState({});
  const [totalProfit, setTotalProfit] = useState(0);

  useEffect(() => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    setStartDate(startOfMonth);
    setEndDate(currentDate);
  }, []);

  const handleFilter = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/intrari-ai', {
        params: { startDate, endDate } 
      });
      setData(response.data);
      calculateSummary(response.data);
      calculateArticleCount(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const calculateSummary = (filteredData) => {
    const summary = {
      under1999: 0,
      under5999: 0,
      under14999: 0,
      over14999: 0
    };

    let totalProfit = 0;

    // Creăm array-uri pentru a păstra produsele pentru fiecare range
    const under1999Products = [];
    const under5999Products = [];
    const under14999Products = [];
    const over14999Products = [];

    filteredData.forEach(item => {
      // Folosim pretIntrareRON pentru filtrare
      const pretIntrare = item.pretIntrareRON;

      // Calculăm profitul doar pentru referință, dar nu îl folosim la filtrare
      const profit = item.pretDeVanzareRON - pretIntrare;
      totalProfit += profit;

      if (pretIntrare <= 1999) {  // Mai mic sau egal cu 1999
        summary.under1999 += pretIntrare;
        under1999Products.push(item);  // Adăugăm produsul la lista corespunzătoare
      } else if (pretIntrare > 1999 && pretIntrare <= 5999) {  // Între 2000 și 5999 inclusiv
        summary.under5999 += pretIntrare;
        under5999Products.push(item);
      } else if (pretIntrare > 5999 && pretIntrare <= 14999) {  // Între 6000 și 14999 inclusiv
        summary.under14999 += pretIntrare;
        under14999Products.push(item);
      } else if (pretIntrare > 14999) {  // Peste 14999
        summary.over14999 += pretIntrare;
        over14999Products.push(item);
      }
    });

    // Afișăm în consola produsele selectate pentru fiecare range
    console.log('Produse cu pretIntrare sub sau egal cu 1999:', under1999Products);
    console.log('Produse cu pretIntrare între 2000 și 5999:', under5999Products);
    console.log('Produse cu pretIntrare între 6000 și 14999:', under14999Products);
    console.log('Produse cu pretIntrare peste 14999:', over14999Products);

    setSummary(summary);
    setTotalProfit(totalProfit);
  };

  const calculateArticleCount = (filteredData) => {
    const count = {
      under1999: 0,
      under5999: 0,
      under14999: 0,
      over14999: 0
    };

    // Creăm array-uri pentru a păstra produsele pentru fiecare range (în funcție de pretIntrareRON)
    const under1999Products = [];
    const under5999Products = [];
    const under14999Products = [];
    const over14999Products = [];

    filteredData.forEach(item => {
      // Folosim pretIntrareRON pentru filtrare
      const pretIntrare = item.pretIntrareRON;

      if (pretIntrare <= 1999) {  // Mai mic sau egal cu 1999
        count.under1999 += 1;
        under1999Products.push(item);  // Adăugăm produsul la lista corespunzătoare
      } else if (pretIntrare > 1999 && pretIntrare <= 5999) {  // Între 2000 și 5999 inclusiv
        count.under5999 += 1;
        under5999Products.push(item);
      } else if (pretIntrare > 5999 && pretIntrare <= 14999) {  // Între 6000 și 14999 inclusiv
        count.under14999 += 1;
        under14999Products.push(item);
      } else if (pretIntrare > 14999) {  // Peste 14999
        count.over14999 += 1;
        over14999Products.push(item);
      }
    });

    // Afișăm în consola produsele selectate pentru fiecare range (în funcție de pretIntrareRON)
    console.log('Produse cu pretIntrare sub sau egal cu 1999 (număr articole):', under1999Products);
    console.log('Produse cu pretIntrare între 2000 și 5999 (număr articole):', under5999Products);
    console.log('Produse cu pretIntrare între 6000 și 14999 (număr articole):', under14999Products);
    console.log('Produse cu pretIntrare peste 14999 (număr articole):', over14999Products);

    setArticleCount(count);
  };

  const maxValues = {
    under1999: 25262,
    under5999: 80205,
    under14999: 66000,
    over14999: 5000,
  };

  const maxArticles = {
    under1999: 84,
    under5999: 86,
    under14999: 28,
    over14999: 2,
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Data Filter</Typography> 
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFilter={handleFilter}
      />
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} md={6}>
          <SummaryCard
            title="Under 1999 RON"
            value={summary.under1999}
            maxValue={maxValues.under1999}
          />
          <SummaryCard
            title="Under 5999 RON"
            value={summary.under5999}
            maxValue={maxValues.under5999}
          />
          <SummaryCard
            title="Under 14999 RON"
            value={summary.under14999}
            maxValue={maxValues.under14999}
          />
          <SummaryCard
            title="Over 14999 RON"
            value={summary.over14999}
            maxValue={maxValues.over14999}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ArticleCountCard
            title="Under 1999 RON"
            count={articleCount.under1999}
            maxCount={maxArticles.under1999}
          />
          <ArticleCountCard
            title="Under 5999 RON"
            count={articleCount.under5999}
            maxCount={maxArticles.under5999}
          />
          <ArticleCountCard
            title="Under 14999 RON"
            count={articleCount.under14999}
            maxCount={maxArticles.under14999}
          />
          <ArticleCountCard
            title="Over 14999 RON"
            count={articleCount.over14999}
            maxCount={maxArticles.over14999}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChartComponent profit={totalProfit} />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Am adăugat componenta AlexandraPayout aici */}
          {data.length > 0 && <AlexandraPayout data={data} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PriceAnalytics;
