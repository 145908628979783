import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CableIcon from '@mui/icons-material/Cable';
import Pagination from 'react-bootstrap/Pagination';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import {
    Container,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Card,
    IconButton,
    CardContent,
    CardActions,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskCard from './TaskCard';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { isPast, parseISO } from 'date-fns';
import KPIDashboard from './KPIDashboard';

const API_BASE_URL = 'https://server-9p4hx.kinsta.app';
//const API_BASE_URL = 'http://localhost:5099';

function App() {
    const [loading, setLoading] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [totalTasksAutoInvoice, setTotalTasksAutoInvoice] = useState(0);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleRefreshTasks = async () => {
        setIsRefreshing(true);
        try {
            const responseUpdate = await fetch(`${API_BASE_URL}/update-database-taskjira`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!responseUpdate.ok) {
                throw new Error(`HTTP error update JIRA to KINSTA! Status: ${responseUpdate.status}`);
            }

            const messageUpdate = await responseUpdate.text();
            console.log(`Update Database: ${messageUpdate}`);

            const responseRefresh = await fetch(`${API_BASE_URL}/api/tasks/refresh-tasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!responseRefresh.ok) {
                throw new Error(`HTTP error refresh tasks! Status: ${responseRefresh.status}`);
            }

            const messageRefresh = await responseRefresh.text();
            console.log(`Refresh: ${messageRefresh}`);

            const responseRefreshDash = await fetch(`${API_BASE_URL}/api/dash/refresh-tasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!responseRefreshDash.ok) {
                throw new Error(`HTTP error refresh dash tasks! Status: ${responseRefreshDash.status}`);
            }

            const messageRefreshDash = await responseRefreshDash.text();
            console.log(`Dashboard Refresh: ${messageRefreshDash}`);

            const responseDelete = await fetch(`${API_BASE_URL}/api/tasks/delete-tasks-file`, {
                method: 'POST',
            });

            if (!responseDelete.ok) {
                throw new Error('HTTP error when trying to delete tasks file');
            }

            const messageDelete = await responseDelete.json();
            console.log(messageDelete.message);
        } catch (error) {
            console.error("Failed to perform operations:", error);
            alert(`Failed to perform operations: ${error.message}`);
        } finally {
            setIsRefreshing(false);
        }
    };

    const handleSyncProducts = () => {
        setIsSyncing(true);
        fetch(`${API_BASE_URL}/update-woocommerce-orders`)
            .then(response => response.json())
            .then(data => {
                alert(data.message);
            })
            .catch(error => {
                console.error('Eroare la sincronizarea produselor:', error);
                alert('Eroare la sincronizarea produselor.');
            })
            .finally(() => setIsSyncing(false));
    };

    const [filteredTasksInTransit, setFilteredTasksInTransit] = useState([]);
    const [tasksInTranzit, setTasksInTransit] = useState([]);
    const [totalTasksTransit, setTotalTasksTransit] = useState(0);
    const [apiData, setApiData] = useState({});
    const [mismatchedTasksNEW, setMismatchedTasksNEW] = useState([]);
    const [misalignedTasksNEW, setMisalignedTasksNEW] = useState([]);
    const [misalignedTasksWoo, setMisalignedTasksWoo] = useState([]);
    const [misalignedTasks, setMisalignedTasks] = useState([]);
    const [error, setError] = useState(null);
    const [vanzariEchipa, setVanzariEchipa] = useState([]);
    const [fiseDeCreat, setFiseDeCreat] = useState([]);
    const [groupedTasks, setGroupedTasks] = useState({});
    const [totalTasksRestituite, setTotalTasksRestituite] = useState(0);
    const [tasksCreated, setTasksCreated] = useState([]);
    const [tasksProspect, setTasksProspect] = useState([]);
    const [authTasks, setAuthTasks] = useState({});
    const [totalTasksPozat, setTotalTasksPozat] = useState(0);
    const [groupedContractTasks, setGroupedContractTasks] = useState({});
    const [expiringTasks, setExpiringTasks] = useState([]);
    const [orphanTasks, setOrphanTasks] = useState([]);
    const [autoInvoiceTasks, setAutoInvoiceTasks] = useState([]);
    const [totalTasksAutofacturi, setTotalTasksAutofacturi] = useState(0);
    const [trackingInfos, setTrackingInfos] = useState({});
    const [acceptedTasks, setAcceptedTasks] = useState([]);
    const [totalSubtotal, setTotalSubtotal] = useState(0);

    const fetchTasksInTransit = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/tasks/tasksInTransit`);
            if (!response.data) {
                setFilteredTasksInTransit([]);
                setTotalTasksTransit(0);
                return;
            }

            setFilteredTasksInTransit(response.data);

            const total = Object.values(response.data).reduce((acc, group) => acc + (Array.isArray(group) ? group.length : 0), 0);
            setTotalTasksTransit(total);
        } catch (error) {
            console.error('Error fetching tasks in transit:', error);
            setFilteredTasksInTransit([]);
            setTotalTasksTransit(0);
        }
    };

    const fetchMismatchedPrices = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/check-mismatched-prices`);
            const data = await response.json();
            setMismatchedTasksNEW(data);
        } catch (error) {
            console.error('Error fetching mismatched prices:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMisalignedTasks = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/misaligned-tasks`);
            const data = await response.json();
            setMisalignedTasksNEW(data);
        } catch (error) {
            console.error("Failed to fetch misaligned tasks:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchApiData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/dash/achizitor`);
            setApiData(response.data);
        } catch (error) {
            console.error('Error fetching API data:', error);
        }
    };

    const fetchVanzariEchipa = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/vanzari-echipa`);
            setVanzariEchipa(response.data);
        } catch (error) {
            console.error('Error fetching vanzari echipa data:', error);
        }
    };

    const fetchGroupedTasks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/restituite`);
            const data = await response.json();
            setGroupedTasks(data);

            const total = Object.values(data).reduce((acc, tasksGroup) => acc + (Array.isArray(tasksGroup) ? tasksGroup.length : 0), 0);
            setTotalTasksRestituite(total);
        } catch (error) {
            console.error("Failed to fetch grouped tasks:", error);
            setGroupedTasks({});
            setTotalTasksRestituite(0);
        }
    };

    const fetchTasksCreated = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/created-tasks`);
            const data = await response.json();
            setTasksCreated(data);
        } catch (error) {
            console.error('Could not fetch tasks:', error);
            setTasksCreated([]);
        }
    };

    const fetchProspectTasks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/prospects`);
            const data = await response.json();
            const groupedData = data.reduce((acc, item) => {
                const key = item.nume_si_prenume;
                if (!acc[key]) {
                    acc[key] = {
                        tasks: [],
                        prospectInfo: item.prospectInfo,
                    };
                }
                acc[key].tasks.push(...item.tasks);
                return acc;
            }, {});

            setTasksProspect(groupedData);
        } catch (error) {
            console.error("Could not fetch prospect tasks:", error);
            setTasksProspect({});
        }
    };

    const fetchAuthTasks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/tasks-in-authentication`);
            const data = await response.json();
            setAuthTasks(data);

            const tasksCount = Object.values(data).flat().length;
            setTotalTasksPozat(tasksCount);
        } catch (error) {
            console.error("Error fetching auth tasks:", error);
            setAuthTasks({});
            setTotalTasksPozat(0);
        }
    };

    const fetchGroupedContractTasks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/contract-status`);
            const data = await response.json();
            setGroupedContractTasks(data);
        } catch (error) {
            console.error('Eroare la încărcarea task-urilor de contract:', error);
            setGroupedContractTasks({});
        }
    };

    const fetchExpiringTasks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/contracts-expiring-soon`);
            const data = await response.json();
            setExpiringTasks(data);
        } catch (error) {
            console.error('Failed to fetch expiring tasks', error);
            setExpiringTasks([]);
        }
    };

    const fetchOrphanTasks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/leb-orphan`);
            const data = await response.json();
            setOrphanTasks(data);
        } catch (error) {
            console.error('Failed to fetch orphan tasks', error);
            setOrphanTasks([]);
        }
    };

    const fetchAutoInvoiceTasks = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/tasks/autofacturi`);
            setAutoInvoiceTasks(response.data);

            const tasksCount = Object.values(response.data).reduce((total, nameGroups) => {
                return total + (Array.isArray(nameGroups) ? nameGroups.reduce((subtotal, tasks) => subtotal + (Array.isArray(tasks) ? tasks.length : 0), 0) : 0);
            }, 0);

            setTotalTasksAutofacturi(tasksCount);
        } catch (error) {
            console.error('There was an error fetching the auto invoice tasks', error);
            setAutoInvoiceTasks([]);
            setTotalTasksAutofacturi(0);
        }
    };

    const fetchAcceptedTasks = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/acceptate`);
            const data = await response.json();
            setAcceptedTasks(data);
        } catch (error) {
            console.error("Could not fetch accepted tasks:", error);
            setAcceptedTasks([]);
        }
    };

    const fetchFiseDeCreat = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/dash/prod-necreat`);
            setFiseDeCreat(response.data);
        } catch (error) {
            console.error('Error fetching Fise De Creat:', error);
            setFiseDeCreat([]);
        }
    };

    const fetchTotalSubtotal = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/jurnale/servicii/totalpachete`);
            const roundedTotal = Math.round(response.data.totalSubtotal);
            setTotalSubtotal(roundedTotal);
        } catch (error) {
            console.error('Eroare la preluarea datelor', error);
            setTotalSubtotal(0);
        }
    };

    const sortMonthYear = (a, b) => {
        const [monthA, yearA] = a.split('-').map(Number);
        const [monthB, yearB] = b.split('-').map(Number);
        const dateA = new Date(yearA, monthA - 1);
        const dateB = new Date(yearB, monthB - 1);
        return dateA - dateB;
    };

    const calculateDaysUntilExpiration = (dueDate) => {
        const today = new Date();
        const due = new Date(dueDate);
        return Math.ceil((due - today) / (1000 * 60 * 60 * 24));
    };

    function stripHtml(html) {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const fetchTrackingInfo = async (awb, index) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/tasks/tracking/${awb}`);
            const data = await response.json();
            setTrackingInfos(prevInfos => ({
                ...prevInfos,
                [`${awb}-${index}`]: data.message
            }));
        } catch (error) {
            console.error('Eroare la obținerea informațiilor de tracking:', error);
        }
    };

    const handleRefreshAll = async () => {
        await Promise.all([
            fetchTasksInTransit(),
            fetchMismatchedPrices(),
            fetchMisalignedTasks(),
            fetchApiData(),
            fetchVanzariEchipa(),
            fetchGroupedTasks(),
            fetchTasksCreated(),
            fetchProspectTasks(),
            fetchAuthTasks(),
            fetchGroupedContractTasks(),
            fetchExpiringTasks(),
            fetchOrphanTasks(),
            fetchAutoInvoiceTasks(),
            fetchAcceptedTasks(),
            fetchFiseDeCreat(),
            fetchTotalSubtotal()
        ]);
    };

    useEffect(() => {
        handleRefreshAll();
    }, []);

    // useEffect(() => {
    //     const fetchMisalignedTasks = async (attempt = 1) => {
    //         setIsLoading(true);
    //         setError(null);
    //         try {
    //             const response = await fetch(`${API_BASE_URL}/api/tasks/compare-tasks-with-woocommerce`);
    //             if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    //             const data = await response.json();
    //             setMisalignedTasks(data.misalignedTasks);
    //             setMisalignedTasksWoo(data.misalignedTasks.length);
    //         } catch (error) {
    //             if (attempt <= 3) {
    //                 console.log(`Retry attempt ${attempt}...`);
    //                 setTimeout(() => fetchMisalignedTasks(attempt + 1), 2000); // Retry after 2 seconds
    //             } else {
    //                 setError("Failed to fetch misaligned tasks: " + error.message);
    //             }
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     fetchMisalignedTasks();
    // }, []);

    return (
        <Container maxWidth={false} style={{ padding: 0, margin: 0 }}>
            <Button onClick={handleRefreshAll} variant="contained" color="primary" style={{ margin: '20px' }}>
                Refresh All
            </Button>
            <KPIDashboard
                totalTasksTransit={totalTasksTransit}
                totalTasksPozat={totalTasksPozat}
                misalignedTasksWoo={misalignedTasksWoo}
                misalignedTasksNEW={misalignedTasksNEW}
                mismatchedTasksNEW={mismatchedTasksNEW}
                totalTasksRestituite={totalTasksRestituite}
                totalTasksAutofacturi={totalTasksAutofacturi}
                handleRefreshTasks={handleRefreshTasks}
                isRefreshing={isRefreshing}
                handleSyncProducts={handleSyncProducts}
                isSyncing={isSyncing}
                apiData={apiData}
                totalSubtotal={totalSubtotal}
                vanzariEchipa={vanzariEchipa}
                fetchTasksInTransit={fetchTasksInTransit}
                fetchMismatchedPrices={fetchMismatchedPrices}
                fetchMisalignedTasks={fetchMisalignedTasks}
                fetchApiData={fetchApiData}
                fetchVanzariEchipa={fetchVanzariEchipa}
                fetchGroupedTasks={fetchGroupedTasks}
                fetchTasksCreated={fetchTasksCreated}
                fetchProspectTasks={fetchProspectTasks}
                fetchAuthTasks={fetchAuthTasks}
                fetchGroupedContractTasks={fetchGroupedContractTasks}
                fetchExpiringTasks={fetchExpiringTasks}
                fetchOrphanTasks={fetchOrphanTasks}
                fetchAutoInvoiceTasks={fetchAutoInvoiceTasks}
                fetchAcceptedTasks={fetchAcceptedTasks}
                fetchFiseDeCreat={fetchFiseDeCreat}
                fetchTotalSubtotal={fetchTotalSubtotal}
            />

            <Grid container spacing={3}>
                {/* 1. Cereri Noi */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Cereri Noi
                        <IconButton onClick={fetchTasksCreated}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {tasksCreated.map((task, index) => (
                        <Box
                            key={index}
                            style={{
                                marginBottom: '10px',
                                padding: '10px',
                                border: '1px solid gray',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography>{task.nume_si_prenume}</Typography>
                            <Typography>{task.summary} ({task.issue_id})</Typography>
                            <Typography>Data creării: {new Date(task.created_date).toLocaleDateString()}</Typography>
                            {task.message && <Typography color="secondary">{task.message}</Typography>}
                        </Box>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Cereri în Prospect
                        <IconButton onClick={fetchProspectTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {Object.entries(tasksProspect).map(([name, detail], index) => (
                        <Accordion key={index} style={{ marginBottom: '10px' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {detail.prospectInfo && <Typography style={{ color: 'red', marginBottom: '10px' }}>{detail.prospectInfo}</Typography>}
                                {Array.isArray(detail.tasks) && detail.tasks.map((task, taskIndex) => (
                                    <Box key={taskIndex} style={{ padding: '10px', border: '1px solid gray', borderRadius: '5px', marginBottom: '10px' }}>
                                        <Typography>{task.summary} ({task.issue_id})</Typography>
                                        <Typography>Data creării: {new Date(task.created_date).toLocaleDateString()}</Typography>
                                        {task.message && <Typography color="red">{task.message}</Typography>}
                                    </Box>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Articole Acceptate
                        <IconButton onClick={fetchAcceptedTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {acceptedTasks.map(({ achizitor, persons }, achizitorIndex) => (
                        <Accordion key={achizitorIndex} style={{ marginBottom: '10px' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{achizitor}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {Array.isArray(persons) && persons.map(({ nume_si_prenume, telefon, email, tasks }, personIndex) => (
                                    <Accordion key={personIndex} style={{ marginBottom: '10px', width: '100%' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{nume_si_prenume}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography style={{ marginBottom: '5px' }}>Telefon: {telefon}</Typography>
                                            <Typography style={{ marginBottom: '15px' }}>Email: {email}</Typography>
                                            {Array.isArray(tasks) && tasks.map((task, taskIndex) => (
                                                <Box key={taskIndex} style={{ padding: '10px', border: '1px solid gray', borderRadius: '5px', marginBottom: '10px' }}>
                                                    <Typography>{task.summary} ({task.issue_id})</Typography>
                                                    <Typography>Data creării: {new Date(task.created_date).toLocaleDateString()}</Typography>
                                                    {task.message && <Typography color="red">{task.message}</Typography>}
                                                </Box>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Articole în Tranzit
                        <IconButton onClick={fetchTasksInTransit}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {Object.entries(filteredTasksInTransit).map(([name, tasks], groupIndex) => {
                        const taskList = Array.isArray(tasks) ? tasks : [];
                        const hasAttachmentIssues = taskList.some(task => task.missingAttachments && task.missingAttachments.length > 0);

                        return (
                            <Accordion key={name} style={{ margin: '10px 0', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                        {name} - {taskList.length} task-uri
                                    </Typography>
                                    {hasAttachmentIssues && (
                                        <span style={{ color: 'red', marginLeft: '10px' }}>⚠️ Probleme cu atașamentele</span>
                                    )}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {taskList.map((task, index) => {
                                            const { issue_id, awb, missingAttachments, summary } = task;
                                            const taskKey = `${awb}-${index}`;
                                            let awbLink = "#";
                                            let awbText = "AWB necunoscut";
                                            if (awb) {
                                                if (awb.startsWith('62')) {
                                                    awbLink = `https://gls-group.eu/RO/ro/urmarire-colet/?match=${awb}`;
                                                    awbText = "Verifică Status Expediție GLS";
                                                } else {
                                                    awbLink = `https://www.fancourier.ro/awb-tracking/?awb=${awb}`;
                                                    awbText = "Verifică Status Expediție Fan Courier";
                                                }
                                            }

                                            return (
                                                <Grid item xs={12} key={index}>
                                                    <Card variant="outlined" style={{ boxShadow: '0 2px 4px rgba(0,0,0,0.2)', marginBottom: '10px' }}>
                                                        <CardContent>
                                                            <Typography variant="body1">{summary}</Typography>
                                                            {missingAttachments && missingAttachments.length > 0 && (
                                                                <Typography style={{ color: 'red', marginTop: '10px' }}>
                                                                    Atașamente lipsă: {missingAttachments.map(a => a.label).join(', ')}
                                                                </Typography>
                                                            )}
                                                            <Typography style={{ marginTop: '10px' }}>
                                                                <a href={awbLink} target="_blank" rel="noopener noreferrer">{awbText}</a>
                                                                <IconButton onClick={() => fetchTrackingInfo(awb, index)} size="small">
                                                                    <TrackChangesIcon />
                                                                </IconButton>
                                                            </Typography>
                                                            {trackingInfos[taskKey] && <Typography style={{ marginTop: '10px' }}>{trackingInfos[taskKey]}</Typography>}
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Articole în Autentificare
                        <IconButton onClick={fetchAuthTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {Object.entries(authTasks).map(([groupName, tasks]) => (
                        <Accordion key={groupName} style={{ margin: '10px 0', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{groupName} ({tasks.length} task-uri)</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    {Array.isArray(tasks) && tasks.map((task) => (
                                        <Grid item xs={12} key={task.issue_id}>
                                            <Card variant="outlined" style={{ boxShadow: '0 2px 4px rgba(0,0,0,0.2)', marginBottom: '10px' }}>
                                                <CardContent>
                                                    <Typography variant="body1">{task.summary}</Typography>
                                                    <Typography variant="body2" style={{ marginTop: '10px' }}>
                                                        Status produs: {task.prodStatus}
                                                    </Typography>
                                                    {task.qualityCheckMsg && (
                                                        <Typography style={{ color: 'red', marginTop: '10px' }}>
                                                            {task.qualityCheckMsg}
                                                        </Typography>
                                                    )}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Fișe de Creat ({fiseDeCreat.length})
                        <IconButton onClick={fetchFiseDeCreat}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {fiseDeCreat.map((task, index) => (
                        <Box key={index} style={{ marginBottom: '10px', padding: '10px', border: '1px solid gray', borderRadius: '5px' }}>
                            <Typography>{task.issue_id || 'N/A'}</Typography>
                            <Typography>{task.summary} ({task.sku})</Typography>
                            <Typography>Status: {task.status}</Typography>
                            <Typography>Data creării: {new Date(task.created_date).toLocaleDateString()}</Typography>
                        </Box>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Contracte
                        <IconButton onClick={fetchGroupedContractTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {Object.entries(groupedContractTasks).map(([status, tasks]) => (
                        <Accordion key={status} style={{ margin: '10px 0', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{status}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    {Array.isArray(tasks) && tasks.map(task => (
                                        <Grid item xs={12} key={task.id}>
                                            <TaskCard task={task} />
                                            {task.status === "⬤  33_SEMNAT" && isPast(parseISO(task.due_date)) && (
                                                <Typography style={{ marginTop: 10, color: 'red' }}>
                                                    Contract expirat, contactați de urgență!
                                                </Typography>
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Contracte Expirând Curând
                        <IconButton onClick={fetchExpiringTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    <Accordion style={{ margin: '10px 0', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Expiră în 5 Zile</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {expiringTasks.map((task) => (
                                    <Grid item xs={12} key={task.issue_id}>
                                        <TaskCard id={task.issue_id} task={task} />
                                        <Typography style={{ marginTop: 10, color: 'blue' }}>
                                            Contractul expiră în {calculateDaysUntilExpiration(task.due_date)} zi(le) <AccessTimeIcon />
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Alocare gresita produse
                        <IconButton onClick={fetchMisalignedTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    <Typography variant="h6" style={{ margin: '20px 0' }}>
                        Verificare preturi Scadentar
                    </Typography>
                    {mismatchedTasksNEW.length > 0 ? (
                        <Grid container spacing={2}>
                            {mismatchedTasksNEW.map((task, index) => (
                                <Grid item xs={12} key={index}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{task.summary}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography color="secondary">Pret Intrare RON în LEB: {task.lebPrice}</Typography>
                                            <Typography color="error">Pret Intrare RON în SCAD: {task.scadPrice}</Typography>
                                            <Typography style={{ color: 'red' }}>Pret gresit in SCAD bazat pe LEB</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography>Felicitari, nu există taskuri cu prețuri nealiniate</Typography>
                    )}

                    <Typography variant="h6" style={{ margin: '20px 0' }}>
                        Verificare statusuri
                    </Typography>
                    {isLoading ? (
                        <Typography>Se încarcă...</Typography>
                    ) : misalignedTasksNEW.length > 0 ? (
                        <Grid container spacing={2}>
                            {misalignedTasksNEW.map((task, index) => (
                                <Grid item xs={12} key={index}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{task.summary}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography color="secondary">Status în LEB: {task.lebStatus}</Typography>
                                            <Typography color="error">Status în PROD: {task.prodStatus}</Typography>
                                            <Typography style={{ color: 'red' }}>Status greșit în PROD bazat pe LEB</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography>Felicitări, nu există taskuri cu statusuri nealiniate</Typography>
                    )}

                    <Typography variant="h6" style={{ margin: '20px 0' }}>
                        Verificare stocuri JIRA x Luxura
                        <IconButton onClick={fetchMisalignedTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {isLoading ? (
                        <LinearProgress />
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <Grid container spacing={2}>
                            {misalignedTasks.length > 0 ? (
                                misalignedTasks.map((task, index) => {
                                    const cleanName = task.name ? stripHtml(task.name).trim() : "";
                                    return (
                                        <Grid item xs={12} key={index}>
                                            <Accordion>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>{task.summary}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography color="secondary">Status în LEB: {task.lebStatus}</Typography>
                                                    {cleanName && <Typography color="error">Denumire: {cleanName}</Typography>}
                                                    <Typography color="error">SKU: {task.sku}</Typography>
                                                    <Typography color="error">Cantitate stoc în Luxura: {task.stockQuantity !== undefined ? task.stockQuantity : 'N/A'}</Typography>
                                                    {task.note && <Typography style={{ color: 'red' }}>{task.note}</Typography>}
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Typography>Felicitări, nu există stocuri cu alocare de status greșită în JIRA.</Typography>
                            )}
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Produse Retur
                        <IconButton onClick={fetchGroupedTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Retur Initiat</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {(([id, task]) => (
                                    <Grid item xs={12} key={id}>
                                        <TaskCard id={id} task={task}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Retur Receptionat</Typography>
                            {(([id, task]) => (task.Attachments)) && (
                                <span style={{ color: 'red', marginLeft: '10px' }}>⚠️</span>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {(([id, task]) => (
                                    <Grid item xs={12} key={id}>
                                        <TaskCard id={id} task={task}/>
                                        {(task.Attachments) && (
                                            <Typography style={{ color: 'red' }}>Nu are atasat factura de Storno</Typography>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Factura Stornata</Typography>
                            {(([id, task]) => (task.Attachments)) && (
                                <span style={{ color: 'red', marginLeft: '10px' }}>⚠️</span>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {(([id, task]) => (
                                    <Grid item xs={12} key={id}>
                                        <TaskCard id={id} task={task}/>
                                        {(task.Attachments) && (
                                            <Typography style={{ marginTop: 10, color: 'red' }}>Nu are atasat factura de Storno</Typography>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Articole ORPHAN în Tranzit
                        <IconButton onClick={fetchOrphanTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {orphanTasks.map((task) => {
                        const awbOrphanValue = task.awb;
                        let awbOrphanLink = "#";
                        let awbOrphanText = "Adaugă AWB în JIRA";

                        if (awbOrphanValue) {
                            if (awbOrphanValue.startsWith('62')) {
                                awbOrphanLink = `https://gls-group.eu/RO/ro/urmarire-colet/?match=${awbOrphanValue}`;
                                awbOrphanText = "Verifică Status Expediție GLS";
                            } else {
                                awbOrphanLink = `https://www.fancourier.ro/awb-tracking/?awb=${awbOrphanValue}`;
                                awbOrphanText = "Verifică Status Expediție Fan Courier";
                            }
                        }

                        return (
                            <Accordion key={task.issue_id} style={{ margin: '10px 0', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{task.summary}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TaskCard
                                                id={task.issue_id}
                                                task={task}
                                                style={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.03)' } }}
                                            />
                                            <Typography style={{ marginTop: '10px' }}>
                                                <a href={awbOrphanLink} target="_blank" rel="noopener noreferrer">{awbOrphanText}</a>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Cereri incomplete Restituit
                        <IconButton onClick={fetchGroupedTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {Object.entries(groupedTasks).map(([name, tasksGroup]) => (
                        <Accordion key={name}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{name} ({Array.isArray(tasksGroup) ? tasksGroup.length : 0} produse)</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {Array.isArray(tasksGroup) && tasksGroup.map((task, index) => (
                                        <ListItem key={index} divider>
                                            <TaskCard task={task} />
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ margin: '20px 0' }}>
                        Situație Autofacturi
                        <IconButton onClick={fetchAutoInvoiceTasks}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                    {Object.entries(autoInvoiceTasks)
                        .sort(([keyA], [keyB]) => sortMonthYear(keyA, keyB))
                        .map(([monthYear, nameGroups]) => (
                            <Accordion key={monthYear}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{monthYear}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {Object.entries(nameGroups).map(([name, tasks]) => (
                                        <Accordion key={name}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>{name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {Array.isArray(tasks) && tasks.map(task => (
                                                    <TaskCard key={task.id} task={task} />
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Grid>
            </Grid>
        </Container>
    );
};

export default App;
