import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  LinearProgress,
  IconButton,
  Badge,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { getDaysInMonth, isPast, isThisMonth, isFuture } from 'date-fns';
import InputPlataRata from './InputPlataRata';
import TranzactiiTimeline from './TranzactiiTimeline';
import axios from 'axios';

const DetaliiClient = ({ client, detaliiRate, tranzactii, creeazaLinkDePlata, handleSetLivrat }) => {
  const [tranzactiiState, setTranzactiiState] = useState(tranzactii);
  const [detaliiRateState, setDetaliiRateState] = useState(detaliiRate);

  useEffect(() => {
    setTranzactiiState(tranzactii);
    setDetaliiRateState(detaliiRate);
  }, [tranzactii, detaliiRate]);

  const handlePaymentUpdate = async (rateID, amountPaid) => {
    const updatedDetails = detaliiRateState.map((produs) => {
      if (produs.issue_id === rateID.split('Rata')[0]) {
        const rataIndex = parseInt(rateID.split('Rata')[1], 10);
        const rataKey = `suma_platita_rata_${rataIndex}`;
        const updatedProdus = {
          ...produs,
          [rataKey]: (produs[rataKey] || 0) + amountPaid,
        };

        const updatedRates = updatedProdus.rates.map((rate) => {
          if (rate.rateID === rateID) {
            const newSumaPlatita = rate.suma_platita + amountPaid;
            return {
              ...rate,
              suma_platita: newSumaPlatita,
              status: newSumaPlatita >= rate.suma_totala ? 'platit' : 'partial platit',
            };
          }
          return rate;
        });

        return { ...updatedProdus, rates: updatedRates };
      }
      return produs;
    });

    setDetaliiRateState(updatedDetails);

    try {
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/llp/get-llp-tranzactii/${rateID}`);
      setTranzactiiState((prevTranzactii) => ({
        ...prevTranzactii,
        [rateID]: response.data,
      }));
    } catch (error) {
      console.error(`Failed to fetch transactions for ${rateID}:`, error);
    }
  };

  const handleTranzactieUpdate = (tranzactieID, suma, status) => {
    setTranzactiiState((prevTranzactii) => {
      const updatedTranzactii = { ...prevTranzactii };
      for (const rateID in updatedTranzactii) {
        updatedTranzactii[rateID] = updatedTranzactii[rateID].map((tranzactie) =>
          tranzactie.tranzactieID === tranzactieID
            ? { ...tranzactie, suma: parseFloat(suma), status }
            : tranzactie
        );
      }
      return updatedTranzactii;
    });

    const updatedDetails = detaliiRateState.map((produs) => {
      const updatedRates = produs.rates.map((rate) => {
        const updatedTransactions = tranzactiiState[rate.rateID]?.map((tranzactie) => {
          if (tranzactie.tranzactieID === tranzactieID) {
            return { ...tranzactie, suma: parseFloat(suma), status };
          }
          return tranzactie;
        });

        const newSumaPlatita = updatedTransactions.reduce(
          (acc, tranzactie) => acc + parseFloat(tranzactie.suma),
          0
        );

        return {
          ...rate,
          tranzactii: updatedTransactions,
          suma_platita: newSumaPlatita,
          status: newSumaPlatita >= rate.suma_totala ? 'platit' : newSumaPlatita === 0 ? 'neplatit' : 'partial platit',
        };
      });

      return { ...produs, rates: updatedRates };
    });

    setDetaliiRateState(updatedDetails);
  };

  const handleTranzactieDelete = (tranzactieID) => {
    setTranzactiiState((prevTranzactii) => {
      const updatedTranzactii = { ...prevTranzactii };
      for (const rateID in updatedTranzactii) {
        updatedTranzactii[rateID] = updatedTranzactii[rateID].filter(
          (tranzactie) => tranzactie.tranzactieID !== tranzactieID
        );
      }
      return updatedTranzactii;
    });

    const updatedDetails = detaliiRateState.map((produs) => {
      const updatedRates = produs.rates.map((rate) => {
        const updatedTransactions = tranzactiiState[rate.rateID]?.filter(
          (tranzactie) => tranzactie.tranzactieID !== tranzactieID
        );

        const newSumaPlatita = updatedTransactions.reduce(
          (acc, tranzactie) => acc + parseFloat(tranzactie.suma),
          0
        );

        return {
          ...rate,
          tranzactii: updatedTransactions,
          suma_platita: newSumaPlatita,
          status: newSumaPlatita >= rate.suma_totala ? 'platit' : newSumaPlatita === 0 ? 'neplatit' : 'partial platit',
        };
      });

      return { ...produs, rates: updatedRates };
    });

    setDetaliiRateState(updatedDetails);
  };

  const formatData = (data) => {
    const date = new Date(data);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const sortRatesByDate = (rates) => {
    return rates.slice().sort((a, b) => new Date(a.data_scadentei) - new Date(b.data_scadentei));
  };

  if (!client) {
    return (
      <Typography variant="h6" style={{ margin: 20, textAlign: 'center' }}>
        Selectați un client pentru a vedea detaliile.
      </Typography>
    );
  }

  return (
    <Card raised style={{ height: '100%' }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          style={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '1.2rem' }}
        >
          {client.nume}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <PhoneIcon fontSize="small" style={{ marginRight: '4px', color: '#757575' }} />
            <Typography variant="body2" style={{ color: '#757575' }}>
              {client.detalii[0]?.client_telefon}
            </Typography>
            <Box marginX={1} style={{ color: '#757575' }}>
              |
            </Box>
            <EmailIcon fontSize="small" style={{ marginRight: '4px', color: '#757575' }} />
            <Typography variant="body2" style={{ color: '#757575' }}>
              {client.detalii[0]?.client_email}
            </Typography>
          </Box>
        </Box>
        <Box
          mt={2.5}
          style={{
            height: '2px',
            background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%)',
            boxShadow: '0 10px 10px rgba(0,0,0,0.1)',
          }}
        ></Box>

        {client.detalii
          .filter((produs) => produs.livratLLP !== 1) // Filtrăm produsele livrate
          .map((produs, indexProdus) => {
            const rateProdus = sortRatesByDate(detaliiRateState.find((detalii) => detalii.issue_id === produs.issue_id)?.rates || []);
            return (
              <Badge
                key={indexProdus}
                badgeContent="achitat"
                color="success"
                invisible={produs.statusrata !== 'achitat'}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiBadge-badge': {
                    transform: 'translate(-10px, 0px)', // Muta badge-ul spre stânga cu 10px
                  },
                }}
              >
                <Accordion key={indexProdus}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${indexProdus}-content`}
                    id={`panel${indexProdus}-header`}
                  >
                    <Typography variant="subtitle1">{produs.sku}</Typography>
                    {produs.statusrata === 'achitat' && (
                      <IconButton
                        aria-label="set-livrat"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSetLivrat(produs.issue_id);
                        }}
                        style={{ marginLeft: 'auto', color: 'green' }}
                      >
                        <LocalShippingIcon />
                      </IconButton>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column" mb={2}>
                      <Typography variant="subtitle2" style={{ color: '#757575', marginBottom: '8px' }}>
                        {produs.model || 'Model N/A'}
                      </Typography>
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <EventNoteIcon style={{ marginRight: '4px', color: '#757575' }} />
                          <Typography variant="body2" style={{ marginRight: '16px' }}>
                            {produs.data_vandut}
                          </Typography>
                          <AttachMoneyIcon style={{ marginRight: '4px', color: '#757575' }} />
                          <Typography variant="body2" style={{ marginRight: '16px' }}>
                            {produs.pret_de_vanzare_ron} RON
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <ConfirmationNumberIcon style={{ marginRight: '4px', color: '#757575' }} />
                          <Typography variant="body2" style={{ marginRight: '16px' }}>
                            {produs.nr_comanda}
                          </Typography>
                          <RateReviewIcon style={{ marginRight: '4px', color: '#757575' }} />
                          <Typography variant="body2">{produs.nr_de_rate}</Typography>
                        </Box>
                      </Box>

                      {rateProdus.map((rata, rataIndex) => {
                        const estePlatit = rata.suma_platita >= rata.suma_totala;
                        const dataScadentei = new Date(rata.data_scadentei);
                        const astazi = new Date();
                        const zileLuna = getDaysInMonth(dataScadentei);
                        let culoareProgres;
                        let progres;

                        if (isFuture(dataScadentei)) {
                          culoareProgres = '#e0e0e0';
                          progres = 100;
                        } else if (isPast(dataScadentei) && !isThisMonth(dataScadentei)) {
                          culoareProgres = '#f44336';
                          progres = 100;
                        } else {
                          progres = (astazi.getDate() / zileLuna) * 100;
                          culoareProgres = astazi.getDate() <= dataScadentei.getDate() ? '#4caf50' : '#f44336';
                        }

                        const pozitieScadenta = (dataScadentei.getDate() / zileLuna) * 100;

                        return (
                          <Box key={rata.rateID} mt={2}>
                            <Box display="flex" alignItems="center" gap={1}>
                              <Typography variant="subtitle1">{`Rata ${rataIndex + 1}`}</Typography>
                              <Chip
                                label={rata.status}
                                color={rata.status.includes('neplatit') ? 'error' : 'success'}
                                size="small"
                                sx={{ fontSize: '0.75rem' }}
                              />
                            </Box>

                            <Box display="flex" justifyContent="space-between">
                              <Typography variant="body2">1</Typography>
                              <Typography variant="body2">{zileLuna}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" mt={1} mb={1}>
                              <Box width="100%" mr={1} position="relative">
                                <LinearProgress
                                  variant="determinate"
                                  value={progres}
                                  style={{ backgroundColor: '#e0e0e0', height: '10px', borderRadius: '5px' }}
                                />
                                <Box
                                  position="absolute"
                                  left={`${pozitieScadenta}%`}
                                  top="0"
                                  zIndex="1"
                                  height="100%"
                                  width="4px"
                                  bgcolor="black"
                                />
                                <Box
                                  position="absolute"
                                  top="0"
                                  left="0"
                                  width={`${progres}%`}
                                  height="100%"
                                  style={{ backgroundColor: culoareProgres, borderRadius: '5px' }}
                                ></Box>
                                <Box position="absolute" top="10px" left={`${pozitieScadenta - 2}%`} zIndex="2">
                                  <Typography
                                    variant="caption"
                                    style={{ backgroundColor: '#fff', padding: '0px 0px 0px', borderRadius: '4px' }}
                                  >{`${dataScadentei.getDate()}/${dataScadentei.getMonth() + 1}/${dataScadentei.getFullYear()}`}</Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ mt: 4, pt: '0px' }}
                            >
                              <Typography variant="body2" component="span">
                                Suma de plată: <strong>{rata.suma_totala} RON</strong>
                              </Typography>
                              <Typography variant="body2" component="span">
                                Scadență: <strong>{formatData(rata.data_scadentei)}</strong>
                              </Typography>
                            </Box>

                            <Box mt={1}>
                              <Typography variant="body2">
                                Suma Plătită: <strong>{rata.suma_platita} RON</strong>
                              </Typography>
                            </Box>

                            <Box
                              mt={2}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                background: '#f5f5f5',
                                padding: '8px 16px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              }}
                            >
                              <InputPlataRata
                                rateID={rata.rateID}
                                onPaymentUpdate={handlePaymentUpdate}
                                creeazaLinkDePlata={creeazaLinkDePlata}
                                sx={{ flex: 1 }}
                              />
                            </Box>

                            {tranzactiiState && tranzactiiState[rata.rateID] && (
                              <TranzactiiTimeline
                                tranzactii={tranzactiiState[rata.rateID]}
                                onTranzactieUpdate={handleTranzactieUpdate}
                                onTranzactieDelete={handleTranzactieDelete}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Badge>
            );
          })}
      </CardContent>
    </Card>
  );
};

export default DetaliiClient;
