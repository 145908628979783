import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Alert } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Results({ results, category, avgPrice }) {
  const [selectedPriceCard, setSelectedPriceCard] = useState(null);
  const [pretAchizitie, setPretAchizitie] = useState('--');

  const { purchasePrice, cheltuieliPerProdus, tax, filteredData } = results || {};
  const pretVoucher = purchasePrice * 0.95;
  const pretVanzare = purchasePrice * 0.90;
  const pretFastSale = purchasePrice * 0.85;

  useEffect(() => {
    console.log('Filtered Data:', filteredData);
  }, [filteredData]);

  useEffect(() => {
    console.log('Results:', results);
    console.log('Filtered Data:', filteredData);
    console.log('Purchase Price:', purchasePrice);
    console.log('Cheltuieli Per Produs:', cheltuieliPerProdus);
  }, [results, filteredData, purchasePrice, cheltuieliPerProdus]);

  const calculatePercentageChange = (newPrice, originalPrice) => {
    return ((originalPrice - newPrice) / originalPrice) * 100;
  };

  const percentageConsignatie = avgPrice ? calculatePercentageChange(purchasePrice, parseFloat(avgPrice)) : 0;
  const percentageVoucher = percentageConsignatie + calculatePercentageChange(pretVoucher, purchasePrice);
  const percentageVanzare = percentageConsignatie + calculatePercentageChange(pretVanzare, purchasePrice);
  const percentageFastSale = percentageConsignatie + calculatePercentageChange(pretFastSale, purchasePrice);

  const formatNumber = (num) => {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleCardSelect = (price) => {
    setSelectedPriceCard(price);
  };

  const getFixedCost = (category) => {
    switch (category) {
      case 'Genti':
        return 150;
      case 'Incaltaminte':
        return 100;
      case 'Ceasuri':
        return 150;
      case 'Accesorii':
        return 100;
      case 'Bijuterii':
        return 150;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const fixedCost = getFixedCost(category);
    if (selectedPriceCard !== null) {
      setPretAchizitie(selectedPriceCard + fixedCost);
    } else {
      setPretAchizitie('--');
    }
  }, [selectedPriceCard, category]);

  if (!results) {
    return null;
  }

  const banda = tax <= 415.14 ? 2000 : tax <= 1528.143409 ? 6000 : tax <= 2163.853908 ? 15000 : 15000;
  const percentageCheltuieli = calculatePercentageChange(cheltuieliPerProdus, banda);

  const priceCards = [
    { title: 'Pret Consignatie Maxim', price: purchasePrice, percentage: percentageConsignatie, color: 'green' },
    { title: 'Pret Voucher', price: pretVoucher, percentage: percentageVoucher, color: 'blue' },
    { title: 'Pret Vanzare', price: pretVanzare, percentage: percentageVanzare, color: 'orange' },
    { title: 'Pret FAST Sale', price: pretFastSale, percentage: percentageFastSale, color: 'red' },
  ];

  const carouselSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mt: 3 }}>
        <Slider {...carouselSettings}>
          {filteredData && filteredData.map((item, index) => (
            <Box key={index} sx={{ px: 1 }}>
              <Card onClick={() => window.open(item.url, "_blank")} sx={{ cursor: 'pointer', maxWidth: 300 }}>
                <CardContent>
                  <img src={item.image} alt={item.model} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                </CardContent>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }}>
        {priceCards.map((card, index) => (
          <Card key={index} sx={{ minWidth: 275, mx: 1, cursor: 'pointer' }} onClick={() => handleCardSelect(card.price)}>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                {card.title}
              </Typography>
              <Typography variant="h4" component="div">
                {formatNumber(card.price)} RON
              </Typography>
              <Typography color={card.color} variant="body2">
                {card.percentage > 0 ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
                {card.percentage.toFixed(2)}%
                <Typography variant="caption" color="textSecondary">
                  {' '}profit
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
        <Alert variant="outlined" color="info" sx={{ minWidth: 275, mx: 1 }}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Cheltuieli per Produs
          </Typography>
          <Typography variant="h5">
            {formatNumber(cheltuieliPerProdus)} RON
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {percentageCheltuieli.toFixed(2)}% mai mica decat Banda
          </Typography>
        </Alert>
        <Alert variant="outlined" color="info" sx={{ minWidth: 275, mx: 1 }}>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Banda
          </Typography>
          <Typography variant="h5">
            {formatNumber(banda)} RON
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Banda {banda}
          </Typography>
        </Alert>
      </Box>
    </Box>
  );
}

export default Results;
