import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable, useExpanded } from 'react-table';

const EditableCell = ({ value: initialValue, row, column, onSave, isInvoiceDateFallback }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onBlur = () => {
        onSave(row.original.issue_id, column.id, value);
    };

    return (
        <input
            type={column.id === 'data_vandut' || column.id === 'data_de_intrare' ? 'date' : 'text'}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={(e) => {
                if (e.key === 'Enter') onBlur();
            }}
            style={{ color: isInvoiceDateFallback ? 'red' : 'inherit' }} // Stil roșu  dacă este fallback
        />
        );
    };

function SalesPage() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [salesData, setSalesData] = useState([]);
    const [editedData, setEditedData] = useState({});

const handleFetchData = async () => {
    if (!startDate || !endDate) {
        alert('Please select both start and end dates.');
        return;
    }

    try {
        console.log("Trimitem cererea către API pentru   intervalul: ", startDate, endDate);
        
        const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/filtered-sales', {
            params: { startDate, endDate }
        });

        console.log("Răspunsul brut de la /filtered-sales:", response.data);

        if (!Array.isArray(response.data)) {
            console.error("Răspunsul nu este un array:", response.data);
            alert("A apărut o eroare: structura datelor nu este corectă.");
            return;
        }

        const formattedDataPromises = response.data.map(async (sale) => {
            console.log("Procesăm fiecare vânzare:", sale);

            if (!Array.isArray(sale.sku_details)) {
                console.error("sku_details nu este un array pentru vânzarea:", sale);
                return [];
            }

            return Promise.all(
                sale.sku_details.map(async (skuDetail) => {
                    console.log("Detalii SKU:", skuDetail);

                    let productData = null;
                    let taskData = {};

                    const sku = skuDetail.sku || skuDetail._ywpi_product_sku;
                    console.log(`SKU folosit pentru căutare: ${sku}`);

                    if (skuDetail.source === 'platforma' && sku) {
                        productData = await fetchProductData(sku);
                        console.log("Datele produsului găsite:", productData);
                    }

                    const issueId = skuDetail.details && skuDetail.details[0] && skuDetail.details[0].issue_id;
                    if (issueId) {
                        taskData = await fetchTaskData(issueId);
                        console.log("Datele task-ului găsite: ", taskData);
                    } else {
                        console.log(`Nu există detalii pentru SKU: ${sku}`);
                    }

                    return {
                        ID: sale.ID,
                        sku,
                        _ywpi_product_sku: skuDetail._ywpi_product_sku,
                        issue_id: issueId,
                        STATUS: sale.STATUS,
                        _ywpi_invoice_formatted_number: sale._ywpi_invoice_formatted_number,
                        _ywpi_invoice_date: sale._ywpi_invoice_date,
                        total: sale.total,
                        data_vandut: taskData.customfield_10079 || (skuDetail.details && skuDetail.details[0]?.data_vandut) || '',
                        nume_si_prenume: taskData.customfield_10067 || (skuDetail.details && skuDetail.details[0]?.nume_si_prenume) || '',
                        pret_intrare_ron: taskData.customfield_10072 || (skuDetail.details && skuDetail.details[0]?.pret_intrare_ron) || '',
                        pret_de_vanzare_ron: taskData.customfield_10073 || (skuDetail.details && skuDetail.details[0]?.pret_de_vanzare_ron) || '',
                        data_de_intrare: taskData.customfield_10077 || (skuDetail.details && skuDetail.details[0]?.data_de_intrare) || '',
                        productData,
                    };
                })
            );
        });

        const formattedData = (await Promise.all(formattedDataPromises)).flat();
        console.log("Datele formatate finale:", formattedData);
        setSalesData(formattedData);
    } catch (error) {
        console.error('Eroare la preluarea datelor:', error.message);
        if (error.response) {
            console.error('Statusul răspunsului:', error.response.status);
            console.error('Mesajul de eroare din răspuns:', error.response.data);
        }
        alert('A apărut o eroare la preluarea datelor.');
    }
};

const fetchProductData = async (sku) => {
    try {
        const response = await axios.get(`https://server-9p4hx.kinsta.app/api/jurnale/products/${sku}`);
        return response.data;
    } catch (error) {
        console.error('Eroare la preluarea datelor produsului:', error);
        return null;
    }
};

const fetchTaskData = async (issueId) => {
    try {
        const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`);
        return {
            customfield_10079: response.data.customfield_10079,
            customfield_10067: response.data.customfield_10067,
            customfield_10072: response.data.customfield_10072,
            customfield_10073: response.data.customfield_10073,
            customfield_10077: response.data.customfield_10077,
        };
    } catch (error) {
        console.error(`Eroare la preluarea datelor din taskjira pentru issue_id ${issueId}:`, error);
        return {};
    }
};
const handleEdit = (issueId, field, value) => {
    setEditedData(prev => ({
        ...prev,
        [issueId]: {
            ...prev[issueId],
            [field]: value,
        }
    }));
};

const handleSave = async (issueId) => {
    const dataToUpdate = editedData[issueId];
    if (!dataToUpdate) {
        alert("Nu există date de salvat pentru acest issue ID.   ");
        return;
    }

    // Verificăm dacă avem valoare pentru `data_vandut`  (customfield_10079)  
    const payload = {
        customfield_10079: dataToUpdate.data_vandut || salesData.find(sale => sale.issue_id === issueId)?.data_vandut || "", 
        customfield_10077: dataToUpdate.data_de_intrare || salesData.find(sale => sale.issue_id === issueId)?.data_de_intrare || "", 
        customfield_10067: dataToUpdate.nume_si_prenume || salesData.find(sale => sale.issue_id === issueId)?.nume_si_prenume || "",
        customfield_10072: Number(dataToUpdate.pret_intrare_ron || salesData.find(sale => sale.issue_id === issueId)?.pret_intrare_ron || 0),
        customfield_10073: Number(dataToUpdate.pret_de_vanzare_ron || salesData.find(sale => sale.issue_id === issueId)?.pret_de_vanzare_ron || 0),
    };

    console.log("Payload trimis la backend:", payload); // Debugging pentru a verifica datele trimise

    try {
        const response = await axios.put(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${issueId}`, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            alert('Datele au fost actualizate cu succes în JIRA.');
        } else {
            alert('Salvarea a fost realizată, dar a existat un răspuns neașteptat.');
        }
    } catch (error) {
        console.error('Eroare la actualizarea datelor în JIRA:', error);
        alert('A apărut o eroare la actualizarea datelor în JIRA.');
    }
};

    const handleCreateJiraTask = async (productData) => {
        try {
            const payload = {
                summary: productData.name,
                customfield_10083: productData.sku,
                customfield_10073: productData.price,
            };

            await axios.post(`https://server-9p4hx.kinsta.app/api/tasks/jira-task`, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            alert('Task-ul a fost creat cu succes în JIRA. ');
        } catch (error) {
            console.error('Eroare la crearea task-ului în JIRA:', error);
            alert('A apărut o eroare la crearea task-ului în JIRA.');
        }
    };
const columns = React.useMemo(() => [
    { Header: 'ID', accessor: 'ID' },
    { Header: 'SKU', accessor: 'sku' },
    { Header: 'Issue ID', accessor: 'issue_id' },
    { Header: 'STATUS', accessor: 'STATUS' },
    { Header: '_ywpi_invoice_formatted_number', accessor: '_ywpi_invoice_formatted_number' },
    { Header: '_ywpi_invoice_date', accessor: '_ywpi_invoice_date' },
    { Header: 'Total', accessor: 'total' },
        {
            Header: 'Data de Intrare',
            accessor: 'data_de_intrare',
            Cell: ({ row, column }) => (
                <EditableCell
                    value={editedData[row.original.issue_id]?.data_de_intrare || row.original.data_de_intrare || ''}
                    row={row}
                    column={column}
                    onSave={handleEdit}
                />
            )
        },
    {
            Header: 'Data Vânzare',
            accessor: 'data_vandut',
            Cell: ({ row, column }) => {
                const formatDate = (dateString) => {
                    if (!dateString) return '';
                    const date = new Date(dateString);
                    return date.toISOString().split('T')[0];
                };

                const isInvoiceDateFallback = !row.original.data_vandut;
                const displayedDate = row.original.data_vandut
                    ? formatDate(row.original.data_vandut)
                    : formatDate(row.original._ywpi_invoice_date);

                return (
                    <EditableCell
                        value={displayedDate}
                        row={row}
                        column={column}
                        onSave={handleEdit}
                        isInvoiceDateFallback={isInvoiceDateFallback} // Parametru pentru stil
                    />
            );
        }
    },
    {
        Header: 'Nume și Prenume', accessor: 'nume_si_prenume', Cell: ({ row, column }) => (
            <EditableCell
                value={editedData[row.original.issue_id]?.nume_si_prenume || row.original.nume_si_prenume || ''}
                row={row}
                column={column}
                onSave={handleEdit}
            />
        )
    },
    {
        Header: 'Preț Intrare (RON)', accessor: 'pret_intrare_ron', Cell: ({ row, column }) => (
            <EditableCell
                value={editedData[row.original.issue_id]?.pret_intrare_ron || row.original.pret_intrare_ron || ''}
                row={row}
                column={column}
                onSave={handleEdit}
            />
        )
    },
    {
        Header: 'Preț Vânzare (RON)', accessor: 'pret_de_vanzare_ron', Cell: ({ row, column }) => (
            <EditableCell
                value={editedData[row.original.issue_id]?.pret_de_vanzare_ron || row.original.pret_de_vanzare_ron || ''}
                row={row}
                column={column}
                onSave={handleEdit}
            />
        )
    },
    {
        Header: 'Actions', Cell: ({ row }) => (
            <div>
                {row.original.productData && row.original.source === 'platforma' ? (
                    <button onClick={() => handleCreateJiraTask(row.original.productData)}>Creare produs</button>
                ) : null}
                <button onClick={() => handleSave(row.original.issue_id)}>Save</button>
            </div>
        )
    }
], [editedData]);

const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: salesData },
    useExpanded
);

return (
    <div>
        <h1>Corectii</h1>
        <div>
            <label>Data de la:</label>
            <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
            <label>Data până la:</label>
            <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
            <button onClick={handleFetchData}>Fetch Sales Data</button>
        </div>
        <table {...getTableProps()} style={{ marginTop: '20px', width: '100%', border: '1px solid black' }}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} style={{ border: '1px solid black', padding: '8px' }}>
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <React.Fragment key={row.id}>
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} style={{ border: '1px solid black', padding: '8px' }}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                            {row.original.productData && (
                                <tr>
                                    <td colSpan={columns.length} style={{ paddingLeft: '40px', background: '#f9f9f9' }}>
                                        <strong>Produs din platformă:</strong> <br />
                                        Nume: {row.original.productData.name} <br />
                                        SKU: {row.original.productData.sku} <br />
                                        Preț: {row.original.productData.price} RON <br />
                                        <button onClick={() => handleCreateJiraTask(row.original.productData)}>CREARE</button>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    </div>
);
}

export default SalesPage;