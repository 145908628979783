import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListSubheader, Button, Radio, RadioGroup, FormControlLabel, IconButton, TextField, Select, MenuItem, Stack, Chip } from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import SyncIcon from '@mui/icons-material/Sync';

export default function VendorInfo({ email, onAddressSelect }) {
    const [passwordVerified, setPasswordVerified] = useState(false);
    const [passwordInput, setPasswordInput] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [vendorData, setVendorData] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [editingAddress, setEditingAddress] = useState(null);
    const [addingAddress, setAddingAddress] = useState(false);
    const [newAddress, setNewAddress] = useState({
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: 'Romania',
        categorie: '',  // Initial category for new address
    });
    const [addingConfidentialInfo, setAddingConfidentialInfo] = useState(false);
    const [confidentialInfo, setConfidentialInfo] = useState({
        cnp: '',
        serieCI: '',
        nrCI: '',
    });

    useEffect(() => {
        fetchVendorData();
    }, [email]);

    const fetchVendorData = async () => {
        if (!email) return;
        try {
        const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/vendor/${email}`);
        setVendorData(response.data);
        } catch (error) {
        console.error('Error fetching vendor data:', error);
        }
    };

    const handlePasswordSubmit = () => {
        if (passwordInput === 'gheorghelazar1') {
            setPasswordVerified(true);
            setPasswordError('');
        } else {
            setPasswordError('Parolă incorectă. Încercați din nou.');
        }
    };


    const handleSync = async () => {
        try {
            await axios.get('https://server-9p4hx.kinsta.app/api/tasks/jira-vendor-sync');
            fetchVendorData();
        } catch (error) {
            console.error('Error syncing vendor data:', error);
        }
    };

    const getUpdatedFields = (history) => {
        const updatedFields = [];
        if (history.old_email !== history.new_email) updatedFields.push(`Email: ${history.new_email}`);
        if (history.old_phone !== history.new_phone) updatedFields.push(`Phone: ${history.new_phone}`);
        if (history.old_iban !== history.new_iban) updatedFields.push(`IBAN: ${history.new_iban}`);
        return updatedFields;
    };

    const handleAddressSelect = (address) => {
        setSelectedAddress(address.address_id);
        onAddressSelect(address);
    };

      const handleAddConfidentialInfo = async () => {
    try {
      const response = await axios.post('https://server-9p4hx.kinsta.app/api/tasks/vendor/confidential-info', {
        vendor_id: vendorData.vendor.vendor_id,
        ...confidentialInfo,
      });

    console.log('Confidential info saved:', response.data);
        setAddingConfidentialInfo(false);
        setConfidentialInfo({ cnp: '', serieCI: '', nrCI: '' });
        fetchVendorData(); // Refresh vendor data after adding confidential info 
        } catch (error) {
        console.error('Error adding confidential info:', error);
        }
    };

    const handleDeleteAddress = async (addressId) => {
        try {
            await axios.delete(`https://server-9p4hx.kinsta.app/api/tasks/addresses/${addressId}`);
            setVendorData((prevData) => ({
                ...prevData,
                addresses: prevData.addresses.filter((address) => address.address_id !== addressId),
            }));
        } catch (error) {
            console.error('Error deleting address:', error);
        }
    };

    const handleEditAddress = (address) => {
        setEditingAddress(address);
    };

// Ensure `editingAddress` contains `categorie`
const handleSaveEdit = async () => {
    try {
        await axios.put(`https://server-9p4hx.kinsta.app/api/tasks/addresses/${editingAddress.address_id}`, editingAddress);
        setVendorData((prevData) => ({
            ...prevData,
            addresses: prevData.addresses.map((address) =>
                address.address_id === editingAddress.address_id ? editingAddress : address
            ),
        }));
        setEditingAddress(null);
    } catch (error) {
        console.error('Error updating address:', error);
    }
};

// Ensure `newAddress` contains `categorie`
const handleAddAddress = async () => {
    try {
        const response = await axios.post('https://server-9p4hx.kinsta.app/api/tasks/addresses', {
            vendor_id: vendorData.vendor.vendor_id,
            ...newAddress,
        });

        setVendorData((prevData) => ({
            ...prevData,
            addresses: [...prevData.addresses, { ...newAddress, address_id: response.data.address_id }]
        }));
        setAddingAddress(false);
        setNewAddress({
            address_line1: '',
            address_line2: '',
            city: '',
            state: '',
            postal_code: '',
            country: 'Romania',
            categorie: '',  // Default category
        });
    } catch (error) {
        console.error('Error adding address:', error);
    }
};

return (
    <Box sx={{ padding: '16px', backgroundColor: '#f7f8fa', borderRadius: '8px', marginBottom: '16px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#3a3a3a', mb: 2 }}>
                    Vendor Information
                </Typography>
                <IconButton color="primary" onClick={handleSync}>
                    <SyncIcon />
                </IconButton>
            </Stack>

        {/* Afisare istoric de contact, daca exista */}
        {vendorData?.contactHistory?.length > 0 && (
            <Box sx={{ marginBottom: 3 }}>
                <List
                    subheader={<ListSubheader component="div" sx={{ backgroundColor: 'inherit', fontWeight: 'bold', color: '#6b6b6b' }}>Contact History</ListSubheader>}
                    sx={{ bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1 }}
                >
                    {vendorData.contactHistory.map((history, index) => (
                        <ListItem key={index} sx={{ paddingY: 1 }}>
                            <ListItemText
                                primary={`Date: ${new Date(history.change_date).toLocaleDateString()}`}
                                secondary={getUpdatedFields(history).join(', ')}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
        )}

        {/* Selectia adreselor */}
<Box sx={{ marginTop: '16px' }}>
    {vendorData?.addresses?.length > 0 && (
        <RadioGroup 
            value={selectedAddress} 
            onChange={(e) => handleAddressSelect(vendorData.addresses.find(addr => addr.address_id === parseInt(e.target.value)))}
        >
            <List subheader={<ListSubheader>Addresses</ListSubheader>}>
                {vendorData.addresses.map((address) => (
                    <ListItem key={address.address_id}>
                        {/* Radio button for address selection */}
                        <FormControlLabel
                            value={address.address_id.toString()}
                            control={<Radio />}
                            label=""
                        />

                        {editingAddress && editingAddress.address_id === address.address_id ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 2 }}>
                                <TextField
                                    label="Address Line 1"
                                    variant="outlined"
                                    value={editingAddress.address_line1}
                                    onChange={(e) => setEditingAddress((prev) => ({ ...prev, address_line1: e.target.value }))}
                                />
                                <TextField
                                    label="Address Line 2"
                                    variant="outlined"
                                    value={editingAddress.address_line2 || ''}
                                    onChange={(e) => setEditingAddress((prev) => ({ ...prev, address_line2: e.target.value }))}
                                />
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    value={editingAddress.city || ''}
                                    onChange={(e) => setEditingAddress((prev) => ({ ...prev, city: e.target.value }))}
                                />
                                <TextField
                                    label="State"
                                    variant="outlined"
                                    value={editingAddress.state || ''}
                                    onChange={(e) => setEditingAddress((prev) => ({ ...prev, state: e.target.value }))}
                                />
                                <TextField
                                    label="Postal Code"
                                    variant="outlined"
                                    value={editingAddress.postal_code || ''}
                                    onChange={(e) => setEditingAddress((prev) => ({ ...prev, postal_code: e.target.value }))}
                                />
                                <Select
                                    label="Country"
                                    value={editingAddress.country || 'Romania'}
                                    onChange={(e) => setEditingAddress((prev) => ({ ...prev, country: e.target.value }))}
                                    variant="outlined"
                                >
                                    <MenuItem value="Romania">Romania</MenuItem>
                                    <MenuItem value="United States">United States</MenuItem>
                                    <MenuItem value="Canada">Canada</MenuItem>
                                </Select>
                                {/* Category dropdown for editing */}
                                <Select
                                    label="Category"
                                    value={editingAddress.categorie || ''}  // Use `categorie` here with empty default value
                                    onChange={(e) => setEditingAddress((prev) => ({ ...prev, categorie: e.target.value }))}
                                    variant="outlined"
                                >
                                    <MenuItem value="">Alege categoria</MenuItem> {/* New default option */}
                                    <MenuItem value="ci">Adresa CI</MenuItem>
                                    <MenuItem value="ridicat">Ridicat</MenuItem>
                                    <MenuItem value="ridicat2">Ridicat secund</MenuItem>
                                    <MenuItem value="retur">Retur</MenuItem>
                                </Select>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSaveEdit}>
                                        Save
                                    </Button>
                                    <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => setEditingAddress(null)}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                {/* Display address details */}
                                <ListItemText
                                    primary={address.address_line1}
                                    secondary={`${address.city || ''} ${address.state || ''} ${address.country || 'Romania'}`}
                                />

                                {/* Display the category as a Chip on the right */}
                                <Chip 
                                    label={
                                        address.categorie === 'ci' ? 'Adresa CI' : 
                                        address.categorie === 'ridicat' ? 'Ridicat' : 
                                        address.categorie === 'ridicat2' ? 'Ridicat secund' : 
                                        address.categorie === 'retur' ? 'Retur' : 
                                        'Unknown'
                                    } 
                                    sx={{ marginLeft: 'auto' }} 
                                />

                                {/* Edit and delete icons */}
                                <IconButton onClick={() => handleEditAddress(address)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteAddress(address.address_id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        )}
                    </ListItem>
                ))}
            </List>
        </RadioGroup>
    )}
</Box>





        {/* Butoane pentru Add Address și Add Info Confidential */}
        <Box sx={{ marginTop: '16px', display: 'flex', gap: 2 }}>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddingAddress(true)}>
                Add Address
            </Button>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddingConfidentialInfo(true)}>
                Add Info Confidential
            </Button>
        </Box>

        {/* Formular de adaugare adresa */}
        {addingAddress && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '16px' }}>
                <TextField
                    label="Address Line 1"
                    variant="outlined"
                    value={newAddress.address_line1}
                    onChange={(e) => setNewAddress((prev) => ({ ...prev, address_line1: e.target.value }))}
                />
                <TextField
                    label="Address Line 2"
                    variant="outlined"
                    value={newAddress.address_line2}
                    onChange={(e) => setNewAddress((prev) => ({ ...prev, address_line2: e.target.value }))}
                />
                <TextField
                    label="City"
                    variant="outlined"
                    value={newAddress.city}
                    onChange={(e) => setNewAddress((prev) => ({ ...prev, city: e.target.value }))}
                />
                <TextField
                    label="State"
                    variant="outlined"
                    value={newAddress.state}
                    onChange={(e) => setNewAddress((prev) => ({ ...prev, state: e.target.value }))}
                />
                <TextField
                    label="Postal Code"
                    variant="outlined"
                    value={newAddress.postal_code}
                    onChange={(e) => setNewAddress((prev) => ({ ...prev, postal_code: e.target.value }))}
                />
                <Select
                    label="Country"
                    value={newAddress.country}
                    onChange={(e) => setNewAddress((prev) => ({ ...prev, country: e.target.value }))}
                    variant="outlined"
                >
                    <MenuItem value="Romania">Romania</MenuItem>
                    <MenuItem value="United States">United States</MenuItem>
                    <MenuItem value="Canada">Canada</MenuItem>
                </Select>
                <Select
                    label="Category"
                    value={newAddress.categorie}  // Use `categorie` here
                    onChange={(e) => setNewAddress((prev) => ({ ...prev, categorie: e.target.value }))}
                    variant="outlined"
                >
                    <MenuItem value="">Alege categoria</MenuItem> {/* New default option */}
                    <MenuItem value="ci">Adresa CI</MenuItem>
                    <MenuItem value="ridicat">Ridicat</MenuItem>
                    <MenuItem value="ridicat2">Ridicat secund</MenuItem>
                    <MenuItem value="retur">Retur</MenuItem>
                </Select>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddAddress}>
                        Add
                    </Button>
                    <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => setAddingAddress(false)}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        )}

        {/* Verificarea parolei și afisarea formularului pentru informatii confidentiale */}
        {addingConfidentialInfo && !passwordVerified ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '16px' }}>
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                />
                <Button variant="contained" color="primary" onClick={handlePasswordSubmit}>
                    Verify Password
                </Button>
            </Box>
        ) : (
            addingConfidentialInfo && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '16px' }}>
                    <TextField
                        label="CNP"
                        variant="outlined"
                        value={confidentialInfo.cnp}
                        onChange={(e) => setConfidentialInfo({ ...confidentialInfo, cnp: e.target.value })}
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="Serie CI"
                            variant="outlined"
                            value={confidentialInfo.serieCI}
                            onChange={(e) => setConfidentialInfo({ ...confidentialInfo, serieCI: e.target.value })}
                        />
                        <TextField
                            label="Număr CI"
                            variant="outlined"
                            value={confidentialInfo.nrCI}
                            onChange={(e) => setConfidentialInfo({ ...confidentialInfo, nrCI: e.target.value })}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleAddConfidentialInfo}>
                            Save
                        </Button>
                        <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => setAddingConfidentialInfo(false)}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            )
        )}
    </Box>
);

}
