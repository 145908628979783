function calculateCost(avgPrice, category) {
  let cheltuieliPerProdus, tax, purchasePrice, purchasePriceAfterDiscount;

  const calculateBand = (price) => {
    if (price <= 2000) {
      cheltuieliPerProdus = 415.14 * (price / 2000);
      tax = 415.14;
    } else if (price <= 6000) {
      cheltuieliPerProdus = 1287.38 * (price / 6000);
      tax = 1287.38;
    } else if (price <= 15000) {
      cheltuieliPerProdus = 3253.80 * (price / 15000);
      tax = 3253.80;
    } else {
      cheltuieliPerProdus = 3451 * (price / 15000);
      tax = 3451;
    }
  };

  // Initial calculation
  calculateBand(avgPrice);
  purchasePrice = avgPrice - cheltuieliPerProdus; // purchasePrice is avgPrice minus cheltuieliPerProdus

  purchasePriceAfterDiscount = purchasePrice * 0.97; // Scădem 3%
  return {
    purchasePrice: Number(purchasePriceAfterDiscount),
    cheltuieliPerProdus: Number(cheltuieliPerProdus),
    tax: Number(tax)
  };
}

export default calculateCost;
