import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, CircularProgress, Box, Collapse, TextField, InputAdornment, IconButton, Typography, Checkbox, Grid } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); } 
`;

const FancyButton = styled(Button)`
  background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
  border: 0;
  border-radius: 3px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
  color: white;
  height: 48px;
  padding: 0 30px;
  margin-right: 8px;
  &:hover {
    animation: ${pulse} 1s infinite;
  }
`;

const RecalculateButton = styled(Button)`
  background: linear-gradient(45deg, #66BB6A 30%, #43A047 90%);
  border: 0;
  border-radius: 3px;
  box-shadow: 0 3px 5px 2px rgba(67, 160, 71, .3);
  color: white;
  height: 48px;
  padding: 0 30px;
  &:hover {
    animation: ${pulse} 1s infinite;
  }
`;

const FancyText = styled('span')`
  font-weight: bold;
  color: #6A0DAD;
`;

const validConditions = ["Nou", "Foarte buna", "Buna", "Rezonabil"];

const ProductTable = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState({});
  const [editableValues, setEditableValues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [productDetails, setProductDetails] = useState({});
  const [priceInRON, setPriceInRON] = useState({});
  const [priceAnalysis, setPriceAnalysis] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [avgPurchasePriceInRON, setAvgPurchasePriceInRON] = useState({});
  const [images, setImages] = useState({});
  const [removedMaterial, setRemovedMaterial] = useState({});
  const [consoleLogs, setConsoleLogs] = useState({});

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios.get('https://server-9p4hx.kinsta.app/api/jurnale/intrari-ai-list')
      .then(response => {
        const initialEditableValues = {};
        const initialLoading = {};
        const initialPriceInRON = {};
        const initialPriceAnalysis = {};
        const initialAvgPurchasePriceInRON = {};
        const initialImages = {};
        const initialRemovedMaterial = {};
        const initialSelectedItems = {};
        const initialConsoleLogs = {};

        response.data.forEach(product => {
          initialEditableValues[product.issueId] = {
            summary: product.summary,
            stare: product.stare,
            pretIntrareRON: product.pretIntrareRON
          };
          initialLoading[product.issueId] = false;
          initialPriceInRON[product.issueId] = null;
          initialPriceAnalysis[product.issueId] = null;
          initialAvgPurchasePriceInRON[product.issueId] = null;
          initialImages[product.issueId] = [];
          initialRemovedMaterial[product.issueId] = null;
          initialSelectedItems[product.issueId] = {};
          initialConsoleLogs[product.issueId] = [];
        });

        setProducts(response.data);
        setDisplayedProducts(response.data.slice(0, ITEMS_PER_PAGE));
        setEditableValues(initialEditableValues);
        setLoading(initialLoading);
        setPriceInRON(initialPriceInRON);
        setPriceAnalysis(initialPriceAnalysis);
        setAvgPurchasePriceInRON(initialAvgPurchasePriceInRON);
        setImages(initialImages);
        setRemovedMaterial(initialRemovedMaterial);
        setSelectedItems(initialSelectedItems);
        setConsoleLogs(initialConsoleLogs);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, []);

  useEffect(() => {
    setDisplayedProducts(
      products
        .filter(product => product.summary.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE)
    );
  }, [searchTerm, products, currentPage]);

  const convertToRON = async (price, currency) => {
    if (currency === 'USD') {
      try {
        const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/USD`);
        const rate = response.data.rates.RON;
        return (price * rate).toFixed(2);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
        return price;
      }
    }
    return price;
  };

  const handleInputChange = (productId, field, value) => {
    setEditableValues(prevState => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        [field]: value
      }
    }));
  };

  const calculatePrice = async (productId) => {
    setLoading(prevState => ({ ...prevState, [productId]: true }));
    try {
      const { summary, stare, pretIntrareRON } = editableValues[productId];

      const response = await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/calculate-price', { summary, stare });
      const { avgPrice, avgPurchasePrice, currency, matchingProducts, removedMaterial, logs } = response.data;

      setConsoleLogs(prevState => ({
        ...prevState,
        [productId]: logs
      }));

      if (avgPrice) {
        const priceInRONValue = await convertToRON(avgPrice, currency);
        const purchasePriceInRONValue = await convertToRON(avgPurchasePrice, currency);

        const percentageDifference = Math.abs(((pretIntrareRON - purchasePriceInRONValue) / purchasePriceInRONValue) * 100).toFixed(2);
        const comparisonText = pretIntrareRON > purchasePriceInRONValue ? 'mai mare' : 'mai mic';

        const analysisText = `Pretul nostru de intrare este de ${pretIntrareRON} RON si este ${comparisonText} decat ${purchasePriceInRONValue} RON cu ${percentageDifference}%.`;

        setPriceInRON(prevState => ({ ...prevState, [productId]: priceInRONValue }));
        setAvgPurchasePriceInRON(prevState => ({ ...prevState, [productId]: purchasePriceInRONValue }));
        setPriceAnalysis(prevState => ({ ...prevState, [productId]: analysisText }));
        setImages(prevState => ({ ...prevState, [productId]: matchingProducts.map(product => product.Image) }));
        setProductDetails(prevState => ({ ...prevState, [productId]: matchingProducts }));
        setRemovedMaterial(prevState => ({ ...prevState, [productId]: removedMaterial }));
        setSelectedItems(prevState => ({
          ...prevState,
          [productId]: matchingProducts.reduce((acc, item) => {
            acc[item.id] = false;
            return acc;
          }, {})
        }));
      } else {
        setPriceInRON(prevState => ({ ...prevState, [productId]: 'N/A' }));
        setPriceAnalysis(prevState => ({ ...prevState, [productId]: null }));
      }
    } catch (error) {
      console.error('Error calculating price:', error);
      setConsoleLogs(prevState => ({
        ...prevState,
        [productId]: [...prevState[productId], `Error calculating price: ${error.message}`]
      }));
      setPriceInRON(prevState => ({ ...prevState, [productId]: 'N/A' }));
      setPriceAnalysis(prevState => ({ ...prevState, [productId]: null }));
    }
    setLoading(prevState => ({ ...prevState, [productId]: false }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleItemSelection = (productId, itemId) => {
    setSelectedItems(prevState => ({
      ...prevState,
      [productId]: {
        ...prevState[productId],
        [itemId]: !prevState[productId][itemId]
      }
    }));
  };

  const recalculatePrice = async (productId) => {
    const selectedProductDetails = productDetails[productId];
    const selectedProductIds = Object.keys(selectedItems[productId]).filter(itemId => selectedItems[productId][itemId]);
    const selectedProducts = selectedProductDetails.filter(product => selectedProductIds.includes(product.id));

    const avgSalePrice = selectedProducts.reduce((acc, product) => acc + (parseFloat(product.Price_sale) || parseFloat(product.Price)), 0) / selectedProducts.length;

    const avgPurchasePrice = selectedProducts.reduce((acc, product) => acc + parseFloat(product.Purchase_price), 0) / selectedProducts.length;

    const avgPriceInRON = await convertToRON(avgSalePrice, 'USD');
    const avgPurchasePriceInRON = await convertToRON(avgPurchasePrice, 'USD');

    setPriceInRON(prevState => ({ ...prevState, [productId]: avgPriceInRON }));
    setAvgPurchasePriceInRON(prevState => ({ ...prevState, [productId]: avgPurchasePriceInRON }));

    const pretIntrareRON = editableValues[productId].pretIntrareRON;
    const percentageDifference = Math.abs(((pretIntrareRON - avgPurchasePriceInRON) / avgPurchasePriceInRON) * 100).toFixed(2);
    const comparisonText = pretIntrareRON > avgPurchasePriceInRON ? 'mai mare' : 'mai mic';

    const analysisText = `Pretul nostru de intrare este de ${pretIntrareRON} RON si este ${comparisonText} decat ${avgPurchasePriceInRON} RON cu ${percentageDifference}%.`;

    setPriceAnalysis(prevState => ({ ...prevState, [productId]: analysisText }));
  };

  return (
    <TableContainer>
      <TextField
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Summary</TableCell>
            <TableCell>Conditie</TableCell>
            <TableCell>Pret Intrare</TableCell>
            <TableCell>Pret de Vânzare (calculat)</TableCell>
            <TableCell>Data cerere</TableCell>
            <TableCell>Status</TableCell>
            <TableCell style={{ minWidth: '200px' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedProducts.map(product => (
            <React.Fragment key={product.issueId}>
              <TableRow key={product.issueId}>
                <TableCell>{product.issueId}</TableCell>
                <TableCell>
                  <TextField
                    value={editableValues[product.issueId]?.summary || ''}
                    onChange={e => handleInputChange(product.issueId, 'summary', e.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                    key={`summary-${product.issueId}`}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={editableValues[product.issueId]?.stare || ''}
                    onChange={e => handleInputChange(product.issueId, 'stare', e.target.value)}
                    fullWidth
                    key={`stare-${product.issueId}`}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={editableValues[product.issueId]?.pretIntrareRON || ''}
                    onChange={e => handleInputChange(product.issueId, 'pretIntrareRON', e.target.value)}
                    fullWidth
                    key={`pretIntrareRON-${product.issueId}`}
                  />
                </TableCell>
                <TableCell>
                  {loading[product.issueId] ? (
                    <CircularProgress />
                  ) : (
                    priceInRON[product.issueId] || 'N/A'
                  )}
                </TableCell>
                <TableCell>
                  {new Date(product.createdDate).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {product.status}
                </TableCell>
                <TableCell>
                  <Box display="flex">
                    <FancyButton 
                      onClick={() => calculatePrice(product.issueId)}
                      disabled={!validConditions.includes(editableValues[product.issueId]?.stare)}
                    >
                      Calculate Price
                    </FancyButton>
                    {selectedItems[product.issueId] && Object.values(selectedItems[product.issueId]).some(selected => selected) && (
                      <RecalculateButton onClick={() => recalculatePrice(product.issueId)}>
                        Recalculate Price
                      </RecalculateButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
              {consoleLogs[product.issueId] && consoleLogs[product.issueId].length > 0 && (
                <TableRow key={`logs-${product.issueId}`}>
                  <TableCell colSpan={8}>
                    <Collapse in={!!consoleLogs[product.issueId].length}>
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Box textAlign="center" mb={2} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                          <Typography variant="body2" style={{ color: '#333', fontFamily: 'monospace' }}>
                            {consoleLogs[product.issueId].map((log, index) => (
                              <div key={index}>{log}</div>
                            ))}
                          </Typography>
                        </Box>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
              {productDetails[product.issueId] && (
                <TableRow key={`details-${product.issueId}`}>
                  <TableCell colSpan={8}>
                    <Collapse in={!!priceInRON[product.issueId]}>
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Box textAlign="center" mb={2}>
                          <FancyText>{priceAnalysis[product.issueId]}</FancyText>
                        </Box>
                        {images[product.issueId] && images[product.issueId].length > 0 && (
                          <Grid container spacing={2}>
                            {images[product.issueId].map((image, index) => (
                              <Grid item xs={12} sm={6} md={3} key={index}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  width="100%"
                                  my={2}
                                  style={{ pageBreakInside: 'avoid' }}
                                >
                                  <Checkbox
                                    checked={selectedItems[product.issueId] && selectedItems[product.issueId][productDetails[product.issueId][index].id]}
                                    onChange={() => handleItemSelection(product.issueId, productDetails[product.issueId][index].id)}
                                  />
                                  <img src={image} alt="Product" style={{ width: '200px', margin: '10px' }} />
                                  {productDetails[product.issueId][index] && (
                                    <>
                                      <Box textAlign="center" mt={1}>
                                        <Typography noWrap variant="body2" style={{ maxWidth: '200px', wordWrap: 'break-word' }}>
                                          ID: {productDetails[product.issueId][index].id}
                                        </Typography>
                                      </Box>
                                      <Box textAlign="center" mt={1}>
                                        <Typography noWrap variant="body2" style={{ maxWidth: '200px', wordWrap: 'break-word' }}>
                                          {productDetails[product.issueId][index].Model === 'N/A' ? productDetails[product.issueId][index].Namemodel : productDetails[product.issueId][index].Model}
                                        </Typography>
                                      </Box>
                                      <Box textAlign="center" mt={1}>
                                        <FancyText>Conditie: {productDetails[product.issueId][index].Conditio}</FancyText>
                                      </Box>
                                      <Box textAlign="center" mt={1}>
                                        <FancyText>Insotit de: {productDetails[product.issueId][index].ComesWith}</FancyText>
                                      </Box>
                                    </>
                                  )}
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                        {removedMaterial[product.issueId] && (
                          <Box textAlign="center" style={{ textDecoration: 'line-through', fontWeight: 'bold' }}>
                            Material negasit: {removedMaterial[product.issueId].toUpperCase()}
                          </Box>
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
          disabled={currentPage === 0}
        >
          Previous
        </Button>
        <Button
          onClick={() => setCurrentPage(prev => prev + 1)}
          disabled={(currentPage + 1) * ITEMS_PER_PAGE >= products.length}
        >
          Next
        </Button>
      </Box>
    </TableContainer>
  );
};

export default ProductTable;
