import React, { useState } from 'react';
import { Timeline, TimelineItem, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const colors = ['primary', 'secondary', 'error', 'warning', 'info'];

const TranzactiiTimeline = ({ tranzactii, onTranzactieUpdate, onTranzactieDelete }) => {
  const [editState, setEditState] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTranzactieID, setSelectedTranzactieID] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
  };

  const handleMenuClick = (event, tranzactieID) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTranzactieID(tranzactieID);
  };

  const handleMenuClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    setSelectedTranzactieID(null);
  };

  const handleEditClick = (event, tranzactieID, suma) => {
    event.preventDefault();
    event.stopPropagation();
    setEditState((prevState) => ({
      ...prevState,
      [tranzactieID]: true,
    }));
    setEditedValues((prevState) => ({
      ...prevState,
      [tranzactieID]: suma,
    }));
    handleMenuClose(event);
  };

  const handleInputChange = (tranzactieID, value) => {
    setEditedValues((prevState) => ({
      ...prevState,
      [tranzactieID]: value,
    }));
  };

  const updateRateStatus = async (rateID) => {
    try {
      const response = await axios.get(`https://server-9p4hx.kinsta.app/api/llp/get-llp-rate/${rateID.split('Rata')[0]}`);
      const rateData = response.data.find(rate => rate.rateID === rateID);

      if (!rateData) {
        console.error(`No rate data found for rateID: ${rateID}`);
        return;
      }

      const { suma_totala, suma_platita } = rateData;

      console.log(`RateID: ${rateID}, Suma Totala: ${suma_totala}, Suma Platita: ${suma_platita}`);

      let status;
      if (suma_platita === 0) {
        status = 'neplatita';
      } else if (suma_platita < suma_totala) {
        status = 'partial platita';
      } else {
        status = 'platita';
      }

      console.log(`Updating status for RateID: ${rateID} to ${status}`);

      await axios.put(`https://server-9p4hx.kinsta.app/api/llp/update-rate-status`, {
        rateID,
        status,
      });

      return { suma_totala, suma_platita, status }; 
    } catch (error) {
      console.error('Eroare la actualizarea statusului ratei:', error);
    }
  };

  const handleSaveClick = async (event, tranzactieID) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const sumaNoua = parseFloat(editedValues[tranzactieID]);
      const tranzactieOriginala = tranzactii.find(tr => tr.tranzactieID === tranzactieID);
      await axios.put(`https://server-9p4hx.kinsta.app/api/llp/update-tranzactie`, {
        tranzactieID,
        suma: sumaNoua,
      });
      onTranzactieUpdate(tranzactieID, sumaNoua);

      await axios.put(`https://server-9p4hx.kinsta.app/api/llp/update-rate-suma`, {
        rateID: tranzactieOriginala.rateID,
        suma: sumaNoua - tranzactieOriginala.suma,
      });

      const updatedRateStatus = await updateRateStatus(tranzactieOriginala.rateID);

      setEditState((prevState) => ({
        ...prevState,
        [tranzactieID]: false,
      }));

      if (updatedRateStatus) {
        onTranzactieUpdate(tranzactieID, sumaNoua, updatedRateStatus.status); 
      }
    } catch (error) {
      console.error('Eroare la actualizarea tranzacției:', error);
      alert('Eroare la actualizarea tranzacției. Verificați consola pentru detalii.');
    }
  };

  const handleDeleteClick = async (event, tranzactieID) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const tranzactieOriginala = tranzactii.find(tr => tr.tranzactieID === tranzactieID);
      await axios.delete(`https://server-9p4hx.kinsta.app/api/llp/delete-tranzactie/${tranzactieID}`);
      onTranzactieDelete(tranzactieID);

      await axios.put(`https://server-9p4hx.kinsta.app/api/llp/update-rate-suma`, {
        rateID: tranzactieOriginala.rateID,
        suma: -tranzactieOriginala.suma,
      });

      const updatedRateStatus = await updateRateStatus(tranzactieOriginala.rateID);

      if (updatedRateStatus) {
        onTranzactieDelete(tranzactieID, updatedRateStatus.status);
      }
    } catch (error) {
      console.error('Eroare la ștergerea tranzacției:', error);
      alert('Eroare la ștergerea tranzacției. Verificați consola pentru detalii.');
    }
    handleMenuClose(event);
  };

  return (
    <Timeline position="alternate">
      {tranzactii.map((tranzactie, index) => (
        <TimelineItem key={tranzactie.tranzactieID}>
          <TimelineOppositeContent color="text.secondary">
            <Typography variant="body2" align={index % 2 === 0 ? 'right' : 'left'}>
              {formatDate(tranzactie.dataPlata)}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={colors[index % colors.length]} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            {editState[tranzactie.tranzactieID] ? (
              <TextField
                value={editedValues[tranzactie.tranzactieID]}
                onChange={(e) => handleInputChange(tranzactie.tranzactieID, e.target.value)}
                size="small"
                sx={{ width: '100px' }}
              />
            ) : (
              <Typography
                variant="body2"
                component="span"
                sx={{
                  position: 'relative',
                  top: '-8px',
                }}
              >
                {`${tranzactie.suma} RON`}
              </Typography>
            )}
            {editState[tranzactie.tranzactieID] ? (
              <IconButton
                size="small"
                onClick={(e) => handleSaveClick(e, tranzactie.tranzactieID)}
                sx={{ ml: 1 }}
              >
                <SaveIcon />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                onClick={(e) => handleMenuClick(e, tranzactie.tranzactieID)}
                sx={{ ml: 1 }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedTranzactieID === tranzactie.tranzactieID}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={(e) => handleEditClick(e, tranzactie.tranzactieID, tranzactie.suma)}>
                <EditIcon fontSize="small" /> Edit
              </MenuItem>
              <MenuItem onClick={(e) => handleDeleteClick(e, tranzactie.tranzactieID)}>
                <DeleteIcon fontSize="small" /> Delete
              </MenuItem>
            </Menu>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default TranzactiiTimeline;
