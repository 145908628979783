import React, { useState } from 'react';
import { Button, Box, Card, CardMedia, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'IMAGE';

const DraggableImage = ({ image, index, moveImage, deleteImage }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index }
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    }
  });

  return (
    <div ref={(node) => ref(drop(node))} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '8px' }}>
      <Card sx={{ maxWidth: 120, mb: 1 }}>
        <CardMedia
          component="img"
          height="100"
          image={URL.createObjectURL(image)}
          alt={`Gallery Image ${index + 1}`}
        />
      </Card>
      <IconButton onClick={() => deleteImage(index)}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

const ImageUploader = ({ onImagesChange }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    setProfileImage(file);
    onImagesChange('profile', file);
  };

  const handleGalleryImagesChange = (event) => {
    const newImages = [...galleryImages, ...Array.from(event.target.files)];
    setGalleryImages(newImages);
    onImagesChange('gallery', newImages);
  };

  const handleDeleteProfileImage = () => {
    setProfileImage(null);
    onImagesChange('profile', null);
  };

  const handleDeleteGalleryImage = (index) => {
    const newGalleryImages = [...galleryImages];
    newGalleryImages.splice(index, 1);
    setGalleryImages(newGalleryImages);
    onImagesChange('gallery', newGalleryImages);
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedGalleryImages = [...galleryImages];
    const [movedImage] = updatedGalleryImages.splice(fromIndex, 1);
    updatedGalleryImages.splice(toIndex, 0, movedImage);
    setGalleryImages(updatedGalleryImages);
    onImagesChange('gallery', updatedGalleryImages);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" component="label">
          Upload Imagine Profil
          <input type="file" hidden onChange={handleProfileImageChange} />
        </Button>
        {profileImage && (
          <Card sx={{ maxWidth: 345, mt: 2 }}>
            <CardMedia
              component="img"
              height="140"
              image={URL.createObjectURL(profileImage)}
              alt="Profile Image"
            />
            <IconButton onClick={handleDeleteProfileImage}>
              <DeleteIcon />
            </IconButton>
          </Card>
        )}
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" component="label">
            Upload Imagini Galerie
            <input type="file" hidden multiple onChange={handleGalleryImagesChange} />
          </Button>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '16px' }}>
            {galleryImages.map((image, index) => (
              <DraggableImage
                key={index}
                image={image}
                index={index}
                moveImage={moveImage}
                deleteImage={handleDeleteGalleryImage}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default ImageUploader;
