import React, { useState } from 'react';
import TaskTable from './TaskTable';
import TaskEdit from './TaskEdit';
import { Tabs, Tab, Box, Typography, Button } from '@mui/material';
import { green, blue, grey, orange, red, purple, teal } from '@mui/material/colors';

const App = () => {
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [reload, setReload] = useState(false);

    const handleTaskSelect = (issueId) => {
        setSelectedTaskId(issueId);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setSelectedTaskId(null); // Reset the selected task when changing tabs
    };

    const handleCloseTaskEdit = () => {
        setSelectedTaskId(null);
        setReload(!reload); // Toggle reload state to refresh tasks
    };

const handleRefusedTasks = async () => {
    const newTab = window.open('', '_blank');
    newTab.document.write('<h3>Loading refused tasks...</h3>');

    try {
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        const formattedDate = threeDaysAgo.toISOString();

        console.log('[INFO] Fetching refused tasks since:', formattedDate);

        const response = await fetch(
            `https://server-9p4hx.kinsta.app/api/tasks/jira-tasks/refused?modifiedSince=${formattedDate}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();

        console.log('[INFO] Refused tasks data:', data);

        if (!data || data.length === 0) {
            newTab.document.body.innerHTML = '<h3>No refused tasks found in the last 3 days.</h3>';
            return;
        }

        const refusedTasksHtml = `
            <h2>Refuzate în ultimele 3 zile</h2>
            <table style="width: 100%; border-collapse: collapse; text-align: left;">
                <thead>
                    <tr>
                        <th style="border: 1px solid black; padding: 8px;">Issue ID</th>
                        <th style="border: 1px solid black; padding: 8px;">Summary</th>
                        <th style="border: 1px solid black; padding: 8px;">Last Updated</th>
                        <th style="border: 1px solid black; padding: 8px;">Custom Field 10193</th>
                    </tr>
                </thead>
                <tbody>
                    ${data
                        .map((task) => {
                            // Extract description (ADF format)
                            const description = task.description?.content
                                ?.map((block) =>
                                    block.content?.map((inline) => inline.text || '').join('<br />')
                                )
                                .join('<br />') || 'No description available';

                            return `
                                <tr>
                                    <td style="border: 1px solid black; padding: 8px;">${task.issue_id}</td>
                                    <td style="border: 1px solid black; padding: 8px;">${task.summary}</td>
                                    <td style="border: 1px solid black; padding: 8px;">${new Date(task.updated).toLocaleDateString()}</td>
                                    <td style="border: 1px solid black; padding: 8px;">${task.customfield_10193 || 'Not Available'}</td>
                                </tr>
                            `;
                        })
                        .join('')}
                </tbody>
            </table>
        `;

        newTab.document.body.innerHTML = refusedTasksHtml;
    } catch (error) {
        newTab.document.body.innerHTML = `<h3>Error loading refused tasks: ${error.message}</h3>`;
        console.error('[ERROR] Error fetching refused tasks:', error);
    }
};


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: '20px' }}>
            {/* Header pentru butonul Refuzat */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', paddingRight: '20px' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRefusedTasks}
                    sx={{
                        backgroundColor: red[500],
                        '&:hover': {
                            backgroundColor: red[700],
                        },
                    }}
                >
                    Refuzat
                </Button>
            </Box>

            {/* Tab-uri */}
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                TabIndicatorProps={{
                    style: {
                        backgroundColor: 'transparent',
                    },
                }}
                sx={{
                    backgroundColor: 'transparent',
                    '.MuiTab-root': {
                        minWidth: '15%', // Dimensiunea minimă redusă pentru a comprima taburile
                        maxWidth: '20%', // Adăugăm o dimensiune maximă pentru flexibilitate
                        position: 'relative',
                        '&:not(:last-of-type)::after': {
                            content: '""',
                            display: 'block',
                            width: '1px',
                            height: '40px',
                            backgroundColor: grey[300],
                            position: 'absolute',
                            right: 0,
                            top: 'calc(50% - 20px)',
                        },
                    },
                }}
            >
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: green[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Creat</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: blue[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Prospect</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: orange[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Acceptat</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: red[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Tranzit</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: purple[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Autentificare</Typography>
                        </Box>
                    }
                />
                <Tab
                    label={
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: teal[500],
                                    marginBottom: '8px',
                                }}
                            />
                            <Typography variant="caption">Online</Typography>
                        </Box>
                    }
                />
            </Tabs>


            {/* Tabele pentru taskuri */}
            <Box sx={{ display: 'flex', width: '120%', marginTop: '20px' }}>
                {selectedTab === 0 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="2_CREAT" reload={reload} />}
                {selectedTab === 1 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="PROSPECT" reload={reload} />}
                {selectedTab === 2 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="ACCEPTAT" reload={reload} />}
                {selectedTab === 3 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="TRANZIT" reload={reload} />}
                {selectedTab === 4 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="AUTENTIFICARE" reload={reload} />}
                {selectedTab === 5 && <TaskTable onTaskSelect={handleTaskSelect} projectKey="LEB" status="ONLINE" reload={reload} />}
                {selectedTaskId && (
                <TaskEdit
                    issueId={selectedTaskId}
                    style={{ width: '40%' }}
                    tab={
                        selectedTab === 0 
                            ? 'created' 
                            : selectedTab === 1 
                            ? 'prospect' 
                            : selectedTab === 2 
                            ? 'acceptat' 
                            : selectedTab === 3 
                            ? 'tranzit' 
                            : selectedTab === 4 
                            ? 'autentificare' 
                            : 'online'
                    }
                    onClose={handleCloseTaskEdit}
                    reloadTasks={() => setReload(!reload)}
                />

                )}
            </Box>
        </Box>
    );
};

export default App;
