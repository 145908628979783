import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, TextField, Button, Grid, Alert } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const extractIDFromSKU = (sku) => {
  const match = sku.match(/0*(\d+)$/);
  return match ? match[1] : null;
};

const WooCommerceProductDetails = ({ sku, journalNumber }) => {
  const [productDetails, setProductDetails] = useState(null);
  const [price, setPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [dateOnSaleFrom, setDateOnSaleFrom] = useState(null);
  const [dateOnSaleTo, setDateOnSaleTo] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showBlueAlert, setShowBlueAlert] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const id = extractIDFromSKU(sku);
      if (id) {
        try {
          const response = await axios.get(`https://www.luxuraelite.ro/wp-json/wc/v3/products/${id}`, {
            headers: {
              'Authorization': 'Basic ' + btoa('ck_a941824be557253d3765a1f6242e6d6a590da999:cs_6b0d98e4c442e707be9888cafff1fac17cdbf259')
            }
          });
          setProductDetails(response.data);
          setPrice(response.data.regular_price);
          setSalePrice(response.data.sale_price);
          setDateOnSaleFrom(response.data.date_on_sale_from ? dayjs(response.data.date_on_sale_from).utc() : null);
          setDateOnSaleTo(response.data.date_on_sale_to ? dayjs(response.data.date_on_sale_to).utc() : null);
        } catch (error) {
          console.error('Error fetching product details from WooCommerce:', error);
        }
      }
    };

    fetchProductDetails();
  }, [sku]);

  useEffect(() => {
    if (price && salePrice) {
      const priceNum = parseFloat(price);
      const salePriceNum = parseFloat(salePrice);
      const discount = ((priceNum - salePriceNum) / priceNum) * 100;
      setDiscountPercentage(discount.toFixed(2));

      if (salePriceNum < priceNum * 0.75) {
        setShowAlert(true);
        setShowBlueAlert(false);
      } else if (salePriceNum < priceNum) {
        setShowAlert(false);
        setShowBlueAlert(true);
      } else {
        setShowAlert(false);
        setShowBlueAlert(false);
      }
    }
  }, [price, salePrice]);

  const handleSave = async () => {
    const id = extractIDFromSKU(sku);
    if (id) {
      try {
        await axios.put(`https://www.luxuraelite.ro/wp-json/wc/v3/products/${id}`, {
          regular_price: price,
          sale_price: salePrice,
          date_on_sale_from: dateOnSaleFrom ? dateOnSaleFrom.utc().toISOString() : null,
          date_on_sale_to: dateOnSaleTo ? dateOnSaleTo.utc().toISOString() : null
        }, {
          headers: {
            'Authorization': 'Basic ' + btoa('ck_a941824be557253d3765a1f6242e6d6a590da999:cs_6b0d98e4c442e707be9888cafff1fac17cdbf259')
          }
        });

        // Save the updated product details to the database
        await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/update-price', {
          sku: productDetails.sku,
          newPrice: salePrice, // Send salePrice as newPrice
          oldPrice: productDetails.regular_price,
          productName: productDetails.name,
          journalNumber: journalNumber // Use journalNumber from props
        });

        alert('Product details updated successfully!');
      } catch (error) {
        console.error('Error updating product details:', error);
        alert('Failed to update product details.');
      }
    }
  };

  return (
    <Paper sx={{ p: 4, m: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h5" sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center', color: '#1976d2' }}>
        Actualizare preț Online (LIVE)
      </Typography>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Preț"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{ style: { color: '#1976d2' } }}
              InputProps={{
                style: {
                  borderRadius: '8px',
                  color: '#1976d2',
                },
              }}
            />
            {showAlert && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                Atenție, prețul nou de vânzare este mai mic cu peste 25% din prețul inițial. Scade reducerea.
              </Alert>
            )}
            {showBlueAlert && (
              <Alert severity="info" sx={{ mt: 2 }}>
                Reducerea este conformă cu Politica de Reduceri de: {discountPercentage}%
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Preț Reducere"
              value={salePrice}
              onChange={(e) => setSalePrice(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              InputLabelProps={{ style: { color: '#1976d2' } }}
              InputProps={{
                style: {
                  borderRadius: '8px',
                  color: '#1976d2',
                },
              }}
            />
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Data Început Reducere"
                    value={dateOnSaleFrom}
                    onChange={(newValue) => setDateOnSaleFrom(dayjs(newValue).utc())}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" variant="outlined" />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Data Sfârșit Reducere"
                    value={dateOnSaleTo}
                    onChange={(newValue) => setDateOnSaleTo(dayjs(newValue).utc())}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" variant="outlined" />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSave} 
            sx={{ py: 1, px: 4, fontSize: '1rem', fontWeight: 'bold', borderRadius: '8px' }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default WooCommerceProductDetails;
