import React from 'react';
import { Card, CardContent, Typography, Box, LinearProgress } from '@mui/material';

const ArticleCountCard = ({ title, count, maxCount }) => {
  const progressBarValue = (count / Math.max(count, maxCount)) * 100;
  const maxProgressBarValue = (maxCount / Math.max(count, maxCount)) * 100;
  const excess = count > maxCount ? ((count - maxCount) / maxCount) * 100 : 0;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{count} articole</Typography>
        {count < maxCount && (
          <Typography variant="body2">
            Fetelor mai trebuie să aduceți: {(maxCount - count)} articole
          </Typography>
        )}
        <Box sx={{ width: '100%', mt: 2, position: 'relative' }}>
          <LinearProgress
            variant="determinate"
            value={progressBarValue}
            sx={{
              height: 10,
              bgcolor: 'lightgray',
              '& .MuiLinearProgress-bar': {
                bgcolor: count >= maxCount ? 'green' : 'tomato',
              },
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              left: `${maxProgressBarValue}%`,
              top: 0,
              height: '100%',
              borderLeft: '2px solid black',
            }}
          />
          {count > maxCount && (
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                left: `${maxProgressBarValue}%`,
                top: -20,
                transform: 'translateX(-50%)',
                bgcolor: 'white',
                p: '2px 4px',
                borderRadius: '4px',
                boxShadow: 1,
              }}
            >
              {excess.toFixed(2)}% over
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ArticleCountCard;
