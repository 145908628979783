import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

function Page3() {
    const [feedback, setFeedback] = useState({ open: false, message: '', severity: 'success' });
    const [processing, setProcessing] = useState(false);
    const [uploading, setUploading] = useState(false);

    const apiBaseUrl = 'https://server-9p4hx.kinsta.app';
    //const apiBaseUrl = 'http://localhost:5099'; 

    useEffect(() => {
        axios.get(`${apiBaseUrl}/ping`)
            .then(response => {
                console.log('Server status: Running');
            })
            .catch(error => {
                console.error('Server status: Not running', error);
                setFeedback({
                    open: true,
                    message: 'Serverul nu rulează. Vă rugăm să verificați conexiunea sau să îl porniți manual.',
                    severity: 'error'
                });
            });

        const interval = setInterval(() => {
            if (processing) {
                axios.get(`${apiBaseUrl}/status`)
                    .then(response => {
                        if (response.data.status === 'done') {
                            setFeedback({
                                open: true,
                                message: response.data.message || 'Procesarea s-a terminat cu succes.',
                                severity: 'success'
                            });
                            setProcessing(false);
                        }
                    })
                    .catch(error => {
                        setFeedback({
                            open: true,
                            message: `Eroare la verificarea statusului: ${error.message}`,
                            severity: 'error'
                        });
                        setProcessing(false);
                    });
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [processing, apiBaseUrl]);

    const handleRunScript = async () => {
        setFeedback({ ...feedback, open: false });
        console.log(`Running script at: ${apiBaseUrl}/run-python-overview`);
        try {
            const response = await axios.get(`${apiBaseUrl}/run-python-overview`);
            setProcessing(true);
            setFeedback({ open: true, message: 'Scriptul este în curs de execuție...', severity: 'info' });
        } catch (error) {
            console.error('Error running script:', error);
            setFeedback({ open: true, message: `Eroare la rularea scriptului: ${error.message}`, severity: 'error' });
            setProcessing(false);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        console.log('Uploading file:', file);
        if (file) {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);

            console.log(`Uploading to: ${apiBaseUrl}/upload-excel`);
            try {
                const response = await axios.post(`${apiBaseUrl}/upload-excel`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setFeedback({ open: true, message: 'Fișierul Excel a fost încărcat cu succes.', severity: 'success' });
            } catch (error) {
                console.error('Error uploading file:', error);
                setFeedback({ open: true, message: `Eroare la încărcarea fișierului: ${error.message}`, severity: 'error' });
            } finally {
                setUploading(false);
            }
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFeedback({ ...feedback, open: false });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">
                        Backoffice - JIRA Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>

            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
                <Card style={{ maxWidth: '600px', margin: 'auto' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Rulare script pentru JIRA Dashboard
                        </Typography>
                        <Button variant="contained" color="primary" onClick={handleRunScript} disabled={processing}>
                            {processing ? 'Rulează...' : 'Rulează Scriptul Python'}
                        </Button>
                        {processing && <CircularProgress size={24} style={{ marginLeft: '10px' }} />}
                        <input
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            onChange={handleFileUpload}
                        />
                        <label htmlFor="file-upload">
                            <Button variant="contained" color="secondary" component="span" style={{ marginLeft: '10px' }} disabled={uploading}>
                                Încarcă fișier Excel
                            </Button>
                        </label>
                        {uploading && <CircularProgress size={24} style={{ marginLeft: '10px' }} />}
                    </CardContent>
                </Card>
            </div>

            <Snackbar open={feedback.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={feedback.severity}>
                    {feedback.message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Page3;
