import React, { useState, useEffect } from "react";
import ReceptionTable from "./ReceptionTable";
import { Container, Typography, Tabs, Tab, Box, Slider, MenuItem, Select, FormControl, InputLabel, Grid, Paper, Chip } from "@mui/material";

function App() {
  const [data, setData] = useState([]); // Datele complete
  const [createdCandidates, setCreatedCandidates] = useState([]); // Candidati pentru tabul Creat
  const [activeTab, setActiveTab] = useState(0); // Folosim index pentru taburi
  const [selectedMonth, setSelectedMonth] = useState(''); // Luna selectată
  const [selectedYear, setSelectedYear] = useState(2024); // Anul selectat

  // State pentru filtrele bazate pe seria recepției
  const [selectedFilters, setSelectedFilters] = useState({
    receptieCN: false,
    receptieST: false,
    receptieVZR: false,
    receptieVD: false,
    receptieRST: false,
  });

  // Încarcă datele la montarea componentului
  useEffect(() => {
    loadReceptionData();
  }, []);

  // Funcție pentru încărcarea datelor din backend, inclusiv refresh-tasks
  const loadReceptionData = async () => {
    try {
      // Rulăm refresh-tasks înainte de încărcarea datelor
      await refreshTasks();

      const response = await fetch('https://server-9p4hx.kinsta.app/api/jurnale/reception-list'); // Endpoint pentru datele de recepții
      const receptionData = await response.json();
      console.log("Date primite din API:", receptionData);
      setData(receptionData); // Setează datele din backend în state
      logRelevantEntries(receptionData); // Filtrăm și setăm datele relevante în state-ul `createdCandidates`
    } catch (error) {
      console.error('Error loading reception data:', error);
    }
  };

  // Funcție pentru apelarea refresh-tasks
  const refreshTasks = async () => {
    try {
      const response = await fetch('https://server-9p4hx.kinsta.app/api/jurnale/refresh-tasks', {
        method: 'POST',
      });
      const result = await response.json();
      console.log('Răspuns de la refresh-tasks:', result);
    } catch (error) {
      console.error('Error refreshing tasks:', error);
    }
  };

  // Funcție pentru a loga toate produsele cu valori de 1 (convertit) în oricare dintre coloanele relevante
  const logRelevantEntries = (receptionData) => {
    const candidates = receptionData.filter((product) => {
      // Convertim valorile la număr dacă sunt stringuri
      const receptieCN = parseInt(product.receptieCN, 10) || 0;
      const receptieST = parseInt(product.receptieST, 10) || 0;
      const receptieVZR = parseInt(product.receptieVZR, 10) || 0;
      const receptieVD = parseInt(product.receptieVD, 10) || 0;
      const receptieRST = parseInt(product.receptieRST, 10) || 0;

      // Returnăm true dacă oricare din aceste coloane are valoarea 1
      return (
        receptieCN === 1 || 
        receptieST === 1 || 
        receptieVZR === 1 || 
        receptieVD === 1 || 
        receptieRST === 1
      );
    });

    setCreatedCandidates(candidates);
    console.log("Toți candidații relevanți pentru tabul Creat:", candidates);
  };

  // Funcție pentru schimbarea taburilor
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Funcție pentru filtrarea datelor după lună și an
  const filterDataByDate = (product) => {
    if (!selectedMonth && !selectedYear) return true; // Dacă nu este selectată nici luna, nici anul, nu filtrăm

    const productDate = new Date(product.dataReceptie);
    const productYear = productDate.getFullYear();
    const productMonth = String(productDate.getMonth() + 1).padStart(2, '0'); // Luna este indexată de la 0, deci adăugăm 1

    if (selectedYear && selectedMonth) {
      return productYear === selectedYear && productMonth === selectedMonth;
    } else if (selectedYear) {
      return productYear === selectedYear;
    } else if (selectedMonth) {
      return productMonth === selectedMonth;
    }
    return true;
  };

  // Funcție pentru filtrarea și afișarea datelor în funcție de tab
  const getFilteredData = () => {
    if (activeTab === 1) {
      // Tabul "Creat" - afișăm doar candidații deja filtrați
      return createdCandidates.filter(filterDataByDate);
    } else {
      // Tabul "Necreat" - afișăm doar ce are toate valorile la 0
      return data.filter((product) => {
        const receptieCN = parseInt(product.receptieCN, 10) || 0;
        const receptieST = parseInt(product.receptieST, 10) || 0;
        const receptieVZR = parseInt(product.receptieVZR, 10) || 0;
        const receptieVD = parseInt(product.receptieVD, 10) || 0;
        const receptieRST = parseInt(product.receptieRST, 10) || 0;

        // Afișăm doar produsele care au toate valorile la 0
        return (
          receptieCN === 0 && 
          receptieST === 0 && 
          receptieVZR === 0 && 
          receptieVD === 0 && 
          receptieRST === 0
        );
      }).filter(filterDataByDate);
    }
  };

  // Funcția de actualizare a stării unei recepții
  const handleCheckboxChange = async (productId, receptionType, isChecked) => {
    const updatedData = data.map((product) => {
      if (product.productId === productId && product.serieReceptie === receptionType) {
        // Actualizăm valoarea în funcție de bifare
        const updatedProduct = { ...product, [receptionType]: isChecked ? 1 : 0 };

        // Trimitere la backend pentru actualizare
        updateReceptionInDB(productId, receptionType, isChecked);

        return updatedProduct; // Returnăm produsul actualizat
      }
      return product;
    });

    setData(updatedData); // Actualizăm state-ul cu noile date
    
    // Dacă debifăm, mutăm produsul în "Necreat"
    if (!isChecked) {
      logRelevantEntries(updatedData); // Actualizăm candidații pentru tabul "Creat"
    }
    console.log('Date actualizate după schimbarea checkbox-ului:', updatedData);
  };

  // Funcție pentru actualizarea datelor în baza de date
  const updateReceptionInDB = async (productId, receptionType, isChecked) => {
    try {
      const response = await fetch(`https://server-9p4hx.kinsta.app/api/jurnale/update-reception/${productId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ receptionType, value: isChecked ? 1 : 0 }),
      });

      const result = await response.json();
      console.log('Răspuns de la backend:', result);
    } catch (error) {
      console.error('Eroare la actualizarea în baza de date:', error);
    }
  };

  // Funcție pentru gestionarea filtrelor Chip
  const handleFilterChange = (filterKey) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey], // Activăm/dezactivăm filtrul
    }));
  };

  // Aplicăm filtrele din Chip-urile selectate
  const applyChipFilters = (data) => {
    return data.filter((product) => {
      const matchesFilters = [
        selectedFilters.receptieCN ? product.serieReceptie.includes("receptieCN") : true,
        selectedFilters.receptieST ? product.serieReceptie.includes("receptieST") : true,
        selectedFilters.receptieVZR ? product.serieReceptie.includes("receptieVZR") : true,
        selectedFilters.receptieVD ? product.serieReceptie.includes("receptieVD") : true,
        selectedFilters.receptieRST ? product.serieReceptie.includes("receptieRST") : true,
      ];
      return matchesFilters.every(Boolean);
    });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Recepții Produse
      </Typography>

      {/* Taburi */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Necreat" />
        <Tab label="Creat" />
      </Tabs>

      {/* Filtrele Chip sunt disponibile pentru ambele taburi */}
      <Box mt={2} mb={3} component={Paper} elevation={2} padding={2} borderRadius={2}>
        <Grid container spacing={2} justifyContent="center">
          <Chip
            label="ReceptieCN"
            color={selectedFilters.receptieCN ? "primary" : "default"}
            onClick={() => handleFilterChange("receptieCN")}
            style={{ backgroundColor: selectedFilters.receptieCN ? "#2196f3" : "default", margin: "4px" }}
          />
          <Chip
            label="ReceptieST"
            color={selectedFilters.receptieST ? "secondary" : "default"}
            onClick={() => handleFilterChange("receptieST")}
            style={{ backgroundColor: selectedFilters.receptieST ? "#f50057" : "default", margin: "4px" }}
          />
          <Chip
            label="ReceptieVZR"
            color={selectedFilters.receptieVZR ? "success" : "default"}
            onClick={() => handleFilterChange("receptieVZR")}
            style={{ backgroundColor: selectedFilters.receptieVZR ? "#4caf50" : "default", margin: "4px" }}
          />
          <Chip
            label="ReceptieVD"
            color={selectedFilters.receptieVD ? "warning" : "default"}
            onClick={() => handleFilterChange("receptieVD")}
            style={{ backgroundColor: selectedFilters.receptieVD ? "#ff9800" : "default", margin: "4px" }}
          />
          <Chip
            label="ReceptieRST"
            color={selectedFilters.receptieRST ? "info" : "default"}
            onClick={() => handleFilterChange("receptieRST")}
            style={{ backgroundColor: selectedFilters.receptieRST ? "#00bcd4" : "default", margin: "4px" }}
          />
        </Grid>
      </Box>

      {/* Filtre pentru an și lună */}
      {activeTab === 1 && (
        <Box mt={2} mb={3} component={Paper} elevation={2} padding={2} borderRadius={2}>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <Typography id="year-slider" gutterBottom>
                Selectează anul
              </Typography>
              <Slider
                value={selectedYear}
                onChange={(e, newValue) => setSelectedYear(newValue)}
                aria-labelledby="year-slider"
                min={2020}
                max={2025}
                step={1}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="month-select-label">Selectează luna</InputLabel>
                <Select
                  labelId="month-select-label"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  label="Selectează luna"
                >
                  <MenuItem value="">Toate lunile</MenuItem>
                  <MenuItem value="01">Ianuarie</MenuItem>
                  <MenuItem value="02">Februarie</MenuItem>
                  <MenuItem value="03">Martie</MenuItem>
                  <MenuItem value="04">Aprilie</MenuItem>
                  <MenuItem value="05">Mai</MenuItem>
                  <MenuItem value="06">Iunie</MenuItem>
                  <MenuItem value="07">Iulie</MenuItem>
                  <MenuItem value="08">August</MenuItem>
                  <MenuItem value="09">Septembrie</MenuItem>
                  <MenuItem value="10">Octombrie</MenuItem>
                  <MenuItem value="11">Noiembrie</MenuItem>
                  <MenuItem value="12">Decembrie</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box mt={3}>
        {/* Afișăm tabelul corespunzător tabului activ */}
        <ReceptionTable
          data={applyChipFilters(getFilteredData())} // Aplicăm filtrele Chip peste datele filtrate
          onCheckboxChange={handleCheckboxChange}
          isCreatTab={activeTab === 1} // Trimitem informația că suntem în tabul Creat
        />
      </Box>
    </Container>
  );
}

export default App;
