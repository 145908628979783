import React from 'react';
import { TextField, Button, Grid } from '@mui/material';

const DateFilter = ({ startDate, endDate, setStartDate, setEndDate, handleFilter }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <TextField
          label="Start Date"
          type="date"
          fullWidth
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          label="End Date"
          type="date"
          fullWidth
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} md={2} display="flex" alignItems="center">
        <Button variant="contained" color="primary" fullWidth onClick={handleFilter}>
          Filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateFilter;
