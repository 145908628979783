import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box, Grid, Typography, Paper } from '@mui/material';
import calculateCost from './calculateCostAnalysiSToc';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

const formatNumber = (number) => {
  return number ? number.toLocaleString('ro-RO', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }) : '';
};

const PurchasePriceCard = ({ avgPrice, pretIntrareRON, category }) => {
  const [costs, setCosts] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [difference, setDifference] = useState(0);
  const [icon, setIcon] = useState(null);
  const [color, setColor] = useState('green'); // Adăugăm un state pentru culoare

  useEffect(() => {
    const result = calculateCost(avgPrice, category);
    const { purchasePrice } = result;
    const percentage = ((pretIntrareRON - purchasePrice) / purchasePrice) * 100;
    setCosts(result);
    setPercentage(percentage.toFixed(2));
    setDifference((pretIntrareRON - purchasePrice).toFixed(2));
    if (pretIntrareRON > purchasePrice) {
      setIcon(<AddIcon color="error" />);
      setColor('red');
    } else {
      setIcon(<RemoveIcon color="success" />);
      setColor('green');
    }
  }, [avgPrice, pretIntrareRON, category]);

  return (
    <Paper sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CircularProgressbar
            value={Math.abs(percentage)}
            text={`${percentage > 0 ? '+' : ''}${percentage}%`}
            styles={buildStyles({
              textColor: color,
              pathColor: color
            })}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6">Preț achiziție</Typography>
          <Typography variant="body1">
            {icon} {difference !== undefined ? formatNumber(Number(difference)) : 'N/A'} RON
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Maxim CN: {costs.purchasePrice !== undefined ? formatNumber(Number(costs.purchasePrice)) : 'N/A'} RON
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PurchasePriceCard;
