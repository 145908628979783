import React, { createContext, useContext, useState } from 'react';

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('publish');
    const [rotationFilter, setRotationFilter] = useState('');
    const [skuSearch, setSkuSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(80);
    const [totalProducts, setTotalProducts] = useState(0);

    return (
        <ProductContext.Provider value={{
            products,
            setProducts,
            filteredProducts,
            setFilteredProducts,
            statuses,
            setStatuses,
            selectedStatus,
            setSelectedStatus,
            rotationFilter,
            setRotationFilter,
            skuSearch,
            setSkuSearch,
            page,
            setPage,
            rowsPerPage,
            setRowsPerPage,
            totalProducts,
            setTotalProducts
        }}>
            {children}
        </ProductContext.Provider>
    );
};
