function calculateCost(avgPrice, accessories = [], category) {
  let cheltuieliPerProdus, tax, purchasePrice, purchasePriceAfterDiscount;

  const accessoryCosts = {
    'cutie - 150 RON': 150,
    'dusbag - 40 RON': 40,
    'factura - 100 lei': 100,
    'certificat - 500 lei': 500,
    'poze': category === 'Genti' || category === 'Incaltaminte' ? 85 : 40,
    'marketing': category === 'Genti' ? 150 : 70
  };

  const calculateBand = (price) => {
    if (price <= 2000) {
      cheltuieliPerProdus = 415.14 * (price / 2000);
      tax = 415.14;
    } else if (price <= 6000) {
      cheltuieliPerProdus = 1287.38 * (price / 6000);
      tax = 1287.38;
    } else if (price <= 15000) {
      cheltuieliPerProdus = 3253.80 * (price / 15000);
      tax = 3253.80;
    } else {
      cheltuieliPerProdus = 3451 * (price / 15000);
      tax = 3451;
    }
  };

  console.log(`Avg Price: ${avgPrice}`);
  // Initial calculation test mac
  calculateBand(avgPrice);
  console.log(`Cheltuieli Per Produs: ${cheltuieliPerProdus}`);
  purchasePrice = avgPrice - cheltuieliPerProdus; // purchasePrice is avgPrice minus cheltuieliPerProdus
  console.log(`Purchase Price before accessories: ${purchasePrice}`);

  // Subtract accessory costs if unchecked
  const allAccessories = Object.keys(accessoryCosts);
  allAccessories.forEach(accessory => {
    if (!accessories.includes(accessory)) {
      purchasePrice -= accessoryCosts[accessory];
    }
  });

  purchasePriceAfterDiscount = purchasePrice * 0.97; // Scădem 3%
  console.log(`Final Purchase Price after 3% discount: ${purchasePriceAfterDiscount}`);
  return {
    purchasePrice: Number(purchasePriceAfterDiscount),
    cheltuieliPerProdus: Number(cheltuieliPerProdus),
    tax: Number(tax)
  };
}

export default calculateCost;
